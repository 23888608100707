import React from "react";
import Input from "../Input";
import Icon from "components/Icon";

const SearchInputText = (props) => {
  return (
    <div className="search-input-text">
      <Icon icon="loupe" />
      <Input type="text" placeholder="RECHERCHER" {...props} />
    </div>
  );
};

export default SearchInputText;
