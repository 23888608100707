import * as yup from "yup";

const validationSchema = yup.object({
  agencyName: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  agencyCity: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  represents: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
});

export default validationSchema;
