import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Text = ({
  children,
  color,
  fontWeight,
  size,
  align,
  font,
  uppercase,
  fontStyle,
  lineHeight,
  marginBottom,
  appearance,
}) => {
  return (
    <div
      className={classNames("text", {
        [`text--color-${color}`]: color,
        [`text--${fontWeight}`]: fontWeight,
        [`text--size-${size}`]: size,
        [`text--align-${align}`]: align,
        [`text--font-${font}`]: font,
        [`text--uppercase`]: uppercase,
        [`text--${fontStyle}`]: fontStyle,
        [`text--line-height-${lineHeight}`]: lineHeight,
        [`text--margin-bottom-${marginBottom}`]: marginBottom,
        [`text--appearance-${appearance}`]: appearance,
      })}
    >
      {children}
    </div>
  );
};

Text.defaultProps = {
  color: "default",
  appearance: "default",
  fontWeight: "regular",
  size: "regular",
  align: "left",
  font: "roboto",
  uppercase: false,
  lineHeight: "default",
  marginBottom: "default",
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "disabled",
    "white",
    "dark",
    "grey",
  ]),
  fontWeight: PropTypes.oneOf(["light", "regular", "semi-bold", "bold"]),
  size: PropTypes.oneOf([
    "regular",
    "mini",
    "small",
    "medium",
    "large",
    "very-large",
    "extra-large",
  ]),
  fontStyle: PropTypes.oneOf(["italic"]),
  align: PropTypes.oneOf(["left", "right", "center", "justify"]),
  font: PropTypes.oneOf(["roboto", "roboto-slab"]),
  lineHeight: PropTypes.oneOf(["default", "medium", "large"]),
  marginBottom: PropTypes.oneOf(["default", "medium", "large"]),
  uppercase: PropTypes.bool,
  appearance: PropTypes.oneOf(["default", "input"]),
};

export default Text;
