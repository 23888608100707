import React from "react";
import Select from "components/Form/Select";
import {
  NUMBER_OF_LOTS,
  YEARS_OF_CONSTRUCTION,
} from "components/Sale/SaleMultistep/saleConstants";
import InputRadio from "components/Form/Input/InputRadio";
import RadioGroup from "components/RadioGroup";

const {
  UNDER_20,
  "20_40": BETWEEN_20_AND_40,
  "40_60": BETWEEN_40_AND_60,
  MORE_THAN_60,
} = NUMBER_OF_LOTS;

const {
  FIFTIES,
  SEVENTIES,
  HEIGHTYS,
  "2000S": TWO_THOUSANDS,
} = YEARS_OF_CONSTRUCTION;

const CoOwnership = ({ isCoOwnership }) => {
  return (
    <div className="co-ownership">
      <RadioGroup>
        <InputRadio name="coOwnership" value={0} label="Non" />
        <InputRadio name="coOwnership" value={1} label="Oui" />
      </RadioGroup>

      {isCoOwnership && (
        <>
          <Select
            name="numberOfLots"
            options={[
              UNDER_20,
              BETWEEN_20_AND_40,
              BETWEEN_40_AND_60,
              MORE_THAN_60,
            ]}
            placeholder="Nombre de lots"
          />
          <Select
            name="year"
            options={[FIFTIES, SEVENTIES, HEIGHTYS, TWO_THOUSANDS]}
            placeholder="Année construction"
          />
        </>
      )}
    </div>
  );
};

export default CoOwnership;
