import React from "react";
import { InputTextArea, InputDate } from "components/Form/Input";
import RadioGroup from "components/RadioGroup";
import InputRadio from "components/Form/Input/InputRadio";
import Text from "components/Text";

const KeyDateInput = ({ name, label }) => {
  return (
    <div className="key-date-input">
      <Text>{label}</Text>
      <InputDate name={name} placeholder="Choisir date" appearance="grey" />
    </div>
  );
};

const SuspensiveConditions = () => {
  return (
    <InputTextArea
      name="suspensiveConditions"
      placeholder="Conditions..."
      appearance="grey"
    />
  );
};

const KeyDates = ({ isBankLoan }) => {
  return (
    <>
      {isBankLoan && (
        <>
          <KeyDateInput
            name="applicationDeadline"
            label="Date limite dépôt dossier de prêt"
          />
          <KeyDateInput
            name="loanOfferDeadline"
            label="Date limite réception offre de prêt"
          />
        </>
      )}
      <KeyDateInput
        name="deedSigningDeadline"
        label="Date limite signature de l'acte"
      />
    </>
  );
};

const KeyDatesInputs = ({ isBankLoan, hasSuspensiveConditions }) => {
  return (
    <>
      <div className="key-dates-form__key-dates">
        <KeyDates isBankLoan={isBankLoan} />
      </div>

      <RadioGroup
        label={
          <Text color="primary" font="roboto-slab">
            Y a t-il des conditions suspensives ?
          </Text>
        }
      >
        <InputRadio name="hasSuspensiveConditions" value={0} label="Non" />
        <InputRadio name="hasSuspensiveConditions" value={1} label="Oui" />
      </RadioGroup>

      {Boolean(Number(hasSuspensiveConditions)) && <SuspensiveConditions />}
    </>
  );
};

export { KeyDatesInputs, KeyDates, SuspensiveConditions };
