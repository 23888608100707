import * as yup from "yup";

const makeClientAndNotaryValidationObject = (
  { client, notary },
  index,
  label
) => {
  return {
    [client]:
      index === 0
        ? yup.string().required(`Veuillez renseigner le premier ${label}.`)
        : yup.string(),
    [notary]: yup.string().when(client, {
      is: (client) => client !== "" || (client !== "" && index === 0),
      then: yup
        .string()
        .required("Un notaire doit être associé à chaque client."),
    }),
  };
};

const validationSchema = ({ sellers = [], buyers = [] }) => {
  const sellersValidation = sellers.reduce((acc, seller, index) => {
    return {
      ...acc,
      ...makeClientAndNotaryValidationObject(seller, index, "vendeur"),
    };
  }, {});

  const buyersValidation = buyers.reduce((acc, buyer, index) => {
    return {
      ...acc,
      ...makeClientAndNotaryValidationObject(buyer, index, "acquéreur"),
    };
  }, {});

  return yup.object({
    ...sellersValidation,
    ...buyersValidation,
  });
};

export default validationSchema;
