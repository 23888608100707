import React from "react";
import ForgotPasswordForm from "components/Login/ForgotPasswordForm";
import LoginHeader from "components/Login/LoginHeader";

const ForgotPassword = () => {
  return (
    <div className="forgot-password">
      <LoginHeader />
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
