import React from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import DashboardFilters from "./DashboardFilters";
import DraftSaleCompletedDialog from "./DraftSaleCompletedDialog";
import TrialPeriodDialog from "./TrialPeriodDialog";
import fetchUrl from "api/fetchUrl";
import { getSales, getPeoples } from "api/requests";
import { extractDataFromLdJsonResponse, isLastPage } from "utils/data";
import { makeSendMail, TEMPLATE_REFERENCES } from "utils/email";
import Form from "components/Form";
import { RECIPIENT_TYPES } from "components/Sale/SaleMultistep/saleConstants";
import GenericLayout from "components/GenericLayout";
import SaleCard from "components/Sale/SaleCard";
import Spinner from "components/Spinner";
import Button from "components/Button";
import { LinkButton } from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import MailModule from "components/MailModule";
import { useAuth } from "components/Hooks/useAuth";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import Statistics from "pages/Statistics";
import { makeFilters, makeHeaderTitle } from "utils/data";
import { getTrialPeriodRemainingDays } from "utils/user";
import { getIsUserAlreadyLoggedIn } from "utils/cookie";
import { useBusinessOfferDialog } from "components/Hooks/useBusinessOfferDialog";
import Text from "components/Text";

const ITEMS_PER_PAGE = window.screen.width > 767 ? 8 : 2;

const Dashboard = ({ history }) => {
  const { mailModuleState, setMailModuleOpen } = useSaleModules();
  const { user, logout, toggleRefetchUser } = useAuth();
  const { businessOfferDialogOpen, setBusinessOfferDialogOpen } =
    useBusinessOfferDialog();
  const [sales, setSales] = React.useState({ data: [], pagination: {} });
  const [peoples, setPeoples] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [toggleRefetch, setToggleRefetch] = React.useState(false);
  const [newSaleDialogOpen, setNewSaleDialogOpen] = React.useState(false);
  const [trialPeriodDialogOpen, setTrialPeriodDialogOpen] = React.useState(
    user.people.agency.subscription.isTrial &&
      getTrialPeriodRemainingDays(user) <= 7 &&
      !getIsUserAlreadyLoggedIn()
  );
  const [newCompletedSale, setNewCompletedSale] = React.useState(null);
  const [areFiltersCollapsed, setFiltersCollapsed] = React.useState(false);
  const [headerTitle, setHeaderTitle] = React.useState("En cours");
  const location = useLocation();

  const fetchMoreSales = () => {
    if (isLastPage(sales.pagination)) {
      return false;
    }
    const retrieveFetchMore = () => {
      return fetchUrl(getSales(sales.pagination.next, ITEMS_PER_PAGE, filters));
    };
    retrieveFetchMore()
      .then((response) => {
        const extractedData = extractDataFromLdJsonResponse(response);
        setSales({
          data: [...sales.data, ...extractedData.data],
          pagination: extractedData.pagination,
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    toggleRefetchUser();
  }, [toggleRefetchUser]);

  React.useEffect(() => {
    if (
      !businessOfferDialogOpen &&
      !user.hasCredits &&
      user.hasBasicSubscription &&
      !getIsUserAlreadyLoggedIn()
    ) {
      setBusinessOfferDialogOpen(true);
    }
  }, [businessOfferDialogOpen, setBusinessOfferDialogOpen, user]);

  React.useEffect(() => {
    const newSale = history.location.state?.newSale;

    if (newSale) {
      setNewSaleDialogOpen(true);
      setNewCompletedSale(newSale);
      history.replace();
    }
  }, [history]);

  React.useEffect(() => {
    const retrieveData = () => {
      return Promise.all([
        fetchUrl(getSales(1, ITEMS_PER_PAGE, filters)),
        fetchUrl(getPeoples()),
      ]);
    };

    retrieveData()
      .then((response) => {
        const [sales, peoples] = response;

        const extractedSaleData = extractDataFromLdJsonResponse(sales);
        const salesNumber = extractedSaleData?.pagination?.totalItems || 0;
        setSales(extractedSaleData);
        setPeoples(peoples);
        setHeaderTitle(makeHeaderTitle(filters, salesNumber));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, [filters, toggleRefetch]);

  React.useEffect(() => {
    /* 
      Big hack to soft refresh the filters when we history.push on this same route 
      see : https://github.com/remix-run/react-router/issues/7416 for the suggestion 
      issue : https://projets.occitech.fr/issues/17455
    */
    setFilters([]);
  }, [location.key]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  const handleSubmitMail = ({
    subject,
    content,
    attachments,
    recipients,
    sale,
    selectedTemplateRef,
    doneCallback,
  }) => {
    makeSendMail({
      subject,
      content,
      attachments,
      recipients,
      selectedTemplateRef,
      sale,
      onSuccess: () => {
        doneCallback();
        setMailModuleOpen({ open: false });
      },
      onError: () => {
        doneCallback();
      },
    });
  };

  const updateFilters = (newFilter) => {
    setFilters(makeFilters(filters, newFilter));
  };

  return (
    <>
      <GenericLayout
        headerIcon="document"
        headerTitle="Tableau de bord"
        headerContent={
          <DashboardHeader
            salesNumber={sales.pagination.totalItems}
            headerTitle={headerTitle}
          />
        }
      >
        <div className="dashboard">
          <Form
            className={classNames("dashboard-filters", {
              "dashboard-filters--collapsed": areFiltersCollapsed,
            })}
            onSubmit={() => {}}
            mode="all"
          >
            <DashboardFilters
              peoples={peoples}
              setFilters={setFilters}
              filters={filters}
              setIsCollapsed={setFiltersCollapsed}
              updateFilters={(value) => updateFilters(value)}
              user={user}
            />
          </Form>
          {sales.data.length > 0 ? (
            <>
              <div className="dashboard__sales">
                {user.hasBasicSubscription && !user.hasCredits && (
                  <div className="dashboard__business-offer">
                    <Text color="dark" align="center" size="large">
                      Vous ne disposez plus
                      <br />
                      de crédits ventes
                    </Text>
                    <LinkButton
                      appearance="primary"
                      to={{ pathname: "https://www.elio-app.com/tarifs" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ajouter des ventes
                    </LinkButton>
                  </div>
                )}
                {sales.data.map((sale) => (
                  <div
                    className="dashboard__sale"
                    key={`dashboard-sale-${sale.id}`}
                  >
                    <SaleCard
                      sale={sale}
                      setToggleRefetch={setToggleRefetch}
                      toggleRefetch={toggleRefetch}
                    />
                  </div>
                ))}
              </div>
              {!isLastPage(sales.pagination) && (
                <div className="dashboard__fetch-more">
                  <Button appearance="ghost" onClick={() => fetchMoreSales()}>
                    AFFICHER PLUS DE VENTES ...
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="dashboard__no-sales">Aucune vente disponible</div>
          )}
        </div>
      </GenericLayout>
      <Statistics statCardsOnly={true} />

      <DraftSaleCompletedDialog
        newSaleDialogOpen={newSaleDialogOpen}
        setNewSaleDialogOpen={setNewSaleDialogOpen}
        setMailModuleOpen={setMailModuleOpen}
      />
      <TrialPeriodDialog
        trialPeriodDialogOpen={trialPeriodDialogOpen}
        setTrialPeriodDialogOpen={setTrialPeriodDialogOpen}
        user={user}
        logout={logout}
      />
      {newCompletedSale && (
        <MailModule
          title="Envoyer un mail au notaire"
          open={mailModuleState.open}
          handleClose={() => setMailModuleOpen({ open: false })}
          showHistory={false}
          handleSubmit={handleSubmitMail}
          sale={newCompletedSale}
          config={{
            template: TEMPLATE_REFERENCES["newTransaction"].value,
            recipients: [
              RECIPIENT_TYPES.BUYER_NOTARY,
              RECIPIENT_TYPES.SELLER_NOTARY,
            ],
            attachments: [{ name: "vente.pdf", isSalePDF: true }],
          }}
        />
      )}
    </>
  );
};
export default Dashboard;
