import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Icon from "components/Icon";
import Button from "components/Button";
import Text from "components/Text";
import Form from "components/Form";

const DeleteUserDialog = ({ user, open, setOpen, handleSubmit }) => {
  if (
    !user ||
    (user &&
      Object.keys(user).length === 0 &&
      Object.getPrototypeOf(user) === Object.prototype)
  ) {
    return null;
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} handleClose={handleClose} className="user-crud-dialog">
      <DialogTitle>
        <Icon icon="trash" size={"big"} />
        <Text font="roboto-slab" fontWeight="light">
          Suppression d'un utilisateur
        </Text>
      </DialogTitle>
      <DialogContent>
        <Text align="center" size="small">
          <p>
            Vous êtes sur le point de supprimer l'utilisateur
            <br />
            <strong>
              {user.firstname} {user.lastname}
            </strong>
          </p>
          <br />
          <p>Cette action est définitive.</p>
          <br />
        </Text>
      </DialogContent>
      <Form defaultValues={user} onSubmit={() => handleSubmit(user?.user?.id)}>
        <DialogActions>
          <Button
            fullWidth
            appearance="ghost"
            onClick={handleClose}
            uppercase={true}
          >
            Annuler
          </Button>
          <Button fullWidth type="submit" appearance="primary" uppercase={true}>
            Valider
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

DeleteUserDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default DeleteUserDialog;
