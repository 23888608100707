import React from "react";
import SkateVideo from "media/videos/login-video-1.mp4";
import LegsUpVideo from "media/videos/login-video-2.mp4";
import PencilVideo from "media/videos/login-video-3.mp4";
import BusyVideo from "media/videos/login-video-4.mp4";
import CoffeeVideo from "media/videos/login-video-5.mp4";
import Icon from "components/Icon";
import Logo from "components/Logo";

const VIDEOS = [SkateVideo, LegsUpVideo, PencilVideo, BusyVideo, CoffeeVideo];

const getRandomVideoComponent = () =>
  VIDEOS[Math.floor(Math.random() * VIDEOS.length)];

const LoginHeader = () => {
  const Video = getRandomVideoComponent();
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      videoElement.play();
    }
  }, [videoRef]);

  return (
    <div className="login-header">
      <div className="login-header__video-container">
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          src={Video}
          controls={false}
          type="video/mp4"
          alt="login-video"
        />
      </div>
      <div className="login-header__gradient">
        <Logo />
        <div className="title">ANTI BURN-OUT APP</div>
        <Icon icon="wave" size="medium" />
      </div>
    </div>
  );
};

export default LoginHeader;
