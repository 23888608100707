import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { InputCheckbox, InputText } from "components/Form/Input";
import Icon from "components/Icon";
import IconButton from "components/Button/IconButton";
import Button from "components/Button";

const CheckboxLabel = ({ isChecked, label }) => {
  return (
    <div className="sale-process-checklist__checkbox-label">
      <Icon icon="paper" color={isChecked ? "primary" : "tertiary"} />
      {label}
    </div>
  );
};

const ChecklistRow = ({
  checklistItem,
  deleteItemFromChecklist,
  isMandatory,
}) => {
  const { getValues } = useFormContext();

  const checklistItemKey = checklistItem.value;

  const isChecked = getValues([checklistItemKey])[0];

  const handleDelete = () => {
    deleteItemFromChecklist(checklistItemKey);
  };

  return (
    <div
      className={classNames("sale-process-checklist__row", {
        "sale-process-checklist__row--is-checked": isChecked,
      })}
    >
      <InputCheckbox
        name={checklistItemKey}
        label={
          <CheckboxLabel isChecked={isChecked} label={checklistItem.label} />
        }
      />
      {!isMandatory && (
        <IconButton
          onClick={handleDelete}
          icon="bin"
          iconColor="medium-grey"
          iconSize="small"
          size="auto"
          appearance="transparent"
        />
      )}
    </div>
  );
};

const SaleProcessCheckList = ({
  checklist,
  mandatoryChecklistItems,
  deleteItemFromChecklist,
  handleAddAdditionalChecklistItem,
  handleCheckAll,
  inputName,
  inputPlaceholder,
}) => {
  const { setValue } = useFormContext();

  return (
    <div className="sale-process-checklist">
      <div className="sale-process-checklist__check-all">
        <Button
          italic
          uppercase={false}
          appearance="naked"
          textSize="mini"
          onClick={handleCheckAll}
        >
          Tout cocher
        </Button>
      </div>

      {checklist.map((checklistItem) => {
        const isMandatory = mandatoryChecklistItems.includes(
          checklistItem.value
        );
        return (
          <ChecklistRow
            key={`row-${checklistItem.value}`}
            checklistItem={checklistItem}
            isMandatory={isMandatory}
            deleteItemFromChecklist={deleteItemFromChecklist}
          />
        );
      })}
      <div className="sale-process-checklist__input">
        <InputCheckbox
          disabled
          label={<Icon icon="paper" color="dark-grey" />}
        />

        <InputText
          name={inputName}
          placeholder={inputPlaceholder}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === "Enter" || e.keyCode === 13) {
              const value = e.target.value;
              if (!value) {
                return;
              }
              handleAddAdditionalChecklistItem(value);
              setValue(inputName, "");
            }
          }}
        />
      </div>
    </div>
  );
};

export default SaleProcessCheckList;
