import React from "react";
import { Dialog, DialogTitle } from "components/Dialog";
import Icon from "components/Icon";
import Text from "components/Text";
import PropTypes from "prop-types";
import UserDialogForm from "../UserDialogForm";
import validationSchema from "../validationSchema";

const AddUserDialog = ({ open, setOpen, handleSubmit }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="user-crud-dialog add-user-dialog"
    >
      <DialogTitle>
        <Icon icon="user" color="primary" size="big" />
        <Text size="regular" font="roboto-slab" fontWeight="light">
          Ajout d'un nouvel utilisateur&nbsp;!
        </Text>
      </DialogTitle>
      <UserDialogForm
        user={null}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        validationSchema={validationSchema}
      />
    </Dialog>
  );
};

AddUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
export default AddUserDialog;
