import React from "react";
import PropTypes from "prop-types";
import { DialogActions, DialogContent } from "components/Dialog";
import { Grid } from "components/Grid";
import Select from "components/Form/Select";
import Input, { InputText } from "components/Form/Input";
import Button from "components/Button";
import Form from "components/Form";
import { PEOPLE_CIVILITIES } from "components/Sale/SaleMultistep/saleConstants";
import { DEFAULT_USER_STATUS_VALUE } from "utils/user";

const UserDialogForm = ({
  user,
  validationSchema,
  handleClose,
  handleSubmit,
}) => {
  const { MLLE, MME, M } = PEOPLE_CIVILITIES;
  const defaultValues = user
    ? {
        id: user.user?.id,
        civility: user.civility,
        firstname: user.firstname,
        lastname: user.lastname,
        mobile: user.mobile,
        email: user.user?.email,
        status: user.status,
      }
    : {
        civility: "",
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        status: DEFAULT_USER_STATUS_VALUE,
      };
  return (
    <Form
      key={defaultValues?.id || `new_user_${Math.floor(Math.random() * 100)}`}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values, user?.user?.id, user?.id);
      }}
    >
      <DialogContent>
        <Grid>
          <Select
            name="civility"
            options={[MLLE, MME, M]}
            placeholder="Civilité"
            appearance="grey"
          />
          <InputText name="lastname" placeholder="Nom" appearance="grey" />
        </Grid>
        <InputText name="firstname" placeholder="Prénom" appearance="grey" />
        <InputText name="mobile" placeholder="Téléphone" appearance="grey" />
        <InputText name="email" placeholder="Email" appearance="grey" />
        <Input type="hidden" name="status" />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          appearance="ghost"
          onClick={handleClose}
          uppercase={true}
        >
          Annuler
        </Button>
        <Button fullWidth type="submit" appearance="primary" uppercase={true}>
          Enregistrer
        </Button>
      </DialogActions>
    </Form>
  );
};

UserDialogForm.propTypes = {
  user: PropTypes.object,
  validationSchema: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UserDialogForm;
