import React from "react";
import Text from "components/Text";
import Icon from "components/Icon";
import Divider from "components/Divider";
import LabelledField from "components/LabelledField";
import Button from "components/Button";

const GeneralCard = ({ title, titleIcon, children }) => {
  return (
    <div className="general-card">
      <div className="general-card__title">
        {titleIcon && <Icon icon={titleIcon} size="medium" color="primary" />}
        <Text font="roboto-slab" color="primary" fontWeight="semi-bold">
          {title}
        </Text>
      </div>
      <Divider />
      <div className="general-card__content">{children}</div>
    </div>
  );
};

const General = ({ agency, setCurrentTab }) => {
  const [defaultValues, setDefaultValues] = React.useState({});

  React.useEffect(() => {
    if (agency) {
      const {
        name,
        tCardNumber,
        siret,
        address,
        zipcode,
        city,
        masterPeople: {
          firstname,
          lastname,
          mobile,
          user: { email },
        },
      } = agency;

      const formattedDefaultValues = {
        name,
        tCardNumber,
        siret,
        address,
        zipcode,
        city,
        firstname,
        lastname,
        mobile,
        email,
      };

      setDefaultValues(formattedDefaultValues);
    }
  }, [agency]);

  return (
    <div className="general">
      <div className="general__container">
        <GeneralCard title="Informations générales">
          <LabelledField label="Raison sociale">
            <Text appearance="input">{defaultValues.name}</Text>
          </LabelledField>
          <LabelledField label="N° carte T">
            <Text appearance="input">{defaultValues.tCardNumber}</Text>
          </LabelledField>
          <LabelledField label="N° SIRET">
            <Text appearance="input">{defaultValues.siret}</Text>
          </LabelledField>
          <LabelledField label="Adresse">
            <Text appearance="input">{defaultValues.address}</Text>
          </LabelledField>
          <LabelledField label="CP / Ville">
            <Text appearance="input">{defaultValues.zipcode}</Text>
            <Text appearance="input">{defaultValues.city}</Text>
          </LabelledField>
        </GeneralCard>

        <GeneralCard title="Compte principal" titleIcon="account">
          <LabelledField label="Responsable">
            <Text appearance="input">{defaultValues.firstname}</Text>
            <Text appearance="input">{defaultValues.lastname}</Text>
          </LabelledField>
          <LabelledField label="Téléphone">
            <Text appearance="input">{defaultValues.mobile}</Text>
          </LabelledField>
          <LabelledField label="E-mail">
            <Text appearance="input">{defaultValues.email}</Text>
          </LabelledField>
        </GeneralCard>
      </div>
      <Text align="center" font="roboto" color="grey" marginBottom="medium">
        Pour modifier vos informations,{" "}
        <Button appearance="link-primary" onClick={() => setCurrentTab(3)}>
          contactez-nous
        </Button>
      </Text>
    </div>
  );
};

export default General;
