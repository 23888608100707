import React from "react";

const Card = ({
  contact,
  isClientTab,
  setSelectedFormData,
  setSelectedEntityId,
  setIsDialogOpen,
}) => (
  <div
    className="contact-card"
    onClick={() => {
      setSelectedFormData(isClientTab ? "editClient" : "editNotary");
      setSelectedEntityId(contact.id);
      setIsDialogOpen(true);
    }}
  >
    <div className="contact-card__name">
      <span>{contact.people.firstname}</span>
      <span>{contact.people.lastname}</span>
    </div>
    <div className="contact-card__data">{contact.people.mobile}</div>
    <div className="contact-card__data">{contact.email}</div>
  </div>
);

const ContactCards = ({
  contacts,
  setSelectedEntityId,
  setSelectedFormData,
  isClientTab,
  setIsDialogOpen,
}) => {
  return (
    <div className="contact-cards">
      {contacts.map((contact) => (
        <Card
          key={`contact-${contact.email}`}
          contact={contact}
          isClientTab={isClientTab}
          setSelectedEntityId={setSelectedEntityId}
          setSelectedFormData={setSelectedFormData}
          setIsDialogOpen={setIsDialogOpen}
        />
      ))}
    </div>
  );
};

export default ContactCards;
