import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Text from "components/Text";
import LinkButton from "components/Button/LinkButton";
import { getTrialPeriodRemainingDays } from "utils/user";
import { setCookie } from "utils/cookie";

const TrialPeriodDialog = ({
  trialPeriodDialogOpen,
  setTrialPeriodDialogOpen,
  user,
  logout,
}) => {
  const remainningDays = getTrialPeriodRemainingDays(user);
  const isExpired = remainningDays <= 0;
  const remainningDaysSentence =
    remainningDays <= 1
      ? "Votre évaluation expire aujourd'hui"
      : `Votre évaluation expire dans ${remainningDays} jours`;

  React.useEffect(() => {
    setCookie("isUserAlreadyLoggedIn", "true");
  }, []);

  return (
    <Dialog
      open={trialPeriodDialogOpen}
      handleClose={() =>
        isExpired ? logout() : setTrialPeriodDialogOpen(false)
      }
      className="trial-period-dialog"
    >
      <DialogTitle>
        <Text
          size="extra-large"
          align="center"
          fontWeight="semi-bold"
          font="roboto-slab"
          color="white"
        >
          Merci d'utiliser Elio !
        </Text>
      </DialogTitle>
      <DialogContent>
        <Text
          align="center"
          fontWeight="semi-bold"
          color="dark"
          size="large"
          lineHeight="large"
          marginBottom="medium"
        >
          {remainningDaysSentence}
        </Text>
        <Text lineHeight="medium" size="small" align="center">
          Pour continuer à profiter des fonctionnalités Elio, à l'issue de votre
          période d'essai, contactez notre service client
        </Text>
      </DialogContent>
      <DialogActions>
        <LinkButton
          appearance="reversed-primary"
          to={{ pathname: "https://www.elio-app.com/contact" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contactez-nous
        </LinkButton>
      </DialogActions>
    </Dialog>
  );
};

export default TrialPeriodDialog;
