import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { BasicLayout } from "components/GenericLayout";
import SaleCard from "components/Sale/SaleCard";
import SaleProcessMenu from "components/SaleProcess/SaleProcessMenu";
import SaleProcessStepsIndicators from "components/SaleProcess/SaleProcessStepsIndicators";
import SaleProcessCountDown from "components/SaleProcess/SaleProcessCountdown";
import fetchUrl from "api/fetchUrl";
import { getSale } from "api/requests";
import Spinner from "components/Spinner";
import saleProcessSteps from "components/SaleProcess/steps";
import {
  SALE_STATUS,
  SALE_SUB_STEP_PREFIX,
} from "components/Sale/SaleMultistep/saleConstants";

const SaleValidationDate = ({ date }) => {
  if (!date) {
    return null;
  }
  const dateTime = new Date(date);
  const formattedDate = new Intl.DateTimeFormat("fr-FR").format(dateTime);

  return (
    <div className="sale-process__validation-date">
      <p>Offre d'achat validée le {formattedDate}</p>
    </div>
  );
};

const HeaderContent = ({ sale, currentSaleStep }) => (
  <div className="sale-process__header">
    <div className="sale-process__card">
      <SaleCard
        cancelMode
        sale={{ ...sale, saleStep: currentSaleStep }}
        canBeArchived={false}
      />
      <SaleValidationDate date={sale.offerDate} />
    </div>
    <SaleProcessMenu sale={sale} />
  </div>
);

const StepContent = ({ children }) => {
  return <div className="sale-process__step-content">{children}</div>;
};

const SaleProcess = ({ match }) => {
  const { saleId } = match.params;
  const history = useHistory();
  const [sale, setSale] = React.useState({});
  const [currentSaleStep, setCurrentSaleStep] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const formatSale = (sale) => {
    const saleSteps = sale.saleSubSteps.reduce(
      (acc, { step, subStep, id, ...restSubStep }) => {
        const formattedSubStep = {
          id: `${SALE_SUB_STEP_PREFIX}${id}`,
          ...restSubStep,
        };

        if (!acc[step]) {
          acc[step] = { [subStep]: formattedSubStep };
          return acc;
        }
        return {
          ...acc,
          [step]: { ...acc[step], [subStep]: formattedSubStep },
        };
      },
      {}
    );

    return {
      ...sale,
      saleSteps,
    };
  };

  const updateSaleStep = (saleStep) => {
    if (saleStep === currentSaleStep) {
      return;
    }

    setCurrentSaleStep(saleStep);
  };

  const setFormattedSale = React.useCallback(
    (sale, forceUpdateSaleStep = true) => {
      const formattedSale = formatSale(sale);
      setSale(formattedSale);
      if (forceUpdateSaleStep) {
        setCurrentSaleStep(formattedSale.saleStep);
      }
    },
    []
  );

  React.useEffect(() => {
    const handleSaleProcessAccess = (sale) => {
      if (sale.status === SALE_STATUS.DRAFT) {
        return history.push("/404");
      }
    };
    fetchUrl(getSale(saleId))
      .then((sale) => {
        handleSaleProcessAccess(sale);
        setFormattedSale(sale);
        setLoading(false);
      })
      .catch(() => {
        history.push("/404");
      });
  }, [history, saleId, setFormattedSale]);

  if (loading) {
    return <Spinner />;
  }

  const SaleStepComponent = saleProcessSteps[currentSaleStep];

  return (
    <BasicLayout
      fullHeight
      greyBackground
      headerContent={
        <HeaderContent sale={sale} currentSaleStep={currentSaleStep} />
      }
    >
      <SaleProcessStepsIndicators
        currentSaleStep={currentSaleStep}
        setCurrentSaleStep={updateSaleStep}
        saleSteps={sale.saleSteps}
        financingMethod={sale.financialInformation.financingMethod}
      />
      <StepContent>
        <SaleProcessCountDown
          offerDate={sale.offerDate}
          signedAt={sale.keyDates?.deedSignedOn}
        />
        <SaleStepComponent sale={sale} setSale={setFormattedSale} />
      </StepContent>
    </BasicLayout>
  );
};

SaleProcess.propTypes = {
  sale: PropTypes.object,
};

export default SaleProcess;
