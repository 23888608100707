import React from "react";
import { InputCheckbox } from "components/Form/Input";
import { ANNEX_PREFIX } from "components/Sale/SaleMultistep/saleConstants";

const Annexes = React.memo(
  ({ annexes }) => {
    if (annexes?.length === 0) {
      return null;
    }

    return (
      <div className="annexes">
        {annexes.map((annex) => (
          <InputCheckbox
            key={`${ANNEX_PREFIX}${annex.id}`}
            name={`${ANNEX_PREFIX}${annex.id}`}
            label={annex.name}
          />
        ))}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.annexes) === JSON.stringify(nextProps.annexes)
    );
  }
);

export default Annexes;
