import { useMediaQuery } from "react-responsive";
import breakpoints from "../Breakpoints";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({
    minWidth: breakpoints.tablet,
  });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.tablet - 1 });
  return isMobile ? children : null;
};

export { Mobile, Tablet, Desktop };
