/**
 * Api request declaration guidelines
 * In order to keep a certain consistency in the declaration of the requests it is essential to follow a common structure
 *
 * 1. Naming
 * The name of the function must be composed of the query type, the target and end with "Request"
 * Example : getUsersRequest
 *
 * 2. Arguments
 * In order to improve the readability of requests, arguments should be passed as an option object if the request has more than one argument
 * Example : postLoginRequest({ email, password })
 *
 * 3. Return value
 * The fetchUrl function takes as parameter an object thus each request function must return an object respecting the following structure:
 * {
 *   path : the request's path
 *   options : fetch API options object (method (default: GET), body, ...)
 *   credentials (default: true) : does the request need to include credentials ? Note : only authenticated and login requests need to include credentials
 *   json (default: false) : by default we send the body as a FormData object, if json is set to "true" we send a stringified JSON
 *   jsonMergePatch (default: false) : by default we send the body as a FormData object, if json is set to "true" we send a stringified JSON
 * }
 *
 * 4. Comments
 *
 * If a request is not clear please feel free to add an additional explanation in the form of a comment above the declaration.
 */

import { buildQueryParams, makeParamsFilters } from "../utils/data";

const getUserRequest = () => ({
  path: "/me",
});

const postLoginRequest = ({ username, password }) => ({
  path: "/login",
  options: {
    method: "POST",
    body: {
      username,
      password,
    },
  },
  json: true,
});

const postLogoutRequest = () => ({
  path: "/logout",
  options: {
    method: "POST",
  },
});

const postForgotPasswordRequest = (email) => ({
  path: "/password/forgot",
  options: {
    method: "POST",
    body: { email },
  },
  credentials: false,
});

const postResetPasswordRequest = ({ token, newPassword }) => ({
  path: `/password/reset/${token}`,
  options: {
    method: "POST",
    body: { plainPassword: newPassword },
  },
  credentials: false,
});

const getAnnexes = () => ({
  path: "/api/item_annexes",
});

const getSale = (saleId) => ({
  path: `/api/sales/${saleId}`,
});

const getSales = (page, itemsPerPage, filters) => {
  const params = [
    ...makeParamsFilters(filters),
    { name: "page", value: page },
    { name: "itemsPerPage", value: itemsPerPage },
    { name: "draft_first", value: true },
  ];

  return {
    path: `/api/sales${buildQueryParams(params)}`,
    jsonLd: true,
    headers: {
      Accept: "application/ld+json",
    },
  };
};

const postSale = (sale) => ({
  path: "/api/sales",
  options: {
    method: "POST",
    body: sale,
  },
  json: true,
});

const putSale = ({ sale, saleId }) => ({
  path: `/api/sales/${saleId}`,
  options: {
    method: "PUT",
    body: sale,
  },
  jsonLd: true,
});

const deleteSale = ({ saleId }) => ({
  path: `/api/sales/${saleId}`,
  options: {
    method: "DELETE",
  },
});

const getPeoples = (isFilteredByActive = true) => {
  if (isFilteredByActive) {
    return {
      path: `/api/people?status=1`,
    };
  }

  return {
    path: "/api/people",
  };
};

const postClient = (client) => ({
  path: "/api/clients",
  options: {
    method: "POST",
    body: client,
  },
  json: true,
});

const putClient = ({ client, clientId }) => ({
  path: `/api/clients/${clientId}`,
  options: {
    method: "PUT",
    body: client,
  },
  jsonLd: true,
});

const getClient = (clientId) => ({
  path: `/api/clients/${clientId}`,
});

const getClients = () => ({
  path: "/api/clients",
});

const getClientsWithPaginate = (page, itemsPerPage, search) => {
  const params = [
    { name: "page", value: page },
    { name: "itemsPerPage", value: itemsPerPage },
    { name: "search", value: search },
    { name: "order[people.createdAt]", value: "desc" },
  ];

  return {
    path: `/api/clients${buildQueryParams(params)}`,
    jsonLd: true,
    headers: {
      Accept: "application/ld+json",
    },
  };
};

const postNotary = (notary) => ({
  path: "/api/notaries",
  options: {
    method: "POST",
    body: notary,
  },
  json: true,
});

const getNotary = (notaryId) => ({
  path: `/api/notaries/${notaryId}`,
});

const putNotary = ({ notary, notaryId }) => ({
  path: `/api/notaries/${notaryId}`,
  options: {
    method: "PUT",
    body: notary,
  },
  jsonLd: true,
});

const getNotaries = () => ({
  path: "/api/notaries",
});

const getNotariesWithPaginate = (page, itemsPerPage, search) => {
  const params = [
    { name: "page", value: page },
    { name: "itemsPerPage", value: itemsPerPage },
    { name: "search", value: search },
    { name: "order[people.createdAt]", value: "desc" },
  ];

  return {
    path: `/api/notaries${buildQueryParams(params)}`,
    jsonLd: true,
    headers: {
      Accept: "application/ld+json",
    },
  };
};

const deleteDocument = (id) => ({
  path: `/api/documents/${id}`,
  options: {
    method: "DELETE",
  },
});

const postDocument = (document) => ({
  path: "/api/documents",
  options: {
    method: "POST",
    body: document,
  },
});

const getStats = () => ({
  path: "/api/stats",
});

const getTemplate = (templateVars) => ({
  path: `/api/mail/template`,
  options: {
    method: "POST",
    body: templateVars,
  },
});

const uploadMailAttachment = (file) => ({
  path: "/api/mail/uploadAttachment",
  options: {
    method: "POST",
    body: { file },
  },
});

const sendMail = (varsMail) => ({
  path: `/api/mail/send`,
  options: {
    method: "POST",
    body: varsMail,
  },
});

const getAgencyPeople = (agencyId, page, itemsPerPage, filters) => {
  const params = [
    ...filters,
    { name: "exists[user]", value: true },
    { name: "people_user_roles_out", value: "MASTER" },
    { name: "page", value: page },
    { name: "itemsPerPage", value: itemsPerPage },
  ];

  return {
    path: `/api/agencies/${agencyId}/people${buildQueryParams(params)}`,
    jsonLd: true,
    headers: {
      Accept: "application/ld+json",
    },
  };
};

const getAgencyAlerts = ({ agencyId }) => ({
  path: `/api/agencies/${agencyId}/alerts`,
});

const putAgencyAlerts = ({ agencyAlertsId, agencyAlerts }) => ({
  path: `/api/agency_alerts/${agencyAlertsId}`,
  options: {
    method: "PUT",
    body: agencyAlerts,
  },
  jsonLd: true,
});

const putTemplates = ({ templateId, templates }) => ({
  path: `/api/people_mail_templates/${templateId}`,
  options: {
    method: "PUT",
    body: templates,
  },
  jsonLd: true,
});

const restoreTemplateByRef = (templateRef) => ({
  path: `/api/mail/restore_template`,
  options: {
    method: "POST",
    body: { mailReference: templateRef },
  },
});

const getAgency = (agencyId) => ({
  path: `/api/agencies/${agencyId}`,
});

const putAgency = ({ agency, agencyId }) => ({
  path: `/api/agencies/${agencyId}`,
  options: {
    method: "PUT",
    body: agency,
  },
  jsonLd: true,
});

const updatePassword = ({ currentPassword, newPassword }) => ({
  path: "/api/password/update",
  options: {
    method: "POST",
    body: { currentPassword, newPassword },
  },
});

const sendContactEmail = ({ subject, content, from }) => ({
  path: "/api/mail/send-contact-email",
  options: {
    method: "POST",
    body: { subject, content, from },
  },
});

const postUser = (user) => ({
  path: `/api/users`,
  options: {
    method: "POST",
    body: user,
  },
  json: true,
});

const putUser = ({ userId, user }) => ({
  path: `/api/users/${userId}`,
  options: {
    method: "PUT",
    body: user,
  },
  jsonLd: true,
});

const deleteUser = (userId) => ({
  path: `/api/users/${userId}`,
  options: {
    method: "DELETE",
  },
  jsonLd: true,
});

const putPeople = ({ peopleId, people }) => ({
  path: `/api/people/${peopleId}`,
  options: {
    method: "PUT",
    body: people,
  },
  json: true,
});

export {
  getUserRequest,
  postLoginRequest,
  postLogoutRequest,
  postForgotPasswordRequest,
  postResetPasswordRequest,
  getAnnexes,
  getSale,
  getSales,
  postSale,
  putSale,
  deleteSale,
  getPeoples,
  postClient,
  putClient,
  getClient,
  getClients,
  getClientsWithPaginate,
  postNotary,
  putNotary,
  getNotary,
  getNotaries,
  getNotariesWithPaginate,
  deleteDocument,
  postDocument,
  getStats,
  getTemplate,
  uploadMailAttachment,
  sendMail,
  getAgencyAlerts,
  putAgencyAlerts,
  putTemplates,
  restoreTemplateByRef,
  getAgency,
  putAgency,
  updatePassword,
  sendContactEmail,
  getAgencyPeople,
  postUser,
  putUser,
  deleteUser,
  putPeople,
};
