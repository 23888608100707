import React from "react";
import { useFormContext } from "react-hook-form";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import Accordion from "components/Accordion";
import { useAuth } from "components/Hooks/useAuth";
import { IconButton } from "components/Button";
import StepActions from "components/Sale/SaleMultistep/StepActions";
import Text from "components/Text";
import Divider from "components/Divider";
import Mandate from "./Mandate";
import FinancialInformations from "./FinancialInformations";
import AgencyFeesDistribution from "./AgencyFeesDistribution";
import {
  AGENCY_FEES_PREFIX,
  FINANCIAL_INFORMATION_PREFIX,
  MANDATE_PREFIX,
  SALE_SUB_STEPS,
  SALE_TYPES,
} from "../../saleConstants";
import { normalizeNumber } from "utils/number";
import { formatSale } from "pages/Sale/Sale";

const { INTER_AGENCY } = SALE_TYPES;

const MandateAndFinancialInformationStep = ({
  isEdit,
  step,
  saleType,
  sale,
  setSale,
  isValid,
  active,
  nextStep,
  peoples,
  fields,
  setValidationSchemaArgs,
  goToStep,
}) => {
  const { user } = useAuth();
  const { values, trigger: triggerValidation } = useFormContext();
  const isInterAgency = saleType === INTER_AGENCY;
  const disableFinancingMethod =
    sale.saleSubSteps &&
    sale.saleSubSteps.find(
      ({ subStep }) =>
        subStep === SALE_SUB_STEPS.REGISTER_KEY_DATES_AND_CONDITIONS
    ).isCompleted;

  const makeStepData = () => {
    const {
      mandateType,
      mandateNumber,
      mandateDate,
      feeToBePaid,
      percentageBuyer,
      percentageSeller,
      fees,
      partnerAgencyFees,
      netSellingPrice,
      financingMethod,
      loanDuration,
      loanRate,
      agencyFees,
      buyerAgent,
      buyerAgentFees,
      sellerAgent,
      sellerAgentFees,
      ...stepData
    } = Object.fromEntries(
      Object.entries(values).filter(([key]) => {
        return fields.includes(key);
      })
    );

    const nextStep = step + 1;

    return {
      draftStep: nextStep > sale.draftStep ? nextStep : sale.draftStep,
      mandate: {
        ...(sale.mandate && {
          "@id": sale.mandate["@id"] ?? `${MANDATE_PREFIX}${sale.mandate.id}`,
        }),
        mandateType,
        mandateNumber,
        mandateDate,
        feeToBePaid,
        percentageBuyer: normalizeNumber(percentageBuyer) || 0,
        percentageSeller: normalizeNumber(percentageSeller) || 0,
      },
      financialInformation: {
        ...(sale.financialInformation && {
          "@id":
            sale.financialInformation["@id"] ??
            `${FINANCIAL_INFORMATION_PREFIX}${sale.financialInformation.id}`,
        }),
        fees: normalizeNumber(fees),
        partnerAgencyFees: normalizeNumber(partnerAgencyFees),
        netSellingPrice: normalizeNumber(netSellingPrice),
        financingMethod,
        loanDuration: normalizeNumber(loanDuration) || 0,
        loanRate: normalizeNumber(loanRate) || 0,
      },
      ...(user.isMasterUser && {
        buyerAgent: buyerAgent !== "" ? buyerAgent : null,
        sellerAgent: sellerAgent !== "" ? sellerAgent : null,
        agencyFees: {
          ...(sale.agencyFees && {
            "@id":
              sale.agencyFees["@id"] ??
              `${AGENCY_FEES_PREFIX}${sale.agencyFees.id}`,
          }),
          agencyFees: normalizeNumber(agencyFees),
          ...(buyerAgent
            ? {
                buyerAgentFees: normalizeNumber(buyerAgentFees),
              }
            : {
                buyerAgentFees: 0,
              }),
          ...(sellerAgent
            ? {
                sellerAgentFees: normalizeNumber(sellerAgentFees),
              }
            : {
                sellerAgentFees: 0,
              }),
        },
      }),
      ...stepData,
    };
  };

  const handleNextStep = () => {
    const stepData = makeStepData();
    fetchUrl(putSale({ sale: stepData, saleId: sale.id })).then((sale) => {
      setSale(formatSale(sale));
      nextStep();
    });
  };

  React.useEffect(() => {
    if (active) {
      setValidationSchemaArgs({ isMasterUser: user.isMasterUser, saleType });
    }
  }, [active, user.isMasterUser, saleType, setValidationSchemaArgs]);

  return (
    <Accordion
      focusContent={false}
      disableToggle={sale.draftStep ? sale.draftStep < step : true}
      title={`${step + 1} • Mandat & informations financières`}
      open={active}
      setOpen={goToStep}
    >
      {active ? (
        <>
          <div className="mandate-financial-informations-step">
            <Text color="primary" font="roboto-slab" size="small">
              Votre mandat
            </Text>
            <Mandate />
            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Informations financières
            </Text>
            <FinancialInformations
              isInterAgency={isInterAgency}
              disableFinancingMethod={disableFinancingMethod}
            />
            {(user.isMasterUser || isEdit) && (
              <>
                <Divider />
                <Text color="primary" font="roboto-slab" size="small">
                  Répartition honoraires d'agence
                </Text>
                <AgencyFeesDistribution
                  isInterAgency={isInterAgency}
                  peoples={peoples}
                  disableAgencyFees={!user.isMasterUser}
                />
              </>
            )}
          </div>
          <StepActions>
            <IconButton
              uppercase
              fullWidth
              {...(!isEdit && { icon: "chevron-right" })}
              textPosition="left"
              appearance="primary"
              boxShadow
              onClick={() =>
                isValid
                  ? handleNextStep()
                  : triggerValidation().then((isValidAfterValidation) => {
                      if (isValidAfterValidation) {
                        return handleNextStep();
                      }
                    })
              }
            >
              {isEdit
                ? "Enregistrer les modifications"
                : "Enregistrer & continuer"}
            </IconButton>
          </StepActions>
        </>
      ) : null}
    </Accordion>
  );
};

export default MandateAndFinancialInformationStep;
