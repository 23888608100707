import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import MailTemplate from "./MailTemplate";
import EmailSendLogs from "./EmailSendLogs";
import { getTemplate } from "api/requests";
import fetchUrl from "api/fetchUrl";
import { Dialog, DialogContent } from "components/Dialog";
import Select from "components/Form/Select";
import Form from "components/Form";
import Button from "components/Button";
import SubmitButton from "components/Form/SubmitButton";
import Accordion from "components/Accordion";
import ErrorMessage from "components/ErrorMessage";
import { useAuth } from "components/Hooks/useAuth";
import {
  formatSubject,
  getMailTemplatesSelectValues,
  getSignature,
} from "utils/email";
import { notifyError } from "utils/notify";
import Icon from "components/Icon";
import { UPLOAD_STATES } from "components/AttachmentUploader/AttachmentUploader";

const FormContent = ({ setSelectedTemplateRef, templateList }) => {
  const { values } = useFormContext();

  React.useEffect(() => {
    setSelectedTemplateRef(values.templateRef);
  }, [values.templateRef, setSelectedTemplateRef]);

  return (
    <div className="mail-module__form-content">
      <Select
        name="templateRef"
        options={templateList}
        placeholder="Sélectionner l'email à envoyer"
        selectFilter
      />
    </div>
  );
};

const MailModule = ({
  title = "Envoyer un email",
  open,
  handleClose,
  config = {},
  handleSubmit,
  showHistory = true,
  sale,
}) => {
  const { user } = useAuth();
  const [selectedTemplateRef, setSelectedTemplateRef] = React.useState(
    config.template || ""
  );
  const [recipients, setRecipients] = React.useState(config.recipients || []);
  const [error, setError] = React.useState("");
  const templateList = React.useMemo(() => getMailTemplatesSelectValues(), []);
  const [editorState, setEditorState] = React.useState(null);
  const [subject, setSubject] = React.useState("");
  const [mailParts, setMailParts] = React.useState([]);
  const [attachments, setAttachments] = React.useState(
    config.attachments || []
  );
  const [mailIsBeingSent, setMailIsBeingSent] = React.useState(false);

  const hasPartnerAgency = !!sale.partnerAgency;

  const someAttachmentsAreBeingUploaded = React.useMemo(() => {
    return attachments.some(
      (attachment) => attachment.state === UPLOAD_STATES.uploading
    );
  }, [attachments]);

  React.useEffect(() => {
    if (!config?.template) {
      return;
    }
    setSelectedTemplateRef(config.template);
  }, [config.template]);

  React.useEffect(() => {
    /**
     * If there is recipients and we don't pass any recipients as config and the modal is closed we reset the recipients
     */
    if (!config?.recipients?.length || !open) {
      setRecipients([]);
    } else if (config?.recipients?.length) {
      setRecipients(config.recipients);
    }
  }, [open, config.recipients]);

  React.useEffect(() => {
    /**
     * If there is attachments and we don't pass any attachments as config and the modal is closed we reset the recipients
     */
    if (!config?.attachments?.length || !open) {
      setAttachments([]);
    } else if (config?.attachments?.length) {
      setAttachments(config.attachments);
    }
  }, [open, config.attachments]);

  React.useEffect(() => {
    if (selectedTemplateRef) {
      const retrieveTemplate = () =>
        fetchUrl(
          getTemplate({
            mailReference: selectedTemplateRef,
          })
        );

      retrieveTemplate()
        .then((response) => {
          const [firstPart, content, lastPart] = response.template
            .replace("style", "twig-style")
            .split("<!-- split -->");

          setMailParts([firstPart, lastPart]);

          const signature = getSignature(user);

          const contentBlock = htmlToDraft(content + signature);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );

          const editorState = EditorState.createWithContent(contentState);

          setEditorState(editorState);
          setSubject(formatSubject({ subject: response.subject, sale }));
        })
        .catch((error) => {
          console.error(error);
          setError(error);
        });
    }
  }, [selectedTemplateRef, setError, sale, user]);

  const getFormattedContent = () => {
    return [
      mailParts[0],
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
      mailParts[1],
    ]
      .join("")
      .replace("twig-style", "style");
  };

  return (
    <div className="mail-module">
      <Dialog
        className="mail-module-dialog"
        open={open}
        handleClose={handleClose}
      >
        <div className="mail-module-dialog__header">
          <Icon icon="letter-open" color="primary" />
          <div className="mail-module-dialog__title">{title}</div>
        </div>
        <DialogContent>
          <Form
            onSubmit={() => {
              if (!recipients.length) {
                notifyError("Veuillez ajouter des destinataires");
                return null;
              }

              setMailIsBeingSent(true);

              handleSubmit({
                subject,
                content: getFormattedContent(),
                attachments,
                recipients,
                selectedTemplateRef,
                sale,
                doneCallback: () => setMailIsBeingSent(false),
              });
            }}
          >
            {!config.template && (
              <FormContent
                setSelectedTemplateRef={setSelectedTemplateRef}
                templateList={templateList}
              />
            )}
            {error ? (
              <ErrorMessage customMessage="Une erreur est survenue lors du chargement du template" />
            ) : (
              editorState &&
              selectedTemplateRef && (
                <>
                  <MailTemplate
                    recipients={recipients}
                    setRecipients={setRecipients}
                    hasPartnerAgency={hasPartnerAgency}
                    editorState={editorState}
                    handleEditorStateChange={setEditorState}
                    subject={subject}
                    setSubject={setSubject}
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                  <div className="mail-module__footer">
                    <Button appearance="ghost" onClick={handleClose}>
                      annuler
                    </Button>
                    <SubmitButton
                      disabled={someAttachmentsAreBeingUploaded}
                      loading={mailIsBeingSent}
                      appearance="primary"
                    >
                      envoyer
                    </SubmitButton>
                  </div>
                </>
              )
            )}
          </Form>
        </DialogContent>
        {showHistory && (
          <div className="mail-module__accordion">
            <Accordion title="Historique des emails envoyés">
              <EmailSendLogs emailSendLogs={sale.emailSendLogs || []} />
            </Accordion>
          </div>
        )}
      </Dialog>
    </div>
  );
};

MailModule.propTypes = {
  config: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default MailModule;
