import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { sendMail } from "api/requests";
import fetchUrl from "api/fetchUrl";
import { formatName } from "utils/sale";
import { notifySuccess, notifyError } from "utils/notify";
import { RECIPIENT_TYPES } from "components/Sale/SaleMultistep/saleConstants";
import { UPLOAD_STATES } from "components/AttachmentUploader/AttachmentUploader";

export const TEMPLATE_REFERENCES = {
  newTransaction: {
    label: "Nouvelle transaction",
    value: "newTransaction",
  },
  requestForAdditionalDocuments: {
    label: "Demande de pièces complémentaires",
    value: "requestForAdditionalDocuments",
  },
  availabilityRequestSignature: {
    label: "Demande de disponibilités signature",
    value: "availabilityRequestSignature",
  },
  confirmationDateOfSignature: {
    label: "Confirmation date de signature",
    value: "confirmationDateOfSignature",
  },
  editDateOfSignature: {
    label: "Modification date de signature",
    value: "editDateOfSignature",
  },
  confirmationNotificationSRU: {
    label: "Confirmation notification SRU",
    value: "confirmationNotificationSRU",
  },
  loanFileDepositConfirmation: {
    label: "Confirmation dépôt du dossier de prêt bancaire",
    value: "loanFileDepositConfirmation",
  },
  loanOfferReceiptConfirmation: {
    label: "Confirmation réception offre de prêt",
    value: "loanOfferReceiptConfirmation",
  },
  notaryConfirmationLoanOffer: {
    label: "Confirmation notaires : réception de l’offre de prêt",
    value: "notaryConfirmationLoanOffer",
  },
  notaryInformationSellCancelation: {
    label: "Information notaires : Annulation de la vente",
    value: "notaryInformationSellCancelation",
  },
  customerLoyalty: {
    label: "Fidélisation clients",
    value: "customerLoyalty",
  },
  freeMail: { label: "Mail libre", value: "freeMail" },
};

export const getMailTemplatesSelectValues = () => {
  return Object.keys(TEMPLATE_REFERENCES).map((refKey) => {
    const { label, value } = TEMPLATE_REFERENCES[refKey];
    return { label, value };
  });
};

export const formatSubject = ({ subject, sale }) => {
  const buyer = formatName(sale.buyerAndNotary[0].client.people, false, false);
  const seller = formatName(
    sale.sellerAndNotary[0].client.people,
    false,
    false
  );

  return subject.replace("{{ buyer }}", buyer).replace("{{ seller }}", seller);
};

export const makeSendMail = ({
  subject,
  content,
  attachments,
  recipients,
  selectedTemplateRef,
  sale,
  onSuccess,
  onError,
}) => {
  const extractMailsEntity = (parentObject, entity) => {
    return sale[parentObject].reduce(
      (acc, saleParentObject) => [
        ...acc,
        entity ? saleParentObject[entity].email : [saleParentObject].email,
      ],
      []
    );
  };

  const mailsTo = recipients.reduce((acc, recipient) => {
    switch (recipient.value) {
      case RECIPIENT_TYPES.BUYER.value:
        return [...acc, ...extractMailsEntity("buyerAndNotary", "client")];
      case RECIPIENT_TYPES.SELLER.value:
        return [...acc, ...extractMailsEntity("sellerAndNotary", "client")];
      case RECIPIENT_TYPES.BUYER_NOTARY.value:
        return [...acc, ...extractMailsEntity("buyerAndNotary", "notary")];
      case RECIPIENT_TYPES.SELLER_NOTARY.value:
        return [...acc, ...extractMailsEntity("sellerAndNotary", "notary")];
      case RECIPIENT_TYPES.PARTNER_AGENCY.value:
        if (sale.partnerAgency.email !== "") {
          return [...acc, sale.partnerAgency.email];
        }
        return acc;
      default:
        return [...acc, recipient.value];
    }
  }, []);

  const recipientsLabel = Array.from(
    new Set(recipients.map((recipient) => recipient.label))
  ).join(",");

  const template = TEMPLATE_REFERENCES[selectedTemplateRef];

  const varsMail = {
    mailReference: template.value,
    mailReferenceLabel: template.label,
    mailSubject: subject,
    mailContent: content,
    options: JSON.stringify({
      to: Array.from(new Set(mailsTo)).join(","),
      recipientsLabel,
      sale_id: sale.id,
      attachments: JSON.stringify(
        attachments
          .filter(
            (file) =>
              file.state === UPLOAD_STATES.uploaded ||
              file?.isSalePDF ||
              file?.documentId
          )
          .map((file) => {
            return {
              path: file.path,
              name: file.name,
              type: file.type,
              isSalePDF: file?.isSalePDF,
              documentId: file?.documentId,
            };
          })
      ),
    }),
  };

  fetchUrl(sendMail(varsMail))
    .then(() => {
      notifySuccess("Email envoyé avec succès");
      onSuccess({
        subject: template.label,
        recipient: recipientsLabel,
      });
    })
    .catch((err) => {
      console.error({ err });
      notifyError("Echec de l'envoi du mail");
      onError();
    });
};

export const getAvailableRecipients = ({ hasPartnerAgency }) =>
  Object.entries(RECIPIENT_TYPES).reduce(
    (acc, [recipient, { label, value }]) => {
      if (!hasPartnerAgency && value === RECIPIENT_TYPES.PARTNER_AGENCY.value) {
        return acc;
      }
      return [...acc, { value: recipient, label }];
    },
    []
  );

export const getFormattedContent = (content, mailParts) => {
  return [
    mailParts[0],
    draftToHtml(convertToRaw(content.getCurrentContent())),
    mailParts[1],
  ]
    .join("<!-- split -->")
    .replace("twig-style", "style");
};

export const editorStateToHtml = (editorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

const generateAvailableSignatureAttribute = (attribute) => {
  if (!attribute) {
    return "";
  }

  return `<p>${attribute}</p>`;
};

export const getSignature = (user) => {
  return (
    `<p>${user.people.firstname} ${user.people.lastname}</p>` +
    generateAvailableSignatureAttribute(user.people.agency.name) +
    generateAvailableSignatureAttribute(
      user.people.mobile || user.people.phone
    ) +
    generateAvailableSignatureAttribute(user.email)
  );
};
