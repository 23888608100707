import CharacteristicsOfThePropertyStep from "./CharacteristicsOfThePropertyStep";
import validationSchema from "./validationSchema";

const step = {
  StepComponent: CharacteristicsOfThePropertyStep,
  fields: [
    "id",
    "saleType",
    "projectType",
    "propertyType",
    "numberOfRooms",
    "area",
    "address",
    "zip",
    "city",
    "country",
    "district",
    "propertySoldRented",
    "coOwnerShip",
    "numberOfLots",
    "year",
    "coOwnership",
    "additionalInformation",
  ],
  dependencies: ["annexes"],
  validationSchema,
};

export default step;
