import * as yup from "yup";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Merci de saisir un e-mail valide.")
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  password: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
});

export default validationSchema;
