import React from "react";
import Text from "components/Text";
import Icon from "components/Icon";
import Button from "components/Button";
import { Dialog, DialogActions, DialogContent } from "components/Dialog";
import SubmitButton from "components/Form/SubmitButton";
import Form from "components/Form";
import LabelledField from "components/LabelledField";
import { InputPassword } from "components/Form/Input";
import validationSchema from "./validationSchema";
import fetchUrl from "api/fetchUrl";
import { updatePassword } from "api/requests";
import { notifyError, notifySuccess } from "utils/notify";

const Security = () => {
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);

  const handleSubmit = ({ currentPassword, newPassword }) => {
    fetchUrl(updatePassword({ currentPassword, newPassword }))
      .then(() => {
        notifySuccess("Le mot de passe a été mis à jour avec succès.");
        setPasswordDialogOpen(false);
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors du changement de mot de passe."
        );
      });
  };

  return (
    <>
      <div className="security">
        <Text align="center" uppercase size="large" font="roboto-slab">
          Modifier votre mot de passe
        </Text>
        <Icon icon="ripple" color="primary" size="very-big" />
        <Text align="center" size="regular">
          Vous pouvez modifier votre
          <br /> mot de passe maintenant
        </Text>
        <Button
          appearance="primary"
          onClick={() => setPasswordDialogOpen(true)}
        >
          Modifier
        </Button>
      </div>
      <Dialog
        open={passwordDialogOpen}
        handleClose={() => setPasswordDialogOpen(false)}
        className="password-dialog"
      >
        <Form
          mode="all"
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Text uppercase align="center" size="medium" font="roboto-slab">
              Modifier votre mot de passe
            </Text>
            <Icon icon="ripple" size="extra-big" color="primary" />
            <div className="password-dialog__legend">
              <Text align="justify" fontWeight="light">
                Utilisez un mot de passe difficile à deviner, pour que nul autre
                que vous ne puisse accéder à votre compte. Si vous craignez que
                votre compte n'ait été piraté, vous pouvez modifier votre mot de
                passe maintenant.
              </Text>
            </div>
            <div className="password-dialog__fields">
              <LabelledField label="Mot de passe actuel">
                <InputPassword name="currentPassword" />
              </LabelledField>
              <LabelledField label="Nouveau mot de passe">
                <InputPassword name="newPassword" />
              </LabelledField>
              <LabelledField label="Confirmer mot de passe">
                <InputPassword name="confirmNewPassword" />
              </LabelledField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPasswordDialogOpen(false)}>
              Annuler
            </Button>
            <SubmitButton appearance="primary">Enregistrer</SubmitButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default Security;
