import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "components/Link";
import Icon from "components/Icon";

const makeButtonClasses = ({
  disabled,
  variant,
  appearance,
  size,
  textSize,
  fullWidth,
  noPadding,
  vertical,
  boxShadow,
  uppercase,
  italic,
  rounded,
  roundedToFullWidthAnimation,
  isLink,
  className,
  loading,
}) => {
  appearance = typeof appearance === "function" ? appearance() : appearance;

  return classNames("button", {
    "button--disabled": disabled,
    [`button--${variant}`]: variant,
    [`button--${appearance}`]: appearance,
    [`button--size-${size}`]: size,
    [`button--text-size-${textSize}`]: textSize,
    [`button--loading`]: loading,
    "button--full-width": fullWidth,
    "button--no-padding": noPadding,
    "button--vertical": vertical,
    "button--shadow": boxShadow,
    "button--uppercase": uppercase,
    "button--italic": italic,
    "button--rounded": rounded,
    "button--rounded-to-full-width-animation": roundedToFullWidthAnimation,
    "button--link": isLink,
    [className]: className,
  });
};

const Button = ({
  type,
  onClick,
  children,
  variant,
  appearance,
  size,
  textSize,
  fullWidth,
  noPadding,
  disabled,
  vertical,
  boxShadow,
  uppercase,
  italic,
  rounded,
  roundedToFullWidthAnimation,
  isLink,
  className,
  loading,
  ...buttonProps
}) => {
  const classes = makeButtonClasses({
    disabled,
    variant,
    appearance,
    fullWidth,
    noPadding,
    uppercase,
    italic,
    rounded,
    roundedToFullWidthAnimation,
    vertical,
    boxShadow,
    size,
    textSize,
    isLink,
    className,
    loading,
  });

  if (isLink) {
    return (
      <div className={classes}>
        <Link {...buttonProps}>{children}</Link>
      </div>
    );
  }

  return (
    <button
      {...buttonProps}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      className={classes}
    >
      {loading ? <Icon icon="spinner" /> : children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["icon", "icon-only"]),
  appearance: PropTypes.oneOf([
    "default",
    "primary",
    "reversed-primary",
    "secondary",
    "ghost",
    "ghost-primary",
    "reversed-tertiary",
    "transparent",
    "grey",
    "naked",
    "link-primary",
  ]),
  size: PropTypes.oneOf(["auto", "mini"]),
  textSize: PropTypes.oneOf(["default", "mini"]),
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  vertical: PropTypes.bool,
  uppercase: PropTypes.bool,
  boxShadow: PropTypes.bool,
  disabled: PropTypes.bool,
  italic: PropTypes.bool,
  isLink: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  appearance: "default",
  fullWidth: false,
  noPadding: false,
  uppercase: true,
  boxShadow: true,
  textSize: "default",
  italic: false,
  isLink: false,
  loading: false,
};

export default Button;
