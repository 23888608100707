import React from "react";
import { useFormContext } from "react-hook-form";
import Select from "components/Form/Select";
import { InputText, InputNumber, InputDate } from "components/Form/Input";
import {
  MANDATE_TYPES,
  FEES_TO_BE_PAID,
} from "components/Sale/SaleMultistep/saleConstants";

const { SALE: MANDATE_SALE, SEARCH: MANDATE_SEARCH } = MANDATE_TYPES;
const { SELLER, BUYER, SELLER_BUYER } = FEES_TO_BE_PAID;

const FeesRepartition = ({ feeToBePaid }) => {
  if (feeToBePaid !== SELLER_BUYER.value) {
    return null;
  }

  return (
    <>
      <InputNumber
        name="percentageSeller"
        placeholder="% vendeur"
        suffix={{ icon: "percentage" }}
        min={0}
        max={100}
      />
      <InputNumber
        name="percentageBuyer"
        placeholder="% acquéreur"
        suffix={{ icon: "percentage" }}
        min={0}
        max={100}
      />
    </>
  );
};

const Mandate = () => {
  const { values, setValue, trigger: triggerValidation } = useFormContext();
  const { feeToBePaid, percentageSeller, percentageBuyer } = values;

  React.useEffect(() => {
    if (feeToBePaid === SELLER_BUYER.value) {
      if (percentageSeller && percentageBuyer) {
        triggerValidation(["percentageSeller", "percentageBuyer"]);
      }
    } else {
      if (percentageSeller) {
        setValue("percentageSeller", "");
      }
      if (percentageBuyer) {
        setValue("percentageBuyer", "");
      }
    }
  }, [
    feeToBePaid,
    percentageSeller,
    percentageBuyer,
    setValue,
    triggerValidation,
  ]);

  return (
    <div className="mandate">
      <Select
        name="mandateType"
        options={[MANDATE_SALE, MANDATE_SEARCH]}
        placeholder="Type de mandat"
      />
      <InputText name="mandateNumber" placeholder="Numéro de mandat" />
      <InputDate name="mandateDate" placeholder="Date du mandat" />
      <Select
        name="feeToBePaid"
        options={[SELLER, BUYER, SELLER_BUYER]}
        placeholder="Honoraires à charge"
      />
      <FeesRepartition feeToBePaid={feeToBePaid} />
    </div>
  );
};

export default Mandate;
