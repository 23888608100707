import React from "react";
import { ToastContainer } from "react-toastify";
import IconButton from "../Button/IconButton";

const CloseButton = ({ closeToast }) => {
  return <IconButton icon="close" onClick={closeToast} />;
};

const Toast = () => {
  return <ToastContainer closeButton={CloseButton} hideProgressBar />;
};

export default Toast;
