import React from "react";
import { useFormContext } from "react-hook-form";
import { InputNumber } from "components/Form/Input";
import Select from "components/Form/Select";
import { FINANCING_METHODS } from "components/Sale/SaleMultistep/saleConstants";
import roundToTwoDecimals from "utils/roundToTwoDecimals";

const { BANK_LOAN, CASH } = FINANCING_METHODS;

const BankLoanInputs = ({ financingMethod, disableFinancingMethod }) => {
  if (financingMethod !== BANK_LOAN.value) {
    return null;
  }

  return (
    <>
      <div className="financial-information">
        <div className="financial-information__label">Durée du prêt</div>
        <InputNumber
          readOnly={disableFinancingMethod}
          name="loanDuration"
          min={0}
          suffix={{ text: "ANS" }}
        />
      </div>
      <div className="financial-information">
        <div className="financial-information__label">Taux d'emprunt</div>
        <InputNumber
          readOnly={disableFinancingMethod}
          name="loanRate"
          min={0}
          suffix={{ icon: "percentage" }}
        />
      </div>
    </>
  );
};

const FinancialInformation = ({ readOnly, name, label, value }) => {
  return (
    <div className="financial-information">
      <div className="financial-information__label">{label}</div>
      <InputNumber
        readOnly={readOnly}
        name={name}
        min={0}
        suffix={{ icon: "euro" }}
        textAlign="right"
        value={value}
      />
    </div>
  );
};

const FinancialInformations = ({ isInterAgency, disableFinancingMethod }) => {
  const { values, setValue } = useFormContext();
  const {
    netSellingPrice,
    fees,
    partnerAgencyFees,
    financingMethod,
    loanDuration,
    loanRate,
  } = values;

  React.useEffect(() => {
    if (financingMethod !== BANK_LOAN.value) {
      if (loanDuration) {
        setValue("loanDuration", 0);
      }
      if (loanRate) {
        setValue("loanRate", 0);
      }
    }
  }, [financingMethod, loanDuration, loanRate, setValue]);

  const totalFees = roundToTwoDecimals(
    Number(fees) + Number(partnerAgencyFees)
  );

  const sellingPriceIncludingFees = roundToTwoDecimals(
    Number(netSellingPrice) + (isInterAgency ? totalFees : Number(fees))
  );

  return (
    <div className="financial-informations">
      <FinancialInformation
        name="netSellingPrice"
        label="Prix de vente net vendeur"
      />
      <FinancialInformation
        name="fees"
        label={`Honoraires TTC${isInterAgency ? " de votre agence" : ""}`}
      />
      {isInterAgency && (
        <>
          <FinancialInformation
            name="partnerAgencyFees"
            label="Honoraires TTC agence partenaire"
          />
          <FinancialInformation
            readOnly
            name="totalFees"
            label="Total honoraires TTC"
            value={totalFees}
          />
        </>
      )}
      <FinancialInformation
        readOnly
        name="sellingPriceIncludingFees"
        label="Prix de vente FAI"
        value={sellingPriceIncludingFees}
      />

      <Select
        readOnly={disableFinancingMethod}
        name="financingMethod"
        options={[BANK_LOAN, CASH]}
        placeholder="Mode de financement"
      />

      <BankLoanInputs
        financingMethod={financingMethod}
        disableFinancingMethod={disableFinancingMethod}
      />
    </div>
  );
};

export default FinancialInformations;
