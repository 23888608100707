import PartnerAgencyStep from "./PartnerAgencyStep";
import validationSchema from "./validationSchema";
import { SALE_TYPES } from "components/Sale/SaleMultistep/saleConstants";

const step = {
  StepComponent: PartnerAgencyStep,
  validationSchema,
  fields: [],
  showStep: ({ saleType }) => {
    return saleType === SALE_TYPES.INTER_AGENCY;
  },
};

export default step;
