import React from "react";
import Accordion from "components/Accordion";
import { IconButton } from "components/Button";
import StepActions from "../../StepActions";
import { InputText } from "components/Form/Input";
import Select from "components/Form/Select";
import Text from "components/Text";
import Divider from "components/Divider";
import { REPRESENTS, SALE_PARTNER_AGENCY_PREFIX } from "../../saleConstants";
import { useFormContext } from "react-hook-form";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import InputGoogleMapAutoComplete from "components/Form/Input/InputGoogleAutoComplete";
import { formatSale } from "pages/Sale/Sale";

const { SELLER, BUYER } = REPRESENTS;

const PartnerAgencyStep = ({
  isEdit,
  step,
  sale,
  setSale,
  isValid,
  active,
  nextStep,
  goToStep,
}) => {
  const { values, trigger: triggerValidation } = useFormContext();

  const makeStepData = () => {
    const {
      agencyCity: city,
      agentFirstName: firstName,
      agentLastName: lastName,
      agentMobile: mobile,
      agentEmail: email,
      ...restPartnerAgency
    } = values;

    const nextStep = step + 1;
    return {
      draftStep: nextStep > sale.draftStep ? nextStep : sale.draftStep,
      partnerAgency: {
        ...(sale.partnerAgency && {
          "@id":
            sale.partnerAgency["@id"] ??
            `${SALE_PARTNER_AGENCY_PREFIX}${sale.partnerAgency.id}`,
        }),
        city,
        firstName,
        lastName,
        mobile,
        email,
        ...restPartnerAgency,
      },
    };
  };

  const handleNextStep = () => {
    const stepData = makeStepData();

    fetchUrl(putSale({ sale: stepData, saleId: sale.id })).then((sale) => {
      setSale(formatSale(sale));
      nextStep();
    });
  };

  return (
    <Accordion
      focusContent={false}
      disableToggle={sale.draftStep ? sale.draftStep < step : true}
      title={`${step + 1} • Informations agence partenaire`}
      open={active}
      setOpen={goToStep}
    >
      {active ? (
        <>
          <div className="partner-agency-step">
            <Text color="primary" font="roboto-slab" size="small">
              Votre agence partenaire
            </Text>
            <InputText name="agencyName" placeholder="Nom agence" />
            <InputGoogleMapAutoComplete
              onlyCity
              name="agencyCity"
              placeholder="Ville"
            />
            <InputText name="agentFirstName" placeholder="Nom agent" />
            <InputText name="agentLastName" placeholder="Prénom agent" />
            <InputText name="agentMobile" placeholder="Mobile" />
            <InputText name="agentEmail" placeholder="Email" />
            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Informations complémentaires
            </Text>
            <Select
              name="represents"
              placeholder="L'agence partenaire représente..."
              options={[SELLER, BUYER]}
            />
          </div>
          <StepActions>
            <IconButton
              uppercase
              fullWidth
              {...(!isEdit && { icon: "chevron-right" })}
              textPosition="left"
              appearance="primary"
              onClick={() =>
                isValid
                  ? handleNextStep()
                  : triggerValidation().then((isValidAfterValidation) => {
                      if (isValidAfterValidation) {
                        return handleNextStep();
                      }
                    })
              }
            >
              {isEdit ? "Enregistrer" : "Enregistrer & continuer"}
            </IconButton>
          </StepActions>
        </>
      ) : null}
    </Accordion>
  );
};

export default PartnerAgencyStep;
