import { PROPERTY_TYPES } from "components/Sale/SaleMultistep/saleConstants";

export const getSaleStepOptions = () => [
  {
    value: "DOCUMENTS",
    label: "1- Constitution du dossier",
    stepper: "Documents",
  },
  {
    value: "COMPROMISE",
    label: "2- Avant-contrat / Promesse",
    stepper: "Compromis",
  },
  {
    value: "FORMALITIES",
    label: "3- Formalités & conditions",
    stepper: "Formalités",
  },
  { value: "ACTED", label: "4- Acte de vente", stepper: "Acte" },
];

export const getSaleStatusOptions = () => [
  { value: "DRAFT", label: "Ventes en cours" },
  { value: "ACTED", label: "Ventes actées" },
  { value: "INTER_AGENCY", label: "Ventes inter-agences" },
  { value: "CANCELED", label: "Ventes annulées" },
];

export const getPeopleOptions = (peoples) =>
  peoples.map(({ id, lastname, firstname }) => ({
    value: id,
    label: `${lastname} ${firstname}`,
  }));

export const formatName = (
  people,
  withCivility = false,
  withFirstname = true
) => {
  if (!people) {
    return "-";
  }

  if (people.companyName) {
    return people.companyName;
  }

  return `${withCivility ? people.civility + " " : ""}${people.lastname}${
    withFirstname ? ` ${people.firstname}` : ""
  }`;
};

export const formatPropertyType = (propertyType, numberOfRooms) =>
  propertyType === PROPERTY_TYPES.APARTMENT.value
    ? "T".concat(numberOfRooms, " ")
    : PROPERTY_TYPES[propertyType].label.concat(" ");
