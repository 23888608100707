import React from "react";
import PropTypes from "prop-types";

const UserAccountsLeft = ({ accountsLeft }) => {
  if (accountsLeft === null) {
    return null;
  }
  const usePlural = accountsLeft > 1;
  return (
    <div className="user-settings__accounts-left">
      {accountsLeft < 1 ? (
        <p>Il ne vous reste plus de licence utilisateur disponible.</p>
      ) : (
        <p>
          {accountsLeft}{" "}
          {usePlural
            ? "licences utilisateurs restantes"
            : "licence utilisateur restante"}
          .
        </p>
      )}
    </div>
  );
};

UserAccountsLeft.defaultProps = {
  accountsLeft: 0,
};

UserAccountsLeft.propTypes = {
  accountsLeft: PropTypes.number,
};

export default UserAccountsLeft;
