import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import classNames from "classnames";

const StepIndicator = ({
  setCurrentSaleStep,
  number,
  title,
  appearance,
  isActive = false,
  isComplete = false,
  isAccessible,
  completeStatusLabel,
}) => {
  const stepIndicatorClassNames = classNames("step-indicator", {
    [`step-indicator--${appearance}`]: appearance,
    "step-indicator--active": isActive,
    "step-indicator--is-accessible": isAccessible,
  });
  return (
    <div className={stepIndicatorClassNames} onClick={setCurrentSaleStep}>
      <div className="step-indicator__inner">
        <strong className="step-indicator__number">Étape {number}</strong>
        <Icon icon="ripple" size="big" />
        <h2 className="step-indicator__title">
          {typeof title === "function" ? title() : title}
        </h2>
        {isComplete && completeStatusLabel && (
          <div className="step-indicator__complete-status">
            <Icon icon="check" size="small" />
            {completeStatusLabel}
          </div>
        )}
      </div>
    </div>
  );
};

StepIndicator.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired,
  ]),
  appearance: PropTypes.oneOf(["default", "ok", "alert", "upcoming"]),
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  completeStatusLabel: PropTypes.string,
};

export default StepIndicator;
