export const getCookie = (cookie) =>
  document.cookie.split("; ").find((row) => row.startsWith(`${cookie}=`));

export const setCookie = (cookie, value) => {
  const allCookies = document.cookie;

  if (getCookie(cookie)) {
    document.cookie = allCookies.replace(
      getCookie(cookie),
      `${cookie}=${value}`
    );
  } else {
    document.cookie = allCookies.concat(cookie, "=", value);
  }
};

export const getIsUserAlreadyLoggedIn = () => {
  const isUserAlreadyLoggedInValue = getCookie("isUserAlreadyLoggedIn")?.split(
    "="
  )[1];

  return isUserAlreadyLoggedInValue === "true";
};
