import React from "react";
import SaleProcessCard from "components/SaleProcess/SaleProcessCard";
import Text from "components/Text";
import Button from "components/Button";

const KeyDatesCardText = ({ isCompleted, preContractSignatureDate }) => {
  if (isCompleted) {
    return (
      <Text align="center" font="roboto-slab" fontWeight="light">
        Dates clés enregistrées !
      </Text>
    );
  }

  if (preContractSignatureDate) {
    return (
      <Text align="center" font="roboto-slab" fontWeight="light">
        Renseignez les dates clés pour être alerté au bon moment !
      </Text>
    );
  }
  return (
    <Text fontStyle="italic" align="center" fontWeight="light">
      La signature n'a pas encore été confirmée.
    </Text>
  );
};

const KeyDatesCard = ({
  isLate,
  isCompleted,
  setHelpDialogOpen,
  preContractSignatureDate,
  setKeyDatesDialogOpen,
}) => {
  const isAlert = isLate && !isCompleted;

  const buttonAppearance = isAlert ? "reversed-tertiary" : "reversed-primary";

  return (
    <SaleProcessCard
      isAlert={isAlert}
      title="Dates clés & conditions"
      handleHelpClick={() => {
        setHelpDialogOpen(true);
      }}
      actions={
        preContractSignatureDate &&
        !isCompleted && (
          <Button
            appearance={buttonAppearance}
            boxShadow={false}
            onClick={() => setKeyDatesDialogOpen(true)}
          >
            Enregistrer dates
          </Button>
        )
      }
    >
      <KeyDatesCardText
        isCompleted={isCompleted}
        preContractSignatureDate={preContractSignatureDate}
      />
    </SaleProcessCard>
  );
};

export default KeyDatesCard;
