const countDaysSince = (fromDateStr, toDateStr = null) => {
  const fromDate = fromDateStr ? new Date(fromDateStr) : new Date();
  fromDate.setHours(0, 0, 0, 0);

  const toDate = toDateStr ? new Date(toDateStr) : new Date();
  toDate.setHours(0, 0, 0, 0);

  const timeDifference = toDate - fromDate;

  if (timeDifference === 0 || isNaN(timeDifference)) {
    return 0;
  }

  return parseInt(timeDifference / (3600 * 1000 * 24));
};

export default countDaysSince;
