import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle } from "components/Dialog";
import Icon from "components/Icon";
import Container from "components/Container";
import classNames from "classnames";

const ModuleDialog = ({
  icon,
  title,
  open,
  handleClose,
  children,
  noRipple,
  ...rest
}) => {
  const makeClassNames = classNames("module-dialog", {
    "module-dialog--no-ripple": noRipple,
  });
  return (
    <Dialog open={open} handleClose={handleClose} className={makeClassNames}>
      <DialogTitle>
        <Icon icon={icon} size={"big"} />
        {title}
      </DialogTitle>
      <div className="module-dialog__content">
        <Container>{children}</Container>
      </div>
    </Dialog>
  );
};

ModuleDialog.propTypes = {
  ...Dialog.propTypes,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  noRipple: PropTypes.bool,
};

export default ModuleDialog;
