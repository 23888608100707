import React from "react";
import { formatDate } from "utils/date";

const EmailSendLogs = ({ emailSendLogs }) => {
  return (
    <div className="mail-module__history">
      <table>
        <thead>
          <tr>
            <th>Objet</th>
            <th>Date</th>
            <th>Destinataires</th>
          </tr>
        </thead>
        <tbody>
          {emailSendLogs.map((history, index) => (
            <tr key={`history-${index}`}>
              <td>{history.subject}</td>
              <td className="mail-sent-at">
                {formatDate(history.sentAt, true)}
              </td>
              <td>{history.recipient.replace(/,/g, ", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmailSendLogs;
