import React from "react";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import { notifyError, notifySuccess } from "utils/notify";
import SaleProcessCard from "components/SaleProcess/SaleProcessCard";
import Button from "components/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Text from "components/Text";
import Form from "components/Form";
import {
  FILE_TYPES,
  SALE_STEPS,
} from "components/Sale/SaleMultistep/saleConstants";
import HelperDialog from "components/SaleProcess/HelperDialog";
import SaleProcessCheckList from "components/SaleProcess/SaleProcessChecklist";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import { TEMPLATE_REFERENCES } from "utils/email";

const CheckDocumentsCard = ({
  setCheckDocumentDialogOpen,
  isLate,
  isCompleted,
  compromiseStepIsCompleted,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = isLate && !isCompleted;

  const getButtonAppearance = () => {
    if (isCompleted) {
      return "grey";
    }

    if (isAlert) {
      return "reversed-tertiary";
    }

    return "reversed-primary";
  };

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Documents de la vente"
        handleHelpClick={() => setHelperDialogOpen(true)}
        actions={
          !compromiseStepIsCompleted && (
            <Button
              boxShadow={false}
              appearance={getButtonAppearance()}
              onClick={() => setCheckDocumentDialogOpen(true)}
            >
              {isCompleted ? "Modifier" : "Vérifier documents"}
            </Button>
          )
        }
      >
        {isCompleted
          ? "Dossier complet !"
          : "S'assurer que le dossier de vente est complet"}
      </SaleProcessCard>
      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Documents de la vente"
      >
        <Text>
          Cette étape est essentielle pour le bon déroulement de votre vente.
        </Text>
        <Text>
          Votre rôle est de constituer le dossier de vente et vous assurer que
          les notaires ont bien reçu tous les documents nécessaires à la&nbsp;
          <b>rédaction de l’avant-contrat.</b>
        </Text>
        <Text>
          Une <b>liste de documents</b> prédéfinie vous aidera à&nbsp;
          <b>ne rien oublier</b> !
        </Text>
        <Text>
          Vous pouvez aussi, grâce au <b>module mail Elio</b>, demander des
          pièces complémentaires à vos clients et les transmettre en suivant aux
          notaires.
        </Text>
      </HelperDialog>
    </>
  );
};

const BuyerAwarenessCard = ({
  sale,
  setSale,
  checkBuyerAcknowledgedDocumentsSubStep,
  isLate,
  isCompleted,
  checkDocumentsSubStepIsCompleted,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = isLate && !isCompleted;
  const buttonAppearance = isAlert ? "reversed-tertiary" : "reversed-primary";

  const completeSubStep = () => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          ...(checkDocumentsSubStepIsCompleted && {
            saleStep: SALE_STEPS.COMPROMISE,
          }),
          saleSubSteps: [
            {
              "@id": checkBuyerAcknowledgedDocumentsSubStep.id,
              isCompleted: true,
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess("La progression a été enregistrée avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la progression."
        );
      });
  };

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Point documents acquéreur"
        handleHelpClick={() => setHelperDialogOpen(true)}
        actions={
          isCompleted ? null : (
            <Button
              appearance={buttonAppearance}
              boxShadow={false}
              onClick={completeSubStep}
            >
              C'est fait !
            </Button>
          )
        }
      >
        {isCompleted
          ? "Point document effectué !"
          : "S'assurer que l'acquéreur a pris connaissance des documents du bien"}
      </SaleProcessCard>
      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Point document acquéreur"
      >
        <Text>
          En tant que professionnel de l’immobilier, vous avez un{" "}
          <b>devoir de conseil et d’information</b> envers votre client.
        </Text>
        <Text>
          Le point documents est une étape importante de votre vente. Il vous
          permettra de <b>conforter votre client</b> dans son choix et{" "}
          <b>
            d’éviter qu’il ne découvre certains aspects du bien le jour de la
            signature.
          </b>
        </Text>
        <Text>
          Vous pouvez lui envoyer les documents via le <b>module mail Elio</b>{" "}
          ou prendre un rdv pour les lui présenter.
        </Text>
      </HelperDialog>
    </>
  );
};

const getMandatoryDocuments = ({ isCoOwnership }) => {
  const commonMandatoryDocuments = [
    FILE_TYPES.TITLE_DEEDS,
    FILE_TYPES.PROPERTY_TAX,
    FILE_TYPES.DIAGNOSIS,
    FILE_TYPES.CIVIL_STATUS_BUYERS_SELLERS,
  ];

  const coOwnershipMandatoryDocuments = [
    FILE_TYPES.STATEMENT_OF_CHARGES,
    FILE_TYPES.GENERAL_MEETINGS_MINUTES,
    FILE_TYPES.RULES_CO_OWNERSHIP,
    FILE_TYPES.CO_OWNERSHIP_MAINTENANCE_BOOKLET,
    FILE_TYPES.DATED_PRE_STATE,
  ];

  return [
    ...commonMandatoryDocuments,
    ...(isCoOwnership ? coOwnershipMandatoryDocuments : []),
  ].map(({ value, label }) => ({ value, label, isChecked: false }));
};

const CheckDocumentsDialog = ({
  sale,
  setSale,
  checkDocumentsSubStep,
  checkDocumentDialogOpen,
  setCheckDocumentDialogOpen,
  checkBuyerAcknowledgedDocumentsSubStepIsCompleted,
}) => {
  const { setMailModuleOpen } = useSaleModules();
  const { id: saleId } = sale;

  const isCoOwnership = Boolean(sale.coOwnership);

  const mandatoryDocuments = React.useMemo(
    () => getMandatoryDocuments({ isCoOwnership }),
    [isCoOwnership]
  );
  const mandatoryDocumentsValues = mandatoryDocuments.map(({ value }) => value);

  const checkIfChecklistIsCorrelatedToCoOwnershipValue = (savedChecklist) => {
    /* We filter out custom check list document before checking for correlation */
    const savedChecklistValues = savedChecklist
      .map((item) => item.value)
      .filter((item) => !item.includes("document"));

    return savedChecklistValues.length === mandatoryDocumentsValues.length;
  };

  const forgeNewCorrelatedChecklist = (savedChecklist = []) => {
    const forgedChecklistHashMap = [
      ...mandatoryDocuments,
      /* 
        When re-generating the correct check list we don't want non-mandatory documents to be included in the new list
        but we still want to keep the custom documents added in the checklist by the user
      */
      ...savedChecklist.filter(
        (item) =>
          mandatoryDocumentsValues.includes(item.value) ||
          item.value.includes("document")
      ),
    ].reduce((acc, cur) => {
      return { ...acc, [cur.value]: cur };
    }, {});

    return Object.keys(forgedChecklistHashMap).map(
      (key) => forgedChecklistHashMap[key]
    );
  };

  const getDocumentChecklist = () => {
    const savedChecklist =
      checkDocumentsSubStep?.additionalData?.documentChecklist;

    /* If the list is not already persisted in database it can't be wrong because it has been calculated by getMandatoryDocuments */
    const isWrongList = !savedChecklist
      ? false
      : /* If there is a saved check list => check if it is corellated with the current value of isCoOwnership */
        !checkIfChecklistIsCorrelatedToCoOwnershipValue(savedChecklist);

    if (isWrongList) {
      return forgeNewCorrelatedChecklist(savedChecklist);
    }

    return savedChecklist ? savedChecklist : mandatoryDocuments;
  };

  const documentCheckList = getDocumentChecklist() || [];

  const defaultValues = documentCheckList.reduce(
    (acc, document) => ({ ...acc, [document.value]: document.isChecked }),
    {}
  );

  const [checklist, setChecklist] = React.useState(documentCheckList);

  const deleteDocumentFromChecklist = (documentKey) => {
    setChecklist(
      checklist.filter((document) => document.value !== documentKey)
    );
  };

  const handleUpdateChecklist = (values) => {
    let stagedDocument;

    if (values["newDocumentName"] !== "") {
      stagedDocument = handleAddAdditionalDocument(values["newDocumentName"]);
    }

    const updatedChecklist = [
      ...checklist,
      ...(stagedDocument ? [stagedDocument] : []),
    ].map((document) => {
      return { ...document, isChecked: values[document.value] };
    });

    const allDocumentsAreChecked = updatedChecklist.every(
      (document) => document.isChecked
    );

    fetchUrl(
      putSale({
        saleId,
        sale: {
          saleStep: SALE_STEPS.DOCUMENTS,
          ...(allDocumentsAreChecked && {
            saleStep: SALE_STEPS.COMPROMISE,
          }),
          saleSubSteps: [
            {
              "@id": checkDocumentsSubStep.id,
              isCompleted: allDocumentsAreChecked,
              additionalData: { documentChecklist: updatedChecklist },
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess("La progression a été enregistrée avec succès.");
        if (allDocumentsAreChecked) {
          closeCheckDocumentDialog();
        }
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la progression."
        );
      });
  };

  const handleAddAdditionalDocument = (documentName) => {
    const document = {
      value: `document${checklist.length + 1}`,
      label: documentName,
      isChecked: false,
    };
    setChecklist((previousChecklist) => [...previousChecklist, document]);

    return document;
  };

  const closeCheckDocumentDialog = () => {
    setCheckDocumentDialogOpen(false);
  };

  const handleCheckAll = ({ setValue, checklist, ignore }) => {
    const checklistKeys = checklist.map((checklistItem) => checklistItem.value);
    checklistKeys.forEach((valueKey) => {
      if (!ignore.includes(valueKey)) {
        setValue(valueKey, true);
      }
    });
  };

  return (
    <Dialog
      open={checkDocumentDialogOpen}
      handleClose={closeCheckDocumentDialog}
      className="documents-dialog"
    >
      <DialogTitle>
        <Text uppercase size="medium" fontWeight="semi-bold" font="roboto-slab">
          étape 1
        </Text>
        <Text uppercase size="mini" font="roboto-slab">
          Constitution du dossier de vente
        </Text>
      </DialogTitle>
      <Form defaultValues={defaultValues} onSubmit={() => {}}>
        {({ values, setValue }) => (
          <>
            <DialogContent>
              <Text
                size="medium"
                color="primary"
                fontWeight="semi-bold"
                align="center"
                font="roboto-slab"
              >
                Cocher les documents obligatoires disponibles
              </Text>
              <SaleProcessCheckList
                checklist={checklist}
                mandatoryChecklistItems={mandatoryDocumentsValues}
                deleteItemFromChecklist={deleteDocumentFromChecklist}
                handleAddAdditionalChecklistItem={handleAddAdditionalDocument}
                handleCheckAll={() =>
                  handleCheckAll({
                    setValue,
                    checklist,
                    ignore: ["newDocumentName"],
                  })
                }
                inputName="newDocumentName"
                inputPlaceholder="Ajouter un document complémentaire..."
              />
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                appearance="reversed-primary"
                boxShadow={false}
                onClick={() => {
                  setCheckDocumentDialogOpen(false);
                  setMailModuleOpen({
                    open: true,
                    template:
                      TEMPLATE_REFERENCES.requestForAdditionalDocuments.value,
                  });
                }}
              >
                Demander un document
              </Button>
              <Button
                fullWidth
                appearance="primary"
                onClick={() => handleUpdateChecklist(values)}
              >
                Enregistrer
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

const DocumentsStep = ({ sale, setSale }) => {
  const [checkDocumentDialogOpen, setCheckDocumentDialogOpen] =
    React.useState(false);

  const {
    CHECK_DOCUMENTS: checkDocumentsSubStep,
    CHECK_BUYER_ACKNOWLEDGED_DOCUMENTS: checkBuyerAcknowledgedDocumentsSubStep,
  } = sale.saleSteps.DOCUMENTS;

  const compromiseStepIsCompleted = Object.keys(
    sale.saleSteps.COMPROMISE
  ).every((subStepKey) => sale.saleSteps.COMPROMISE[subStepKey].isCompleted);

  return (
    <>
      <div className="sale-process-documents-step">
        <CheckDocumentsCard
          isLate={checkDocumentsSubStep.isLate}
          isCompleted={checkDocumentsSubStep.isCompleted}
          setCheckDocumentDialogOpen={setCheckDocumentDialogOpen}
          compromiseStepIsCompleted={compromiseStepIsCompleted}
        />
        <BuyerAwarenessCard
          sale={sale}
          setSale={setSale}
          checkBuyerAcknowledgedDocumentsSubStep={
            checkBuyerAcknowledgedDocumentsSubStep
          }
          isLate={checkBuyerAcknowledgedDocumentsSubStep.isLate}
          isCompleted={checkBuyerAcknowledgedDocumentsSubStep.isCompleted}
          checkDocumentsSubStepIsCompleted={checkDocumentsSubStep.isCompleted}
        />
      </div>
      <CheckDocumentsDialog
        sale={sale}
        setSale={setSale}
        checkDocumentsSubStep={checkDocumentsSubStep}
        checkDocumentDialogOpen={checkDocumentDialogOpen}
        setCheckDocumentDialogOpen={setCheckDocumentDialogOpen}
        checkBuyerAcknowledgedDocumentsSubStepIsCompleted={
          checkBuyerAcknowledgedDocumentsSubStep.isCompleted
        }
      />
    </>
  );
};

export default DocumentsStep;
