import React from "react";
import Form from "components/Form";
import SubmitButton from "components/Form/SubmitButton";
import { InputText, InputPassword } from "components/Form/Input";
import validationSchema from "./validationSchema";
import { useAuth } from "components/Hooks/useAuth";
import Link from "components/Link";
import Spinner from "components/Spinner";

const LoginForm = () => {
  const [fetching, setFetching] = React.useState(false);
  const { login } = useAuth();

  const handleSubmit = ({ email, password }) => {
    setFetching(true);
    login(email, password).then((success) => {
      if (!success) {
        setFetching(false);
      }
    });
  };

  return (
    <>
      {fetching && <Spinner />}
      <Form
        className="login-form"
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <InputText rounded name="email" placeholder="Email" />
        <InputPassword rounded name="password" placeholder="Mot de passe" />
        <Link to="/forgot-password">Mot de passe oublié ?</Link>
        <SubmitButton appearance="primary" uppercase={false}>
          Me connecter
        </SubmitButton>
      </Form>
    </>
  );
};

export default LoginForm;
