import React from "react";
import fetchUrl from "api/fetchUrl";
import { getNotary, putNotary } from "api/requests";
import validationSchema from "./validationSchema";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Form from "components/Form";
import { InputText } from "components/Form/Input";
import Select from "components/Form/Select";
import Button from "components/Button";
import {
  NOTARY_PREFIX,
  PEOPLE_CIVILITIES,
  PEOPLE_PREFIX,
} from "components/Sale/SaleMultistep/saleConstants";
import Spinner from "components/Spinner";
import InputGoogleMapAutoComplete from "components/Form/Input/InputGoogleAutoComplete";
import { notifyError } from "utils/notify";

const { MLLE, MME, M } = PEOPLE_CIVILITIES;

const EditNotaryDialog = ({
  open,
  setOpen,
  updateNotaryList,
  setValue,
  target,
  notaryId,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [notary, setNotary] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditNotary = ({
    peopleId,
    type,
    civility,
    firstname,
    lastname,
    mobile,
    email,
    city,
    agency,
  }) => {
    const updatedNotary = {
      email,
      city,
      people: {
        "@id": `${PEOPLE_PREFIX}${peopleId}`,
        type,
        civility,
        firstname,
        lastname,
        mobile,
        agency,
      },
    };
    fetchUrl(putNotary({ notary: updatedNotary, notaryId })).then((notary) => {
      updateNotaryList(notary);
      setValue(target, `${NOTARY_PREFIX}${notary.id}`, {
        shouldValidate: true,
      });
      handleClose();
    });
  };

  React.useEffect(() => {
    if (!notaryId) {
      return;
    }
    setLoading(true);
    fetchUrl(getNotary(notaryId))
      .then(
        ({
          people: { id, type, civility, firstname, lastname, mobile, agency },
          email,
          city,
        }) => {
          setNotary({
            peopleId: id,
            type,
            civility,
            firstname,
            lastname,
            mobile,
            email,
            city,
            agency,
          });
          setLoading(false);
        }
      )
      .catch(() => {
        /** We assume the error can only be non-unique email for the moment because we don't get any error message */
        notifyError(
          "Cette adresse mail est déjà utilisée par un autre notaire."
        );
        setLoading(false);
      });
  }, [notaryId]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="edit-notary-dialog"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          mode="all"
          validationSchema={validationSchema}
          defaultValues={notary}
        >
          {({ values, isValid, trigger: triggerValidation }) => (
            <>
              <DialogTitle>MODIFIER UN NOTAIRE</DialogTitle>
              <DialogContent>
                <Select
                  name="civility"
                  options={[MLLE, MME, M]}
                  placeholder="Civilité"
                />
                <InputText name="lastname" placeholder="Nom" />
                <InputText name="firstname" placeholder="Prénom" />
                <InputText name="mobile" placeholder="Téléphone" />
                <InputText name="email" placeholder="Email" />
                <InputGoogleMapAutoComplete
                  onlyCity
                  name="city"
                  placeholder="Ville"
                />
              </DialogContent>
              <div className="edit-client-dialog__disclaimer">
                La modification de la fiche impactera toutes les ventes avec ce
                notaire
              </div>
              <DialogActions>
                <Button fullWidth appearance="ghost" onClick={handleClose}>
                  ANNULER
                </Button>
                <Button
                  fullWidth
                  appearance="primary"
                  onClick={() =>
                    isValid
                      ? handleEditNotary(values)
                      : triggerValidation().then((isValidAfterValidation) => {
                          if (isValidAfterValidation) {
                            return handleEditNotary(values);
                          }
                        })
                  }
                >
                  ENREGISTRER
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      )}
    </Dialog>
  );
};

export default EditNotaryDialog;
