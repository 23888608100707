import MandateAndFinancialInformationsStep from "./MandateAndFinancialInformationsStep";
import validationSchema from "./validationSchema";

const step = {
  StepComponent: MandateAndFinancialInformationsStep,
  validationSchema,
  fields: [
    "id",
    "mandateType",
    "mandateNumber",
    "mandateDate",
    "feeToBePaid",
    "percentageSeller",
    "percentageBuyer",
    "netSellingPrice",
    "fees",
    "partnerAgencyFees",
    "financingMethod",
    "loanDuration",
    "loanRate",
    "buyerAgent",
    "sellerAgent",
    "agencyFees",
    "sellerAgentFees",
    "buyerAgentFees",
  ],
  dependencies: ["peoples"],
};

export default step;
