import React from "react";
import classNames from "classnames";
import { notifyError } from "utils/notify";

const FILE_MAX_SIZE_IN_BYTES = 20000000;

const FileUploader = ({ disabled, multiple, process, fileComponent }) => {
  const [currentFiles, setCurrentFiles] = React.useState([]);

  const handleChange = ({ target }) => {
    if (multiple) {
      const { files } = target;

      Array.from(files).forEach((file) => {
        process(file);
      });
      return;
    }

    const file = target.files[0];

    if (!file) {
      return;
    }

    if (file.size > FILE_MAX_SIZE_IN_BYTES) {
      notifyError("Le fichier est trop volumineux (maximum 20mo)");
      return;
    }

    process(file).finally(() => {
      setCurrentFiles([]);
    });
  };

  return (
    <div
      className={classNames("file-uploader", {
        "file-uploader--disabled": disabled,
      })}
    >
      <input
        disabled={disabled}
        multiple={multiple}
        type="file"
        value={currentFiles}
        onChange={handleChange}
      />
      <div className="controller">{fileComponent}</div>
    </div>
  );
};

export default FileUploader;
