import React from "react";
import GenericLayout from "components/GenericLayout";
import AlertSettings from "components/Settings/AlertSettings";
import EmailSettings from "components/Settings/EmailSettings";
import AnimatedTabs from "components/AnimatedTabs";

const Settings = () => {
  const [currentTab, setCurrentStep] = React.useState(0);

  return (
    <GenericLayout
      headerIcon="clock"
      headerTitle="Paramétrages"
      headerContent={<h1>Alertes et emails</h1>}
    >
      <AnimatedTabs
        tabs={[
          {
            label: "Paramètres alertes",
            onClick: () => {
              setCurrentStep(0);
            },
          },
          {
            label: "Paramétrages emails",
            onClick: () => {
              setCurrentStep(1);
            },
          },
        ]}
      />
      {currentTab === 0 ? <AlertSettings /> : <EmailSettings />}
    </GenericLayout>
  );
};

export default Settings;
