import AgentInChargeStep from "./AgentInChargeStep";
import validationSchema from "./validationSchema";

const step = {
  StepComponent: AgentInChargeStep,
  validationSchema,
  dependencies: ["peoples"],
  showStep: ({ isMasterUser }) => {
    return isMasterUser;
  },
};

export default step;
