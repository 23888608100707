import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import { formatDate } from "utils/date";

const PointSeparator = () => (
  <div className="sale-card-assigned-to__point-separator">&#183;</div>
);

const SaleCardFooter = ({
  isInterAgency = false,
  isActed = false,
  assignedTo,
  createdAt,
}) => {
  return (
    <div className="sale-card-assigned-to">
      {isInterAgency && (
        <>
          <Icon icon="people" />
          <PointSeparator />
        </>
      )}
      <div className="sale-card-assigned-to__name">{assignedTo}</div>
      <PointSeparator />
      {isActed ? (
        <span>Vente actée !</span>
      ) : (
        <>Créée le {formatDate(createdAt)}</>
      )}
    </div>
  );
};

SaleCardFooter.propTypes = {
  isInterAgency: PropTypes.bool,
  isActed: PropTypes.bool,
  assignedTo: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default SaleCardFooter;
