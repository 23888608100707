import React from "react";
import {
  getInitialDefaultValues,
  convertJsonPlanningEvents,
  computeAvailabilities,
  makeOwnerTags,
  mergeNewEventIfOverlappingPreviousEvents,
  addIdentifiersToEvents,
  getCurrentlyEditedEventOwners,
  getEventsFormattedForCalendar,
} from "./planningUtils";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";

const initialDefaultValues = getInitialDefaultValues();

const usePlanning = ({ sale, isMobile }) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [events, setEvents] = React.useState(
    convertJsonPlanningEvents(sale.planningEvents || [])
  );
  const [defaultValues, setDefaultValues] =
    React.useState(initialDefaultValues);
  const [currentlyEditedEvent, setCurrentlyEditedEvent] = React.useState(null);
  const [selectOwnersDialogOpen, setSelectOwnersDialogOpen] =
    React.useState(false);

  const availabilities = React.useMemo(
    () => computeAvailabilities(events),
    [events]
  );
  const ownerTags = React.useMemo(() => makeOwnerTags(), []);
  const calendarEvents = React.useMemo(
    () => getEventsFormattedForCalendar(events),
    [events]
  );

  const getCurrentEventsWithoutCurrentlyEditedEvent = () => {
    const selectedEventIndex = events.findIndex(
      (event) => event.id === currentlyEditedEvent.id
    );
    const currentEvents = [...events];
    currentEvents.splice(selectedEventIndex, 1);

    setCurrentlyEditedEvent(null);
    return currentEvents;
  };

  const handleSelectOwners = (values, { reset }) => {
    const currentEvents = currentlyEditedEvent
      ? getCurrentEventsWithoutCurrentlyEditedEvent()
      : events;

    const owners = Object.keys(values).filter((key) => values[key]);
    const newEvent = { ...currentEvent, owners: owners.sort() };

    const mergedEvents = mergeNewEventIfOverlappingPreviousEvents(
      currentEvents,
      newEvent
    );

    const eventsWithIdentifiers = addIdentifiersToEvents(mergedEvents);

    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          planningEvents: eventsWithIdentifiers,
        },
      })
    )
      .then(() => {
        setEvents(eventsWithIdentifiers);
        setSelectOwnersDialogOpen(false);
        reset(initialDefaultValues);
      })
      .catch(() => {});
  };

  const handleEditSelectedOwners = ({ id, start, end, owners }) => {
    /**
     * Mark currently edited event to delete it later to prevent undesired behavior
     * Basicly we will replace the event instead of editing it because
     * it is easier to add an event than to edit it due to the fact that we have to merge the adjacent/overlapping events.
     */
    setCurrentlyEditedEvent({ id, owners });
    setCurrentEvent({ start, end, owners });
    const currentlyEditedEventOwners = getCurrentlyEditedEventOwners(owners);
    setDefaultValues(currentlyEditedEventOwners);
    setSelectOwnersDialogOpen(true);
  };

  const handleSelectSlot = ({ start, end }) => {
    setCurrentEvent({ start, end });
    setSelectOwnersDialogOpen(true);
  };

  const handleDateChange = (date) => {
    if (isMobile) {
      document.getElementById("calendar").scrollIntoView();
    }
    setCurrentDate(date);
  };

  const handleDeleteEvent = (eventId) => {
    const updatedEvents = events.filter((event) => event.id !== eventId);

    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          planningEvents: updatedEvents,
        },
      })
    ).then(() => {
      setEvents(updatedEvents);
    });
  };

  return {
    currentDate,
    setCurrentDate,
    handleDateChange,
    events,
    defaultValues,
    setDefaultValues,
    availabilities,
    ownerTags,
    handleSelectOwners,
    handleEditSelectedOwners,
    currentlyEditedEvent,
    setCurrentlyEditedEvent,
    selectOwnersDialogOpen,
    setSelectOwnersDialogOpen,
    calendarEvents,
    handleSelectSlot,
    handleDeleteEvent,
  };
};

export default usePlanning;
