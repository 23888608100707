import React from "react";
import { useAuth } from "components/Hooks/useAuth";
import GenericLayout from "components/GenericLayout";
import ErrorMessage from "components/ErrorMessage";
import AnimatedTabs from "components/AnimatedTabs";
import UserCard from "components/User/UserCard";
import Spinner from "components/Spinner";
import AddUserCard from "components/User/AddUserCard";
import AddUserDialog from "components/User/dialogs/AddUserDialog";
import EditUserDialog from "components/User/dialogs/EditUserDialog";
import DeleteUserDialog from "components/User/dialogs/DeleteUserDialog";
import UserAccountsLeft from "components/User/UserAccountsLeft";
import {
  isMasterUser,
  isCurrentUser,
  isUserStatusActive,
  hasActiveStatusFilterEnabled,
  makeUserPostBody,
  makeUserPutBody,
  USER_STATUS_TYPES,
  USER_API_ERRORS,
} from "utils/user";
import fetchUrl from "api/fetchUrl";
import {
  getAgencyPeople,
  postUser,
  putUser,
  putPeople,
  deleteUser,
  getAgency,
} from "api/requests";

import { notifyError, notifySuccess } from "utils/notify";
import { extractDataFromLdJsonResponse, isLastPage } from "utils/data";
import Button from "components/Button";

const UserSettings = () => {
  const { user } = useAuth();
  const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = React.useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = React.useState(false);
  const [users, setUsers] = React.useState({
    data: [],
    pagination: {},
  });
  const [filters, setFilters] = React.useState([
    { name: "status", value: USER_STATUS_TYPES.ACTIVE.value },
  ]);
  const [loading, setLoading] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [editedUser, setEditedUser] = React.useState(null);
  const [accountsLeft, setAccountsLeft] = React.useState(null);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const limitSeatsErrorMessage =
    "Vous ne disposez pas de suffisamment de licences utilisateurs";

  /* 
    Hotfix to remove pagination on this page, we let the pagination but we increase the items per page to 999
    We don't know if the client will want a pagination in the next versions so we let the pagination code.
    See : https://projets.occitech.fr/issues/17715
  */
  const ITEMS_PER_PAGE = 999;

  const agencyId = user?.people?.agency?.id;

  const fetchMoreUsers = () => {
    if (isLastPage(users.pagination)) {
      return false;
    }
    const retrieveFetchMore = () => {
      return fetchUrl(
        getAgencyPeople(
          agencyId,
          users.pagination.next,
          ITEMS_PER_PAGE,
          filters
        )
      );
    };
    retrieveFetchMore()
      .then((response) => {
        const extractedData = extractDataFromLdJsonResponse(response);
        setUsers({
          data: [...users?.data, ...extractedData.data],
          pagination: extractedData.pagination,
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleDeleteUserSubmit = (userId) => {
    fetchUrl(deleteUser(userId))
      .then(() => {
        notifySuccess("L'utilisateur a bien été supprimé");
        setOpenDeleteUserDialog(false);
        setEditedUser(null);
      })
      .catch(() => {
        notifyError("Une erreur est survenue.");
      });
  };

  const handleAddUserSubmit = (values) => {
    const body = makeUserPostBody(values, agencyId);
    fetchUrl(postUser(body))
      .then(() => {
        notifySuccess("L'utilisateur a bien été créé");
        setOpenAddUserDialog(false);
        setEditedUser(null);
      })
      .catch((error) => {
        if (error.detail === USER_API_ERRORS.TOTAL_LIMIT_SEATS_EXCEEDED) {
          notifyError(limitSeatsErrorMessage);
        } else {
          notifyError("Une erreur est survenue.");
        }
      });
  };

  const handleEditUserSubmit = (values, userId, peopleId) => {
    const body = makeUserPutBody(values, peopleId, agencyId);
    fetchUrl(putUser({ userId: userId, user: body }))
      .then(() => {
        notifySuccess("L'utilisateur a bien été modifié");
        setOpenEditUserDialog(false);
        setEditedUser(null);
      })
      .catch(() => {
        notifyError("Une erreur est survenue.");
      });
  };

  const handleActivateUser = (activate, peopleId) => {
    const statusValue = activate ? 1 : 0;
    const successMessage = activate
      ? "L'utilisateur a bien été activé"
      : "L'utilisateur a bien été désactivé";
    fetchUrl(putPeople({ peopleId: peopleId, people: { status: statusValue } }))
      .then(() => {
        let seatsLeft = activate ? accountsLeft - 1 : accountsLeft + 1;
        setAccountsLeft(seatsLeft);
        notifySuccess(successMessage);
        setEditedUser({ ...editedUser, status: statusValue });
      })
      .catch((error) => {
        if (error.detail === USER_API_ERRORS.TOTAL_LIMIT_SEATS_EXCEEDED) {
          notifyError(limitSeatsErrorMessage);
        } else {
          notifyError("Une erreur est survenue.");
        }
      });
  };

  const userSettingsTabs = [
    {
      label: "Utilisateurs actifs",
      value: USER_STATUS_TYPES.ACTIVE.value,
      onClick: () => {
        setActiveTabIndex(0);
        setFilters([
          { name: "status", value: USER_STATUS_TYPES.ACTIVE.value },
          { name: "people_user_roles", value: "MASTER" },
        ]);
      },
    },
    {
      label: "Utilisateurs désactivés",
      value: USER_STATUS_TYPES.INACTIVE.value,
      onClick: () => {
        setActiveTabIndex(1);
        setFilters([
          { name: "status", value: USER_STATUS_TYPES.INACTIVE.value },
          { name: "isMasterUser", value: true },
        ]);
      },
    },
  ];

  const getSalesCount = (people) => {
    return (
      (people?.salesAsBuyerAgent?.length || 0) +
      (people?.salesAsSellerAgent?.length || 0)
    );
  };

  const isDeletable = (currentUser, people) => {
    return (
      !isCurrentUser(currentUser, people) &&
      !isMasterUser(people.user?.roles) &&
      !isUserStatusActive(people) &&
      getSalesCount(people) === 0 &&
      !people.assignedSales?.length
    );
  };

  React.useEffect(() => {
    if (!agencyId) {
      return;
    }
    setLoading(true);
    fetchUrl(getAgencyPeople(agencyId, 1, ITEMS_PER_PAGE, filters))
      .then((response) => {
        const usersResponse = extractDataFromLdJsonResponse(response);
        if (
          filters.some(
            ({ name, value }) =>
              name === "status" && value === USER_STATUS_TYPES.ACTIVE.value
          )
        ) {
          fetchUrl(getAgency(agencyId)).then((agency) => {
            const totalSeats = agency.subscription?.seats || 0;
            const seatsLeft =
              totalSeats - usersResponse.pagination.totalItems > 0
                ? totalSeats - usersResponse.pagination.totalItems
                : 0;
            setAccountsLeft(seatsLeft);
          });
        }
        setUsers({
          data: usersResponse.data,
          pagination: usersResponse.pagination,
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [agencyId, user, editedUser, filters, activeTabIndex, openAddUserDialog]);

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <GenericLayout
      headerIcon="user-settings"
      headerTitle="Gestion des utilisateurs"
      headerContent={
        <h1 className="user-settings__header-content">
          {userSettingsTabs[activeTabIndex].label}
        </h1>
      }
    >
      <AnimatedTabs tabs={userSettingsTabs} />
      {!loading ? (
        <>
          <UserAccountsLeft accountsLeft={accountsLeft} />
          <div className="user-settings__users-list">
            {users?.pagination?.totalItems > 0 &&
              users?.data?.map((people) => (
                <UserCard
                  key={`${people.lastname}_${people.firstname}`}
                  user={people}
                  lastname={people.lastname}
                  firstname={people.firstname}
                  salesCount={getSalesCount(people)}
                  isDeletable={isDeletable(user, people)}
                  handleDelete={(e) => {
                    e.stopPropagation();
                    setEditedUser(people);
                    setOpenDeleteUserDialog(true);
                  }}
                  handleEdit={() => {
                    setEditedUser(people);
                    setOpenEditUserDialog(true);
                  }}
                />
              ))}
            {accountsLeft && hasActiveStatusFilterEnabled(filters) ? (
              <AddUserCard setOpenAddUserDialog={setOpenAddUserDialog} />
            ) : null}

            {/* DIALOGS HERE */}
            <EditUserDialog
              user={editedUser}
              open={openEditUserDialog}
              setOpen={setOpenEditUserDialog}
              isCurrentUser={isCurrentUser(user, editedUser)}
              handleSubmit={handleEditUserSubmit}
              handleActivate={handleActivateUser}
            />
            <DeleteUserDialog
              user={editedUser}
              open={openDeleteUserDialog}
              setOpen={setOpenDeleteUserDialog}
              handleSubmit={handleDeleteUserSubmit}
            />
            <AddUserDialog
              open={openAddUserDialog}
              setOpen={setOpenAddUserDialog}
              handleSubmit={handleAddUserSubmit}
            />
            {/* END DIALOGS HERE */}
          </div>

          {!isLastPage(users.pagination) && (
            <div className="user-settings__fetch-more">
              <Button appearance="ghost" onClick={() => fetchMoreUsers()}>
                Afficher plus d'utilisateurs ...
              </Button>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </GenericLayout>
  );
};

export default UserSettings;
