import React from "react";
import fetchUrl from "api/fetchUrl";
import {
  getClientsWithPaginate,
  getNotariesWithPaginate,
  postClient,
  putClient,
  postNotary,
  putNotary,
} from "api/requests";
import { extractDataFromLdJsonResponse, isLastPage } from "utils/data";
import ContactCards from "./ContactCards";
import TabContent from "./TabContent";
import GenericLayout from "components/GenericLayout";
import AnimatedTabs from "components/AnimatedTabs";
import Spinner from "components/Spinner";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import {
  PEOPLE_TYPES,
  AGENCY_PREFIX,
  PEOPLE_PREFIX,
} from "components/Sale/SaleMultistep/saleConstants";
import ContactDirectoryForm from "./ContactDirectoryForm";
import { useAuth } from "components/Hooks/useAuth";
import { Dialog } from "components/Dialog";
import {
  validationSchemaClient,
  validationSchemaNotary,
} from "./validationSchema";

const ITEMS_PER_PAGE = 4;
const { INDIVIDUAL } = PEOPLE_TYPES;

const ContactDirectory = () => {
  const [currentTab, setCurrentStep] = React.useState(0);
  const [contacts, setContacts] = React.useState({
    data: [],
    pagination: {},
  });
  const [search, setSearch] = React.useState("");
  const [toggleRefetch, setToggleRefetch] = React.useState(false);
  const [selectedEntityId, setSelectedEntityId] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [selectedFormData, setSelectedFormData] = React.useState("");
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { user } = useAuth();
  const isClientTab = currentTab === 0;

  React.useEffect(() => {
    const request = isClientTab
      ? getClientsWithPaginate
      : getNotariesWithPaginate;

    setLoading(true);

    fetchUrl(request(1, ITEMS_PER_PAGE, search))
      .then((response) => {
        const extractedData = extractDataFromLdJsonResponse(response);

        setContacts({
          data: extractedData.data,
          pagination: extractedData.pagination || {},
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, [
    currentTab,
    setContacts,
    setLoading,
    setError,
    toggleRefetch,
    isClientTab,
    search,
  ]);

  const fetchMoreContacts = () => {
    if (isLastPage(contacts.pagination)) {
      return false;
    }

    const request = isClientTab
      ? getClientsWithPaginate
      : getNotariesWithPaginate;

    fetchUrl(request(contacts.pagination.next, ITEMS_PER_PAGE))
      .then((response) => {
        const extractedData = extractDataFromLdJsonResponse(response);

        setContacts({
          data: [...contacts.data, ...extractedData.data],
          pagination: extractedData.pagination,
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmitClient = ({
    type,
    companyName,
    civility,
    firstname,
    lastname,
    mobile,
    email,
  }) => {
    const client = {
      email,
      people: {
        ...(selectedFormData === "editClient"
          ? {
              "@id": `${PEOPLE_PREFIX}${currentContact.people.id}`,
            }
          : []),
        type,
        companyName: type !== INDIVIDUAL.value ? companyName : null,
        civility,
        firstname,
        lastname,
        mobile,
        agency: `${AGENCY_PREFIX}${user.people.agency.id}`,
      },
    };

    const request = selectedEntityId
      ? putClient({ client, clientId: selectedEntityId })
      : postClient(client);

    fetchUrl(request).then(() => {
      setSelectedFormData("");
      setIsDialogOpen(false);
      setToggleRefetch(!toggleRefetch);
    });
  };

  const handleSubmitNotary = ({
    civility,
    firstname,
    lastname,
    mobile,
    email,
    city,
  }) => {
    const notary = {
      email,
      city,
      people: {
        ...(selectedFormData === "editNotary"
          ? {
              "@id": `${PEOPLE_PREFIX}${currentContact.people.id}`,
            }
          : []),
        type: PEOPLE_TYPES.INDIVIDUAL.value,
        civility,
        firstname,
        lastname,
        mobile,
        agency: `${AGENCY_PREFIX}${user.people.agency.id}`,
      },
    };

    const request = selectedEntityId
      ? putNotary({ notary, notaryId: selectedEntityId })
      : postNotary(notary);

    fetchUrl(request).then(() => {
      setSelectedFormData("");
      setIsDialogOpen(false);
      setToggleRefetch(!toggleRefetch);
    });
  };

  const formatContact = (entityData) => {
    if (!entityData) {
      return null;
    }
    const { email } = entityData;
    const { type, companyName, civility, lastname, firstname, mobile } =
      entityData?.people;

    return {
      type,
      companyName,
      civility,
      lastname,
      firstname,
      mobile,
      email,
    };
  };

  const formatClient = (clientEntityData) => {
    if (!clientEntityData) {
      return {};
    }
    return formatContact(clientEntityData);
  };

  const formatNotary = (notaryEntityData) => {
    if (!notaryEntityData) {
      return {};
    }
    return { ...formatContact(notaryEntityData), city: notaryEntityData.city };
  };

  const currentContact = React.useMemo(() => {
    return contacts.data.find((contact) => contact.id === selectedEntityId);
  }, [contacts, selectedEntityId]);

  const formData = {
    addClient: {
      title: "Créer un nouveau client",
      callback: (values) => handleSubmitClient(values),
      validationSchema: validationSchemaClient,
    },
    addNotary: {
      title: "Créer un nouveau notaire",
      callback: (values) => handleSubmitNotary(values),
      validationSchema: validationSchemaNotary,
    },
    editClient: {
      title: "éditer un client",
      callback: (values) => handleSubmitClient(values),
      validationSchema: validationSchemaClient,
      defaultValues: formatClient(currentContact),
    },
    editNotary: {
      title: "éditer un notaire",
      callback: (values) => handleSubmitNotary(values),
      validationSchema: validationSchemaNotary,
      defaultValues: formatNotary(currentContact),
    },
  };

  if (error) {
    return (
      <ErrorMessage customMessage="Une erreur est survenue lors du chargement des clients" />
    );
  }

  return (
    <GenericLayout
      noPadding
      headerIcon="agenda"
      headerTitle="Répertoire"
      headerContent={
        <h1 className="header-content__contact-directory-title">
          Clients
          <br /> & notaires
        </h1>
      }
    >
      <Dialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        className="clients-notaries-dialog"
      >
        {selectedFormData && (
          <Form
            defaultValues={formData[selectedFormData].defaultValues}
            mode="all"
            onSubmit={(values) => formData[selectedFormData].callback(values)}
            validationSchema={formData[selectedFormData].validationSchema}
          >
            {({ values }) => (
              <ContactDirectoryForm
                title={formData[selectedFormData].title}
                setSelectedFormData={setSelectedFormData}
                setIsDialogOpen={setIsDialogOpen}
                isClientTab={isClientTab}
                values={values}
              />
            )}
          </Form>
        )}
      </Dialog>
      <div className="contact-directory__tabs">
        <AnimatedTabs
          tabs={[
            {
              label: "Clients",
              onClick: () => {
                setCurrentStep(0);
              },
            },
            {
              label: "Notaires",
              onClick: () => {
                setCurrentStep(1);
              },
            },
          ]}
          currentTab={currentTab}
        />
        <TabContent
          setSearch={setSearch}
          setSelectedFormData={setSelectedFormData}
          isClientTab={isClientTab}
          setIsDialogOpen={setIsDialogOpen}
        />
      </div>
      <div className="contact-directory__content">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="contact-directory__title">
              Derniers enregistrements
            </div>
            <ContactCards
              contacts={contacts.data}
              setSelectedEntityId={setSelectedEntityId}
              setSelectedFormData={setSelectedFormData}
              isClientTab={isClientTab}
              setIsDialogOpen={setIsDialogOpen}
            />
            {!isLastPage(contacts.pagination) && (
              <div className="contact-directory__fetch-more">
                <Button appearance="ghost" onClick={() => fetchMoreContacts()}>
                  Afficher plus de {isClientTab ? "clients" : "notaires"} ...
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </GenericLayout>
  );
};

export default ContactDirectory;
