import classNames from "classnames";
import React from "react";
import Icon from "../Icon";

const Spinner = ({ fixed = true }) => {
  return (
    <div className={classNames("spinner", { "spinner--fixed": fixed })}>
      <Icon icon="spinner" size="very-big" />
    </div>
  );
};

export default Spinner;
