import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

const LinkButton = ({ to, children, ...buttonProps }) => {
  return (
    <Button {...buttonProps} to={to} isLink={true}>
      {children}
    </Button>
  );
};

LinkButton.propTypes = {
  ...Button.propTypes,
  to: PropTypes.string.isRequired,
};

LinkButton.defaultProps = {
  ...Button.defaultProps,
};

export default LinkButton;
