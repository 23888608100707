import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Text from "components/Text";
import LinkButton from "components/Button/LinkButton";
import { useBusinessOfferDialog } from "components/Hooks/useBusinessOfferDialog";

const BusinessOfferDialog = () => {
  const { businessOfferDialogOpen, setBusinessOfferDialogOpen } =
    useBusinessOfferDialog();

  return (
    <Dialog
      className="business-offer-dialog"
      open={businessOfferDialogOpen}
      handleClose={() => setBusinessOfferDialogOpen(false)}
    >
      <DialogTitle>
        <Text
          size="extra-large"
          align="center"
          fontWeight="semi-bold"
          font="roboto-slab"
          color="white"
        >
          Merci d'utiliser Elio !
        </Text>
      </DialogTitle>
      <DialogContent>
        <Text color="dark" align="center" size="large" marginBottom="large">
          Vous ne disposez plus de crédits ventes
        </Text>
        <Text align="center" size="small">
          Pour profiter pleinement d'Elio, découvrez nos offres Business
        </Text>
      </DialogContent>
      <DialogActions>
        <LinkButton
          appearance="reversed-primary"
          to={{ pathname: "https://www.elio-app.com/tarifs" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          NOS OFFRES
        </LinkButton>
      </DialogActions>
    </Dialog>
  );
};

export default BusinessOfferDialog;
