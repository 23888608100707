import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Container = ({ noPadding, flex, children }) => {
  const containerClassNames = classNames("container", {
    "container--no-padding": noPadding,
    "container--flex": flex,
  });
  return <div className={containerClassNames}>{children}</div>;
};

export default Container;

Container.propTypes = {
  noPadding: PropTypes.bool,
  flex: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
