import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import { priceDutyFree } from "utils/number";

const StatisticsCard = ({
  index,
  title,
  icon,
  fees,
  numberOfSales,
  isFeesUp,
  isNumberOfSalesUp,
}) => {
  const isWhiteCard = index === 1;
  const isLastCard = index === 2;

  return (
    <div className={`statistics-card ${isWhiteCard ? "white-card" : ""}`}>
      <div className="statistics-card__header">
        <h3>
          {title}
          {icon}
        </h3>
      </div>
      <div className="statistics-card__content">
        <div className="stat-realised">
          <div className="stat-realised__title">CA Acté</div>
          <div className="stat-realised__nb">
            {fees}
            <sup>€</sup>
          </div>
          <div
            className={`stat-realised__mention ${isLastCard && "last-card"}`}
          >
            soit {priceDutyFree(fees)} € HT
          </div>
          {typeof isFeesUp === "boolean" && (
            <Icon
              icon={`${isFeesUp ? "arrow-up" : "arrow-down"}`}
              size="medium"
              color="primary"
            />
          )}
        </div>
        <div className="stat-nb-sale">
          <div className="stat-realised__title">Ventes actées</div>
          <div className={`stat-realised__nb ${isLastCard && "last-card"}`}>
            {isLastCard ? (
              <>
                <Icon icon="moustache-left" size="medium" color="white" />
                <span>{numberOfSales}</span>
                <Icon icon="moustache-right" size="medium" color="white" />
              </>
            ) : (
              <span>{numberOfSales}</span>
            )}
            {typeof isNumberOfSalesUp === "boolean" && (
              <div className="stat-realised__icon">
                <Icon
                  icon={`${isNumberOfSalesUp ? "arrow-up" : "arrow-down"}`}
                  size="medium"
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StatisticsCard.propTypes = {
  title: PropTypes.any.isRequired,
  fees: PropTypes.number.isRequired,
  numberOfSales: PropTypes.number.isRequired,
};

export default StatisticsCard;
