import React from "react";
import { useHistory } from "react-router-dom";
import { denormalizeNumber } from "utils/number";
import SaleLayout from "components/Sale/SaleLayout";
import Spinner from "components/Spinner";
import fetchUrl from "api/fetchUrl";
import {
  getSale,
  getAnnexes,
  getPeoples,
  getClients,
  getNotaries,
} from "api/requests";
import { convertDateToFormDate } from "utils/convertDate";
import {
  AGENCY_FEES_PREFIX,
  CO_OWNERSHIP_PREFIX,
  PEOPLE_PREFIX,
  MANDATE_PREFIX,
  FINANCIAL_INFORMATION_PREFIX,
  ANNEX_PREFIX,
  CLIENT_PREFIX,
  NOTARY_PREFIX,
  SALE_PARTNER_AGENCY_PREFIX,
  SALE_STATUS,
} from "components/Sale/SaleMultistep/saleConstants";
import steps from "components/Sale/SaleMultistep/steps";
import SaleMultistep from "components/Sale/SaleMultistep";
import ChooseSaleType from "components/Sale/SaleMultistep/steps/ChooseSaleType";
import { useAuth } from "components/Hooks/useAuth";

const formatDefaultValues = ({
  assignedTo,
  numberOfRooms,
  area,
  propertySoldRented,
  coOwnership,
  itemAnnexes,
  mandate,
  financialInformation,
  agencyFees,
  sellerAndNotary,
  buyerAndNotary,
  offerDate,
  partnerAgency,
  buyerAgent,
  sellerAgent,
  ...restSale
}) => {
  const isPropertySoldRented = Boolean(propertySoldRented);
  const isCoOwnership = Boolean(coOwnership);
  const annexes = itemAnnexes?.reduce((acc, cur) => {
    return { ...acc, [`${ANNEX_PREFIX}${cur.id}`]: true };
  }, {});

  const { mandateDate, percentageBuyer, percentageSeller, ...restMandate } =
    mandate || {};

  const {
    fees,
    partnerAgencyFees,
    netSellingPrice,
    loanDuration,
    loanRate,
    ...restFinancialInformation
  } = financialInformation || {};

  const {
    agencyFees: agencyFeesValue,
    sellerAgentFees,
    buyerAgentFees,
    ...restAgencyFees
  } = agencyFees || {};

  const sellersAndNotaries = sellerAndNotary?.reduce(
    (acc, { client, notary }, index) => {
      const sellerIndex = `seller${index}`;
      const notaryIndex = `${sellerIndex}_notary`;

      return {
        ...acc,
        ...{
          [sellerIndex]: `${CLIENT_PREFIX}${client.id}`,
          [notaryIndex]: `${NOTARY_PREFIX}${notary.id}`,
        },
      };
    },
    {}
  );

  const buyersAndNotaries = buyerAndNotary?.reduce(
    (acc, { client, notary }, index) => {
      const buyerIndex = `buyer${index}`;
      const notaryIndex = `${buyerIndex}_notary`;

      return {
        ...acc,
        ...{
          [buyerIndex]: `${CLIENT_PREFIX}${client.id}`,
          [notaryIndex]: `${NOTARY_PREFIX}${notary.id}`,
        },
      };
    },
    {}
  );

  const {
    city: agencyCity,
    firstName: agentFirstName,
    lastName: agentLastName,
    mobile: agentMobile,
    email: agentEmail,
    ...restPartnerAgency
  } = partnerAgency || {};

  const defaultValues = {
    assignedTo: `${PEOPLE_PREFIX}${assignedTo.id}`,
    numberOfRooms: denormalizeNumber(numberOfRooms),
    area: denormalizeNumber(area),
    propertySoldRented: String(Number(isPropertySoldRented)),
    coOwnership: String(Number(isCoOwnership)),
    ...(coOwnership && {
      numberOfLots: coOwnership.numberOfLots,
      year: coOwnership.year,
    }),
    ...annexes,
    mandateDate: convertDateToFormDate(mandateDate),
    ...restMandate,
    percentageSeller: denormalizeNumber(percentageSeller),
    percentageBuyer: denormalizeNumber(percentageBuyer),
    fees: denormalizeNumber(fees),
    partnerAgencyFees: denormalizeNumber(partnerAgencyFees),
    netSellingPrice: denormalizeNumber(netSellingPrice),
    loanDuration: denormalizeNumber(loanDuration),
    loanRate: denormalizeNumber(loanRate),
    ...restFinancialInformation,
    agencyFees: denormalizeNumber(agencyFeesValue) || 100,
    sellerAgent: sellerAgent?.id || "",
    sellerAgentFees: denormalizeNumber(sellerAgentFees) || 0,
    buyerAgent: buyerAgent?.id || "",
    buyerAgentFees: denormalizeNumber(buyerAgentFees) || 0,
    ...restAgencyFees,
    ...restSale,
    ...sellersAndNotaries,
    ...buyersAndNotaries,
    offerDate: convertDateToFormDate(offerDate),
    agencyCity,
    agentFirstName,
    agentLastName,
    agentMobile,
    agentEmail,
    ...restPartnerAgency,
  };

  return defaultValues;
};

const formatSale = ({
  coOwnership,
  financialInformation,
  mandate,
  agencyFees,
  partnerAgency,
  sellerAgent,
  buyerAgent,
  ...restSale
}) => {
  const { id: coOwnershipId, ...restCoOwnership } = coOwnership || {};

  const { id: financialInformationId, ...restFinancialInformation } =
    financialInformation || {};

  const { id: mandateId, ...restMandate } = mandate || {};

  const {
    id: agencyFeesId,

    ...restAgencyFees
  } = agencyFees || {};

  const { id: partnerAgencyId, ...restPartnerAgency } = partnerAgency || {};

  return {
    ...(coOwnership && {
      coOwnership: {
        "@id": `${CO_OWNERSHIP_PREFIX}${coOwnershipId}`,
        ...restCoOwnership,
      },
    }),
    ...(financialInformation && {
      financialInformation: {
        "@id": `${FINANCIAL_INFORMATION_PREFIX}${financialInformationId}`,
        ...restFinancialInformation,
      },
    }),
    ...(mandate && {
      mandate: {
        "@id": `${MANDATE_PREFIX}${mandateId}`,
        ...restMandate,
      },
    }),
    ...(agencyFees && {
      agencyFees: {
        "@id": `${AGENCY_FEES_PREFIX}${agencyFeesId}`,

        ...restAgencyFees,
      },
    }),
    ...(partnerAgency && {
      partnerAgency: {
        "@id": `${SALE_PARTNER_AGENCY_PREFIX}${partnerAgencyId}`,
        ...restPartnerAgency,
      },
    }),
    sellerAgent: sellerAgent
      ? { ...sellerAgent, id: `${PEOPLE_PREFIX}${sellerAgent.id}` }
      : null,
    buyerAgent: buyerAgent
      ? { ...buyerAgent, id: `${PEOPLE_PREFIX}${buyerAgent.id}` }
      : null,
    ...restSale,
  };
};

const Sale = ({ match, edit }) => {
  const saleId = match?.params?.saleId;
  const isEdit = Boolean(edit);
  const history = useHistory();
  const defaultCurrentStep = history?.location?.state?.defaultCurrentStep;
  const { user } = useAuth();
  const [sale, setSale] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState(defaultCurrentStep || 0);
  const [loading, setLoading] = React.useState(true);
  const [formDependencies, setFormDependencies] = React.useState(null);
  const [saleType, setSaleType] = React.useState(null);

  const filteredSteps = React.useMemo(
    () =>
      steps.filter(({ showStep }) => {
        return (
          typeof showStep !== "function" ||
          showStep({ saleType, isMasterUser: user.isMasterUser })
        );
      }),
    [saleType, user.isMasterUser]
  );

  React.useEffect(() => {
    const retrieveFormData = async () => {
      const [annexes, peoples, clients, notaries] = await Promise.all([
        fetchUrl(getAnnexes()),
        fetchUrl(getPeoples()),
        fetchUrl(getClients()),
        fetchUrl(getNotaries()),
      ]);

      return {
        annexes,
        peoples: peoples.map(({ id, firstname, lastname, user = null }) => ({
          id,
          firstname,
          lastname,
          isAUser: Boolean(user),
        })),
        clients: clients.map(({ id, people }) => ({
          id,
          firstname: people.firstname,
          lastname: people.lastname,
        })),
        notaries: notaries.map(({ id, people }) => ({
          id,
          firstname: people.firstname,
          lastname: people.lastname,
        })),
      };
    };

    const retrieveSale = async (saleId) => {
      const sale = await fetchUrl(getSale(saleId));
      return sale;
    };

    const handleSaleAccess = (isEdit, sale) => {
      const cannotAccessEditPage = isEdit && sale.status === SALE_STATUS.DRAFT;

      const cannotAccessCreateEditPage =
        !isEdit && saleId && sale.status !== SALE_STATUS.DRAFT;
      if (cannotAccessEditPage || cannotAccessCreateEditPage) {
        return history.push("/404");
      }
    };

    retrieveFormData().then((dependencies) => {
      setFormDependencies(dependencies);
      if (saleId) {
        retrieveSale(saleId)
          .then((sale) => {
            handleSaleAccess(isEdit, sale);
            setSaleType(sale.saleType);
            if (defaultCurrentStep) {
              setCurrentStep(defaultCurrentStep);
            } else {
              const _currentStep = isEdit ? null : sale.draftStep;
              const sanitazedCurrentStep =
                _currentStep >= filteredSteps.length
                  ? filteredSteps.length - 1
                  : _currentStep;

              setCurrentStep(sanitazedCurrentStep);
            }
            const formattedSale = formatSale(sale);
            setSale(formattedSale);
            setLoading(false);
          })
          .catch(() => {
            /**
             * If an ID is provided but for some reason
             * - the sale doesnt exist
             * - the user is not permitted to see this sale
             * We redirect him to the new sale page
             */
            history.push("/sales/new");
          });
      } else {
        setLoading(false);
      }
    });
  }, [saleId, history, isEdit, defaultCurrentStep, filteredSteps]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <SaleLayout
      currentStep={currentStep}
      steps={steps}
      saleType={saleType}
      isEdit={isEdit}
      sale={sale}
      saleId={saleId}
    >
      {saleType ? (
        <SaleMultistep
          isEdit={isEdit}
          defaultValues={
            saleId
              ? formatDefaultValues(sale)
              : { coOwnership: "0", propertySoldRented: "0" }
          }
          saleType={saleType}
          sale={sale}
          setSale={setSale}
          steps={filteredSteps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          {...formDependencies}
        />
      ) : (
        <ChooseSaleType setSaleType={setSaleType} />
      )}
    </SaleLayout>
  );
};

export { formatSale };
export default Sale;
