import React from "react";
import ResetPasswordForm from "components/Login/ResetPasswordForm";
import LoginHeader from "components/Login/LoginHeader";

const ResetPassword = (props) => {
  return (
    <div className="reset-password">
      <LoginHeader />
      <ResetPasswordForm token={props?.match?.params?.token} />
    </div>
  );
};

export default ResetPassword;
