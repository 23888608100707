import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";

const PLACEHOLDER_VALUE = "";

const BaseSelect = ({
  name,
  options = [],
  placeholder,
  hasDefaultValue,
  register,
  selectError,
  selectFilter,
  allowEmpty,
  readOnly,
  ...selectProps
}) => {
  return (
    /**
     * data-parent-of is a custom attribute used to apply css because at the moment there is no way
     * to select a parent element depending on it's children name/class or whatever property.
     */
    <div
      className={classNames("select-wrapper", {
        "select-wrapper--has-default-value": hasDefaultValue,
      })}
      data-parent-of={name}
    >
      <select
        className={classNames("select", {
          "select--error": selectError,
          "select--filter": selectFilter,
          "select--read-only": readOnly,
        })}
        {...(placeholder ? { defaultValue: PLACEHOLDER_VALUE } : {})}
        {...(name ? { ...register(name) } : {})}
        {...selectProps}
      >
        {placeholder && (
          <option disabled={!allowEmpty} value={PLACEHOLDER_VALUE}>
            {placeholder}
          </option>
        )}
        {options.map(({ value, label }) => (
          <option key={`value-${value}`} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

const Select = (selectProps) => {
  const {
    register,
    formState: { errors },
    values,
  } = useFormContext();

  const selectValue = values[selectProps.name];
  const selectError = { ...errors }[selectProps.name];

  const hasDefaultValue = selectValue === undefined || selectValue === "";

  return (
    <BaseSelect
      register={register}
      /**
       * errors is a shallow copy so we need to use the spread operator to make a deep copy of it
       * in order to use it in the React.memo state comparison, if we don't do that previous errors and next errors
       * will always be equal...
       */
      selectError={selectError}
      hasDefaultValue={hasDefaultValue}
      {...selectProps}
    />
  );
};

export default Select;
