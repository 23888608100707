import React from "react";
import { useHistory } from "react-router-dom";
import GenericLayout from "components/GenericLayout";
import { useAuth } from "components/Hooks/useAuth";
import { IconButton } from "components/Button";
import SaleHeader from "../SaleHeader";
import SaleCard from "../SaleCard";
import classNames from "classnames";

const SaleLayout = ({
  children,
  currentStep,
  saleType,
  isEdit,
  saleId,
  sale,
}) => {
  const {
    user: { isMasterUser },
  } = useAuth();

  const history = useHistory();
  return (
    <div className={classNames("sale-layout", { "sale-layout--edit": isEdit })}>
      <GenericLayout
        noBottomWave={isEdit}
        headerIcon={isEdit ? null : "folder-bold"}
        headerTitle={isEdit ? null : "Création d'une vente"}
        headerContent={
          isEdit ? (
            <SaleCard sale={sale} />
          ) : (
            <SaleHeader
              currentStep={currentStep}
              saleType={saleType}
              isMasterUser={isMasterUser}
            />
          )
        }
        {...(isEdit && {
          headerActions: (
            <div className="sale-layout__back-button">
              <IconButton
                uppercase
                appearance="reversed-primary"
                icon="chevron-left"
                onClick={() => {
                  history.push(`/sales/process/${saleId}`);
                }}
              >
                Retour
              </IconButton>
            </div>
          ),
        })}
      >
        {children}
      </GenericLayout>
    </div>
  );
};

export default SaleLayout;
