import React from "react";
import { useHistory } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import Accordion from "components/Accordion";
import { IconButton } from "components/Button";
import StepActions from "components/Sale/SaleMultistep/StepActions";
import Text from "components/Text";
import Select from "components/Form/Select";
import {
  INSUFFICIENT_CREDIT_EXCEPTION,
  PEOPLE_PREFIX,
  SALE_KEY_DATES_PREFIX,
  SALE_STATUS,
} from "../../saleConstants";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import { formatSale } from "pages/Sale/Sale";
import { notifyError } from "utils/notify";
import { useBusinessOfferDialog } from "components/Hooks/useBusinessOfferDialog";

const AgentInChargeStep = ({
  isEdit,
  step,
  sale,
  setSale,
  isValid,
  active,
  peoples,
  goToStep,
}) => {
  const {
    trigger: triggerValidation,
    values: { assignedTo },
  } = useFormContext();

  const history = useHistory();
  const { setBusinessOfferDialogOpen } = useBusinessOfferDialog();

  const users = peoples.filter(({ isAUser }) => isAUser);
  const peopleOptions = users.map(({ id, firstname, lastname }) => ({
    value: `${PEOPLE_PREFIX}${id}`,
    label: `${firstname} ${lastname}`,
  }));

  const makeStepData = (sale) => {
    const stepData = {
      assignedTo,
    };

    if (sale?.keyDates?.saleRecordedOn) {
      return stepData;
    }

    return {
      ...stepData,
      status: SALE_STATUS.SAVE,
      keyDates: {
        "@id": `${SALE_KEY_DATES_PREFIX}${sale.keyDates.id}`,
        saleRecordedOn: new Date(),
      },
    };
  };

  const handleFinish = () => {
    const stepData = makeStepData(sale);
    fetchUrl(putSale({ sale: stepData, saleId: sale.id }))
      .then((sale) => {
        if (isEdit) {
          setSale(formatSale(sale));
          history.push({ pathname: `/sales/process/${sale.id}` });
          return;
        }
        history.push({ pathname: "/", state: { newSale: sale } });
      })
      .catch((error) => {
        if (error?.detail === INSUFFICIENT_CREDIT_EXCEPTION) {
          setBusinessOfferDialogOpen(true);
          notifyError("Nombre de crédits insuffisant");
        } else {
          notifyError("Une erreur est survenue");
        }
      });
  };

  return (
    <Accordion
      focusContent={false}
      disableToggle={sale.draftStep ? sale.draftStep < step : true}
      title={`${step + 1} • Responsable du suivi`}
      open={active}
      setOpen={goToStep}
    >
      {active ? (
        <>
          <div className="agent-in-charge-step">
            <Text color="primary" font="roboto-slab" size="small">
              Sélectionnez l'agent qui a réalisé la vente
            </Text>
            <Select
              name="assignedTo"
              options={peopleOptions}
              placeholder="Choisissez"
            />
          </div>

          <StepActions>
            <IconButton
              uppercase
              fullWidth
              {...(!isEdit && { icon: "chevron-right" })}
              textPosition="left"
              appearance="primary"
              onClick={() =>
                isValid
                  ? handleFinish()
                  : triggerValidation().then((isValidAfterValidation) => {
                      if (isValidAfterValidation) {
                        return handleFinish();
                      }
                    })
              }
            >
              {isEdit
                ? "Enregistrer les modifications"
                : "Enregistrer la vente"}
            </IconButton>
          </StepActions>
        </>
      ) : null}
    </Accordion>
  );
};

export default AgentInChargeStep;
