import * as yup from "yup";

import { PROPERTY_TYPES } from "components/Sale/SaleMultistep/saleConstants";

const { APARTMENT, HOUSE, OFFICE, BUSINESS } = PROPERTY_TYPES;

const validationSchema = yup.object({
  projectType: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  propertyType: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  numberOfRooms: yup.string().when("propertyType", {
    is: (propertyType) =>
      [APARTMENT.value, HOUSE.value, OFFICE.value, BUSINESS.value].includes(
        propertyType
      ),
    then: yup
      .string()
      .test(
        "is-positive",
        "Le nombre de pièces doit être supérieur à 0",
        (value) => value > 0
      ),
  }),
  area: yup
    .string()
    .test(
      "is-positive",
      "La surface doit être supérieure à 0",
      (value) => value > 0
    )
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  address: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  propertySoldRented: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  coOwnership: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  numberOfLots: yup.string().when("coOwnership", {
    is: (coOwnership) => !!+coOwnership,
    then: yup
      .string()
      .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  }),
  year: yup.string().when("coOwnership", {
    is: (coOwnership) => !!+coOwnership,
    then: yup
      .string()
      .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  }),
  additionalInformation: yup.string(),
});

export default validationSchema;
