import React from "react";

const LabelledField = ({ label, children }) => {
  return (
    <div className="labelled-field">
      <div className="labelled-field__label">{label}</div>
      <div className="labelled-field__field">{children}</div>
    </div>
  );
};

export default LabelledField;
