import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
import StepIndicator from "./StepIndicator";
import { Mobile, Tablet } from "components/MediaQuery";
import { saleStepsConfiguration } from "utils/saleSteps";
import {
  SALE_STEPS,
  FINANCING_METHODS,
} from "components/Sale/SaleMultistep/saleConstants";

const swiperProps = {
  slidesPerView: 2,
  spaceBetween: 20,
  centeredSlides: true,
  pagination: {
    clickable: true,
  },
};

const MobileIndicators = ({
  stepIndicators,
  currentSaleStep,
  setCurrentSaleStep,
}) => {
  SwiperCore.use([Pagination]);
  const [swiper, setSwiper] = React.useState(null);

  React.useEffect(() => {
    if (swiper && stepIndicators && currentSaleStep) {
      const stepIndex = stepIndicators.findIndex(
        (step) => step.stepKey === currentSaleStep
      );
      if (stepIndex !== -1) {
        swiper.slideTo(stepIndex);
      }
    }
  }, [swiper, stepIndicators, currentSaleStep]);

  return (
    <Swiper
      {...swiperProps}
      onSwiper={setSwiper}
      onSlideChange={(swiper) => {
        const currentStepIndicator = stepIndicators[swiper.realIndex];
        if (currentStepIndicator.isAccessible) {
          setCurrentSaleStep(stepIndicators[swiper.realIndex].stepKey);
        } else {
          swiper.slidePrev();
        }
      }}
    >
      {stepIndicators.map(
        (
          {
            isActive,
            isValid,
            appearance,
            saleStepConfiguration,
            isAccessible,
            stepKey,
          },
          index
        ) => {
          return (
            <SwiperSlide key={`step-indicator-${index}`}>
              <StepIndicator
                setCurrentSaleStep={() => {
                  if (isAccessible) {
                    setCurrentSaleStep(stepKey);
                    swiper.slideTo(index);
                  }
                }}
                isActive={isActive}
                isValid={isValid}
                appearance={appearance}
                number={index + 1}
                {...saleStepConfiguration}
              />
            </SwiperSlide>
          );
        }
      )}
    </Swiper>
  );
};

const SaleProcessStepsIndicators = ({
  currentSaleStep,
  setCurrentSaleStep,
  saleSteps,
  financingMethod,
}) => {
  const getAppearance = ({
    isActive,
    isFullyCompleted,
    isLate,
    isAccessible,
  }) => {
    if (isFullyCompleted || (isActive && !isLate)) {
      return "ok";
    }

    if (isLate && !isFullyCompleted && isAccessible) {
      return "alert";
    }

    if (isAccessible) {
      return "upcoming";
    }

    return "default";
  };

  const stepIndicators = Object.keys(saleStepsConfiguration).map((stepKey) => {
    const saleStepConfiguration = saleStepsConfiguration[stepKey];
    // deep copy to prevent side effects when using 'delete' operator
    const saleStep = { ...saleSteps[stepKey] };

    const isActive = stepKey === currentSaleStep;

    // If it's CASH we doesn't take this 2 steps into account, we could do this backend but if the user change the financing method during the process
    // it would be more expensive to handle
    if (
      stepKey === SALE_STEPS.FORMALITIES &&
      financingMethod === FINANCING_METHODS.CASH.value
    ) {
      delete saleStep.ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_FD;
      delete saleStep.ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_RO;
    }

    const isFullyCompleted = Object.keys(saleStep).every(
      (saleSubStepKey) => saleStep[saleSubStepKey].isCompleted
    );
    const isLate = Object.keys(saleStep).some(
      (saleSubStepKey) => saleStep[saleSubStepKey].isLate
    );
    const isAccessible =
      typeof saleStepConfiguration.isAccessible === "function"
        ? saleStepConfiguration.isAccessible({ saleSteps })
        : saleStepConfiguration.isAccessible;

    const appearance = getAppearance({
      isFullyCompleted,
      isLate,
      isActive,
      isAccessible,
    });

    return {
      stepKey,
      isActive,
      isFullyCompleted,
      appearance,
      saleStepConfiguration,
      isAccessible,
    };
  });

  return (
    <div className="sale-process__steps-indicators">
      <Tablet>
        {stepIndicators.map(
          (
            {
              stepKey,
              isActive,
              isFullyCompleted,
              appearance,
              saleStepConfiguration,
              isAccessible,
            },
            index
          ) => {
            return (
              <StepIndicator
                key={`step-indicator-${index}`}
                setCurrentSaleStep={() =>
                  isAccessible && setCurrentSaleStep(stepKey)
                }
                isActive={isActive}
                isComplete={isFullyCompleted}
                isAccessible={isAccessible}
                appearance={appearance}
                number={index + 1}
                {...saleStepConfiguration}
              />
            );
          }
        )}
      </Tablet>
      <Mobile>
        <MobileIndicators
          stepIndicators={stepIndicators}
          currentSaleStep={currentSaleStep}
          setCurrentSaleStep={setCurrentSaleStep}
        />
      </Mobile>
    </div>
  );
};

export default SaleProcessStepsIndicators;

SaleProcessStepsIndicators.propTypes = {
  currentSaleStep: PropTypes.string.isRequired,
  saleSteps: PropTypes.object.isRequired,
};
