import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconButton } from "components/Button";
import Icon from "components/Icon";
import MailModule from "components/MailModule";
import Container from "../../Container";
import { makeSendMail } from "utils/email";
import PlanningModule from "components/PlanningModule";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import { useHistory } from "react-router-dom";
import DocumentModule from "components/DocumentModule";
import ContactModule from "components/ContactModule";

const MenuItem = ({ icon, children, onClick, variant }) => {
  const itemClassName = classNames("sale-menu-item", {
    [`sale-menu-item--${variant}`]: variant,
  });
  return (
    <li className={itemClassName}>
      <IconButton
        icon={icon}
        textPosition="bottom"
        onClick={onClick}
        iconSize="big"
      >
        {children}
      </IconButton>
    </li>
  );
};

MenuItem.propTypes = {
  ...IconButton.PropTypes,
  variant: PropTypes.oneOf(["gap-left"]),
};

const SaleProcessMenu = ({ sale }) => {
  const [emailLogs, setEmailLogs] = React.useState(sale.emailSendLogs || []);

  const {
    planningModuleOpen,
    setPlanningModuleOpen,
    mailModuleState,
    setMailModuleOpen,
    documentModuleOpen,
    setDocumentModuleOpen,
    contactModuleOpen,
    setContactModuleOpen,
  } = useSaleModules();

  const history = useHistory();

  const handleMailSubmit = ({
    subject,
    content,
    attachments,
    recipients,
    sale,
    selectedTemplateRef,
    doneCallback,
  }) => {
    makeSendMail({
      subject,
      content,
      attachments,
      recipients,
      selectedTemplateRef,
      sale,
      onSuccess: ({ subject, recipient }) => {
        doneCallback();
        setMailModuleOpen({
          open: false,
          recipients: [],
          attachments: [],
          template: "",
        });

        setEmailLogs([
          ...emailLogs,
          {
            subject,
            recipient,
            sentAt: new Date().toISOString(),
          },
        ]);
      },
      onError: () => {
        doneCallback();
      },
    });
  };

  return (
    <>
      <div className="sale-process-menu">
        <Container flex>
          <span className="ripple-container">
            <Icon icon="ripple" size="big" />
          </span>
          <ul>
            <MenuItem
              icon="folder-info"
              onClick={() => {
                history.push({
                  pathname: `/sales/edit/${sale.id}`,
                });
              }}
            >
              Infos Vente
            </MenuItem>
            <MenuItem
              icon="address-book"
              onClick={() => setContactModuleOpen(true)}
            >
              Contacts
            </MenuItem>
            <MenuItem
              icon="document-download"
              onClick={() => setDocumentModuleOpen(true)}
              variant="gap-left"
            >
              Documents
            </MenuItem>
            <MenuItem
              icon="emails"
              onClick={() => setMailModuleOpen({ open: true })}
            >
              Emails
            </MenuItem>
            <MenuItem
              icon="planning"
              onClick={() => setPlanningModuleOpen(true)}
            >
              Planning
            </MenuItem>
          </ul>
        </Container>
      </div>
      <MailModule
        config={{
          template: mailModuleState.template,
          recipients: mailModuleState.recipients,
          attachments: mailModuleState.attachments,
        }}
        title="Envoyer un email"
        open={mailModuleState.open}
        handleClose={() => {
          setMailModuleOpen({
            open: false,
            recipients: [],
            attachments: [],
            template: "",
          });
        }}
        sale={{ ...sale, emailSendLogs: emailLogs }}
        handleSubmit={handleMailSubmit}
      />
      <PlanningModule
        open={planningModuleOpen}
        handleClose={() => setPlanningModuleOpen(false)}
        sale={sale}
      />
      <DocumentModule
        open={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        sale={sale}
      />
      <ContactModule
        open={contactModuleOpen}
        handleClose={() => setContactModuleOpen(false)}
        sale={sale}
      />
    </>
  );
};

export default SaleProcessMenu;
