import React from "react";
import GenericLayout from "components/GenericLayout";
import { LinkButton } from "components/Button";
import Text from "components/Text";

const NotFound = () => (
  <GenericLayout
    headerIcon="close"
    headerTitle="Erreur 404"
    headerContent={
      <h1 className="user-settings__header-content">Page non trouvée</h1>
    }
  >
    <div className="not-found">
      <Text size="medium" align={"center"}>
        <p>Oups, la page demandée n'existe pas.</p>
        <br />
        <LinkButton to={"/"}>Retour à l'accueil</LinkButton>
      </Text>
    </div>
  </GenericLayout>
);

export default NotFound;
