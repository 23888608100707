import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress-bar__gauge"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const SaleProgress = ({ progress }) => {
  /** Hack to preserve progress bar value even if all accordion are closed */
  const [previousProgress, setPreviousProgress] = React.useState(progress);

  React.useEffect(() => {
    if (progress) {
      setPreviousProgress(progress);
    }
  }, [progress]);

  return (
    <div className="sale-progress">
      <div className="sale-progress__percentage">{`${
        progress || previousProgress
      }%`}</div>
      <ProgressBar progress={progress || previousProgress} />
    </div>
  );
};

export default SaleProgress;
