const FACTOR = 100;

const normalizeNumber = (number) => {
  return Math.trunc(Number(number) * FACTOR);
};

const denormalizeNumber = (number) => {
  return number / FACTOR;
};

const priceDutyFree = (value) => {
  return Math.round(value / 1.2);
};

export { normalizeNumber, denormalizeNumber, priceDutyFree };
