import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import Button from "./Button";
import classNames from "classnames";

const ButtonText = ({ className, children }) => {
  return <span className={className}>{children}</span>;
};

const IconButton = ({
  icon,
  iconSize,
  iconColor,
  textPosition,
  children,
  ...buttonProps
}) => {
  const makeIconVertical = (textPosition) => {
    return textPosition === "bottom";
  };

  const makeButtonTextClassNames = (textPosition) =>
    classNames("button-text", {
      [`button-text--${textPosition}`]: textPosition,
    });

  const buttonTextClassName = makeButtonTextClassNames(textPosition);

  return (
    <Button
      variant={children ? "icon" : "icon-only"}
      vertical={makeIconVertical(textPosition)}
      {...buttonProps}
    >
      {children && textPosition === "left" && (
        <ButtonText className={buttonTextClassName}>{children}</ButtonText>
      )}
      {icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
      {children && (textPosition === "right" || textPosition === "bottom") && (
        <ButtonText className={buttonTextClassName}>{children}</ButtonText>
      )}
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  iconSize: Icon.propTypes.size,
  iconColor: PropTypes.oneOfType([Icon.propTypes.color, PropTypes.func]),
  textPosition: PropTypes.oneOf(["left", "right", "bottom"]),
  children: PropTypes.node,
};

IconButton.defaultProps = {
  textPosition: "right",
  boxShadow: false,
  uppercase: false,
};

export default IconButton;
