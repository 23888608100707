import convertObjectToFormData from "../utils/convertObjectToFormData";

const handleResponse = async (response) => {
  const contentType = response.headers.get("content-type");
  const isJsonResponse = contentType && contentType.indexOf("json") !== -1;

  if (!response.ok) {
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
      detail: isJsonResponse
        ? await response.json().then((data) => data.detail)
        : await response.text(),
    });
  }

  if (isJsonResponse) {
    return response.json();
  } else {
    return response.text();
  }
};

const fetchUrl = (request) => {
  const basePath = process.env.REACT_APP_API_ENDPOINT;

  const {
    path,
    options = {},
    headers = {},
    credentials = true,
    json = false,
    jsonLd = false,
  } = request;

  const { body, ...remainingOptions } = options;

  let formattedBody;

  if (body) {
    formattedBody =
      json || jsonLd ? JSON.stringify(body) : convertObjectToFormData(body);
  }

  const url = `${basePath}${path}`;

  const contentType = json
    ? "application/json"
    : jsonLd && "application/ld+json";

  return fetch(url, {
    headers: {
      Accept: "application/json",
      ...(contentType && { "Content-Type": contentType }),
      ...headers,
    },
    ...(formattedBody && { body: formattedBody }),
    ...(credentials && { credentials: "include" }),
    ...remainingOptions,
  }).then(handleResponse);
};

export default fetchUrl;
