import DocumentsStep from "./DocumentsStep";
import CompromiseStep from "./CompromiseStep";
import FormalitiesStep from "./FormalitiesStep";
import ActedStep from "./ActedStep";

const steps = {
  DOCUMENTS: DocumentsStep,
  COMPROMISE: CompromiseStep,
  FORMALITIES: FormalitiesStep,
  ACTED: ActedStep,
};

export default steps;
