import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import fetchUrl from "api/fetchUrl";
import { putSale, deleteSale } from "api/requests";
import { notifySuccess, notifyError } from "utils/notify";
import { formatName, formatPropertyType } from "utils/sale";
import SaleCardDraft from "./SaleCardDraft";
import SaleCardStep from "./SaleCardStep";
import SaleCardFooter from "./SaleCardFooter";
import DialogForm from "./DialogForm";
import validationSchema from "./validationSchema";
import IconButton from "components/Button/IconButton";
import { Dialog, DialogWrapper } from "components/Dialog";
import {
  SALE_STATUS,
  SALE_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";
import Select from "components/Form/Select";
import { denormalizeNumber } from "utils/number";

const SaleCard = ({
  sale,
  canBeArchived = true,
  cancelMode = false,
  setToggleRefetch,
  toggleRefetch,
}) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const isDraft = sale.status === "DRAFT";
  const isActed = sale.saleStep === "ACTED";
  const hasCloseIcon =
    isDraft || (canBeArchived && isActed) || (cancelMode && !isActed);
  const assignedTo = `${sale.assignedTo.lastname} ${sale.assignedTo.firstname}`;
  const seller = formatName(
    sale.sellerAndNotary[0]?.client?.people || "",
    false,
    false
  );
  const buyer = formatName(
    sale.buyerAndNotary[0]?.client?.people || "",
    false,
    false
  );
  const district = sale.district
    ? formatPropertyType(
        sale.propertyType,
        denormalizeNumber(sale.numberOfRooms)
      ).concat(sale.district)
    : formatPropertyType(
        sale.propertyType,
        denormalizeNumber(sale.numberOfRooms)
      ).concat(sale.city);

  const archivingDialogData = {
    icon: "archive",
    title: "Confirmation archivage",
    beforeInput: (
      <div>
        <p>Voulez-vous réellement archiver cette vente ?</p>
        <p>
          Si oui, merci d'écrire le mot <strong>ARCHIVER</strong> ci-dessous
        </p>
      </div>
    ),
    afterInput: (
      <div>
        <p>Votre vente restera consultable dans la rubrique</p>
        <p>« ventes actées » et ses données seront sauvegardées</p>
      </div>
    ),
    confirmation: "archiver",
    action: putSale({
      sale: { status: SALE_STATUS.ARCHIVED },
      saleId: sale.id,
    }),
  };

  const deletionDialogData = {
    icon: "bin",
    title: "Confirmation suppression",
    beforeInput: (
      <div>
        <p>Voulez-vous réellement supprimer cette vente ?</p>
        <p>
          Si oui, merci d'écrire le mot <strong>SUPPRIMER</strong> ci-dessous
        </p>
      </div>
    ),
    afterInput: (
      <div>
        <p>Toutes les informations enregistrées dans cette vente</p>
        <p>seront supprimées</p>
      </div>
    ),
    confirmation: "supprimer",
    action: deleteSale({ saleId: sale.id }),
  };

  const cancelDialogData = {
    icon: "bin",
    title: "Confirmation annulation",
    beforeInput: (
      <div>
        <p>
          Voulez-vous réellement <strong>annuler</strong> cette vente ?
        </p>
        <p>
          Si oui, merci d'écrire le mot <strong>ANNULER</strong> ci-dessous
        </p>
      </div>
    ),
    afterInput: (
      <div>
        <p>Votre vente restera consultable dans la rubrique</p>
        <p>« ventes annulées » et ses données seront sauvegardées</p>
      </div>
    ),
    additionalInputs: (
      <Select
        name="cancelReason"
        placeholder="Motif de l'annulation"
        options={[
          {
            value: "Rétractation avant compromis",
            label: "Rétractation avant compromis",
          },
          {
            value: "Rétractation après compromis",
            label: "Rétractation après compromis",
          },
          { value: "Refus de prêt bancaire", label: "Refus de prêt bancaire" },
          { value: "Autre motif", label: "Autre motif" },
        ]}
      />
    ),
    confirmation: "annuler",
    action: (values) => {
      return putSale({
        sale: {
          status: SALE_STATUS.CANCELED,
          cancelReason: values.cancelReason,
        },
        saleId: sale.id,
      });
    },
  };

  const dialogData = cancelMode
    ? cancelDialogData
    : isDraft
    ? deletionDialogData
    : archivingDialogData;

  const handleSubmit = (values) => {
    fetchUrl(
      typeof dialogData.action === "function"
        ? dialogData.action(values)
        : dialogData.action
    )
      .then(() => {
        notifySuccess(
          `La vente a bien été ${
            cancelMode ? "annulée" : isDraft ? "supprimée" : "archivée"
          }.`
        );

        if (typeof setToggleRefetch === "function") {
          setToggleRefetch(!toggleRefetch);
        }
        history.push("/");
      })
      .catch(() => {
        notifyError(
          `Une erreur est survenue lors de ${
            isDraft ? "la suppression" : "l'archivage"
          }`
        );
      });

    setIsModalOpen(false);
  };

  return (
    <div className="sale-card-wrapper">
      <div className="sale-card-wrapper__close-icon">
        {hasCloseIcon &&
          ![SALE_STATUS.CANCELED, SALE_STATUS.ARCHIVED].includes(
            sale.status
          ) && <IconButton icon="close" onClick={() => setIsModalOpen(true)} />}
      </div>
      <div className="sale-card">
        <div
          className="sale-card__content"
          {...(!isDraft && {
            onClick: () => history.push(`/sales/process/${sale.id}`),
          })}
        >
          {isDraft ? (
            <SaleCardDraft saleId={sale.id} seller={seller} buyer={buyer} />
          ) : (
            <SaleCardStep
              sale={sale}
              seller={seller}
              buyer={buyer}
              district={district}
            />
          )}

          <SaleCardFooter
            isInterAgency={sale.saleType === SALE_TYPES.INTER_AGENCY}
            isActed={isActed}
            assignedTo={assignedTo}
            createdAt={sale.createdAt}
          />
        </div>
      </div>
      <Dialog
        className="dialog-sale-card"
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <DialogWrapper dialogData={dialogData}>
          <DialogForm
            dialogData={dialogData}
            setIsModalOpen={setIsModalOpen}
            handleSubmit={handleSubmit}
            validationSchema={validationSchema}
          />
        </DialogWrapper>
      </Dialog>
    </div>
  );
};

SaleCard.propTypes = {
  sale: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    assignedTo: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    interAgency: PropTypes.number,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default SaleCard;
