import React from "react";
import fetchUrl from "api/fetchUrl";
import { postClient } from "api/requests";
import validationSchema from "./validationSchema";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Form from "components/Form";
import { InputText } from "components/Form/Input";
import Select from "components/Form/Select";
import Button from "components/Button";
import {
  PEOPLE_TYPES,
  PEOPLE_CIVILITIES,
  AGENCY_PREFIX,
  CLIENT_PREFIX,
} from "components/Sale/SaleMultistep/saleConstants";
import { useAuth } from "components/Hooks/useAuth";
import { notifyError } from "utils/notify";

const { INDIVIDUAL, COMPANY, SCI, INDIVISION } = PEOPLE_TYPES;
const { MLLE, MME, M } = PEOPLE_CIVILITIES;

const NewClientDialog = ({
  open,
  setOpen,
  addNewClientToList,
  setValue,
  target,
}) => {
  const { user } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddClient = (
    { type, companyName, civility, firstname, lastname, mobile, email },
    reset
  ) => {
    const newClient = {
      email,
      people: {
        type,
        companyName: type !== INDIVIDUAL.value ? companyName : null,
        civility,
        firstname,
        lastname,
        mobile,
        agency: `${AGENCY_PREFIX}${user.people.agency.id}`,
      },
    };
    fetchUrl(postClient(newClient))
      .then((client) => {
        addNewClientToList(client);
        setValue(target, `${CLIENT_PREFIX}${client.id}`, {
          shouldValidate: true,
        });
        handleClose();
        reset();
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'ajout du nouveau client."
        );
      });
  };

  return (
    <Dialog open={open} handleClose={handleClose} className="new-client-dialog">
      <Form mode="all" validationSchema={validationSchema}>
        {({ values, reset, isValid, trigger: triggerValidation }) => (
          <>
            <DialogTitle>CRÉER UN NOUVEAU CLIENT</DialogTitle>
            <DialogContent>
              <Select
                name="type"
                options={[INDIVIDUAL, COMPANY, SCI, INDIVISION]}
                placeholder="Type"
              />
              {[COMPANY.value, SCI.value, INDIVISION.value].includes(
                values?.type
              ) ? (
                <InputText name="companyName" placeholder="Raison sociale" />
              ) : null}
              <Select
                name="civility"
                options={[MLLE, MME, M]}
                placeholder="Civilité"
              />
              <InputText name="lastname" placeholder="Nom" />
              <InputText name="firstname" placeholder="Prénom" />
              <InputText name="mobile" placeholder="Téléphone" />
              <InputText name="email" placeholder="Email" />
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                appearance="ghost"
                boxShadow
                onClick={handleClose}
              >
                ANNULER
              </Button>
              <Button
                fullWidth
                boxShadow
                appearance="primary"
                onClick={() =>
                  isValid
                    ? handleAddClient(values, reset)
                    : triggerValidation().then((isValidAfterValidation) => {
                        if (isValidAfterValidation) {
                          return handleAddClient(values, reset);
                        }
                      })
                }
              >
                ENREGISTRER
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default NewClientDialog;
