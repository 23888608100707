import React from "react";
import Text from "components/Text";
import Icon from "components/Icon";
import { SALE_TYPES } from "components/Sale/SaleMultistep/saleConstants";

const { REGULAR, INTER_AGENCY } = SALE_TYPES;

const SaleType = ({ setSaleType, text }) => {
  return (
    <div className="sale-type" onClick={setSaleType}>
      <div className="sale-type__inner">
        <Icon icon="folder-bold" size="big" color="primary" />
        {text}
        <Icon icon="ripple" size="big" color="primary" />
      </div>
    </div>
  );
};

const ChooseSaleType = ({ setSaleType }) => {
  return (
    <div className="choose-sale-type">
      <div className="choose-sale-type__title">
        <Text font="roboto-slab" align="center" fontWeight="light">
          <b>Sélectionnez le type de vente</b> que vous souhaitez créer <br />{" "}
          et <b>laissez vous guider</b> par Elio !
        </Text>
      </div>
      <div className="sale-types-wrapper">
        <SaleType
          setSaleType={() => setSaleType(REGULAR)}
          text={
            <span>
              <Text fontWeight="bold" font="roboto-slab" align="center">
                NOUVELLE VENTE
              </Text>
              <Text size="mini" font="roboto-slab" align="center">
                ou vente en cours
              </Text>
            </span>
          }
        />
        <SaleType
          setSaleType={() => setSaleType(INTER_AGENCY)}
          text={
            <span>
              <Text fontWeight="bold" font="roboto-slab" align="center">
                VENTE
              </Text>
              <Text fontWeight="bold" font="roboto-slab" align="center">
                INTER-AGENCE
              </Text>
            </span>
          }
        />
      </div>
      <div className="choose-sale-type__content">
        <Text font="roboto" align="center">
          <p>
            <b>
              Créez une nouvelle vente ou enregistrez vos ventes en cours, et
              retrouvez-les dans votre tableau de bord&nbsp;!
            </b>
          </p>
          <p>
            Vous pouvez enregistrer un brouillon de votre vente et terminer la
            création plus tard.
          </p>
        </Text>
      </div>
    </div>
  );
};

export default ChooseSaleType;
