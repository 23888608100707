import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import Icon from "../Icon";

const AccordionHeader = ({ disableToggle, title, open, setOpen }) => {
  return (
    <div
      className={classNames("accordion-header", {
        "accordion-header--disabled": disableToggle,
      })}
      onClick={disableToggle ? () => {} : () => setOpen(!open)}
    >
      <div className="accordion-header__title">{title}</div>
      <div className="accordion-header__toggle">
        <Icon icon="chevron-right" />
      </div>
    </div>
  );
};

const AccordionContent = ({ open, children, focusContent, accordionRef }) => {
  return (
    <AnimateHeight
      duration={500}
      height={open ? "auto" : 0}
      onAnimationEnd={() => {
        if (focusContent && open) {
          accordionRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }}
    >
      <div className="accordion-content">{children}</div>
    </AnimateHeight>
  );
};

const Accordion = ({
  title,
  open,
  setOpen,
  children,
  disableToggle,
  focusContent = true,
}) => {
  const accordionRef = React.useRef();
  const [innerOpen, setInnerOpen] = React.useState(false);

  const controlled = typeof open === "boolean";

  const toggle = () => {
    setInnerOpen(!innerOpen);
  };

  return (
    <div
      ref={accordionRef}
      className={classNames("accordion", {
        "accordion--opened": controlled ? open : innerOpen,
      })}
    >
      <AccordionHeader
        disableToggle={disableToggle}
        title={title}
        open={controlled ? open : innerOpen}
        setOpen={controlled ? setOpen : toggle}
      />
      <AccordionContent
        focusContent={focusContent}
        accordionRef={accordionRef}
        open={controlled ? open : innerOpen}
      >
        {children}
      </AccordionContent>
    </div>
  );
};

Accordion.propTypes = {
  focusContent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.node,
  disableToggle: PropTypes.bool,
};

export default Accordion;
