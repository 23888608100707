import React from "react";
import Icon from "components/Icon";
import { InputText } from "components/Form/Input";
import Select from "components/Form/Select";
import Button from "components/Button";
import SubmitButton from "components/Form/SubmitButton";
import InputGoogleMapAutoComplete from "components/Form/Input/InputGoogleAutoComplete";
import {
  PEOPLE_TYPES,
  PEOPLE_CIVILITIES,
} from "components/Sale/SaleMultistep/saleConstants";

const { INDIVIDUAL, COMPANY, SCI, INDIVISION } = PEOPLE_TYPES;
const { MLLE, MME, M } = PEOPLE_CIVILITIES;

const ContactDirectoryForm = ({
  title,
  setSelectedFormData,
  isClientTab,
  values,
  setIsDialogOpen,
}) => {
  return (
    <div className="contact-directory-form">
      <div className="contact-directory-form__title">
        <span>{title}</span>
        <Icon icon="ripple" color="primary" size="very-big" />
      </div>
      {isClientTab && (
        <Select
          name="type"
          options={[INDIVIDUAL, COMPANY, SCI, INDIVISION]}
          placeholder="Type"
        />
      )}
      {[COMPANY.value, SCI.value, INDIVISION.value].includes(values?.type) ? (
        <InputText name="companyName" placeholder="Raison sociale" />
      ) : null}
      <Select name="civility" options={[MLLE, MME, M]} placeholder="Civilité" />
      <InputText name="lastname" placeholder="Nom" />
      <InputText name="firstname" placeholder="Prénom" />
      <InputText name="mobile" placeholder="Téléphone" />
      <InputText name="email" placeholder="Email" />
      {!isClientTab && (
        <InputGoogleMapAutoComplete onlyCity name="city" placeholder="Ville" />
      )}
      <div className="contact-directory-form__buttons">
        <Button
          fullWidth
          appearance="ghost"
          boxShadow
          onClick={() => {
            setSelectedFormData("");
            setIsDialogOpen(false);
          }}
        >
          ANNULER
        </Button>
        <SubmitButton fullWidth boxShadow appearance="primary">
          ENREGISTRER
        </SubmitButton>
      </div>
    </div>
  );
};

export default ContactDirectoryForm;
