import React from "react";
import PropTypes from "prop-types";
import { Tablet } from "components/MediaQuery";
import Menu from "components/Menu";
import Logout from "components/Logout";
import Greeting from "components/Greeting";
import Icon from "components/Icon";
import classNames from "classnames";

const HeaderTopBar = () => {
  return (
    <div className="header-topbar">
      <Menu />
      <div className="right-block">
        <Tablet>
          <Greeting />
        </Tablet>
        <Logout />
      </div>
    </div>
  );
};

const HeaderIconContainer = ({ icon }) => {
  if (!icon) {
    return null;
  }
  return (
    <div className="header-icon-container">
      <Icon icon={icon} />
    </div>
  );
};

const HeaderTitle = ({ title }) => {
  if (!title) {
    return null;
  }
  return <div className="header-title">{title}</div>;
};

const HeaderContent = ({ children }) => {
  return <div className="header-content">{children}</div>;
};

export const BasicLayout = ({
  headerContent,
  fullHeight,
  greyBackground,
  children,
}) => {
  return (
    <div
      className={classNames("generic-layout", {
        "generic-layout--full-height": fullHeight,
        "generic-layout--bg-grey": greyBackground,
      })}
    >
      <div className="generic-layout-header generic-layout-header--tall">
        <div className="generic-layout-header__gradient">
          <HeaderTopBar />
          <HeaderContent>{headerContent}</HeaderContent>
        </div>
      </div>
      <GenericLayoutContent noPadding fullWidth>
        {children}
      </GenericLayoutContent>
    </div>
  );
};

const GenericLayoutHeader = ({
  children,
  icon,
  title,
  actions,
  noBottomWave,
}) => {
  return (
    <div className="generic-layout-header">
      <div className="generic-layout-header__gradient">
        <HeaderTopBar />
        <HeaderIconContainer icon={icon} />
        <HeaderTitle title={title} />
        <HeaderContent>{children}</HeaderContent>
        {!noBottomWave && <Icon icon="wave" size="medium" />}
        {actions && (
          <div className="generic-layout-header__actions">{actions}</div>
        )}
      </div>
    </div>
  );
};

const GenericLayoutContent = ({ children, noPadding, fullWidth }) => {
  const contentClassNames = classNames("generic-layout-content", {
    "generic-layout-content--no-padding": noPadding,
    "generic-layout-content--full": fullWidth,
  });
  return <div className={contentClassNames}>{children}</div>;
};

const GenericLayout = ({
  children,
  headerIcon,
  headerTitle,
  headerContent,
  headerActions,
  noPadding = false,
  noBottomWave,
}) => {
  return (
    <div className="generic-layout">
      <GenericLayoutHeader
        icon={headerIcon}
        title={headerTitle}
        actions={headerActions}
        noBottomWave={noBottomWave}
      >
        {headerContent}
      </GenericLayoutHeader>
      <GenericLayoutContent noPadding={noPadding}>
        {children}
      </GenericLayoutContent>
    </div>
  );
};

GenericLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerIcon: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  headerContent: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
};

export default GenericLayout;
