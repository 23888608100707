import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { getSaleStepOptions } from "utils/sale";

const SaleCardStepper = ({ currentStep }) => {
  const saleSteps = getSaleStepOptions();

  return (
    <div className="stepper">
      {saleSteps.map((saleStep, index) => {
        const classes = classNames("stepper__step", {
          valid: currentStep.isValid,
          active: currentStep.step.includes(saleStep.value),
          "on-border-left": index === 0,
          "on-border-rigth": index === saleSteps.length - 1,
        });

        return (
          <div key={`step-${saleStep.value}`} className={classes}>
            {saleStep.stepper}
          </div>
        );
      })}
    </div>
  );
};

SaleCardStepper.propTypes = {
  currentStep: PropTypes.object.isRequired,
};

export default SaleCardStepper;
