import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Text from "components/Text";
import Button from "components/Button";

const DraftSaleCompletedDialog = ({
  newSaleDialogOpen,
  setNewSaleDialogOpen,
  setMailModuleOpen,
}) => (
  <Dialog
    open={newSaleDialogOpen}
    handleClose={() => setNewSaleDialogOpen(false)}
    className="draft-sale-completed-dialog"
  >
    <DialogTitle>
      <Text
        size="very-large"
        align="center"
        fontWeight="semi-bold"
        font="roboto-slab"
      >
        Félicitation !
      </Text>
      <Text
        uppercase
        size="small"
        color="primary"
        font="roboto-slab"
        fontWeight="semi-bold"
      >
        Enregistrement validé
      </Text>
    </DialogTitle>
    <DialogContent>
      <Text align="center">
        Votre vente a été enregistrée avec succès. <br />
        Souhaitez-vous <b>l'envoyer au notaire ?</b>
        <br />
        <br />
      </Text>
      <Text size="mini" align="center">
        Si vous <b>ignorez cette étape</b>, vous pourrez à tout moment,
        l'envoyer
        <br /> à partir du module mail
      </Text>
    </DialogContent>
    <DialogActions>
      <Button
        fullWidth
        appearance="ghost"
        onClick={() => setNewSaleDialogOpen(false)}
      >
        Ignorer
      </Button>
      <Button
        fullWidth
        appearance="primary"
        onClick={() => {
          setNewSaleDialogOpen(false);
          setMailModuleOpen({ open: true });
        }}
      >
        Partager
      </Button>
    </DialogActions>
  </Dialog>
);

export default DraftSaleCompletedDialog;
