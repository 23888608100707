import React from "react";
import Input from "../Input";

const InputRadio = ({ name, label, ...inputProps }) => {
  return (
    <label className="radio-wrapper">
      <Input type="radio" name={name} {...inputProps} />
      <span className="label">{label}</span>
    </label>
  );
};

export default InputRadio;
