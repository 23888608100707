import React from "react";
import { InputText } from "components/Form/Input";
import Form from "components/Form";
import Button from "components/Button";
import SubmitButton from "components/Form/SubmitButton";

const DialogForm = ({
  dialogData,
  setIsModalOpen,
  handleSubmit,
  validationSchema,
}) => (
  <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
    <div className="dialog-wrapper__content">
      {dialogData.beforeInput}
      <InputText
        type="text"
        name="confirmation"
        placeholder={`Saisir ${dialogData.confirmation.toUpperCase()} ici`}
      />
      {dialogData.additionalInputs}
      {dialogData.afterInput}
    </div>
    <div className="dialog-wrapper__footer">
      <Button
        appearance="ghost"
        fullWidth
        onClick={() => setIsModalOpen(false)}
      >
        annuler
      </Button>
      <SubmitButton appearance="primary" fullWidth>
        valider
      </SubmitButton>
    </div>
  </Form>
);

export default DialogForm;
