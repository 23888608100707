import React from "react";
import { useFormContext } from "react-hook-form";
import Accordion from "components/Accordion";
import { InputText, InputTextArea } from "components/Form/Input";
import Select from "components/Form/Select";
import { IconButton } from "components/Button";
import Divider from "components/Divider";
import Text from "components/Text";
import {
  ANNEX_PREFIX,
  CO_OWNERSHIP_PREFIX,
  PEOPLE_PREFIX,
  SALE_STATUS,
  SALE_STEPS,
  PROJECT_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";
import fetchUrl from "api/fetchUrl";
import { postSale, putSale } from "api/requests";
import { useAuth } from "components/Hooks/useAuth";
import updateUrlWithoutReload from "utils/updateUrlWithoutReload";
import InputRadio from "components/Form/Input/InputRadio";
import RadioGroup from "components/RadioGroup";
import PropertyType from "./PropertyType";
import CoOwnership from "./CoOwnership";
import Annexes from "./Annexes";
import InputGoogleMapAutoComplete from "components/Form/Input/InputGoogleAutoComplete";
import { normalizeNumber } from "utils/number";
import { formatSale } from "pages/Sale/Sale";

const { PRIMARY_RESIDENCE, INVESTMENT } = PROJECT_TYPES;
const { DRAFT } = SALE_STATUS;
const { DOCUMENTS } = SALE_STEPS;

const CharacteristicsOfThePropertyStep = ({
  isEdit,
  step,
  saleType,
  sale,
  setSale,
  isValid,
  active,
  nextStep,
  annexes,
  fields,
  goToStep,
}) => {
  const { user } = useAuth();
  const { values, trigger: triggerValidation, setValue } = useFormContext();

  const isCoOwnership = Boolean(Number(values.coOwnership));

  React.useEffect(() => {
    if (values.address === "") {
      setValue("zip", "");
      setValue("city", "");
    }
  }, [values.address, setValue]);

  const setZipCityCountryValues = ({ zip, city, country }) => {
    setValue("zip", zip);
    setValue("city", city);
    setValue("country", country);
  };

  const makeStepData = () => {
    const selectedAnnexes = annexes
      .filter(({ id }) => {
        return values[`${ANNEX_PREFIX}${id}`];
      })
      .map(({ id }) => `${ANNEX_PREFIX}${id}`);

    const {
      coOwnership,
      year,
      numberOfLots,
      area,
      numberOfRooms,
      propertySoldRented,
      ...stepData
    } = Object.fromEntries(
      Object.entries(values).filter(([key]) => {
        return fields.includes(key);
      })
    );

    const nextStep = step + 1;

    return {
      ...(!sale?.saleType && { saleType }),
      ...(!sale?.createdBy && {
        createdBy: `${PEOPLE_PREFIX}${user.people.id}`,
      }),
      ...(!sale?.assignedTo && {
        assignedTo: `${PEOPLE_PREFIX}${user.people.id}`,
      }),
      draftStep: nextStep > (sale.draftStep || 0) ? nextStep : sale.draftStep,
      saleStep: DOCUMENTS,
      ...(!isEdit && { status: DRAFT }),
      area: normalizeNumber(area),
      numberOfRooms:
        Number(numberOfRooms) > 0 ? normalizeNumber(numberOfRooms) : null,
      propertySoldRented: Number(propertySoldRented),
      ...(isCoOwnership
        ? {
            coOwnership: {
              ...(sale.coOwnership && {
                "@id":
                  sale.coOwnership["@id"] ??
                  `${CO_OWNERSHIP_PREFIX}${sale.coOwnership.id}`,
              }),
              year,
              numberOfLots,
            },
          }
        : { coOwnership: null }),
      itemAnnexes: selectedAnnexes,
      ...stepData,
    };
  };

  const handleNextStep = () => {
    const stepData = makeStepData();

    if (sale.id) {
      fetchUrl(putSale({ sale: stepData, saleId: sale.id })).then((sale) => {
        setSale(formatSale(sale));
        nextStep();
      });
    } else {
      fetchUrl(postSale(stepData)).then((sale) => {
        /**
         * Update the URL with the returned Sale id, so this way,
         * even if the user reloads the page, he'll get back to his draft
         */
        setSale(formatSale(sale));
        updateUrlWithoutReload(`/sales/${sale.id}`);
        nextStep();
      });
    }
  };

  return (
    <Accordion
      focusContent={false}
      disableToggle={sale.draftStep ? sale.draftStep < step : false}
      title={`${step + 1} • Caractéristiques du bien`}
      open={active}
      setOpen={goToStep}
    >
      {active ? (
        <>
          <div className="characteristics-step">
            <Text color="primary" font="roboto-slab" size="small">
              Le bien
            </Text>
            <Select
              name="projectType"
              options={[PRIMARY_RESIDENCE, INVESTMENT]}
              placeholder="Type de projet"
            />
            <PropertyType />
            <InputGoogleMapAutoComplete
              name="address"
              placeholder="Adresse"
              onPlaceSelected={setZipCityCountryValues}
            />

            <InputText readOnly name="zip" placeholder="Code postal" />
            <InputText readOnly name="city" placeholder="Ville" />
            <InputText name="country" type="hidden" />

            <InputText name="district" placeholder="Quartier" />
            <br />
            <RadioGroup label="Bien vendu loué ?">
              <InputRadio name="propertySoldRented" value={0} label="Non" />
              <InputRadio name="propertySoldRented" value={1} label="Oui" />
            </RadioGroup>
            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Annexe(s)
            </Text>
            <Annexes annexes={annexes} />
            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Copropriété
            </Text>
            <CoOwnership isCoOwnership={isCoOwnership} />
            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Infos complémentaires ?
            </Text>
            <InputTextArea name="additionalInformation" />
          </div>
          <div className="step-actions">
            <IconButton
              uppercase
              fullWidth
              {...(!isEdit && { icon: "chevron-right" })}
              textPosition="left"
              appearance="primary"
              boxShadow
              onClick={() =>
                isValid
                  ? handleNextStep()
                  : triggerValidation().then((isValidAfterValidation) => {
                      if (isValidAfterValidation) {
                        return handleNextStep();
                      }
                    })
              }
            >
              {isEdit
                ? "Enregistrer les modifications"
                : "Enregistrer & continuer"}
            </IconButton>
          </div>
        </>
      ) : null}
    </Accordion>
  );
};

export default CharacteristicsOfThePropertyStep;
