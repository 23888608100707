import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/Dialog";
import Text from "components/Text";
import SignDateCard from "./SignDateCard";
import KeyDatesCard from "./KeyDatesCard";
import SignConfirm from "./SignConfirm/SignConfirm";
import HelperDialog from "components/SaleProcess/HelperDialog";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import {
  FINANCING_METHODS,
  SALE_KEY_DATES_PREFIX,
  SALE_STEPS,
  SALE_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";
import { notifyError, notifySuccess } from "utils/notify";
import Form from "components/Form";
import { KeyDatesInputs } from "./KeyDatesInputs";
import SubmitButton from "components/Form/SubmitButton";
import { keyDatesValidationSchema } from "./validationSchemas";
import { getDateFromDateAndHoursAndMinutes } from "utils/date";
import SignDateConfirmationEmailDialog from "components/SaleProcess/SignDateConfirmationEmailDialog";

const SignConfirmHelpDialog = ({ isOpen, setOpen }) => (
  <HelperDialog
    title="Confirmation signature"
    open={isOpen}
    handleClose={() => setOpen(false)}
  >
    <Text>
      En confirmant que la signature a bien eu lieu, vous pourrez passer à{" "}
      <b>l’étape suivante</b> et enregistrer les <b>dates clés</b> de votre
      vente.
    </Text>
  </HelperDialog>
);
const KeyDatesHelpDialog = ({ isOpen, setOpen }) => (
  <HelperDialog
    title="Dates clés & conditions"
    open={isOpen}
    handleClose={() => setOpen(false)}
  >
    <Text>
      Les <b>dates clés</b> correspondent aux&nbsp;
      <b>dates limites définies par le notaire</b> lors de la signature de
      l’avant-contrat.
    </Text>
    <Text>
      Pour le bon déroulement de votre vente et le respect des délais,&nbsp;
      <b>enregistrez-les</b> immédiatement après la signature de
      l’avant-contrat.
    </Text>
    <Text>
      <b>Elio</b> se chargera de <b>vous alerter à temps&nbsp;!</b>
    </Text>
  </HelperDialog>
);

const KeyDatesDialog = ({
  isOpen,
  setOpen,
  isBankLoan,
  handleAddKeyDatesAndConditions,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      className="key-dates-dialog"
    >
      <DialogTitle>
        <Text uppercase size="medium" fontWeight="semi-bold" font="roboto-slab">
          Dates clés & conditions
        </Text>
        <Text
          color="primary"
          font="roboto-slab"
          align="center"
          fontWeight="semi-bold"
        >
          Enregistrez les dates clés pour accéder
          <br /> à l'étape 3 de votre vente !
        </Text>
      </DialogTitle>
      <Text size="small" fontStyle="italic" align="center">
        Vous trouverez toutes les dates dans l'avant-contrat signé
      </Text>
      <Form
        mode="all"
        className="key-dates-form"
        validationSchema={keyDatesValidationSchema({ isBankLoan })}
        defaultValues={{
          hasSuspensiveConditions: "0",
        }}
        onSubmit={handleAddKeyDatesAndConditions}
      >
        {({ values: { hasSuspensiveConditions } }) => (
          <>
            <DialogContent>
              <KeyDatesInputs
                isBankLoan={isBankLoan}
                hasSuspensiveConditions={hasSuspensiveConditions}
              />
            </DialogContent>
            <DialogActions>
              <SubmitButton appearance="primary" onClick={() => {}}>
                Enregistrer
              </SubmitButton>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

const CompromiseStep = ({ sale, setSale }) => {
  const [signConfirmHelpDialogOpen, setSignConfirmHelpDialogOpen] =
    React.useState(false);
  const [keyDatesHelpDialogOpen, setKeyDatesHelpDialogOpen] =
    React.useState(false);
  const [sendEmailConfirmDialogOpen, setSendEmailConfirmDialogOpen] =
    React.useState(false);
  const [keyDatesDialogOpen, setKeyDatesDialogOpen] = React.useState(false);
  const previousPreContractSignatureDate = React.useRef(
    sale.keyDates?.beforeContractSignedOn || undefined
  );

  const planPreContractSignatureDate =
    sale.keyDates?.beforeContractSignedOn || null;

  const {
    CHECK_DOCUMENTS: checkDocumentsSubStep,
    CHECK_BUYER_ACKNOWLEDGED_DOCUMENTS: checkBuyerAcknowledgedDocumentsSubStep,
  } = sale.saleSteps.DOCUMENTS;

  const {
    PLAN_PRE_CONTRACT_SIGNATURE: planPreContactSignatureSubStep,
    CONFIRM_PRE_CONTRACT_SIGNATURE: confirmPreContractSignature,
    REGISTER_KEY_DATES_AND_CONDITIONS: registerKeyDatesAndConditionsSubStep,
  } = sale.saleSteps.COMPROMISE;

  const preContractSignatureDate = confirmPreContractSignature.completedAt
    ? planPreContractSignatureDate
    : null;

  const financingMethod = sale.financialInformation.financingMethod;
  const isBankLoan = financingMethod === FINANCING_METHODS.BANK_LOAN.value;
  const isInterAgency = sale.type === SALE_TYPES.INTER_AGENCY;

  const handleSubmitSignDate = (values) => {
    const beforeContractSignedOn = getDateFromDateAndHoursAndMinutes(
      values.signDate,
      values.signTime
    );

    previousPreContractSignatureDate.current =
      sale.keyDates?.beforeContractSignedOn;

    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          saleStep: SALE_STEPS.COMPROMISE,
          keyDates: {
            "@id": `${SALE_KEY_DATES_PREFIX}${sale.keyDates.id}`,
            beforeContractSignedOn,
          },
          saleSubSteps: [
            {
              "@id": planPreContactSignatureSubStep.id,
              isCompleted: true,
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        setSendEmailConfirmDialogOpen(true);
        notifySuccess(
          "La date de signature avant-contrat a été enregistrée avec succès."
        );
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la date de signature avant-contrat."
        );
      });
  };

  const handleSignConfirm = () => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          saleSubSteps: [
            ...(!checkDocumentsSubStep.isCompleted
              ? [
                  {
                    "@id": checkDocumentsSubStep.id,
                    isCompleted: true,
                  },
                ]
              : []),
            ...(!checkBuyerAcknowledgedDocumentsSubStep.isCompleted
              ? [
                  {
                    "@id": checkBuyerAcknowledgedDocumentsSubStep.id,
                    isCompleted: true,
                  },
                ]
              : []),
            {
              "@id": confirmPreContractSignature.id,
              isCompleted: true,
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess(
          "La confirmation de signature de l'avant-contrat a été enregistrée avec succès."
        );
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de la confirmation de signature de l'avant-contrat"
        );
      });
  };

  const handleAddKeyDatesAndConditions = ({
    applicationDeadline,
    loanOfferDeadline,
    deedSigningDeadline,
    hasSuspensiveConditions,
    suspensiveConditions,
  }) => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          saleStep: SALE_STEPS.FORMALITIES,
          keyDates: {
            "@id": `${SALE_KEY_DATES_PREFIX}${sale.keyDates.id}`,
            ...(isBankLoan && {
              applicationDeadline: new Date(applicationDeadline),
              loanOfferDeadline: new Date(loanOfferDeadline),
            }),
            deedSigningDeadline: new Date(deedSigningDeadline),
          },
          saleSubSteps: [
            {
              "@id": registerKeyDatesAndConditionsSubStep.id,
              isCompleted: true,
              ...(hasSuspensiveConditions && {
                additionalData: {
                  suspensiveConditions,
                },
              }),
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess(
          "Les dates clés et les conditions suspensives ont été enregistrées avec succès."
        );
        setKeyDatesDialogOpen(false);
      })
      .catch(() => {
        notifyError(
          "Une erreur s'est produite lors de l'enregistrement des dates clés et des conditions suspensives."
        );
      });
  };

  return (
    <>
      <div className="sale-process-compromise-step">
        <SignDateCard
          isLate={planPreContactSignatureSubStep.isLate}
          isCompleted={planPreContactSignatureSubStep.isCompleted}
          signatureDate={planPreContractSignatureDate}
          signatureDateConfirmation={preContractSignatureDate}
          handleSubmitSignDate={handleSubmitSignDate}
          isInterAgency={isInterAgency}
        />
        <SignConfirm
          isLate={confirmPreContractSignature.isLate}
          isCompleted={confirmPreContractSignature.isCompleted}
          planPreContractSignatureDate={planPreContractSignatureDate}
          preContractSignatureDate={preContractSignatureDate}
          setHelpDialogOpen={setSignConfirmHelpDialogOpen}
          handleSignConfirm={handleSignConfirm}
        />
        <KeyDatesCard
          isLate={registerKeyDatesAndConditionsSubStep.isLate}
          isCompleted={registerKeyDatesAndConditionsSubStep.isCompleted}
          preContractSignatureDate={preContractSignatureDate}
          setHelpDialogOpen={setKeyDatesHelpDialogOpen}
          setKeyDatesDialogOpen={setKeyDatesDialogOpen}
        />
      </div>

      <SignConfirmHelpDialog
        isOpen={signConfirmHelpDialogOpen}
        setOpen={setSignConfirmHelpDialogOpen}
      />
      <KeyDatesHelpDialog
        isOpen={keyDatesHelpDialogOpen}
        setOpen={setKeyDatesHelpDialogOpen}
      />
      <SignDateConfirmationEmailDialog
        open={sendEmailConfirmDialogOpen}
        setOpen={setSendEmailConfirmDialogOpen}
        isInterAgency={isInterAgency}
        isEdit={
          previousPreContractSignatureDate.current &&
          previousPreContractSignatureDate.current !==
            planPreContractSignatureDate
        }
      />
      <KeyDatesDialog
        isOpen={keyDatesDialogOpen}
        setOpen={setKeyDatesDialogOpen}
        isBankLoan={isBankLoan}
        handleAddKeyDatesAndConditions={handleAddKeyDatesAndConditions}
      />
    </>
  );
};

export default CompromiseStep;
