import React from "react";

const RadioGroup = ({ label, children }) => {
  return (
    <div className="radio-group">
      {label && <label>{typeof label === "function" ? label() : label}</label>}
      <div className="radio-group__radio-container">{children}</div>
    </div>
  );
};

export default RadioGroup;
