import React from "react";
import { useHistory } from "react-router-dom";
import { Dialog, DialogActions, DialogContent } from "components/Dialog";
import Icon from "components/Icon";
import Button from "components/Button";

const ContactInformations = ({ contact, label }) => {
  return (
    <div className="contact-informations">
      <div className="contact-informations__title">{label}</div>
      <div className="contact-informations__content">
        <p>{`${contact.people.lastname} ${contact.people.firstname}`}</p>
        <p>{contact.people.mobile}</p>
        <p>{contact.email}</p>
      </div>
    </div>
  );
};

const PartnerAgencyInformations = ({ partnerAgency }) => {
  return (
    <div className="partner-agency-informations">
      <div className="partner-agency-informations__title">
        Agence partenaire
      </div>
      <div className="partner-agency-informations__content">
        <p>{partnerAgency.agencyName}</p>
        <p>{`${partnerAgency.lastName} ${partnerAgency.firstName}`}</p>
        <p>{partnerAgency.mobile}</p>
        <p>{partnerAgency.email}</p>
      </div>
    </div>
  );
};

const ClientAndNotary = ({ client, notary, label, partnerAgency }) => {
  return (
    <div className="client-and-notary">
      <ContactInformations
        contact={client}
        label={label}
        partnerAgency={partnerAgency}
      />
      <ContactInformations
        contact={notary}
        label={`Notaire ${label}`}
        partnerAgency={partnerAgency}
      />
      {partnerAgency && (
        <PartnerAgencyInformations partnerAgency={partnerAgency} />
      )}
    </div>
  );
};

const ContactModule = ({ open, handleClose, sale }) => {
  const history = useHistory();

  const { sellerAndNotary, buyerAndNotary } = sale;

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="contact-module-dialog"
    >
      <div className="contact-module-dialog__header">
        <Icon icon="address-book" color="primary" />
        <div className="contact-module-dialog__title">Contacts de la vente</div>
        <Icon icon="ripple" size="extra-big" color="primary" />
      </div>
      <DialogContent>
        <div className="clients-and-notaries">
          <ClientAndNotary
            client={sellerAndNotary[0].client}
            notary={sellerAndNotary[0].notary}
            label="Vendeur(s)"
            {...(sale?.partnerAgency?.represents === "SELLER" && {
              partnerAgency: sale.partnerAgency,
            })}
          />
        </div>
        <div className="clients-and-notaries">
          <ClientAndNotary
            client={buyerAndNotary[0].client}
            notary={buyerAndNotary[0].notary}
            label="Acquéreur(s)"
            {...(sale?.partnerAgency?.represents === "BUYER" && {
              partnerAgency: sale.partnerAgency,
            })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          appearance="primary"
          onClick={() => {
            history.push({
              pathname: `/contact-directory`,
            });
          }}
        >
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContactModule.propTypes = {};

export default ContactModule;
