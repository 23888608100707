import React from "react";
import Text from "components/Text";
import Icon from "components/Icon";
import { useAuth } from "components/Hooks/useAuth";

const Formula = ({ agency, setCurrentTab }) => {
  const { subscription } = agency;
  const {
    user: { isMasterUser, hasBasicSubscription, hasCredits },
  } = useAuth();
  const isBusinessOfferDisplay = React.useMemo(() => {
    return (
      hasBasicSubscription &&
      !hasCredits &&
      !subscription.isTrial &&
      isMasterUser
    );
  }, [hasBasicSubscription, hasCredits, subscription, isMasterUser]);

  return (
    <div className="formula">
      <div className="formula__your-formula">
        <Text align="center" size="very-large" font="roboto-slab">
          Votre formule actuelle
        </Text>
        <Icon icon="ripple" color="primary" size="very-big" />
        <Text align="center" uppercase size="very-large" fontWeight="bold">
          {subscription.subscriptionType.split("_").join(" ")}
        </Text>
        <Text
          size="small"
          font="roboto-slab"
          align="center"
        >{`1 compte principal + ${subscription?.seats} compte${
          subscription?.seats > 0 ? "s" : ""
        } utilisateur${subscription?.seats > 0 ? "s" : ""}`}</Text>
        {subscription.isTrial && (
          <Text color="primary" font="roboto-slab" fontWeight="bold">
            Compte d'essai gratuit
          </Text>
        )}
        {hasBasicSubscription && isMasterUser && (
          <Text color="primary" fontWeight="bold" align="center" size="medium">
            Crédits ventes : {subscription.credits}
          </Text>
        )}
      </div>

      {subscription.isTrial && (
        <div className="formula__informations">
          <Text align="center" fontWeight="semi-bold">
            Votre période d'essai gratuit se terminera le :{" "}
            <b>{new Date(subscription.trialEndsAt).toLocaleDateString()}</b>
          </Text>
          <Text align="center" fontWeight="semi-bold">
            Pour continuer à profiter des services Elio à l'issue de votre essai
            gratuit,{" "}
            <span
              className="formula__contact-us"
              onClick={() => setCurrentTab(3)}
            >
              contactez-nous
            </span>
          </Text>
        </div>
      )}
      {isBusinessOfferDisplay && (
        <div className="formula__business-offer">
          <Text
            color="dark"
            fontWeight="bold"
            align="center"
            marginBottom="large"
          >
            Votre compte est limité en nombre de ventes
          </Text>
          <Text align="center">
            Pour profiter pleinement d'Elio, découvrez nos
            <a
              href="https://www.elio-app.com/tarifs"
              target="_blank"
              rel="noreferrer"
            >
              offres Business
            </a>
          </Text>
        </div>
      )}
      {isMasterUser && (
        <div className="formula__cgv-link">
          <a
            href="https://www.elio-app.com/conditions-generales"
            target="_blank"
            rel="noreferrer"
          >
            Conditions générales
          </a>
        </div>
      )}
    </div>
  );
};

export default Formula;
