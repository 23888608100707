import React from "react";
import SaleProcessCard from "components/SaleProcess/SaleProcessCard";
import Button from "components/Button";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import { notifySuccess, notifyError } from "utils/notify";
import HelperDialog from "components/SaleProcess/HelperDialog";
import Text from "components/Text";
import {
  FILE_TYPES,
  FINANCING_METHODS,
  FORMALITY_TYPES,
  SALE_KEY_DATES_PREFIX,
  SALE_STEPS,
} from "components/Sale/SaleMultistep/saleConstants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import { formatToLocaleDate } from "utils/date";
import {
  KeyDates,
  SuspensiveConditions,
} from "../CompromiseStep/KeyDatesInputs";
import Form from "components/Form";
import Accordion from "components/Accordion";
import SaleProcessCheckList from "components/SaleProcess/SaleProcessChecklist";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import { TEMPLATE_REFERENCES } from "utils/email";

const NotificationSruDiaCard = ({
  isCompleted,
  isLate,
  confirmNotificationSruDia,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = !isCompleted && isLate;

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Notification SRU - DIA"
        handleHelpClick={() => setHelperDialogOpen(true)}
        actions={
          !isCompleted && (
            <Button
              appearance={isAlert ? "reversed-tertiary" : "reversed-primary"}
              onClick={confirmNotificationSruDia}
              boxShadow={false}
            >
              Je confirme !
            </Button>
          )
        }
      >
        {isCompleted ? (
          "Formalités effectuées !"
        ) : (
          <>
            Le notaire a t'il effectué la notification <br />
            SRU & DIA ?
          </>
        )}
      </SaleProcessCard>
      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Notification SRU - DIA"
      >
        <Text>
          Vous devez vous assurer que le notaire a procédé à la{" "}
          <b>notification SRU</b>.
        </Text>
        <Text>
          L’acquéreur dispose d’un délai de rétractation de 10 jours en sa
          faveur à compter de la <b>date de notification de l’avant-contrat</b>.
        </Text>
        <Text>
          Il est donc essentiel pour vous, que le notaire{" "}
          <b>notifie l’acquéreur sans délai afin de sécuriser votre vente.</b>
        </Text>
        <Text>
          Le <b>module mail Elio</b> vous permettra de{" "}
          <b>relancer le notaire</b> si besoin.
        </Text>
      </HelperDialog>
    </>
  );
};

const FundingDialogAction = ({
  text,
  dueDate,
  isLate,
  isCompleted,
  action,
}) => {
  const isAlert = !isCompleted && isLate;

  return (
    <div className="funding-dialog__action">
      <Text align="center" font="roboto-slab">
        {text}
      </Text>
      <Text fontWeight="semi-bold">{`Date limite le ${formatToLocaleDate(
        dueDate
      )}`}</Text>
      {isCompleted ? (
        <Text
          color="primary"
          fontWeight="semi-bold"
          size="medium"
          font="roboto-slab"
        >
          Confirmé !
        </Text>
      ) : (
        <Button
          appearance={isAlert ? "reversed-tertiary" : "reversed-primary"}
          onClick={action}
          boxShadow={false}
        >
          Je confirme
        </Button>
      )}
    </div>
  );
};

const FundingDialog = ({
  open,
  handleClose,
  fileDepositDueDate,
  fileDepositIsLate,
  fileDepositIsCompleted,
  confirmFileDeposit,
  receiptOfferDueDate,
  receiptOfferIsLate,
  receiptOfferIsCompleted,
  confirmReceiptOffer,
}) => {
  return (
    <Dialog open={open} handleClose={handleClose} className="funding-dialog">
      <DialogTitle>
        <Text uppercase size="medium" fontWeight="semi-bold" font="roboto-slab">
          Financement
        </Text>
      </DialogTitle>
      <DialogContent>
        <div className="funding-dialog__content">
          <FundingDialogAction
            text={
              <>
                Le dossier de prêt a t-il <br /> été déposé en banque ?
              </>
            }
            dueDate={fileDepositDueDate}
            isLate={fileDepositIsLate}
            isCompleted={fileDepositIsCompleted}
            action={confirmFileDeposit}
          />
          <FundingDialogAction
            text={
              <>
                L'offre de prêt a t-elle <br /> été reçue ?
              </>
            }
            dueDate={receiptOfferDueDate}
            isLate={receiptOfferIsLate}
            isCompleted={receiptOfferIsCompleted}
            action={confirmReceiptOffer}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const FundingCardText = ({ isBankLoan, isCompleted }) => {
  if (!isBankLoan) {
    return "Paiement comptant";
  }

  if (isCompleted) {
    return "Financement validé !";
  }

  return (
    <>
      S'assurer de l'avancée des démarches <br />
      bancaires
    </>
  );
};

const FundingCard = ({
  actedStepIsFullyCompleted,
  isBankLoan,
  isCompleted,
  isLate,
  openFundingDialog,
}) => {
  const [bankLoanHelperDialogOpen, setBankLoanHelperDialogOpen] =
    React.useState(false);

  const isAlert = !isCompleted && isLate;

  const getButtonAppearance = () => {
    if (isCompleted) {
      return "grey";
    }

    if (isAlert) {
      return "reversed-tertiary";
    }

    return "reversed-primary";
  };

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Financement"
        {...(isBankLoan && {
          handleHelpClick: () => setBankLoanHelperDialogOpen(true),
        })}
        actions={
          !actedStepIsFullyCompleted &&
          isBankLoan && (
            <Button
              appearance={getButtonAppearance()}
              onClick={openFundingDialog}
              boxShadow={false}
            >
              Consulter
            </Button>
          )
        }
      >
        <FundingCardText isBankLoan={isBankLoan} isCompleted={isCompleted} />
      </SaleProcessCard>
      <HelperDialog
        open={bankLoanHelperDialogOpen}
        handleClose={() => setBankLoanHelperDialogOpen(false)}
        title="Financement"
      >
        <Text>
          Vous devez vous assurer de la{" "}
          <b>bonne avancée des démarches bancaires </b>de votre client.
        </Text>
        <Text>
          Pour rappel, votre client doit{" "}
          <b>déposer son dossier de prêt avant la date limite</b> stipulée dans
          l'avant-contrat et vous fournir la preuve de dépôt. À réception de{" "}
          <b>l'offre de prêt</b>, il devra en transmettre une{" "}
          <b>copie aux notaires</b>
        </Text>
        <Text>
          Les <b>modules Elio</b> vous permettront de <b>relancer</b> votre
          client et <b>d'informer</b> les notaires
        </Text>
      </HelperDialog>
    </>
  );
};

const FormalitiesAndDocumentsDialog = ({
  open,
  handleClose,
  isBankLoan,
  formalitiesAndDocumentsDefaultValues,
  handleFormalitiesAndDocumentsUpdate,
  documentChecklist,
  mandatoryDocuments,
  setDocumentChecklist,
  formalityChecklist,
  mandatoryFormalities,
  setFormalityChecklist,
}) => {
  const { setMailModuleOpen } = useSaleModules();

  const handleAddAdditionalDocument = (documentName) => {
    const document = {
      value: `document${documentChecklist.length + 1}`,
      label: documentName,
      isChecked: false,
    };
    setDocumentChecklist((previousChecklist) => [
      ...previousChecklist,
      document,
    ]);

    return document;
  };

  const handleAddAdditionalFormality = (documentName) => {
    const formality = {
      value: `formality${formalityChecklist.length + 1}`,
      label: documentName,
      isChecked: false,
    };
    setFormalityChecklist((previousChecklist) => [
      ...previousChecklist,
      formality,
    ]);

    return formality;
  };

  const handleCheckAll = ({ setValue, checklist, ignore }) => {
    const checklistKeys = checklist.map((checklistItem) => checklistItem.value);
    checklistKeys.forEach((valueKey) => {
      if (!ignore.includes(valueKey)) {
        setValue(valueKey, true);
      }
    });
  };

  const deleteDocumentFromChecklist = (documentKey) => {
    setDocumentChecklist(
      documentChecklist.filter((document) => document.value !== documentKey)
    );
  };

  const deleteFormalityFromChecklist = (formalityKey) => {
    setFormalityChecklist(
      formalityChecklist.filter((formality) => formality.value !== formalityKey)
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="formalities-and-documents-dialog"
    >
      <DialogTitle>
        <Text uppercase size="medium" fontWeight="semi-bold" font="roboto-slab">
          Formalités & documents
        </Text>
        <Text
          color="primary"
          font="roboto-slab"
          align="center"
          fontWeight="semi-bold"
        >
          Cocher tous les documents disponibles
        </Text>
      </DialogTitle>

      <Form mode="all" defaultValues={formalitiesAndDocumentsDefaultValues}>
        {({ values, setValue }) => (
          <>
            <DialogContent>
              <Accordion title="Dates clés de la vente & conditions suspensives">
                <div className="formalities-and-documents-dialog__key-dates">
                  <div>
                    <Text color="primary" font="roboto-slab">
                      Dates clés de la vente
                    </Text>
                    <KeyDates isBankLoan={isBankLoan} />
                  </div>
                  <div>
                    <Text color="primary" font="roboto-slab">
                      Conditions suspensives
                    </Text>
                    <SuspensiveConditions />
                  </div>
                </div>
              </Accordion>
              <div className="formalities-and-documents-dialog__checklist">
                <Text color="primary" font="roboto-slab">
                  Liste des documents
                </Text>
                <SaleProcessCheckList
                  checklist={documentChecklist}
                  mandatoryChecklistItems={mandatoryDocuments}
                  deleteItemFromChecklist={deleteDocumentFromChecklist}
                  handleAddAdditionalChecklistItem={handleAddAdditionalDocument}
                  handleCheckAll={() =>
                    handleCheckAll({
                      setValue,
                      checklist: documentChecklist,
                      ignore: ["newDocumentName"],
                    })
                  }
                  inputName="newDocumentName"
                  inputPlaceholder="Ajouter un document"
                />
              </div>
              <div className="formalities-and-documents-dialog__checklist">
                <Text color="primary" font="roboto-slab">
                  Formalités avant signature
                </Text>
                <SaleProcessCheckList
                  checklist={formalityChecklist}
                  mandatoryChecklistItems={mandatoryFormalities}
                  deleteItemFromChecklist={deleteFormalityFromChecklist}
                  handleAddAdditionalChecklistItem={
                    handleAddAdditionalFormality
                  }
                  handleCheckAll={() =>
                    handleCheckAll({
                      setValue,
                      checklist: formalityChecklist,
                      ignore: ["newFormalityName"],
                    })
                  }
                  inputName="newFormalityName"
                  inputPlaceholder="Ajouter une formalité"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                appearance="reversed-primary"
                boxShadow={false}
                onClick={() => {
                  handleClose();
                  setMailModuleOpen({
                    open: true,
                    template:
                      TEMPLATE_REFERENCES.requestForAdditionalDocuments.value,
                  });
                }}
              >
                Demander un document
              </Button>
              <Button
                appearance="primary"
                onClick={() =>
                  handleFormalitiesAndDocumentsUpdate(
                    values,
                    setValue,
                    handleAddAdditionalDocument,
                    handleAddAdditionalFormality
                  )
                }
              >
                Enregistrer
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

const FormalitiesAndDocumentsCard = ({
  actedStepIsFullyCompleted,
  isCompleted,
  isLate,
  openFormalitiesAndDocumentsDialog,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = !isCompleted && isLate;

  const getButtonAppearance = () => {
    if (isCompleted) {
      return "grey";
    }

    if (isAlert) {
      return "reversed-tertiary";
    }

    return "reversed-primary";
  };

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Formalités & documents"
        handleHelpClick={() => setHelperDialogOpen(true)}
        actions={
          !actedStepIsFullyCompleted && (
            <Button
              appearance={getButtonAppearance()}
              onClick={openFormalitiesAndDocumentsDialog}
              boxShadow={false}
            >
              Consulter
            </Button>
          )
        }
      >
        {isCompleted ? (
          <>
            Dossier complet <br />
            et prêt à signer !
          </>
        ) : (
          <>
            S'assurer que le dossier de vente <br />
            est complet
          </>
        )}
      </SaleProcessCard>
      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Formalités & documents"
      >
        <Text>
          Cette étape est essentielle à l’aboutissement de votre vente.
        </Text>
        <Text>
          Assurez-vous que le <b>dossier de vente est complet</b> et que toutes
          les
          <b> pièces sont à jour</b> avant la signature de l’acte.
        </Text>
        <Text>
          Une <b>liste de documents</b> prédéfinie vous aidera à&nbsp;
          <b>ne rien oublier</b> !
        </Text>
        <Text>
          Vous pouvez, grâce au <b>module mail Elio</b>, demander des pièces
          complémentaires à vos clients et les transmettre en suivant aux
          notaires.
        </Text>
      </HelperDialog>
    </>
  );
};

const getMandatoryDocuments = ({ isCoOwnership, isBankLoan }) => {
  const bankLoanMandatoryDocuments = [
    FILE_TYPES.CERTIFICATE_OF_DEPOSIT_OF_BANK_FILE,
    FILE_TYPES.BANK_LOAN_OFFER,
  ];

  const coOwnershipMandatoryDocuments = [FILE_TYPES.DATED_CONDITION];

  return [
    ...(isCoOwnership ? coOwnershipMandatoryDocuments : []),
    ...(isBankLoan ? bankLoanMandatoryDocuments : []),
  ].map(({ value, label }) => ({ value, label, isChecked: false }));
};

const getMandatoryFormalities = () => {
  return [
    FORMALITY_TYPES.RENEWAL_OF_DIAGNOSTICS,
    FORMALITY_TYPES.SUSPENSIVE_CONDITIONS_LIFTED,
    FORMALITY_TYPES.COUNTER_VISIT_OF_PROPERTY_BEFORE_THE_ACT,
  ].map(({ value, label }) => ({ value, label, isChecked: false }));
};

const FormalitiesStep = ({ sale, setSale }) => {
  const [fundingDialogOpen, setFundingDialogOpen] = React.useState(false);
  const [
    formalitiesAndDocumentsDialogOpen,
    setFormalitiesAndDocumentsDialogOpen,
  ] = React.useState(false);

  const isCoOwnership = Boolean(sale.coOwnership);
  const isBankLoan =
    sale.financialInformation.financingMethod ===
    FINANCING_METHODS.BANK_LOAN.value;

  const { REGISTER_KEY_DATES_AND_CONDITIONS: registerKeyDatesAndConditions } =
    sale.saleSteps.COMPROMISE;

  const {
    CHECK_NOTARY_NOTIFIED_PRE_CONTRACT: checkNotaryNotifiedPreContract,
    ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_FD:
      ensureBankingProcedureAreAdvancedFD,
    ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_RO:
      ensureBankingProcedureAreAdvancedRO,
    ENSURE_PRE_DEED_SALES_FILE_IS_COMPLETE: ensurePreDeedSalesFileIsComplete,
  } = sale.saleSteps.FORMALITIES;

  const { applicationDeadline, loanOfferDeadline, deedSigningDeadline } =
    sale.keyDates;

  const mandatoryDocuments = React.useMemo(
    () =>
      getMandatoryDocuments({
        isCoOwnership,
        isBankLoan,
      }),
    [isCoOwnership, isBankLoan]
  );

  const mandatoryDocumentsValues = mandatoryDocuments.map(({ value }) => value);

  const _documentChecklist =
    ensurePreDeedSalesFileIsComplete?.additionalData?.documentChecklist ??
    mandatoryDocuments;

  const documentChecklistDefaultValues = React.useMemo(
    () =>
      _documentChecklist.reduce(
        (acc, cur) => ({ ...acc, [cur.value]: cur.isChecked }),
        {}
      ),
    [_documentChecklist]
  );

  const mandatoryFormalities = React.useMemo(
    () => getMandatoryFormalities(),
    []
  );

  const mandatoryFormalitiesValues = mandatoryFormalities.map(
    ({ value }) => value
  );

  const _formalityChecklist =
    ensurePreDeedSalesFileIsComplete?.additionalData.formalityChecklist ??
    mandatoryFormalities;

  const formalitiesChecklistDefaultValues = React.useMemo(
    () =>
      _formalityChecklist.reduce(
        (acc, cur) => ({ ...acc, [cur.value]: cur.isChecked }),
        {}
      ),
    [_formalityChecklist]
  );

  const formalitiesAndDocumentsDefaultValues = {
    ...registerKeyDatesAndConditions.additionalData,
    deedSigningDeadline: deedSigningDeadline
      ? new Date(deedSigningDeadline)
      : null,

    ...(isBankLoan && {
      loanOfferDeadline: loanOfferDeadline ? new Date(loanOfferDeadline) : null,
      applicationDeadline: applicationDeadline
        ? new Date(applicationDeadline)
        : null,
    }),
    ...documentChecklistDefaultValues,
    ...formalitiesChecklistDefaultValues,
  };

  const [documentChecklist, setDocumentChecklist] =
    React.useState(_documentChecklist);
  const [formalityChecklist, setFormalityChecklist] =
    React.useState(_formalityChecklist);

  const actedStepIsFullyCompleted = Object.keys(sale.saleSteps.ACTED).every(
    (subStepKey) => sale.saleSteps.ACTED[subStepKey].isCompleted
  );

  const confirmNotificationSruDia = () => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          ...(ensureBankingProcedureAreAdvancedFD.isCompleted &&
            ensureBankingProcedureAreAdvancedRO.isCompleted &&
            ensurePreDeedSalesFileIsComplete.isCompleted && {
              saleStep: SALE_STEPS.ACTED,
            }),
          saleSubSteps: [
            { "@id": checkNotaryNotifiedPreContract.id, isCompleted: true },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess("La progression a été enregistrée avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la progression."
        );
      });
  };

  const confirmFileDeposit = () => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          ...(checkNotaryNotifiedPreContract.isCompleted &&
            ensureBankingProcedureAreAdvancedRO.isCompleted &&
            ensurePreDeedSalesFileIsComplete.isCompleted && {
              saleStep: SALE_STEPS.ACTED,
            }),
          saleSubSteps: [
            {
              "@id": ensureBankingProcedureAreAdvancedFD.id,
              isCompleted: true,
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess("La progression a été enregistrée avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la progression."
        );
      });
  };

  const confirmReceiptOffer = () => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          ...(checkNotaryNotifiedPreContract.isCompleted &&
            ensureBankingProcedureAreAdvancedFD.isCompleted &&
            ensurePreDeedSalesFileIsComplete.isCompleted && {
              saleStep: SALE_STEPS.ACTED,
            }),
          saleSubSteps: [
            {
              "@id": ensureBankingProcedureAreAdvancedRO.id,
              isCompleted: true,
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        notifySuccess("La progression a été enregistrée avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la progression."
        );
      });
  };

  const handleFormalitiesAndDocumentsUpdate = (
    {
      applicationDeadline,
      loanOfferDeadline,
      deedSigningDeadline,
      suspensiveConditions,
      ...values
    },
    setValue,
    handleAddAdditionalDocument,
    handleAddAdditionalFormality
  ) => {
    let stagedDocument, stagedFormality;

    if (values["newDocumentName"]) {
      stagedDocument = handleAddAdditionalDocument(values["newDocumentName"]);
      setValue("newDocumentName", "");
    }

    if (values["newFormalityName"]) {
      stagedFormality = handleAddAdditionalFormality(
        values["newFormalityName"]
      );
      setValue("newFormalityName", "");
    }

    const updatedDocumentChecklist = [
      ...documentChecklist,
      ...(stagedDocument ? [stagedDocument] : []),
    ].map((document) => {
      return { ...document, isChecked: values[document.value] };
    });

    const allDocumentsAreChecked = updatedDocumentChecklist.every(
      (document) => document.isChecked
    );

    const updatedFormalityChecklist = [
      ...formalityChecklist,
      ...(stagedFormality ? [stagedFormality] : []),
    ].map((formality) => {
      return { ...formality, isChecked: values[formality.value] };
    });

    const allFormalitiesAreChecked = updatedFormalityChecklist.every(
      (formality) => formality.isChecked
    );

    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          saleStep: SALE_STEPS.FORMALITIES,
          ...(allDocumentsAreChecked &&
            allFormalitiesAreChecked &&
            checkNotaryNotifiedPreContract.isCompleted &&
            (!isBankLoan ||
              (ensureBankingProcedureAreAdvancedFD.isCompleted &&
                ensureBankingProcedureAreAdvancedRO.isCompleted)) && {
              saleStep: SALE_STEPS.ACTED,
            }),
          keyDates: {
            "@id": `${SALE_KEY_DATES_PREFIX}${sale.keyDates.id}`,
            applicationDeadline,
            deedSigningDeadline: new Date(deedSigningDeadline),
            ...(isBankLoan && {
              loanOfferDeadline: new Date(loanOfferDeadline),
            }),
          },
          saleSubSteps: [
            {
              "@id": registerKeyDatesAndConditions.id,
              additionalData: { suspensiveConditions },
            },
            {
              "@id": ensurePreDeedSalesFileIsComplete.id,
              additionalData: {
                documentChecklist: updatedDocumentChecklist,
                formalityChecklist: updatedFormalityChecklist,
              },
              isCompleted: allDocumentsAreChecked && allFormalitiesAreChecked,
            },
          ],
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        if (allDocumentsAreChecked && allFormalitiesAreChecked) {
          setFormalitiesAndDocumentsDialogOpen(false);
        }
        notifySuccess("La progression a été enregistrée avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la progression."
        );
      });
  };

  return (
    <>
      <div className="sale-process-documents-step">
        <NotificationSruDiaCard
          isCompleted={checkNotaryNotifiedPreContract.isCompleted}
          isLate={checkNotaryNotifiedPreContract.isLate}
          confirmNotificationSruDia={confirmNotificationSruDia}
        />
        <FundingCard
          actedStepIsFullyCompleted={actedStepIsFullyCompleted}
          isBankLoan={isBankLoan}
          /** If the financing method is not bank loan the substep is considered completed and no alert will be triggered visually */
          isCompleted={
            !isBankLoan ||
            (ensureBankingProcedureAreAdvancedFD.isCompleted &&
              ensureBankingProcedureAreAdvancedRO.isCompleted)
          }
          isLate={
            isBankLoan &&
            (ensureBankingProcedureAreAdvancedFD.isLate ||
              ensureBankingProcedureAreAdvancedRO.isLate)
          }
          openFundingDialog={() => setFundingDialogOpen(true)}
        />
        <FormalitiesAndDocumentsCard
          actedStepIsFullyCompleted={actedStepIsFullyCompleted}
          isCompleted={ensurePreDeedSalesFileIsComplete.isCompleted}
          isLate={ensurePreDeedSalesFileIsComplete.isLate}
          openFormalitiesAndDocumentsDialog={() =>
            setFormalitiesAndDocumentsDialogOpen(true)
          }
        />
      </div>
      <FundingDialog
        open={fundingDialogOpen}
        handleClose={() => setFundingDialogOpen(false)}
        fileDepositDueDate={sale.keyDates.applicationDeadline}
        fileDepositIsLate={
          isBankLoan && ensureBankingProcedureAreAdvancedFD.isLate
        }
        fileDepositIsCompleted={
          isBankLoan && ensureBankingProcedureAreAdvancedFD.isCompleted
        }
        confirmFileDeposit={confirmFileDeposit}
        receiptOfferDueDate={sale.keyDates.loanOfferDeadline}
        receiptOfferIsLate={
          isBankLoan && ensureBankingProcedureAreAdvancedRO.isLate
        }
        receiptOfferIsCompleted={
          isBankLoan && ensureBankingProcedureAreAdvancedRO.isCompleted
        }
        confirmReceiptOffer={confirmReceiptOffer}
      />
      <FormalitiesAndDocumentsDialog
        open={formalitiesAndDocumentsDialogOpen}
        handleClose={() => setFormalitiesAndDocumentsDialogOpen(false)}
        isBankLoan={isBankLoan}
        formalitiesAndDocumentsDefaultValues={
          formalitiesAndDocumentsDefaultValues
        }
        handleFormalitiesAndDocumentsUpdate={
          handleFormalitiesAndDocumentsUpdate
        }
        documentChecklist={documentChecklist}
        mandatoryDocuments={mandatoryDocumentsValues}
        setDocumentChecklist={setDocumentChecklist}
        formalityChecklist={formalityChecklist}
        mandatoryFormalities={mandatoryFormalitiesValues}
        setFormalityChecklist={setFormalityChecklist}
      />
    </>
  );
};

export default FormalitiesStep;
