import * as yup from "yup";

const ACCEPTED_VALUES = ["SUPPRIMER", "ARCHIVER", "ANNULER"];

const validationSchema = yup.object({
  confirmation: yup
    .string()
    .test("isEqual", "Merci de saisir le mot demandé en majuscule", (value) =>
      ACCEPTED_VALUES.includes(value)
    ),
});

export default validationSchema;
