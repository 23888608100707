import React from "react";
import SaleProcessCard from "components/SaleProcess/SaleProcessCard";
import SignDateHandler from "components/SaleProcess/SignDateHandler";
import HelperDialog from "components/SaleProcess/HelperDialog";
import Text from "components/Text";

const SignDateCard = ({
  isLate,
  isCompleted,
  handleSubmitSignDate,
  signatureDate,
  signatureDateConfirmation,
  isInterAgency,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = !isCompleted && isLate;

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Date signature avant-contrat"
        handleHelpClick={() => setHelperDialogOpen(true)}
      >
        <SignDateHandler
          handleSubmitSignDate={handleSubmitSignDate}
          signatureDate={signatureDate}
          signatureDateConfirmation={signatureDateConfirmation}
          isInterAgency={isInterAgency}
          isAlert={isAlert}
        />
      </SaleProcessCard>
      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Date de signature avant-contrat"
      >
        <Text>
          Cette étape est destinée à la planification de la date de signature de
          l’avant-contrat.
        </Text>
        <Text>
          Demandez, via le <b>module mail</b>, les disponibilités des
          intervenants de la vente et enregistrez-les dans le&nbsp;
          <b>module planning</b> pour afficher les&nbsp;
          <b>créneaux communs à tous.</b>
        </Text>
        <Text>
          Une fois la date de signature confirmée, <b>enregistrez-là</b> et
          envoyez une <b> confirmation mail</b> à tous les intervenants.
        </Text>
      </HelperDialog>
    </>
  );
};

export default SignDateCard;
