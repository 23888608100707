import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import icons from "./icons";

const Icon = ({ icon, size, color, ...iconProps }) => {
  if (!icon) {
    console.warn("The 'icon' prop is required in order to use <Icon />.");
    return null;
  }

  color = typeof color === "function" ? color() : color;

  const svgClassName = classNames(`icon`, {
    [`icon--size-${size}`]: size,
    [`icon--color-${color}`]: color,
    /** this class is only used to provide a way to target icon by their name */
    [`icon-${icon}`]: icon,
  });

  if (!icons[icon]) {
    console.warn(
      "The icon provided to the 'icon' attribute of <Icon /> is not recognized, please make sure you import your icon in 'src/components/Icon/icons/index.js' before using it."
    );
    return null;
  }

  const IconComponent = icons[icon];
  return (
    <div {...iconProps} className={svgClassName}>
      <IconComponent />
    </div>
  );
};

Icon.defaultProps = {
  size: "default",
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    "default",
    "mini",
    "small",
    "medium",
    "big",
    "extra-big",
    "very-big",
    "bigger",
  ]),
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      "primary",
      "secondary",
      "tertiary",
      "grey",
      "medium-grey",
      "dark-grey",
      "black",
      "white",
    ]),
    PropTypes.func,
  ]),
};

export default Icon;
