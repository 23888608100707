import { ReactComponent as AddressBook } from "./address-book.svg";
import { ReactComponent as AgendaIcon } from "./agenda.svg";
import { ReactComponent as ArchiveIcon } from "./archive.svg";
import { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "./arrow-up.svg";
import { ReactComponent as BinIcon } from "./bin.svg";
import { ReactComponent as BellIcon } from "./bell.svg";
import { ReactComponent as BuildingIcon } from "./building.svg";
import { ReactComponent as CaveBoldIcon } from "./cave-bold.svg";
import { ReactComponent as CaveIcon } from "./cave.svg";
import { ReactComponent as ChatIcon } from "./chat.svg";
import { ReactComponent as CheckIcon } from "./check.svg";
import { ReactComponent as ClockIcon } from "./clock.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as DocumentIcon } from "./document.svg";
import { ReactComponent as DownloadIcon } from "./download.svg";
import { ReactComponent as ElioLogoBubbleIcon } from "./elio-logo-bubble.svg";
import { ReactComponent as EmailsIcon } from "./emails.svg";
import { ReactComponent as FlipFlopsIcon } from "./flipflops.svg";
import { ReactComponent as FolderBoldIcon } from "./folder-bold.svg";
import { ReactComponent as FolderInfoIcon } from "./folder-info.svg";
import { ReactComponent as FolderIcon } from "./folder.svg";
import { ReactComponent as GearIcon } from "./gear.svg";
import { ReactComponent as HouseIcon } from "./house.svg";
import { ReactComponent as LandIcon } from "./land.svg";
import { ReactComponent as LetterCloseIcon } from "./letter-close.svg";
import { ReactComponent as LetterOpenIcon } from "./letter-open.svg";
import { ReactComponent as LockCloseIcon } from "./lock-close.svg";
import { ReactComponent as LockOpenIcon } from "./lock-open.svg";
import { ReactComponent as LogoIcon } from "./logo.svg";
import { ReactComponent as LogoutIcon } from "./logout.svg";
import { ReactComponent as LoupeIcon } from "./loupe.svg";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as MoustacheIcon } from "./moustache.svg";
import { ReactComponent as MoustacheLeftIcon } from "./moustache-left.svg";
import { ReactComponent as MoustacheRightIcon } from "./moustache-right.svg";
import { ReactComponent as NotesIcon } from "./notes.svg";
import { ReactComponent as OfficeIcon } from "./office.svg";
import { ReactComponent as OutlineQuestionCircleIcon } from "./outline-question-circle.svg";
import { ReactComponent as PaperIcon } from "./paper.svg";
import { ReactComponent as PinIcon } from "./pin.svg";
import { ReactComponent as PlanningIcon } from "./planning.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as PlusLigthIcon } from "./plus-ligth.svg";
import { ReactComponent as PrintIcon } from "./print.svg";
import { ReactComponent as PeopleIcon } from "./people.svg";
import { ReactComponent as PencilIcon } from "./pencil.svg";
import { ReactComponent as PersonCircleIcon } from "./person-circle.svg";
import { ReactComponent as ChevronRightIcon } from "./chevron-right.svg";
import { ReactComponent as ChevronDownIcon } from "./chevron-down.svg";
import { ReactComponent as ChevronLeftIcon } from "./chevron-left.svg";
import { ReactComponent as RippleIcon } from "./ripple.svg";
import { ReactComponent as SliderIcon } from "./slider.svg";
import { ReactComponent as SmileIcon } from "./smile.svg";
import { ReactComponent as SpinnerIcon } from "./spinner.svg";
import { ReactComponent as StatisticBoldIcon } from "./statistic-bold.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as UserSettings } from "./user-settings.svg";
import { ReactComponent as StatisticIcon } from "./statistic.svg";
import { ReactComponent as WaveIcon } from "./wave.svg";
import { ReactComponent as WellDoneIcon } from "./well-done.svg";
import { ReactComponent as PercentageIcon } from "./percentage.svg";
import { ReactComponent as EuroIcon } from "./euro.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as DocumentDownloadIcon } from "./document-download.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as AccountIcon } from "./account.svg";
import { ReactComponent as MobileIcon } from "./mobile.svg";
import { ReactComponent as FiltersIcon } from "./filters.svg";
import { ReactComponent as WarningIcon } from "./warning.svg";

const icons = {
  "address-book": AddressBook,
  agenda: AgendaIcon,
  archive: ArchiveIcon,
  "arrow-up": ArrowUpIcon,
  "arrow-down": ArrowDownIcon,
  bin: BinIcon,
  bell: BellIcon,
  building: BuildingIcon,
  "cave-bold": CaveBoldIcon,
  cave: CaveIcon,
  chat: ChatIcon,
  check: CheckIcon,
  clock: ClockIcon,
  close: CloseIcon,
  document: DocumentIcon,
  "document-download": DownloadIcon,
  "elio-logo-bubble": ElioLogoBubbleIcon,
  emails: EmailsIcon,
  "flip-flops": FlipFlopsIcon,
  "folder-bold": FolderBoldIcon,
  folder: FolderIcon,
  "folder-info": FolderInfoIcon,
  gear: GearIcon,
  house: HouseIcon,
  land: LandIcon,
  "letter-close": LetterCloseIcon,
  "letter-open": LetterOpenIcon,
  "lock-close": LockCloseIcon,
  "lock-open": LockOpenIcon,
  logo: LogoIcon,
  logout: LogoutIcon,
  loupe: LoupeIcon,
  menu: MenuIcon,
  moustache: MoustacheIcon,
  "moustache-left": MoustacheLeftIcon,
  "moustache-right": MoustacheRightIcon,
  office: OfficeIcon,
  "outline-question-circle": OutlineQuestionCircleIcon,
  notes: NotesIcon,
  paper: PaperIcon,
  pin: PinIcon,
  planning: PlanningIcon,
  plus: PlusIcon,
  "plus-ligth": PlusLigthIcon,
  print: PrintIcon,
  people: PeopleIcon,
  "person-circle": PersonCircleIcon,
  "chevron-right": ChevronRightIcon,
  "chevron-down": ChevronDownIcon,
  "chevron-left": ChevronLeftIcon,
  ripple: RippleIcon,
  slider: SliderIcon,
  smile: SmileIcon,
  spinner: SpinnerIcon,
  "statistic-bold": StatisticBoldIcon,
  statistic: StatisticIcon,
  user: User,
  "user-settings": UserSettings,
  wave: WaveIcon,
  "well-done": WellDoneIcon,
  percentage: PercentageIcon,
  pencil: PencilIcon,
  euro: EuroIcon,
  edit: EditIcon,
  download: DocumentDownloadIcon,
  account: AccountIcon,
  mobile: MobileIcon,
  trash: TrashIcon,
  filters: FiltersIcon,
  warning: WarningIcon,
};

export default icons;
