import { useCallback } from "react";
const useYupValidationResolver = (validationSchema) => {
  return useCallback(
    async (data) => {
      if (!validationSchema) {
        return { values: data, errors: {} };
      }
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors?.inner
            ? errors.inner.reduce(
                (allErrors, currentError) => ({
                  ...allErrors,
                  [currentError.path]: {
                    type: currentError.type ?? "validation",
                    message: currentError.message,
                  },
                }),
                {}
              )
            : {},
        };
      }
    },
    [validationSchema]
  );
};
export default useYupValidationResolver;
