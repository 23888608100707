import CharacteristicsOfThePropertyStep from "./CharacteristicsOfThePropertyStep";
import PartnerAgencyStep from "./PartnerAgencyStep";
import MandateAndFinancialInformationsStep from "./MandateAndFinancialInformationsStep";
import ClientsAndNotariesStep from "./ClientsAndNotariesStep";
import DocumentsStep from "./DocumentsStep";
import AgentInChargeStep from "./AgentInChargeStep";

const steps = [
  CharacteristicsOfThePropertyStep,
  PartnerAgencyStep,
  MandateAndFinancialInformationsStep,
  ClientsAndNotariesStep,
  DocumentsStep,
  AgentInChargeStep,
];

export default steps;
