import Input from "./Input";
import InputText from "./InputText";
import SearchInputText from "./SearchInputText";
import InputTextArea from "./InputTextArea";
import InputNumber from "./InputNumber";
import InputPassword from "./InputPassword";
import InputCheckbox from "./InputCheckbox";
import InputDate from "./InputDate";
import InputTime from "./InputTime";

export default Input;

export {
  InputText,
  InputTextArea,
  InputNumber,
  InputPassword,
  InputCheckbox,
  InputDate,
  InputTime,
  SearchInputText,
};
