import { SALE_STEPS } from "components/Sale/SaleMultistep/saleConstants";

const saleStepIsFullyCompleted = (saleStep) => {
  return Object.keys(saleStep).every(
    (saleSubStepKey) => saleStep[saleSubStepKey].isCompleted
  );
};

const saleStepsConfiguration = {
  DOCUMENTS: {
    title: "Constitution du dossier de vente",
    completeStatusLabel: "Dossier complet !",
    isAccessible: true,
  },
  COMPROMISE: {
    title: "Signature de l'avant-contrat",
    completeStatusLabel: "Signé !",
    isAccessible: true,
  },
  FORMALITIES: {
    title: () => (
      <>
        Formalités <br /> & conditions
      </>
    ),
    completeStatusLabel: "Dossier complet !",
    isAccessible: ({ saleSteps }) => {
      return saleStepIsFullyCompleted(saleSteps[SALE_STEPS.COMPROMISE]);
    },
  },
  ACTED: {
    title: "Signature de l'acte de vente",
    completeStatusLabel: "Signé !",
    isAccessible: ({ saleSteps }) => {
      return saleStepIsFullyCompleted(saleSteps[SALE_STEPS.COMPROMISE]);
    },
  },
};

export { saleStepsConfiguration };
