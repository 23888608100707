import { differenceInDays } from "date-fns";
import {
  AGENCY_PREFIX,
  PEOPLE_PREFIX,
  PEOPLE_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";
const { INDIVIDUAL } = PEOPLE_TYPES;

const USER_STATUS_TYPES = {
  INACTIVE: {
    label: "inactif",
    value: 0,
  },
  ACTIVE: {
    label: "actif",
    value: 1,
  },
};

const USER_API_ERRORS = {
  TOTAL_LIMIT_SEATS_EXCEEDED: "Total seats limit exceeded.",
};

const DEFAULT_USER_STATUS_VALUE = 1;

const isMasterUser = (roles) => roles?.includes("ROLE_MASTER_USER");

const isUserStatusActive = (people) => people.status === 1;

const isCurrentUser = (loggedUser, people) =>
  loggedUser.id === people?.user?.id;

const hasActiveStatusFilterEnabled = (filters) =>
  filters.some(
    (filter) =>
      filter.name === "status" &&
      filter.value === USER_STATUS_TYPES.ACTIVE.value
  );

const makeUserPutBody = (values, peopleId, agencyId) => ({
  email: values.email,
  people: {
    "@id": `${PEOPLE_PREFIX}${peopleId}`,
    civility: values.civility,
    firstname: values.firstname,
    lastname: values.lastname,
    mobile: values.mobile,
    type: INDIVIDUAL.value,
    agency: `${AGENCY_PREFIX}${agencyId}`,
    status: values.status,
  },
});

const makeUserPostBody = (values, agencyId) => ({
  email: values.email,
  people: {
    civility: values.civility,
    firstname: values.firstname,
    lastname: values.lastname,
    mobile: values.mobile,
    type: INDIVIDUAL.value,
    agency: `${AGENCY_PREFIX}${agencyId}`,
    status: values.status,
  },
});

const getTrialPeriodRemainingDays = (user) => {
  const trialEndsAt = new Date(user.people.agency.subscription.trialEndsAt);

  if (!trialEndsAt) {
    return 0;
  }

  return differenceInDays(trialEndsAt, new Date());
};

export {
  USER_STATUS_TYPES,
  DEFAULT_USER_STATUS_VALUE,
  USER_API_ERRORS,
  isMasterUser,
  isUserStatusActive,
  isCurrentUser,
  hasActiveStatusFilterEnabled,
  makeUserPostBody,
  makeUserPutBody,
  getTrialPeriodRemainingDays,
};
