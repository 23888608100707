import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import AutoComplete from "react-google-autocomplete";

const InputGoogleMapAutoComplete = ({
  name,
  placeholder,
  onlyCity,
  onPlaceSelected,
}) => {
  const {
    setValue,
    values,
    formState: { errors },
    trigger: triggerValidation,
  } = useFormContext();

  const currentValue = values[name];
  const hasError = errors[name];

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const handlePlaceSelection = ({ address_components, formatted_address }) => {
    if (!address_components) {
      return;
    }

    const { long_name: zip = "" } =
      address_components.find((element) =>
        element.types.includes("postal_code")
      ) || {};
    const { long_name: city = "" } =
      address_components.find((element) =>
        element.types.includes("locality")
      ) || {};
    const { long_name: country = "" } =
      address_components.find((element) => element.types.includes("country")) ||
      {};

    if (onlyCity) {
      setValue(name, city);
    } else {
      setValue(name, formatted_address);
    }

    if (typeof onPlaceSelected === "function") {
      onPlaceSelected({ zip, city, country });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setValue(name, value);
    triggerValidation([name]);
  };

  const handleBlur = () => {
    triggerValidation([name]);
  };

  if (!GOOGLE_API_KEY) {
    console.warn("You must specify your google api key in the .env");
    return null;
  }

  return (
    <>
      <AutoComplete
        name={name}
        className={classNames("input", {
          "input--error": hasError,
        })}
        placeholder={placeholder}
        defaultValue={currentValue}
        apiKey={GOOGLE_API_KEY}
        onPlaceSelected={handlePlaceSelection}
        onChange={handleChange}
        onBlur={handleBlur}
        options={{
          types: [onlyCity ? "(cities)" : "address"],
          componentRestrictions: { country: "fr" },
          debounce: 500,
        }}
      />
    </>
  );
};

InputGoogleMapAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onlyCity: PropTypes.bool,
  onPlaceSelected: PropTypes.func,
};

export default InputGoogleMapAutoComplete;
