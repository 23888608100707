import * as yup from "yup";

const signDateValidationSchema = yup.object({
  signDate: yup
    .string()
    .required("La date de signature de l'avant contrat est obligatoire."),
  signTime: yup
    .string()
    .required("L'heure de signature de l'avant contrat est obligatoire."),
});

const keyDatesValidationSchema = ({ isBankLoan }) => {
  return yup.object({
    ...(isBankLoan && {
      applicationDeadline: yup
        .string()
        .required(
          "Veuillez renseigner la date limite du dépôt de dossier de prêt bancaire"
        ),
      loanOfferDeadline: yup
        .string()
        .required(
          "Veuillez renseigner la date limite de réception de l'offre de prêt."
        ),
    }),
    deedSigningDeadline: yup
      .string()
      .required("Veuillez renseigner la date limite de signature de l'acte."),
    suspensiveConditions: yup.string().when("hasSuspensiveConditions", {
      is: (hasSuspensiveConditions) => Boolean(Number(hasSuspensiveConditions)),
      then: yup
        .string()
        .required("Veuillez renseigner les conditions suspensives."),
    }),
  });
};

export { signDateValidationSchema, keyDatesValidationSchema };
