import React from "react";
import PropTypes from "prop-types";
import SaleProcessCard from "components/SaleProcess/SaleProcessCard";
import Button from "components/Button";
import Text from "components/Text";
import { formatDate } from "utils/date";

const SignConfirmText = ({
  planPreContractSignatureDate,
  preContractSignatureDate,
}) => {
  if (preContractSignatureDate) {
    return (
      <Text align="center" font="roboto-slab" fontWeight="light">
        {"Avant-contrat signé"} <br />{" "}
        {`le ${formatDate(preContractSignatureDate)} !`}
      </Text>
    );
  }

  if (planPreContractSignatureDate) {
    return (
      <Text align="center" fontWeight="light">
        L'avant-contrat a t-il bien été signé ?
      </Text>
    );
  }

  return (
    <Text fontStyle="italic" align="center" fontWeight="light">
      La date de signature n'a pas encore <br />
      été renseignée.
    </Text>
  );
};

const SignConfirm = ({
  isLate,
  isCompleted,
  planPreContractSignatureDate,
  preContractSignatureDate,
  setHelpDialogOpen,
  handleSignConfirm,
}) => {
  const isAlert = isLate && !isCompleted;
  const buttonAppearance = isLate ? "reversed-tertiary" : "reversed-primary";

  return (
    <SaleProcessCard
      isAlert={isAlert}
      title="Confirmation signature"
      handleHelpClick={() => {
        setHelpDialogOpen(true);
      }}
      actions={
        planPreContractSignatureDate && !preContractSignatureDate ? (
          <Button
            appearance={buttonAppearance}
            boxShadow={false}
            onClick={handleSignConfirm}
          >
            Je confirme !
          </Button>
        ) : null
      }
    >
      <SignConfirmText
        planPreContractSignatureDate={planPreContractSignatureDate}
        preContractSignatureDate={preContractSignatureDate}
      />
    </SaleProcessCard>
  );
};

SignConfirm.propTypes = {
  isLate: PropTypes.bool,
  isCompleted: PropTypes.bool,
  planPreContractSignatureDate: PropTypes.func,
  preContractSignatureDate: PropTypes.func,
  setHelpDialogOpen: PropTypes.func.isRequired,
  handleSignConfirm: PropTypes.func.isRequired,
};
export default SignConfirm;
