import React from "react";

const BusinessOfferDialogContext = React.createContext({});

const BusinessOfferDialogProvider = ({ children }) => {
  const [businessOfferDialogOpen, setBusinessOfferDialogOpen] =
    React.useState(false);

  return (
    <BusinessOfferDialogContext.Provider
      value={{
        businessOfferDialogOpen,
        setBusinessOfferDialogOpen,
      }}
    >
      {children}
    </BusinessOfferDialogContext.Provider>
  );
};
export { BusinessOfferDialogProvider, BusinessOfferDialogContext };

export const useBusinessOfferDialog = () =>
  React.useContext(BusinessOfferDialogContext);
