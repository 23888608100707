import React from "react";
import { DebounceInput } from "react-debounce-input";
import Icon from "components/Icon";
import Form from "components/Form";
import Button from "components/Button";
import SearchInputText from "components/Form/Input/SearchInputText";

const TabContent = ({
  setSearch,
  isClientTab,
  setSelectedFormData,
  setIsDialogOpen,
}) => {
  return (
    <div className="tab-content">
      <div className="tab-content__search">
        <p className="tab-content__label">
          Saisissez le nom du {isClientTab ? "client" : "notaire"} ou son email
        </p>
        <Form onSubmit={() => {}}>
          <DebounceInput
            element={SearchInputText}
            minLength={2}
            debounceTimeout={300}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            type="search"
            name="search"
            placeholder="RECHERCHER"
          />
        </Form>
      </div>
      <div className="tab-content__create">
        <p className="tab-content__label">
          Ajouter un {isClientTab ? "client" : "notaire"} au répertoire
        </p>
        <Button
          onClick={() => {
            setSelectedFormData(isClientTab ? "addClient" : "addNotary");
            setIsDialogOpen(true);
          }}
          appearance="ghost"
        >
          Créer un nouveau {isClientTab ? "client" : "notaire"}{" "}
          <Icon icon="plus" color="primary" />
        </Button>
      </div>
    </div>
  );
};

export default TabContent;
