const NUMBER_OF_MILLISECONDS_IN_ONE_MINUTE = 1000 * 60;

export const OWNER_TYPES = [
  { id: "seller", label: "Vendeur", tag: "V" },
  { id: "sellerNotary", label: "Notaire vendeur", tag: "NV" },
  { id: "buyer", label: "Acquéreur", tag: "A" },
  { id: "buyerNotary", label: "Notaire acquéreur", tag: "NA" },
];

const initializeDateAtMidnight = (stringDate) => {
  const dateAtMidnight = new Date(stringDate);
  dateAtMidnight.setHours(0, 0, 0, 0);
  return dateAtMidnight;
};

const formatEventFromMinutesToRealDate = (event, baseDate) => {
  const start = new Date(
    baseDate.getTime() + event.start * NUMBER_OF_MILLISECONDS_IN_ONE_MINUTE
  );
  const end = new Date(
    baseDate.getTime() + event.end * NUMBER_OF_MILLISECONDS_IN_ONE_MINUTE
  );

  return {
    start,
    end,
    owners: event.owners,
  };
};

const fromTheEarliestToTheLatest = (intervalA, intervalB) => {
  return Math.max(
    Number(intervalA.replace("-", "")) - Number(intervalB.replace("-", ""))
  );
};

const makeEventMatrice = (event) => {
  const [startDate, endDate] = [new Date(event.start), new Date(event.end)];

  const start = startDate.getHours() * 60 + startDate.getMinutes();

  const numberOfMinutesBetweenTheTwoDates = Math.floor(
    Math.abs(endDate - startDate) / NUMBER_OF_MILLISECONDS_IN_ONE_MINUTE
  );

  const numberOfHalfHours = numberOfMinutesBetweenTheTwoDates / 30;

  const eventMatrice = Object.fromEntries(
    Array.from({ length: numberOfHalfHours }, (_, i) => [
      `${start + 30 * i}-${start + 30 * (i + 1)}`,
      [...event.owners],
    ])
  );

  return eventMatrice;
};

const getTimeFromStringDate = (stringDate) => {
  return new Date(stringDate).getTime();
};

const getStartAndEndFromTwoEvents = (eventA, eventB) => {
  return [
    getTimeFromStringDate(eventA.start),
    getTimeFromStringDate(eventA.end),
    getTimeFromStringDate(eventB.start),
    getTimeFromStringDate(eventB.end),
  ];
};

const checkIfTwoEventsOverlap = (eventA, eventB) => {
  const [startA, endA, startB, endB] = getStartAndEndFromTwoEvents(
    eventA,
    eventB
  );
  return startA <= endB && endA >= startB;
};

const getOverlappingAndNonOverlappingEvents = (events, newEvent) => {
  return events.reduce(
    (acc, event) => {
      const previousEventAndNewEventOverlap = checkIfTwoEventsOverlap(
        event,
        newEvent
      );
      if (previousEventAndNewEventOverlap) {
        acc.overlappingEvents.push(event);
        return acc;
      }
      acc.nonOverlappingEvents.push(event);
      return acc;
    },
    { overlappingEvents: [], nonOverlappingEvents: [] }
  );
};

export const getInitialDefaultValues = () => {
  return OWNER_TYPES.reduce(
    (acc, owner) => ({ ...acc, [owner.id]: false }),
    {}
  );
};

export const convertJsonPlanningEvents = (planningEvents) => {
  return planningEvents.map((planningEvent) => ({
    ...planningEvent,
    start: new Date(planningEvent.start),
    end: new Date(planningEvent.end),
  }));
};

export const computeAvailabilities = (events) => {
  return events
    .filter((event) => event.owners.length === OWNER_TYPES.length)
    .map((event) => event.start);
};

export const makeOwnerTags = () => {
  return OWNER_TYPES.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur.tag,
    }),
    {}
  );
};

export const addIdentifiersToEvents = (events) => {
  return events.map((event, index) => ({ ...event, id: index }));
};

export const mergeNewEventIfOverlappingPreviousEvents = (events, newEvent) => {
  const baseDate = initializeDateAtMidnight(newEvent.start);

  /** Separate overlapping events from non-overlapping events because we are not doing any treatment on non-overlapping events */
  const { overlappingEvents, nonOverlappingEvents } =
    getOverlappingAndNonOverlappingEvents(events, newEvent);

  /** If there is no overlapping between the current events and the new event we just merge them and return */
  if (overlappingEvents.length === 0) {
    return [...nonOverlappingEvents, newEvent];
  }

  /**
   * A representation of an event starting at 14:00 and ending at 17:00 would look like this
   * I used an object for simplicity, the index is the half-hour interval and the value is an array of "owners"
   * newEventMatrice: {
        840-870:  ["B"]
        870-900:  ["B"]
        900-930:  ["B"]
        930-960:  ["B"]
        960-990:  ["B"]
        990-1020: ["B"]
    }
   */
  const newEventMatrice = makeEventMatrice(newEvent);

  /** Same as above but an array of event representation */
  const overlappingEventsMatrices = overlappingEvents.map((event) =>
    makeEventMatrice(event)
  );

  /** 
   * We are creating a common representation of all the overlapping events and the new events
   * This object will look like this : 
   * 
   * mergedEvents: {
        660-690: ["A"]            | Event 1
        690-720: ["A"]            |
        720-750: ["A"]            | 
        750-780: ["A"]            |
        780-810: ["A"]            |
        810-840: ["A"]            |
        840-870: ["A", "B"]           | Event 2
        870-900: ["A", "B"]           |
        900-930: ["B"]                    | Event 3
        930-960: ["B"]                    |
        960-990: ["B"]                    |
        990-1020: ["B"]                   |
    }
   *
   * this representation allows us to have a view of the partitioning that we will have to do in order to create the new events
  */
  const mergedEvents = overlappingEventsMatrices.reduce(
    (acc, currentEventMatrice) => {
      Object.keys(currentEventMatrice).forEach((intervalKey) => {
        if (!acc[intervalKey]) {
          acc[intervalKey] = [...currentEventMatrice[intervalKey]];
        } else {
          acc[intervalKey] = Array.from(
            new Set([...acc[intervalKey], ...currentEventMatrice[intervalKey]])
          ).sort();
        }
      });
      return acc;
    },
    { ...newEventMatrice }
  );

  /**
   * When using Object.keys() you can't guarantee the order of the attributes,
   * we ensure that they are ordered from the earliest interval to the latest
   **/
  const sortedEventKeys = Object.keys(mergedEvents).sort(
    fromTheEarliestToTheLatest
  );

  /**
   * We will then split the merged events into X events like described above
   * It could be done in a more elegant way but it is quite tricky.
   */
  const firstInterval = sortedEventKeys[0];
  let previousOwners = mergedEvents[firstInterval];
  let currentArrayIndex = 0;

  /** The output is too big to add it here but it is used to simplify event processing in the last step */
  const splittedEvents = sortedEventKeys.reduce((acc, cur) => {
    const currentOwners = mergedEvents[cur];
    if (JSON.stringify(previousOwners) !== JSON.stringify(currentOwners)) {
      currentArrayIndex += 1;
    }

    if (!acc[currentArrayIndex]) {
      acc[currentArrayIndex] = [];
    }

    const [start, end] = cur.split("-");
    acc[currentArrayIndex].push({ start, end, owners: mergedEvents[cur] });
    previousOwners = currentOwners;

    return acc;
  }, []);

  /**
   * Each element of the splittedEvents is an event, each event contains intervals
   * the start of the event is the first interval and the end the last interval
   * This might seems over complicated but splitting and then joining like that is
   * so far the easiest solution...
   */
  const finalEvents = splittedEvents.map((intervals) => {
    const finalEvent = {
      start: intervals[0].start,
      end: intervals[intervals.length - 1].end,
      owners: intervals[0].owners,
    };
    return formatEventFromMinutesToRealDate(finalEvent, baseDate);
  });

  return [...nonOverlappingEvents, ...finalEvents];
};

export const getCurrentlyEditedEventOwners = (owners) => {
  return OWNER_TYPES.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: owners.includes(cur.id),
    }),
    {}
  );
};

export const getEventsFormattedForCalendar = (events) => {
  return events.reduce((acc, { start, owners }) => {
    const stringDate = start.toLocaleDateString();
    const allOwnersAreAvailableDuringThisSlot =
      owners.length === OWNER_TYPES.length;
    if (acc[stringDate]) {
      if (acc[stringDate].common) {
        return acc;
      }
    }
    acc[stringDate] = {
      common: allOwnersAreAvailableDuringThisSlot,
    };
    return acc;
  }, {});
};
