import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import Icon from "components/Icon";
import SaleCardStepper from "./SaleCardStepper";
import { SALE_STATUS } from "../SaleMultistep/saleConstants";

const ICON_NAMES = {
  APARTMENT: "building",
  HOUSE: "house",
  BUILDING: "building",
  GARAGE_PARKING: "print",
  OFFICE: "office",
  BUSINESS: "office",
  CELLAR: "cave",
  LAND: "land",
  OTHER: "wave",
};

const getIsActed = (sale) => {
  switch (sale.status) {
    case SALE_STATUS.ACTED:
    case SALE_STATUS.ARCHIVED:
      return true;
    default:
      return false;
  }
};

const SaleCardStep = ({ sale, seller, buyer, district }) => {
  const numberOfAlerts = sale.saleSubSteps.filter(
    (substep) => substep.isLate && !substep.isCompleted
  ).length;
  const hasAlerts = numberOfAlerts > 0;
  const isActed = getIsActed(sale);
  const iconClasses = classNames("card-step__icon", {
    "card-step__icon--alert": hasAlerts,
  });

  return (
    <div className="card-step">
      {isActed ? (
        <div className="card-step__done-icon">
          <Icon size="big" icon="well-done" />
        </div>
      ) : (
        <div className="card-step__icon-container">
          <div className={iconClasses}>
            <Icon size="big" icon={ICON_NAMES[sale.propertyType]} />
            {hasAlerts && <div className="alert-chip">{numberOfAlerts}</div>}
          </div>
        </div>
      )}
      <div className="card-step__buyer-seller">
        {seller}
        <br />
        {buyer}
      </div>
      <div className="card-step__district">{district}</div>
      {!isActed && <SaleCardStepper currentStep={sale.currentSaleStepStatus} />}
    </div>
  );
};

SaleCardStep.propTypes = {
  buyer: PropTypes.string.isRequired,
  seller: PropTypes.string.isRequired,
  district: PropTypes.string.isRequired,
  sale: PropTypes.shape({
    propertyType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    currentSaleStepStatus: PropTypes.object.isRequired,
  }).isRequired,
};

export default SaleCardStep;
