import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "components/Icon";
import IconButton from "components/Button/IconButton";
import Modal from "react-modal";

Modal.setAppElement("#root");

const DialogWrapper = ({
  dialogData,
  children,
  childrenBeforeRipple = false,
}) => {
  return (
    <div className="dialog-wrapper">
      <div className="dialog-wrapper__head">
        <Icon icon={dialogData.icon} color="primary" size="big" />
        <div className="dialog-wrapper__head__title">{dialogData.title}</div>
        {childrenBeforeRipple && children}
        <Icon icon="ripple" color="primary" size="big" />
      </div>
      {!childrenBeforeRipple && children}
    </div>
  );
};

const DialogTitle = ({ children }) => {
  return (
    <div className="dialog__title">
      {children}
      <Icon icon="ripple" />
    </div>
  );
};

const DialogContent = ({ children }) => {
  return <div className="dialog__content">{children}</div>;
};

const DialogActions = ({ children }) => {
  return <div className="dialog__actions">{children}</div>;
};

const Dialog = ({ open, handleClose, children, className }) => {
  return (
    <Modal
      preventScroll
      isOpen={open}
      onRequestClose={(e) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <div className={classNames("dialog", { [className]: className })}>
        <div className="dialog__overlay" onClick={handleClose} />
        <div className="dialog__container">
          <div className="dialog__close">
            <IconButton
              noPadding
              onClick={handleClose}
              icon="close"
              iconSize="small"
            />
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export { Dialog, DialogTitle, DialogContent, DialogActions, DialogWrapper };
