import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tablet, Mobile } from "components/MediaQuery";

const AnimatedTabs = ({ tabs, currentTab }) => {
  const [tabLeftPosition, setTabLeftPosition] = React.useState(0);
  const [tabWidth, setTabWidth] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [showCursor, setShowCursor] = React.useState(false);
  const tabsRefs = React.useRef([Array(tabs.length).fill(React.createRef())]);

  React.useEffect(() => {
    const firstElement = tabsRefs.current[0];
    if (firstElement) {
      setTabLeftPosition(firstElement.offsetLeft);
      setTabWidth(firstElement.clientWidth);
      setShowCursor(true);
    }
  }, [tabsRefs]);

  React.useEffect(() => {
    if (currentTab === 0) {
      return;
    }

    if (currentTab && tabsRefs?.current[currentTab]) {
      const tabRef = tabsRefs.current[currentTab];

      setActiveIndex(currentTab);
      setTabWidth(tabRef.clientWidth);
      setTabLeftPosition(tabRef.offsetLeft);
    }
  }, [currentTab]);

  const handleMouseEnter = (e) => {
    setTabWidth(e.currentTarget.clientWidth);
    setTabLeftPosition(e.currentTarget.offsetLeft);
  };

  const handleMouseOut = () => {
    const activeElement = tabsRefs.current[activeIndex];
    if (activeElement) {
      setTabWidth(activeElement.clientWidth);
      setTabLeftPosition(activeElement.offsetLeft);
    }
  };

  return (
    <>
      <Tablet>
        <div className="tabs">
          <ul>
            {tabs.map(({ onClick, label }, index) => (
              <li
                key={`${label}_${index}`}
                ref={(el) => (tabsRefs.current[index] = el)}
                className={classNames("tab", {
                  "tab--active": activeIndex === index,
                })}
                onClick={() => {
                  setActiveIndex(index);
                  onClick();
                }}
                onMouseEnter={handleMouseEnter}
                onMouseOut={handleMouseOut}
              >
                {label}
              </li>
            ))}
          </ul>
          <div
            className="tabs__cursor"
            style={{
              left: tabLeftPosition,
              width: tabWidth,
              display: showCursor ? "block" : "none",
            }}
          />
        </div>
      </Tablet>
      <Mobile>
        <div className="select-wrapper">
          <select
            name="tab_select"
            className="select"
            onChange={(e) => {
              const tabIndex = e.target.value;
              tabs[tabIndex].onClick();
              setActiveIndex(tabIndex);
            }}
            value={currentTab}
          >
            {tabs.map(({ label }, index) => {
              return (
                <option value={index} key={`${label}_${index}`}>
                  {label}
                </option>
              );
            })}
          </select>
        </div>
      </Mobile>
    </>
  );
};

AnimatedTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default AnimatedTabs;
