import React from "react";
import Form from "components/Form";
import SubmitButton from "components/Form/SubmitButton";
import { InputText } from "components/Form/Input";
import { notifyError, notifySuccess } from "utils/notify";
import Link from "components/Link";
import fetchUrl from "api/fetchUrl";
import { postForgotPasswordRequest } from "api/requests";
import Spinner from "components/Spinner";
import validationSchema from "./validationSchema";
import { useLocation } from "react-router-dom";

const ForgotPasswordForm = () => {
  const [fetching, setFetching] = React.useState(false);
  const location = useLocation();

  const resetTokenExpired = location?.state?.resetTokenExpired;

  const handleSubmit = ({ email }) => {
    setFetching(true);
    fetchUrl(postForgotPasswordRequest(email))
      .then(() => {
        setFetching(false);
        notifySuccess(
          "Un nouveau mot de passe vous a été envoyé. Veuillez regarder votre boite de messagerie."
        );
      })
      .catch(() => {
        setFetching(false);
        notifyError("Une erreur est survenue.");
      });
  };

  return (
    <>
      {fetching && <Spinner />}
      <Form
        className="forgot-password-form"
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <b>
          {`${
            resetTokenExpired
              ? "Votre lien de réinitialisation de mot de passe a expiré. \n"
              : ""
          } Veuillez saisir votre email pour recevoir un nouveau mot de passe :`}
        </b>
        <br />
        <InputText rounded name="email" placeholder="Email" />
        <SubmitButton appearance="primary" uppercase={false}>
          Regénérer un mot de passe
        </SubmitButton>
        <Link to="/login">Retourner à l'interface de connexion</Link>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
