import {
  SALE_STATUS,
  SALE_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";

export const extractDataFromLdJsonResponse = (data) => {
  const regexGetPageNumber = /page=([^&]*(?:&[^&=]+(?=&|$))*)/;

  const extractPageNumber = (path) => path.match(regexGetPageNumber)[1];

  return {
    data: data["hydra:member"],
    pagination: {
      totalItems: data["hydra:totalItems"],
      first: data["hydra:view"]["hydra:first"]
        ? extractPageNumber(data["hydra:view"]["hydra:first"])
        : null,
      last: data["hydra:view"]["hydra:last"]
        ? extractPageNumber(data["hydra:view"]["hydra:last"])
        : null,
      next: data["hydra:view"]["hydra:next"]
        ? extractPageNumber(data["hydra:view"]["hydra:next"])
        : null,
    },
  };
};

export const isLastPage = (pagination) => pagination.next === null;

export const getFilterStates = (filters) => ({
  hasStatusFilter: filters.some((filter) => filter.name === "status[]"),
  hasDraftStatus: filters.some(
    (filter) => filter.name === "status[]" && filter.value === SALE_STATUS.DRAFT
  ),
  hasSaveStatus: filters.some(
    (filter) => filter.name === "status[]" && filter.value === SALE_STATUS.SAVE
  ),
  hasInterAgencyStatus: filters.some(
    (filter) =>
      filter.name === "status[]" && filter.value === SALE_TYPES.INTER_AGENCY
  ),
  hasActedStatus: filters.some(
    (filter) => filter.name === "status[]" && filter.value === SALE_STATUS.ACTED
  ),
  hasCanceledStatus: filters.some(
    (filter) =>
      filter.name === "status[]" && filter.value === SALE_STATUS.CANCELED
  ),
  hasSaleStepFilter: filters.some((filter) => filter.name === "saleStep"),
});

export const makeFilters = (filters, newFilter) => {
  if (!newFilter.value) {
    return filters.filter((filter) => filter.name !== newFilter.name);
  }

  if (filters.length === 0) {
    return [newFilter];
  }

  const isAlreadyAdded = filters.some(
    (filter) => filter.name === newFilter.name
  );

  if (isAlreadyAdded) {
    return [
      ...filters.filter((filter) => filter.name !== newFilter.name),
      newFilter,
    ];
  }

  return [...filters, newFilter];
};

export const makeParamsFilters = (filters) => {
  let paramFilters = filters.filter((filter) => filter.name !== "status[]");

  if (
    filters.some(
      (filter) =>
        filter.name === "status[]" && filter.value === SALE_STATUS.ACTED
    )
  ) {
    paramFilters = filters.filter((filter) => filter.name !== "saleStep");
  }

  return [...paramFilters, ...makeStatusParams(filters)];
};

export const makeStatusParams = (filters) => {
  const {
    hasDraftStatus,
    hasSaleStepFilter,
    hasActedStatus,
    hasCanceledStatus,
    hasInterAgencyStatus,
    hasStatusFilter,
  } = getFilterStates(filters);

  const actedStatus = [
    { name: "status[]", value: SALE_STATUS.ACTED },
    { name: "status[]", value: SALE_STATUS.ARCHIVED },
  ];

  const draftStatus = [
    { name: "status[]", value: SALE_STATUS.DRAFT },
    { name: "status[]", value: SALE_STATUS.SAVE },
  ];

  const defaultStatus = [
    ...draftStatus,
    { name: "status[]", value: SALE_STATUS.ACTED },
  ];

  if (hasDraftStatus && !hasSaleStepFilter) {
    return draftStatus;
  }

  if (hasActedStatus) {
    return actedStatus;
  }

  if (hasInterAgencyStatus) {
    if (hasSaleStepFilter) {
      return [
        { name: "status[]", value: SALE_STATUS.SAVE },
        { name: "saleType", value: SALE_TYPES.INTER_AGENCY },
      ];
    }

    return [
      ...defaultStatus,
      { name: "saleType", value: SALE_TYPES.INTER_AGENCY },
    ];
  }

  if (hasSaleStepFilter && !hasCanceledStatus) {
    return [{ name: "status[]", value: SALE_STATUS.SAVE }];
  }

  if (!hasStatusFilter) {
    return defaultStatus;
  }

  return [filters.find((filter) => filter.name === "status[]")] || [];
};

export const buildQueryParams = (params = []) => {
  return params.reduce((acc, param) => {
    const isFirstParam = !acc.includes("=");

    if (param.value !== 0 && !param.value) {
      return acc;
    }

    return acc.concat(
      `${isFirstParam ? "?" : "&"}${param.name}=${param.value}`
    );
  }, "");
};

export const makeHeaderTitle = (filters, salesNumber) => {
  const {
    hasDraftStatus,
    hasSaveStatus,
    hasSaleStepFilter,
    hasActedStatus,
    hasInterAgencyStatus,
    hasCanceledStatus,
  } = getFilterStates(filters);

  const usePlural = salesNumber > 1;
  const status = {
    draft: "en cours",
    acted: usePlural ? "actées" : "actée",
    canceled: usePlural ? "annulées" : "annulée",
    interAgency: usePlural ? "inter-agences" : "inter-agence",
  };

  if (hasInterAgencyStatus && !hasSaleStepFilter) {
    return status.interAgency;
  }

  if (
    (hasDraftStatus || hasSaveStatus || hasSaleStepFilter) &&
    !hasCanceledStatus
  ) {
    return status.draft;
  }

  if (hasActedStatus) {
    return status.acted;
  }

  if (hasCanceledStatus) {
    return status.canceled;
  }

  return status.draft;
};
