import React from "react";
import Icon from "../Icon";

const Logo = () => {
  return (
    <div className="logo">
      <Icon icon="logo" />
    </div>
  );
};

export default Logo;
