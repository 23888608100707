import React from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import Input from "../Input";

const InputTime = (props) => {
  const ref = React.createRef();
  const {
    values: { [props.name]: value },
    setValue,
    formState: { errors },
  } = useFormContext();
  const isPlaceholder = React.useRef(!value);

  React.useEffect(() => {
    /**
     * Run this only when we pass from placeholder to time input
     */
    if (value && isPlaceholder.current && ref.current) {
      isPlaceholder.current = false;

      /**
       * Be sure to dispatch all nessecary events for all platforms
       * order is important
       */
      ref.current.focus();
      ref.current.select();
    }
  }, [value, ref]);

  const inputError = { ...errors }[props.name];

  const className = classNames("input", {
    "input--error": inputError,
    "input--rounded": props.rounded,
    "input--text-right": props.textAlign === "right",
    [`input--${props.appearance}`]: props.appearance,
  });

  return (
    <>
      {/* Fake a placeholder */}
      {!value && (
        <Input
          onClick={() => {
            setValue("signTime", "00:00");
          }}
          readOnly="readonly"
          placeholder={props.placeholder}
          {...props}
        />
      )}

      {/* Use direct HTML input because I didn't manage to forward the ref */}
      <input
        onChange={(el) => {
          setValue("signTime", el.target.value);
        }}
        ref={ref}
        type="time"
        value={value}
        className={className}
        {...props}
        style={!value ? { display: "none" } : {}}
      />
    </>
  );
};

export default InputTime;
