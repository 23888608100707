import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogTitle } from "components/Dialog";
import Icon from "components/Icon";
import Container from "components/Container";
import Button from "components/Button";
import Text from "components/Text";

const HelperDialog = ({ title, open, handleClose, children, ...rest }) => {
  return (
    <Dialog open={open} handleClose={handleClose} className="helper-dialog">
      <DialogTitle>
        <Icon icon="elio-logo-bubble" size="bigger" />
        <Text
          uppercase={true}
          font="roboto-slab"
          fontWeight="bold"
          size="regular"
        >
          {title}
        </Text>
      </DialogTitle>
      <div className="helper-dialog__content">
        <Container>
          <Text size={"small"}>{children}</Text>
        </Container>
      </div>
      <DialogActions>
        <Button appearance="primary" onClick={handleClose} size="mini">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

HelperDialog.propTypes = {
  ...Dialog.propTypes,
  title: PropTypes.string.isRequired,
};

export default HelperDialog;
