import React from "react";
import SaleProcessCard from "components/SaleProcess/SaleProcessCard";
import SignDateHandler from "components/SaleProcess/SignDateHandler";
import HelperDialog from "components/SaleProcess/HelperDialog";
import Text from "components/Text";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import {
  SALE_KEY_DATES_PREFIX,
  SALE_STATUS,
  SALE_STEPS,
  SALE_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";
import { notifyError, notifySuccess } from "utils/notify";
import SignDateConfirmationEmailDialog from "components/SaleProcess/SignDateConfirmationEmailDialog";
import Button from "components/Button";
import { Dialog } from "components/Dialog";
import Icon from "components/Icon";
import { formatDate, getDateFromDateAndHoursAndMinutes } from "utils/date";
import countDaysSince from "utils/countDaysSince";

const PlanDeedSignatureCard = ({
  isLate,
  isCompleted,
  handleSubmitSignDate,
  signatureDate,
  signatureDateConfirmation,
  isInterAgency,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = !isCompleted && isLate;

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Date de signature de l'acte"
        handleHelpClick={() => setHelperDialogOpen(true)}
      >
        <SignDateHandler
          handleSubmitSignDate={handleSubmitSignDate}
          signatureDate={signatureDate}
          signatureDateConfirmation={signatureDateConfirmation}
          isInterAgency={isInterAgency}
          isAlert={isAlert}
        />
      </SaleProcessCard>
      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Date de signature de l'acte"
      >
        <Text>
          Cette étape est destinée à la planification de la date de signature de
          l’acte.
        </Text>
        <Text>
          Demandez, via le <b>module mail</b>, les disponibilités des
          intervenants de la vente et enregistrez-les dans le&nbsp;
          <b>module planning</b> pour afficher les&nbsp;
          <b>créneaux communs à tous !</b>
        </Text>
        <Text>
          Une fois la date de signature confirmée, <b>enregistrez-là</b> et
          envoyez une <b>confirmation mail</b> à tous les intervenants.
        </Text>
      </HelperDialog>
    </>
  );
};

const ConfirmDeedSignatureCard = ({
  isLate,
  isCompleted,
  signatureDate,
  signatureDateConfirmation,
  handleSignConfirm,
}) => {
  const [helperDialogOpen, setHelperDialogOpen] = React.useState(false);

  const isAlert = isLate && !isCompleted;
  const buttonAppearance = isLate ? "reversed-tertiary" : "reversed-primary";

  return (
    <>
      <SaleProcessCard
        isAlert={isAlert}
        title="Confirmation de signature"
        handleHelpClick={() => {
          setHelperDialogOpen(true);
        }}
        actions={
          signatureDate && !signatureDateConfirmation ? (
            <Button
              appearance={buttonAppearance}
              boxShadow={false}
              onClick={handleSignConfirm}
            >
              Je confirme !
            </Button>
          ) : null
        }
      >
        <SignDateConfirmCardText
          signatureDate={signatureDate}
          signatureDateConfirmation={signatureDateConfirmation}
        />
      </SaleProcessCard>

      <HelperDialog
        open={helperDialogOpen}
        handleClose={() => setHelperDialogOpen(false)}
        title="Confirmation signature"
      >
        <Text>
          Quand vous serez à cette étape vous pourrez vous <b>féliciter</b> pour
          le travail accompli !
        </Text>
        <Text>
          Pensez à envoyer un <b>mail de remerciement</b> à vos clients pour
          les&nbsp;
          <b>fidéliser.</b>
        </Text>
        <Text>
          Dans le <b>module mail Elio</b>, vous avez un mail rédigé et prêt à
          l’envoi !
        </Text>
      </HelperDialog>
    </>
  );
};

const SignDateConfirmCardText = ({
  signatureDate,
  signatureDateConfirmation,
}) => {
  if (signatureDateConfirmation) {
    return (
      <Text align="center" font="roboto-slab" fontWeight="light">
        {"Acte signé"} <br /> {`le ${formatDate(signatureDateConfirmation)} !`}
      </Text>
    );
  }

  if (signatureDate) {
    return <Text align="center">L'acte a t-il bien été signé ?</Text>;
  }

  return (
    <Text fontStyle="italic" align="center" fontWeight="light">
      La date de signature n'a pas encore <br />
      été renseignée.
    </Text>
  );
};

const SaleCompletedDialog = ({ open, setOpen, saleCreatedAt }) => {
  const elapsedDays = countDaysSince(saleCreatedAt);

  return (
    <Dialog
      open={open}
      handleClose={() => setOpen(false)}
      className="sale-process-finished-dialog"
    >
      <div className="congratulations">
        <Icon icon="flip-flops" size="bigger" color="black" />
        <Text color="white" size="medium" align="center" font="roboto-slab">
          Félicitations, votre vente a été réalisée en
        </Text>
        <Text color="white" size="extra-large" font="roboto-slab">
          {elapsedDays} jour{elapsedDays > 1 && "s"} !
        </Text>
      </div>
      <div className="tips">
        <Text align="center" size="small">
          Pensez à envoyer un mail de remerciement à vos clients pour les
          fidéliser !
        </Text>

        <div className="tips__icon-container">
          <Icon
            size="medium"
            icon="emails"
            color="dark-grey"
            appearance="grey"
          />
        </div>
      </div>
    </Dialog>
  );
};

const ActedStep = ({ sale, setSale }) => {
  const [
    signDateConfirmationEmailDialogOpen,
    setSignDateConfirmationEmailDialogOpen,
  ] = React.useState(false);
  const [saleCompletedDialogOpen, setSaleCompletedDialogOpen] =
    React.useState(false);
  const previousDeedSignedOn = React.useRef(
    sale.keyDates.deedSignedOn || undefined
  );

  const {
    PLAN_THE_DEED_SIGNATURE: planTheDeedSignature,
    CONFIRM_DEED_SIGNATURE: confirmDeedSignature,
  } = sale.saleSteps.ACTED;

  const { deedSignedOn } = sale.keyDates;
  const deedSignedOnConfirm = confirmDeedSignature.completedAt
    ? deedSignedOn
    : null;
  const isInterAgency = sale.saleType === SALE_TYPES.INTER_AGENCY;

  const handleSubmitSignDate = (values) => {
    const deedSignedOn = getDateFromDateAndHoursAndMinutes(
      values.signDate,
      values.signTime
    );

    previousDeedSignedOn.current = sale.keyDates?.deedSignedOn;

    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          keyDates: {
            "@id": `${SALE_KEY_DATES_PREFIX}${sale.keyDates.id}`,
            deedSignedOn,
          },
          saleSubSteps: [{ "@id": planTheDeedSignature.id, isCompleted: true }],
        },
      })
    )
      .then((sale) => {
        setSale(sale, false);
        setSignDateConfirmationEmailDialogOpen(true);
        notifySuccess(
          "La date de signature de l'acte a été enregistrée avec succès."
        );
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'enregistrement de la date de signature de l'acte."
        );
      });
  };

  const handleSignConfirm = () => {
    fetchUrl(
      putSale({
        saleId: sale.id,
        sale: {
          saleStep: SALE_STEPS.ACTED,
          saleSubSteps: [
            {
              "@id": confirmDeedSignature.id,
              isCompleted: true,
            },
            ...Object.keys(sale.saleSteps.FORMALITIES).reduce(
              (acc, formalityStepKey) => {
                const formalityStep =
                  sale.saleSteps.FORMALITIES[formalityStepKey];
                if (!formalityStep.isCompleted) {
                  return [
                    ...acc,
                    { "@id": formalityStep.id, isCompleted: true },
                  ];
                }

                return acc;
              },
              []
            ),
          ],
          status: SALE_STATUS.ACTED,
        },
      })
    )
      .then((sale) => {
        setSale(sale);
        setSaleCompletedDialogOpen(true);
        notifySuccess(
          "La confirmation de signature de l'acte a été enregistrée avec succès."
        );
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de la confirmation de signature de l'acte"
        );
      });
  };

  return (
    <>
      <div className="sale-process-acted-step">
        <PlanDeedSignatureCard
          isLate={planTheDeedSignature.isLate}
          isCompleted={planTheDeedSignature.isCompleted}
          handleSubmitSignDate={handleSubmitSignDate}
          signatureDate={deedSignedOn}
          signatureDateConfirmation={deedSignedOnConfirm}
          isInterAgency={isInterAgency}
        />
        <ConfirmDeedSignatureCard
          isLate={confirmDeedSignature.isLate}
          isCompleted={confirmDeedSignature.isCompleted}
          signatureDate={deedSignedOn}
          signatureDateConfirmation={deedSignedOnConfirm}
          handleSignConfirm={handleSignConfirm}
        />
      </div>
      <SignDateConfirmationEmailDialog
        open={signDateConfirmationEmailDialogOpen}
        setOpen={setSignDateConfirmationEmailDialogOpen}
        isInterAgency={isInterAgency}
        isEdit={
          previousDeedSignedOn.current &&
          previousDeedSignedOn.current !== deedSignedOn
        }
      />
      <SaleCompletedDialog
        open={saleCompletedDialogOpen}
        setOpen={setSaleCompletedDialogOpen}
        saleCreatedAt={sale.createdAt}
        saleSignedAt={sale.keyDates?.deedSignedOn}
      />
    </>
  );
};

export default ActedStep;
