import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { AuthProvider } from "./components/Hooks/useAuth";
import { BusinessOfferDialogProvider } from "./components/Hooks/useBusinessOfferDialog";
import "./main.scss";
import Toast from "./components/Toast";
import ProtectedRoute from "./components/Router/ProtectedRoute";
import UnAuthenticatedRoute from "./components/Router/UnAuthenticatedRoute";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Sale from "./pages/Sale";
import SaleProcess from "./pages/SaleProcess";
import { SaleModulesProvider } from "pages/SaleProcess/useSaleModules";
import Settings from "pages/Settings";
import ContactDirectory from "pages/ContactDirectory";
import UserSettings from "./pages/UserSettings";
import UserArea from "pages/UserArea";
import BusinessOfferDialog from "components/BusinessOfferDialog";

registerLocale("fr", fr);
setDefaultLocale("fr");

const App = () => {
  return (
    <>
      <AuthProvider>
        <BusinessOfferDialogProvider>
          <Router>
            <Switch>
              <UnAuthenticatedRoute path="/login" component={Login} />
              <UnAuthenticatedRoute
                path="/reset-password/:token"
                component={ResetPassword}
              />
              <UnAuthenticatedRoute
                path="/forgot-password"
                component={ForgotPassword}
              />

              <ProtectedRoute
                key="new-sale"
                path="/sales/new"
                component={Sale}
              />
              <ProtectedRoute
                exact
                path="/users"
                component={UserSettings}
                onlyMaster
              />
              <ProtectedRoute
                exact
                key="draft-sale"
                path="/sales/:saleId"
                component={Sale}
              />
              <ProtectedRoute
                exact
                path="/sales/edit/:saleId"
                component={(props) => <Sale edit {...props} />}
              />
              <ProtectedRoute
                exact
                onlyMaster
                key="settings"
                path="/settings"
                component={Settings}
              />
              <ProtectedRoute
                exact
                key="contact-directory"
                path="/contact-directory"
                component={ContactDirectory}
              />

              <ProtectedRoute
                exact
                onlyMaster
                path="/user-area"
                component={UserArea}
              />
              <ProtectedRoute exact path="/404" component={NotFound} />
              <SaleModulesProvider>
                <ProtectedRoute exact path="/" component={Dashboard} />
                <ProtectedRoute
                  exact
                  path="/sales/process/:saleId"
                  component={SaleProcess}
                />
              </SaleModulesProvider>
              <Redirect to="/404" />
            </Switch>
            <BusinessOfferDialog />
          </Router>
        </BusinessOfferDialogProvider>
      </AuthProvider>
      <Toast />
    </>
  );
};

export default App;
