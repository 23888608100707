import React from "react";
import PropTypes from "prop-types";
import Input from "../Input";

const CustomInput = React.forwardRef(
  ({ value, onClick, placeholder, disabled, error }, ref) => {
    return (
      <Input
        readOnly="readonly"
        type="text"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        inputError={error}
      />
    );
  }
);

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default CustomInput;
