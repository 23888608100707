import React from "react";
import Input from "../Input";

const InputCheckbox = ({ name, label, readOnly, checked, ...inputProps }) => {
  return (
    <label className="checkbox-wrapper">
      {readOnly ? (
        <>
          <Input type="hidden" name={name} {...inputProps} />
          <Input disabled readOnly type="checkbox" checked={checked} />
        </>
      ) : (
        <Input type="checkbox" name={name} {...inputProps} />
      )}
      <span className="label">
        {typeof label === "function" ? label() : label}
      </span>
    </label>
  );
};

export default InputCheckbox;
