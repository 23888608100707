import React from "react";
import classnames from "classnames";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import fetchUrl from "api/fetchUrl";
import { getTemplate, putTemplates, restoreTemplateByRef } from "api/requests";
import { getFormattedContent, TEMPLATE_REFERENCES } from "utils/email";
import Icon from "components/Icon";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import { useAuth } from "components/Hooks/useAuth";
import { notifyError, notifySuccess } from "utils/notify";

const Edition = ({
  title,
  content,
  setContent,
  loading,
  updateTemplate,
  restoreTemplate,
}) => {
  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <>
      <div className="header">
        <span>Modification rédactionnel :</span>
        <div className="header__title">{title}</div>
      </div>
      <div className="content">
        <Editor
          toolbarHidden
          editorState={content}
          onEditorStateChange={setContent}
        />
      </div>
      <div className="footer">
        <Button appearance="ghost" onClick={restoreTemplate}>
          Rétablir le texte par défaut
        </Button>
        <Button appearance="primary" onClick={updateTemplate}>
          enregistrer
        </Button>
      </div>
    </>
  );
};

const Section = ({
  title,
  templateList,
  loadTemplate,
  selectedTemplateRef,
  scrollToEdition,
}) => {
  return (
    <div onClick={scrollToEdition} className="section">
      <div className="section__title">{title}</div>
      <div className="section__items">
        {templateList.map((template) => {
          const isActive = template.value === selectedTemplateRef;

          const itemClasses = classnames("section__item", {
            active: isActive,
          });

          return (
            <div
              key={template.value}
              className={itemClasses}
              onClick={() => loadTemplate(template.value)}
            >
              <span>{template.label}</span>
              <Icon
                icon="pencil"
                size="big"
                color={`${isActive ? "white" : "dark-grey"}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const EmailSettings = () => {
  const { user } = useAuth();
  const refEdition = React.useRef(null);
  const userMailTemplatesId = user?.people?.mailTemplates?.id;

  const [currentTemplateContent, setCurrentTemplateContent] =
    React.useState(null);
  const [selectedTemplateRef, setSelectedTemplateRef] = React.useState();
  const [mailParts, setMailParts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const templates = TEMPLATE_REFERENCES;

  const scrollToEdition = () => refEdition.current.scrollIntoView();

  const getContentFromTemplate = (response) => {
    const [firstPart, content, lastPart] = response.template
      .replace("style", "twig-style")
      .split("<!-- split -->");

    setMailParts([firstPart, lastPart]);

    const contentBlock = htmlToDraft(content);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );

    const editorState = EditorState.createWithContent(contentState);

    return editorState;
  };

  const loadTemplate = React.useCallback(
    (templateRef) => {
      setLoading(true);
      setSelectedTemplateRef(templateRef);

      fetchUrl(
        getTemplate({
          mailReference: templateRef,
        })
      )
        .then((response) => {
          const emailContent = getContentFromTemplate(response);
          setCurrentTemplateContent(emailContent);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setError(e);
          setLoading(false);
        });
    },
    [setLoading, setSelectedTemplateRef, setError]
  );

  React.useEffect(() => {
    if (!selectedTemplateRef) {
      loadTemplate(templates.newTransaction.value);
    }
  }, [selectedTemplateRef, loadTemplate, templates]);

  const updateTemplate = () => {
    fetchUrl(
      putTemplates({
        templateId: userMailTemplatesId,
        templates: {
          [selectedTemplateRef]: getFormattedContent(
            currentTemplateContent,
            mailParts
          ),
        },
      })
    )
      .then(() => {
        notifySuccess("Email mis à jour avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de la mise à jour de l'email."
        );
      });
  };

  const restoreTemplate = () => {
    fetchUrl(restoreTemplateByRef(selectedTemplateRef))
      .then((response) => {
        const emailContent = getContentFromTemplate(response);
        setCurrentTemplateContent(emailContent);
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de la mise à jour de l'email."
        );
      });
  };

  const sections = [
    {
      title: "Constitution du dossier de vente",
      templateList: [
        templates.newTransaction,
        templates.requestForAdditionalDocuments,
      ],
    },
    {
      title: "Signature : Avant-contrat & Acte",
      templateList: [
        templates.availabilityRequestSignature,
        templates.confirmationDateOfSignature,
        templates.editDateOfSignature,
      ],
    },
    {
      title: "Formalités & conditions",
      templateList: [
        templates.confirmationNotificationSRU,
        templates.loanFileDepositConfirmation,
        templates.loanOfferReceiptConfirmation,
        templates.notaryConfirmationLoanOffer,
      ],
    },
    {
      title: "Divers",
      templateList: [
        templates.notaryInformationSellCancelation,
        templates.customerLoyalty,
        templates.freeMail,
      ],
    },
  ];

  return (
    <div className="email-settings">
      <div className="email-settings__sections">
        {sections.map((section) => (
          <Section
            key={section.title}
            title={section.title}
            templateList={section.templateList}
            loadTemplate={loadTemplate}
            selectedTemplateRef={selectedTemplateRef}
            scrollToEdition={scrollToEdition}
          />
        ))}
      </div>
      <div ref={refEdition} className="email-settings__edition">
        {error ? (
          <ErrorMessage customMessage="Erreur lors du chargement du contenu" />
        ) : (
          <Edition
            loading={loading || !currentTemplateContent}
            title={templates[selectedTemplateRef]?.label}
            content={currentTemplateContent}
            setContent={setCurrentTemplateContent}
            selectedTemplateRef={selectedTemplateRef}
            updateTemplate={updateTemplate}
            restoreTemplate={restoreTemplate}
          />
        )}
      </div>
    </div>
  );
};

export default EmailSettings;
