import * as yup from "yup";
import { PEOPLE_TYPES } from "components/Sale/SaleMultistep/saleConstants";

const { INDIVIDUAL } = PEOPLE_TYPES;

const baseContactSchema = {
  civility: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  lastname: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  firstname: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  mobile: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  email: yup
    .string()
    .email("Veuillez renseigner une adresse email valide.")
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
};

export const validationSchemaClient = yup.object({
  ...baseContactSchema,
  type: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  companyName: yup
    .string()
    .test(
      "is-company-name-valid",
      "Merci de saisir une raison sociale.",
      (value, context) => {
        const { type } = context.parent;
        return (
          type === INDIVIDUAL.value ||
          (type !== INDIVIDUAL.value && value !== undefined && value !== "")
        );
      }
    ),
});

export const validationSchemaNotary = yup.object({
  ...baseContactSchema,
  city: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
});
