import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import Spinner from "../Spinner";

const UnAuthenticatedRoute = ({ component: Component, ...routeProps }) => {
  const { authenticated } = useAuth();

  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (authenticated === null) {
          return <Spinner />;
        }
        if (authenticated) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default UnAuthenticatedRoute;
