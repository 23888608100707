import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import countDaysSince from "utils/countDaysSince";

const SaleProcessCountDown = ({ offerDate, signedAt = null }) => {
  const days = countDaysSince(offerDate, signedAt);
  return (
    <div className="sale-process__countdown">
      {days > 1 ? (
        <p>
          <strong className="sale-process__countdown-title">
            {days} jours
          </strong>
          se sont écoulés depuis le début de la vente.
        </p>
      ) : (
        <p>
          <strong className="sale-process__countdown-title">{days} jour</strong>
          s'est écoulé depuis le début de la vente.
        </p>
      )}
      <Icon icon="ripple" size="very-big" />
    </div>
  );
};

SaleProcessCountDown.propTypes = {
  createdAt: PropTypes.string,
  isComplete: PropTypes.bool,
};

export default SaleProcessCountDown;
