import * as yup from "yup";

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  newPassword: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  confirmNewPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "Le nouveau mot de passe et la confirmation du nouveau mot de passe doivent être identique."
    )
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
});

export default validationSchema;
