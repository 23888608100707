import React from "react";
import { useHistory } from "react-router-dom";
import Form from "components/Form";
import SubmitButton from "components/Form/SubmitButton";
import { InputPassword } from "components/Form/Input";
import fetchUrl from "api/fetchUrl";
import { postResetPasswordRequest } from "api/requests";
import Spinner from "components/Spinner";
import { notifyError, notifySuccess } from "utils/notify";
import validationSchema from "./validationSchema";

const ResetPasswordForm = ({ token }) => {
  const history = useHistory();
  const [fetching, setFetching] = React.useState(false);

  const handleSubmit = ({ password }) => {
    setFetching(true);
    fetchUrl(postResetPasswordRequest({ token, newPassword: password }))
      .then(() => {
        setFetching(false);
        notifySuccess("Votre mot de passe a été mis à jour avec succès.");
        history.push("/login");
      })
      .catch((e) => {
        setFetching(false);
        if (e?.detail === "Reset token expired") {
          notifyError(
            "Votre lien de réinitialisation de mot de passe a expiré."
          );
          history.push({
            pathname: "/forgot-password",
            state: { resetTokenExpired: true },
          });
          return;
        }

        notifyError(
          "Une erreur est survenue lors de la tentative de mise à jour de votre mot de passe."
        );
      });
  };

  return (
    <>
      {fetching && <Spinner />}
      <Form
        className="reset-password-form"
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <b>Veuillez saisir votre nouveau mot de passe :</b>
        <br />
        <InputPassword rounded name="password" placeholder="Mot de passe" />
        <InputPassword
          rounded
          name="passwordConfirmation"
          placeholder="Confirmer votre mot de passe"
        />
        <SubmitButton appearance="primary">
          Changer le mot de passe
        </SubmitButton>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
