import PropTypes from "prop-types";
import React from "react";
import SwiperCore, { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import Icon from "components/Icon";
import Text from "components/Text";
import Divider from "components/Divider";

const UserColumnContent = ({ row, index }) => (
  <>
    <span>
      <Icon
        icon="person-circle"
        color={`${
          row.status === 1 ? (index === 0 ? "white" : "primary") : "grey"
        }`}
      />
    </span>
    <span>{row.user}</span>
  </>
);

const Title = ({ title }) => (
  <div className="mobile-table-data__title">
    <Text color="primary" font="roboto-slab" align="center">
      {title}
    </Text>
    <Divider />
  </div>
);

const MobileTableData = ({ data }) => {
  SwiperCore.use([Pagination]);

  const swiperProps = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    pagination: {
      clickable: true,
    },
  };

  return (
    <Swiper {...swiperProps}>
      <SwiperSlide key={`step-indicator-1`}>
        <div className="mobile-table-data">
          <Title title="Ventes enregistrées" />
          <div className="mobile-table-data__head">
            <div className="mobile-table-data__column">Utilisateurs</div>
            <div className="mobile-table-data__column">Total</div>
            <div className="mobile-table-data__column">Moyenne / mois</div>
          </div>
          <div className="mobile-table-data__body">
            {[data.synthesisData, ...data.tableData].map((row, index) => (
              <div
                key={`swipper-row-1-${index}`}
                className="mobile-table-data__row"
              >
                <div className="mobile-table-data__column">
                  <UserColumnContent row={row} index={index} />
                </div>
                <div className="mobile-table-data__column">{row.nb_total}</div>
                <div className="mobile-table-data__column">
                  {parseFloat(row.average_sales_by_month).toFixed(1)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide key={`step-indicator-2`}>
        <div className="mobile-table-data">
          <Title title="Détail des ventes" />
          <div className="mobile-table-data__head">
            <div className="mobile-table-data__column">Utilisateurs</div>
            <div className="mobile-table-data__column">En cours</div>
            <div className="mobile-table-data__column">Actées</div>
            <div className="mobile-table-data__column">Annulées</div>
          </div>
          <div className="mobile-table-data__body">
            {[data.synthesisData, ...data.tableData].map((row, index) => (
              <div
                key={`swipper-row-2-${index}`}
                className="mobile-table-data__row"
              >
                <div className="mobile-table-data__column">
                  <UserColumnContent row={row} index={index} />
                </div>
                <div className="mobile-table-data__column">
                  {row.nb_in_progress}
                </div>
                <div className="mobile-table-data__column">{row.nb_acted}</div>
                <div className="mobile-table-data__column">
                  {row.nb_canceled}
                </div>
              </div>
            ))}
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide key={`step-indicator-3`}>
        <div className="mobile-table-data">
          <Title title="Chiffre d'affaire HT" />
          <div className="mobile-table-data__head">
            <div className="mobile-table-data__column">Utilisateurs</div>
            <div className="mobile-table-data__column">CA en cours</div>
            <div className="mobile-table-data__column">CA acté</div>
          </div>
          <div className="mobile-table-data__body">
            {[data.synthesisData, ...data.tableData].map((row, index) => (
              <div
                key={`swipper-row-3-${index}`}
                className="mobile-table-data__row"
              >
                <div className="mobile-table-data__column">
                  <UserColumnContent row={row} index={index} />
                </div>
                <div className="mobile-table-data__column">
                  {Math.round(row.SAVE)} &euro;
                </div>
                <div className="mobile-table-data__column">
                  {Math.round(row.ACTED)} &euro;
                </div>
              </div>
            ))}
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

MobileTableData.propTypes = {
  data: PropTypes.shape({
    synthesisData: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
  }),
};

export default MobileTableData;
