import ClientsAndNotariesStep from "./clientsAndNotariesStep";
import validationSchema from "./validationSchema";

const step = {
  StepComponent: ClientsAndNotariesStep,
  validationSchema,
  dependencies: ["clients", "notaries"],
};

export default step;
