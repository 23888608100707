import React from "react";

const useClickOutside = (nodes, listen, isClickOutside) => {
  const handleClick = React.useCallback(
    (e) => {
      const isOutsideAllNode = nodes.every((node) => {
        if (!node.current || !(typeof node.current.contains == "function")) {
          return false;
        }
        if (node.current.contains(e.target)) {
          return false;
        }

        return true;
      });

      if (isOutsideAllNode) {
        isClickOutside();
      }
    },
    [nodes, isClickOutside]
  );

  React.useEffect(() => {
    if (!listen) {
      return;
    }
    // add when mounted
    document.addEventListener("mousedown", handleClick, {
      capture: false,
      passive: true,
    });
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick, {
        capture: false,
        passive: true,
      });
    };
  }, [handleClick, listen]);
};

export default useClickOutside;
