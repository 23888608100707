import React from "react";
import ReactDatePicker from "react-datepicker";
import { IconButton } from "components/Button";
import { format } from "utils/date";

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <header className="planning-calendar__header">
      <div className="planning-calendar__date">{format(date, "MMMM yyyy")}</div>
      <div className="planning-calendar__controls">
        <IconButton
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
          icon="chevron-left"
          iconSize="small"
          appearance="naked"
        />
        <IconButton
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
          icon="chevron-right"
          iconSize="small"
          appearance="naked"
        />
      </div>
    </header>
  );
};

const PlanningCalendar = ({ date, handleDateChange, events }) => {
  return (
    <div className="planning-calendar">
      <ReactDatePicker
        inline
        selected={date}
        onChange={handleDateChange}
        renderCustomHeader={CustomHeader}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1).toUpperCase()}
        dayClassName={(date) => {
          const dateString = date.toLocaleDateString();
          if (events[dateString]) {
            return events[dateString].common
              ? "react-datepicker__day--has-common-entry"
              : "react-datepicker__day--has-entry";
          }
        }}
      />
    </div>
  );
};

export default PlanningCalendar;
