import React from "react";
import { useAuth } from "../Hooks/useAuth";

const Greeting = () => {
  const { user } = useAuth();

  return (
    <span className="greeting">
      {user?.people && (
        <>
          Hello <b>{`${user.people.firstname} ${user.people.lastname} `}</b>!
        </>
      )}
    </span>
  );
};

export default Greeting;
