import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

const BaseInput = ({
  type,
  name,
  rounded,
  label,
  register,
  inputError,
  value,
  textAlign = "left",
  appearance,
  ...inputProps
}) => {
  const className = classNames("input", {
    "input--error": inputError,
    "input--textarea": type === "textarea",
    "input--rounded": rounded,
    "input--text-right": textAlign === "right",
    [`input--${appearance}`]: appearance,
  });

  const commonProps = {
    // in some case we don't want to register the input because he is controlled by something else
    ...(name ? { ...register(name) } : {}),
    ...(value !== undefined ? { value } : {}),
    ...inputProps,
  };

  if (type === "checkbox") {
    return <input type="checkbox" className="checkbox" {...commonProps} />;
  }

  if (type === "textarea") {
    return <textarea className={className} {...commonProps} />;
  }

  return <input type={type} className={className} {...commonProps} />;
};

const Input = (inputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputError = { ...errors }[inputProps.name];

  return (
    <BaseInput
      register={register}
      /**
       * errors is a shallow copy so we need to use the spread operator to make a deep copy of it
       * in order to use it in the React.memo state comparison, if we don't do that previous errors and next errors
       * will always be equal...
       */
      inputError={inputError}
      {...inputProps}
    />
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  rounded: PropTypes.bool,
  label: PropTypes.string,
  textAlign: PropTypes.string,
  appearance: PropTypes.oneOf(["default", "grey"]),
};

export default Input;
