import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import Form from "components/Form";
import * as yup from "yup";

const Step = ({
  lastStep,
  children,
  active,
  step,
  setCurrentStep,
  isDraft,
  ...stepProps
}) => {
  const {
    formState: { isValid, isDirty },
  } = useFormContext();

  let [previousStep, nextStep] = [null, null];

  if (step !== 0) {
    previousStep = () => {
      setCurrentStep(step - 1);
    };
  }

  if (step !== lastStep) {
    nextStep = () => {
      setCurrentStep(step + 1);
    };
  }

  return (
    <div
      className={classNames("sale-multistep__step", {
        "sale-multistep__step--inactive": !active,
      })}
    >
      {React.cloneElement(children, {
        /**
         * We need to have a double condition here because react-hook-form set isValid as true at first render
         * see https://github.com/react-hook-form/react-hook-form/issues/1512 for more context...
         */
        step,
        isValid: isValid && (isDirty || isDraft),
        active,
        previousStep: previousStep !== null && previousStep,
        nextStep: nextStep !== null && nextStep,
        setCurrentStep,
        ...stepProps,
      })}
    </div>
  );
};

Step.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

const SaleMultistep = ({
  isEdit,
  saleType,
  sale,
  setSale,
  defaultValues,
  currentStep,
  setCurrentStep,
  setValidationSchema,
  steps,
  ...formDependencies
}) => {
  const [validationSchemaArgs, setValidationSchemaArgs] = React.useState({});

  const lastStep = steps.length;
  const isDraft = Boolean(sale);

  const currentValidationSchema = Number.isInteger(currentStep)
    ? steps[currentStep].validationSchema
    : yup.object();

  const validationSchema = currentValidationSchema
    ? typeof currentValidationSchema === "function"
      ? currentValidationSchema(validationSchemaArgs)
      : currentValidationSchema
    : null;

  return (
    <div className="sale-multistep">
      <Form
        mode="all"
        validationSchema={validationSchema}
        defaultValues={defaultValues}
      >
        {steps.map(({ StepComponent, dependencies, fields }, index) => {
          const stepDependencies =
            dependencies?.length > 0 &&
            dependencies.reduce((acc, key) => {
              return { ...acc, [key]: formDependencies[key] };
            }, {});

          const stepProps = {
            ...stepDependencies,
            fields,
          };

          const step = index;

          return (
            <Step
              key={`step${step}`}
              isEdit={isEdit}
              lastStep={lastStep}
              step={step}
              setCurrentStep={setCurrentStep}
              goToStep={() => {
                if (currentStep === step) {
                  setCurrentStep(null);
                } else {
                  setCurrentStep(step);
                }
              }}
              active={currentStep === step}
              currentStep={currentStep}
              isDraft={isDraft}
              sale={sale}
              saleType={saleType}
              setSale={setSale}
              setValidationSchemaArgs={setValidationSchemaArgs}
              {...stepProps}
            >
              <StepComponent />
            </Step>
          );
        })}
      </Form>
    </div>
  );
};

SaleMultistep.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func.isRequired,
};

export default SaleMultistep;
