import PropTypes from "prop-types";
import React from "react";
import validationSchema from "./validationSchema";
import Icon from "components/Icon";
import Form from "components/Form";
import { InputNumber } from "components/Form/Input";
import fetchUrl from "api/fetchUrl";
import { getAgencyAlerts, putAgencyAlerts } from "api/requests";
import { useAuth } from "components/Hooks/useAuth";
import { notifyError, notifySuccess } from "utils/notify";

const DataItem = ({
  item: { title, label, bankLoanName, cashPaymentName },
}) => {
  return (
    <div className="data-item">
      <div className="data-item__title">
        <Icon icon="bell" size="medium" color="primary" />
        <span>{title}</span>
      </div>
      <div className="data-item__label">{label}</div>
      <div className="data-item__inputs">
        <div className="data-item__input">
          <span>prêt bancaire ou mixte</span>
          <InputNumber
            disabled
            name={bankLoanName}
            suffix={{ text: "jour(s)" }}
          />
        </div>
        {cashPaymentName ? (
          <div className="data-item__input">
            <span>paiement comptant</span>
            <InputNumber
              disabled
              name={cashPaymentName}
              suffix={{ text: "jour(s)" }}
            />
          </div>
        ) : (
          <div className="data-item__no-cash-payment"></div>
        )}
      </div>
    </div>
  );
};

const DataSection = ({ title, isFirstSection = false, dataItems }) => (
  <div className="data-section">
    {isFirstSection && (
      <div className="data-section__data-header">
        <div>prêt bancaire ou mixte</div>
        <div>paiement comptant</div>
      </div>
    )}
    <div className="data-section__title">{title}</div>
    {dataItems.map((item, index) => (
      <DataItem key={`data-alert-${index}`} item={item} />
    ))}
  </div>
);

DataSection.propTypes = {
  dataItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFirstSection: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const AlertSettings = () => {
  const {
    user: {
      people: {
        agency: { id: agencyId },
      },
    },
  } = useAuth();

  const [agencyAlerts, setAgencyAlerts] = React.useState({});

  const handleSubmit = (agencyAlerts) => {
    const { id: agencyAlertsId, ...restAgencyAlerts } = agencyAlerts;
    fetchUrl(
      putAgencyAlerts({ agencyAlertsId, agencyAlerts: restAgencyAlerts })
    )
      .then(() => {
        notifySuccess(
          "Le paramétrage des alertes a été enregistré avec succès."
        );
      })
      .catch(() => {
        notifyError("Une erreur est survenue lors du paramétrage des alertes.");
      });
  };

  React.useEffect(() => {
    fetchUrl(getAgencyAlerts({ agencyId })).then((_agencyAlerts) => {
      const { defaultAgencyAlerts, id, ...restAgencyAlerts } = _agencyAlerts;
      setAgencyAlerts({ ...restAgencyAlerts, id });
    });
  }, [agencyId]);

  return (
    <Form
      className="alert-settings"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      defaultValues={agencyAlerts}
    >
      {({ reset }) => (
        <>
          <div className="alert-settings__sections">
            <DataSection
              title="Constitution du dossier de vente"
              isFirstSection={true}
              dataItems={[
                {
                  title: "S'assurer que le dossier de vente est complet",
                  label: "Date enregistrement vente +",
                  bankLoanName: "loanCompleteSalesFile",
                  cashPaymentName: "cashCompleteSalesFile",
                },
                {
                  title:
                    "S'assurer que l'acquéreur a pris connaissance des documents du bien",
                  label: "Date enregistrement vente +",
                  bankLoanName: "loanBuyerKnowPropertyFiles",
                  cashPaymentName: "cashBuyerKnowPropertyFiles",
                },
              ]}
            />
            <DataSection
              title="Signature de l'avant-contrat"
              dataItems={[
                {
                  title:
                    "Planifier la signature de l'avant-contrat (compromis)",
                  label: "Date enregistrement vente +",
                  bankLoanName: "loanPlanSignatureCompromise",
                  cashPaymentName: "cashPlanSignatureCompromise",
                },
              ]}
            />
            <DataSection
              title="Formalités & conditions"
              dataItems={[
                {
                  title:
                    "S'assurer que le notaire a notifié l'avant-contrat (SRU)",
                  label: "Date signature avant-contrat +",
                  bankLoanName: "loanNotaryNotifySRU",
                  cashPaymentName: "cashNotaryNotifySRU",
                },
                {
                  title:
                    "S'assurer de l'avancée des démarches bancaires (dépôt dossier)",
                  label: "Date limite de dépôt de dossier -",
                  bankLoanName: "loanBankFileDeposit",
                  cashPaymentName: null,
                },
                {
                  title:
                    "S'assurer de l'avancée des démarches bancaires (réception offre)",
                  label: "Date limite réception offre de prêt -",
                  bankLoanName: "loanBankOfferReception",
                  cashPaymentName: null,
                },
                {
                  title:
                    "S'assurer que le dossier de vente avant acte est complet",
                  label: "Date limite signature de l'acte -",
                  bankLoanName: "loanSaleFileIsComplete",
                  cashPaymentName: "cashSaleFileIsComplete",
                },
              ]}
            />
            <DataSection
              title="Signature de l'acte de vente"
              dataItems={[
                {
                  title: "Planifier la signature de l'acte",
                  label: "Date limite signature de l'acte -",
                  bankLoanName: "loanSignaturePlanned",
                  cashPaymentName: "cashSignaturePlanned",
                },
              ]}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default AlertSettings;
