import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import Spinner from "../Spinner";

const ProtectedRoute = ({
  component: Component,
  onlyMaster,
  ...routeProps
}) => {
  const { authenticated, user } = useAuth();

  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (authenticated === null) {
          return <Spinner />;
        }
        if (authenticated && onlyMaster && !user?.isMasterUser) {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
        if (authenticated === true) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
