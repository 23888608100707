import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card";
import Icon from "components/Icon";
import IconButton from "components/Button/IconButton";

const UserCard = ({
  user,
  salesCount,
  isDeletable,
  handleDelete,
  handleEdit,
}) => {
  if (!user) {
    return null;
  }

  // const usePlural = salesCount > 1;
  const isUserActive = user.status === 1;

  return (
    <Card className="user-card" onClick={handleEdit}>
      <div className="user-card__content">
        <div className="user-card__close-icon">
          {isDeletable && <IconButton icon="close" onClick={handleDelete} />}
        </div>
        <Icon
          icon="user"
          color={isUserActive ? "primary" : "grey"}
          size="big"
        />
        <h2 className="user-card__people">
          {user.firstname}{" "}
          <span className="people-lastname">{user.lastname}</span>
        </h2>
        {/** Remove number of sales by client waiting for v2 advanced stats feature
          <p
            className={classNames("user-card__sales-count", {
              "user-card__sales-count--empty": salesCount < 1,
            })}
          >
            {salesCount} {usePlural ? "ventes" : "vente"}
          </p>
          **/}
      </div>
    </Card>
  );
};

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  salesCount: PropTypes.number.isRequired,
  isDeletable: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default UserCard;
