import React from "react";
import PropTypes from "prop-types";
import { Mobile, Tablet } from "components/MediaQuery";
import Icon from "components/Icon";
import MobileTableData from "./MobileTableData";

const StatisticsTable = ({ data }) => {
  return (
    <div className="statistics-table">
      <Tablet>
        <table>
          <thead>
            <tr className="statistics-table__over-head">
              <td colSpan="2"></td>
              <td colSpan="2">Ventes enregistrées</td>
              <td colSpan="3">Détail des ventes</td>
              <td colSpan="2">Chiffre d'affaire HT</td>
            </tr>
            <tr className="statistics-table__head">
              <td className="statistics-table__head__status" colSpan="2">
                <span>Status</span>
                <span>Utilisateurs</span>
              </td>
              <td>Total</td>
              <td>
                Moyenne <br />/ mois
              </td>
              <td>En cours</td>
              <td>Actées</td>
              <td>Annulées</td>
              <td>CA en cours</td>
              <td>CA acté</td>
            </tr>
          </thead>
          <tbody>
            {[data.synthesisData, ...data.tableData].map((row, index) => {
              return (
                <tr key={index}>
                  <td colSpan="2">
                    <span>
                      <Icon
                        icon="person-circle"
                        color={`${
                          row.status === 1
                            ? index === 0
                              ? "white"
                              : "primary"
                            : "grey"
                        }`}
                      />
                    </span>
                    <span>{row.user}</span>
                  </td>

                  <td>{row.nb_total}</td>
                  <td>{parseFloat(row.average_sales_by_month).toFixed(1)}</td>
                  <td>{row.nb_in_progress}</td>
                  <td>{row.nb_acted}</td>
                  <td>{row.nb_canceled}</td>
                  <td>{Math.round(row.SAVE)} &euro;</td>
                  <td>{Math.round(row.ACTED)} &euro;</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Tablet>
      <Mobile>
        <MobileTableData data={data} />
      </Mobile>
    </div>
  );
};

StatisticsTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StatisticsTable;
