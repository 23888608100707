import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Grid = ({ children, vertical }) => (
  <div
    className={classNames("grid", {
      "grid--vertical": vertical,
    })}
  >
    {children}
  </div>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
};

export default Grid;
