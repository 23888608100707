import React from "react";
import { useFormContext } from "react-hook-form";
import { InputNumber } from "components/Form/Input";
import Select from "components/Form/Select";
import { PROPERTY_TYPES } from "components/Sale/SaleMultistep/saleConstants";

const {
  APARTMENT,
  HOUSE,
  BUILDING,
  OFFICE,
  BUSINESS,
  GARAGE_PARKING,
  CELLAR,
  LAND,
  OTHER,
} = PROPERTY_TYPES;

const PropertyType = () => {
  const { values, setValue } = useFormContext();

  const { propertyType, numberOfRooms } = values;

  const displayNumberOfRooms = [
    APARTMENT.value,
    HOUSE.value,
    OFFICE.value,
    BUSINESS.value,
  ].includes(propertyType);

  React.useEffect(() => {
    if (!displayNumberOfRooms && numberOfRooms !== "") {
      setValue("numberOfRooms", "");
    }
  }, [displayNumberOfRooms, numberOfRooms, setValue]);

  return (
    <div className="property-type">
      <Select
        name="propertyType"
        options={[
          APARTMENT,
          HOUSE,
          BUILDING,
          OFFICE,
          BUSINESS,
          GARAGE_PARKING,
          CELLAR,
          LAND,
          OTHER,
        ]}
        placeholder="Type de bien"
      />
      {displayNumberOfRooms && (
        <InputNumber name="numberOfRooms" placeholder="Pièces" min={1} />
      )}
      <InputNumber name="area" placeholder="Surface" min={1} />
    </div>
  );
};

export default PropertyType;
