import * as yup from "yup";

const validationSchema = yup.object({
  civility: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  lastname: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  firstname: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  mobile: yup
    .string()
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
  email: yup
    .string()
    .email("Veuillez renseigner une adresse email valide.")
    .required("Merci de remplir le(s) champ(s) encadré(s) en rouge."),
});

export default validationSchema;
