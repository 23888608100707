import React from "react";
import fetchUrl from "api/fetchUrl";
import SwiperCore, { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { getStats } from "api/requests";
import { formatDate } from "utils/date";
import Form from "components/Form";
import { Tablet } from "components/MediaQuery";
import GenericLayout from "components/GenericLayout";
import Spinner from "components/Spinner";
import ErrorMessage from "components/ErrorMessage";
import Icon from "components/Icon";
import StatisticsMonthSelect from "components/Statistics/StatisticsMonthSelect";
import StatisticsTable from "components/Statistics/StatisticsTable";
import StatisticsCard from "components/Statistics/StatisticsCard";
import { useAuth } from "components/Hooks/useAuth";
import { format } from "date-fns";

const StatisticsCards = ({
  recapData = {
    oldestSaleOfferDate: null,
    totalNumberOfSales: 0,
    totalFees: 0,
    averageFees: 0,
    averageNumberOfSales: 0,
    currentMonthFeesAreHigherThanPreviousMonthFees: 0,
    currentMonthSalesAreHigherThanPreviousMonthSales: 0,
    feesAndNumberOfSalesByMonth: [],
  },
  monthData = {
    fees: 0,
    numberOfSales: 0,
  },
  setSelectedMonthAndYear,
  user,
}) => {
  SwiperCore.use([Pagination]);

  const swiperProps = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    pagination: {
      clickable: true,
    },
  };

  const cardData = [
    {
      title:
        "Synthèse depuis le " +
        formatDate(recapData.oldestSaleOfferDate || user?.createdAt),
      icon: <Icon icon="wave" size="very-big" color="white" />,
      fees: Math.round(recapData.totalFees),
      numberOfSales: recapData.totalNumberOfSales,
      oldestSaleOfferDate: recapData.oldestSaleOfferDate,
    },
    {
      title: "Moyenne mensuelle",
      icon: <Icon icon="wave" size="very-big" color="primary" />,
      fees: Math.round(recapData.averageFees),
      numberOfSales: recapData.averageNumberOfSales,
      isFeesUp: recapData.currentMonthFeesAreHigherThanPreviousMonthFees,
      isNumberOfSalesUp:
        recapData.currentMonthSalesAreHigherThanPreviousMonthSales,
    },
    {
      title: (
        <Form>
          {({ values }) => (
            <StatisticsMonthSelect
              values={values}
              setSelectedMonthAndYear={setSelectedMonthAndYear}
            />
          )}
        </Form>
      ),
      fees: monthData.fees,
      numberOfSales: monthData.numberOfSales,
    },
  ];

  return (
    <>
      <div className="statistics-recap__cards">
        <Tablet>
          {cardData.map((data, index) => (
            <StatisticsCard
              key={`card-${data.title}`}
              index={index}
              {...data}
            />
          ))}
        </Tablet>
      </div>
      <div className="statistics-recap__swipper">
        <Swiper {...swiperProps}>
          {cardData.map((data, index) => (
            <SwiperSlide key={`step-indicator-${index}`}>
              <StatisticsCard
                key={`card-${data.title}`}
                index={index}
                {...data}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

const Statistics = ({ statCardsOnly = false }) => {
  const [recapData, setRecapData] = React.useState({ data: [] });
  const [selectedMonthAndYear, setSelectedMonthAndYear] = React.useState(
    format(new Date(), "MM/yyyy")
  );
  const [tableData, setTableData] = React.useState([]);
  const [synthesisData, setSynthesisData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const { user } = useAuth();

  const monthData = React.useMemo(() => {
    const getMonthData = () => {
      const _monthData =
        recapData?.feesAndNumberOfSalesByMonth?.[selectedMonthAndYear];
      if (_monthData) {
        return {
          fees: _monthData.fees,
          numberOfSales: _monthData.numberOfSales,
        };
      }
      return {
        fees: 0,
        numberOfSales: 0,
      };
    };

    return getMonthData();
  }, [recapData?.feesAndNumberOfSalesByMonth, selectedMonthAndYear]);

  React.useEffect(() => {
    fetchUrl(getStats())
      .then((response) => {
        setRecapData(response?.recapData);
        setTableData(response.agency);
        setSynthesisData(response.synthesis);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, [setLoading, setError]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <ErrorMessage customMessage="Une erreur est survenue lors de la récupération des statistiques" />
    );
  }

  if (statCardsOnly) {
    return (
      <div className="statistics-recap --stat-cards-only">
        <div className="statistics-recap__icon">
          <Icon icon="statistic" size="very-big" color="primary" />
        </div>
        <StatisticsCards
          recapData={recapData}
          monthData={monthData}
          setSelectedMonthAndYear={setSelectedMonthAndYear}
          selectedMonthAndYear={selectedMonthAndYear}
          user={user}
        />
      </div>
    );
  }

  return (
    <GenericLayout
      headerIcon="statistic-bold"
      headerTitle="Statistiques"
      headerContent={
        <div className="statistics-header__key-numbers">Chiffres clés</div>
      }
      noPadding
    >
      <div className="statistics">
        {user.isMasterUser && (
          <StatisticsTable data={{ tableData, synthesisData }} />
        )}
        <div className="statistics-recap">
          <div className="statistics-recap__icon">
            <Icon icon="statistic" size="very-big" color="primary" />
          </div>
          <StatisticsCards
            recapData={recapData}
            monthData={monthData}
            setSelectedMonthAndYear={setSelectedMonthAndYear}
            selectedMonthAndYear={selectedMonthAndYear}
            user={user}
          />
        </div>
      </div>
    </GenericLayout>
  );
};

export default Statistics;
