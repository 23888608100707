import React from "react";
import { useFormContext } from "react-hook-form";
import { IconButton } from "components/Button";
import InputAutoComplete from "components/Form/Input/InputAutoComplete/InputAutoComplete";
import Text from "components/Text";
import Accordion from "components/Accordion";
import NewClientDialog from "./dialogs/NewClientDialog";
import NewNotaryDialog from "./dialogs/NewNotaryDialog";
import StepActions from "../../StepActions";
import { CLIENT_PREFIX, NOTARY_PREFIX } from "../../saleConstants";
import fetchUrl from "api/fetchUrl";
import { putSale } from "api/requests";
import EditClientDialog from "./dialogs/EditClientDialog";
import EditNotaryDialog from "./dialogs/EditNotaryDialog";
import { formatSale } from "pages/Sale/Sale";

const formatClient = (client) => {
  return {
    value: `${CLIENT_PREFIX}${client.id}`,
    label: `${client.firstname} ${client.lastname}`,
  };
};

const formatNotary = (notary) => {
  return {
    value: `${NOTARY_PREFIX}${notary.id}`,
    label: `${notary.firstname} ${notary.lastname}`,
  };
};

const formatClientsAndNotaries = ({ sellerAndNotary, buyerAndNotary }) => {
  const sellers = Array.from(sellerAndNotary.keys()).map((index) => {
    const sellerFieldName = `seller${index}`;
    const notaryFieldName = `${sellerFieldName}_notary`;
    return { client: sellerFieldName, notary: notaryFieldName };
  });

  const buyers = Array.from(buyerAndNotary.keys()).map((index) => {
    const buyerFieldName = `buyer${index}`;
    const notaryFieldName = `${buyerFieldName}_notary`;
    return { client: buyerFieldName, notary: notaryFieldName };
  });

  return {
    sellers,
    buyers,
  };
};

const OpenDialogButton = ({ children, onClick }) => {
  return (
    <IconButton
      fullWidth
      icon="plus"
      textPosition="left"
      appearance="reversed-primary"
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

const ClientAndNotary = ({
  field,
  clientList,
  notaryList,
  setDialogsState,
}) => {
  const { values, trigger: triggerValidation } = useFormContext();

  const client = values[field.client];
  const notary = values[field.notary];

  const clientId = client?.replace(CLIENT_PREFIX, "");
  const notaryId = notary?.replace(NOTARY_PREFIX, "");

  const openCreateDialog = ({ type, stateKey }) => {
    const newDialogState = { target: field[type], open: true };
    setDialogsState((previousDialogsState) => ({
      ...previousDialogsState,
      [stateKey]: newDialogState,
    }));
  };

  const openEditDialog = ({ type, stateKey, entityId }) => {
    const newDialogState = { target: field[type], open: true, entityId };
    setDialogsState((previousDialogsState) => ({
      ...previousDialogsState,
      [stateKey]: newDialogState,
    }));
  };

  React.useEffect(() => {
    if (client === "") {
      triggerValidation([field.notary]);
    }
  }, [client, field.notary, triggerValidation]);

  return (
    <div className="client-and-notary">
      <div className="edit-wrapper">
        <InputAutoComplete
          name={field.client}
          options={clientList}
          placeholder="Rechercher un client..."
        />
        <IconButton
          disabled={!client}
          icon="edit"
          appearance="reversed-primary"
          onClick={() =>
            openEditDialog({
              type: "client",
              stateKey: "editClient",
              entityId: clientId,
            })
          }
        />
      </div>
      {!client ? (
        <OpenDialogButton
          onClick={() =>
            openCreateDialog({ type: "client", stateKey: "client" })
          }
        >
          NOUVEAU CLIENT
        </OpenDialogButton>
      ) : null}

      <div className="edit-wrapper">
        <InputAutoComplete
          name={field.notary}
          options={notaryList}
          placeholder="Rechercher un notaire..."
        />
        <IconButton
          disabled={!notary}
          icon="edit"
          appearance="reversed-primary"
          onClick={() =>
            openEditDialog({
              type: "notary",
              stateKey: "editNotary",
              entityId: notaryId,
            })
          }
        />
      </div>
      {!notary ? (
        <OpenDialogButton
          onClick={() =>
            openCreateDialog({ type: "notary", stateKey: "notary" })
          }
        >
          NOUVEAU NOTAIRE
        </OpenDialogButton>
      ) : null}
    </div>
  );
};

const AddClientAndNotary = ({ target, type, label, setFields }) => {
  const addField = () => {
    setFields((previousFields) => {
      const fieldsCount = previousFields[target].length;
      const clientFieldName = `${type}${fieldsCount}`;
      const notaryFieldName = `${clientFieldName}_notary`;
      return {
        ...previousFields,
        [target]: [
          ...previousFields[target],
          { client: clientFieldName, notary: notaryFieldName },
        ],
      };
    });
  };
  return (
    <div className="add-client-and-notary">
      <Text size="small" fontWeight="semi-bold">
        {label}
      </Text>
      <IconButton
        textPosition="left"
        appearance="reversed-primary"
        icon="plus"
        onClick={addField}
      >
        Ajouter
      </IconButton>
    </div>
  );
};

const ClientsAndNotariesStep = ({
  isEdit,
  step,
  sale,
  setSale,
  isValid,
  active,
  nextStep,
  goToStep,
  clients,
  notaries,
  setValidationSchemaArgs,
}) => {
  const { values, setValue, trigger: triggerValidation } = useFormContext();
  const [dialogsState, setDialogsState] = React.useState({
    client: {
      open: false,
      target: null,
    },
    notary: {
      open: false,
      target: null,
    },
    editClient: {
      open: false,
      target: null,
      entityId: null,
    },
    editNotary: {
      open: false,
      target: null,
      entityId: null,
    },
  });

  const [clientList, setClientList] = React.useState(clients.map(formatClient));
  const [notaryList, setNotaryList] = React.useState(
    notaries.map(formatNotary)
  );
  const [fields, setFields] = React.useState(
    sale?.sellerAndNotary?.length > 0 && sale?.buyerAndNotary?.length > 0
      ? formatClientsAndNotaries(sale)
      : {
          sellers: [{ client: "seller0", notary: "seller0_notary" }],
          buyers: [{ client: "buyer0", notary: "buyer0_notary" }],
        }
  );

  const setDialogOpen = (type, open) => {
    const newDialogState = { ...dialogsState[type], open };
    setDialogsState({
      ...dialogsState,
      [type]: newDialogState,
    });
  };

  const addNewClientToList = ({ id, people }) => {
    setClientList((previousClientList) => [
      ...previousClientList,
      formatClient({
        id,
        firstname: people.firstname,
        lastname: people.lastname,
      }),
    ]);
  };

  const updateClientList = ({ id, people }) => {
    const clientIndex = clientList.findIndex(
      (client) => client.value === `${CLIENT_PREFIX}${id}`
    );

    const clonedClientList = [...clientList];
    clonedClientList[clientIndex] = formatClient({
      id,
      firstname: people.firstname,
      lastname: people.lastname,
    });

    setClientList(clonedClientList);
  };

  const addNewNotaryToList = ({ id, people }) => {
    setNotaryList((previousNotaryList) => [
      ...previousNotaryList,
      formatNotary({
        id,
        firstname: people.firstname,
        lastname: people.lastname,
      }),
    ]);
  };

  const updateNotaryList = ({ id, people }) => {
    const notaryIndex = notaryList.findIndex(
      (notary) => notary.value === `${NOTARY_PREFIX}${id}`
    );

    const clonedNotaryList = [...notaryList];
    clonedNotaryList[notaryIndex] = formatNotary({
      id,
      firstname: people.firstname,
      lastname: people.lastname,
    });

    setNotaryList(clonedNotaryList);
  };

  const renderClientsAndNotaries = ({ target, type }) => {
    const clientsAndNotaries = fields[target].map((field, i) => (
      <ClientAndNotary
        key={`${type}-and-notary-${i}`}
        field={field}
        clientList={clientList}
        notaryList={notaryList}
        setDialogsState={setDialogsState}
      />
    ));
    return clientsAndNotaries;
  };

  const filterEmptyFields = React.useCallback(
    ({ client, notary }) => {
      const { [client]: clientValue, [notary]: notaryValue } = values;
      return clientValue && notaryValue;
    },
    [values]
  );

  const makeStepData = () => {
    const sellerAndNotary = fields.sellers
      .filter(filterEmptyFields)
      .map(({ client, notary }) => ({
        client: values[client],
        notary: values[notary],
      }));
    const buyerAndNotary = fields.buyers
      .filter(filterEmptyFields)
      .map(({ client, notary }) => ({
        client: values[client],
        notary: values[notary],
      }));

    const nextStep = step + 1;
    const stepData = {
      draftStep: nextStep > sale.draftStep ? nextStep : sale.draftStep,
      sellerAndNotary,
      buyerAndNotary,
    };

    return stepData;
  };

  const handleNextStep = () => {
    const stepData = makeStepData();

    fetchUrl(putSale({ sale: stepData, saleId: sale.id })).then((sale) => {
      setSale(formatSale(sale));
      nextStep();
    });
  };

  React.useEffect(() => {
    if (active) {
      const { sellers, buyers } = fields;
      setValidationSchemaArgs({ sellers, buyers });
    }
  }, [active, fields, setValidationSchemaArgs]);

  React.useEffect(() => {
    const removeEmptyFields = () => {
      const sellersFieldsWithoutEmpty =
        fields.sellers.length === 1
          ? fields.sellers
          : fields.sellers.filter(filterEmptyFields);
      const buyersFieldsWithoutEmpty =
        fields.buyers.length === 1
          ? fields.buyers
          : fields.buyers.filter(filterEmptyFields);

      setFields({
        sellers: sellersFieldsWithoutEmpty,
        buyers: buyersFieldsWithoutEmpty,
      });
    };

    if (!active) {
      removeEmptyFields();
    }
  }, [active, fields.buyers, fields.sellers, setFields, filterEmptyFields]);

  return (
    <>
      <Accordion
        focusContent={false}
        disableToggle={sale.draftStep ? sale.draftStep < step : true}
        title={`${step + 1} • Clients & Notaires`}
        open={active}
        setOpen={goToStep}
      >
        {active ? (
          <>
            <div className="client-notaries-step">
              <div className="sellers-notaries">
                <>
                  <Text color="primary" font="roboto-slab" size="small">
                    Vendeur(s) et notaire(s)
                  </Text>
                  {renderClientsAndNotaries({
                    target: "sellers",
                    type: "seller",
                  })}
                  <AddClientAndNotary
                    target="sellers"
                    type="seller"
                    label="Plusieurs vendeurs ?"
                    setFields={setFields}
                  />
                </>
              </div>
              <div className="buyers-notaries">
                <>
                  <Text color="primary" font="roboto-slab" size="small">
                    Acquéreur(s) et notaire(s)
                  </Text>
                  {renderClientsAndNotaries({
                    target: "buyers",
                    type: "buyer",
                  })}
                  <AddClientAndNotary
                    target="buyers"
                    type="buyer"
                    label="Plusieurs acquéreurs ?"
                    setFields={setFields}
                  />
                </>
              </div>
            </div>
            <StepActions>
              <IconButton
                uppercase
                {...(!isEdit && { icon: "chevron-right" })}
                textPosition="left"
                appearance="primary"
                onClick={() =>
                  isValid
                    ? handleNextStep()
                    : triggerValidation().then((isValidAfterValidation) => {
                        if (isValidAfterValidation) {
                          return handleNextStep();
                        }
                      })
                }
              >
                {isEdit
                  ? "Enregistrer les modifications"
                  : "Enregistrer & continuer"}
              </IconButton>
            </StepActions>
          </>
        ) : null}
      </Accordion>
      <NewClientDialog
        open={dialogsState.client.open}
        setOpen={(open) => setDialogOpen("client", open)}
        addNewClientToList={addNewClientToList}
        setValue={setValue}
        target={dialogsState.client.target}
      />
      <NewNotaryDialog
        open={dialogsState.notary.open}
        setOpen={(open) => setDialogOpen("notary", open)}
        addNewNotaryToList={addNewNotaryToList}
        setValue={setValue}
        target={dialogsState.notary.target}
      />
      <EditClientDialog
        open={dialogsState.editClient.open}
        setOpen={(open) => setDialogOpen("editClient", open)}
        updateClientList={updateClientList}
        setValue={setValue}
        target={dialogsState.editClient.target}
        clientId={dialogsState.editClient.entityId}
      />
      <EditNotaryDialog
        open={dialogsState.editNotary.open}
        setOpen={(open) => setDialogOpen("editNotary", open)}
        updateNotaryList={updateNotaryList}
        setValue={setValue}
        target={dialogsState.editNotary.target}
        notaryId={dialogsState.editNotary.entityId}
      />
    </>
  );
};

export default ClientsAndNotariesStep;
