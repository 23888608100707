import React from "react";
import PropTypes from "prop-types";

const DEFAULT_ERROR_MESSAGE =
  "Une erreur est survenue à la récupération de vos ventes. Veuillez ré-essayer ou contacter le support";

const ErrorMessage = ({ customMessage = "" }) => {
  const message = customMessage || DEFAULT_ERROR_MESSAGE;

  return <div className="error-message">{message}</div>;
};

ErrorMessage.propTypes = {
  customMessage: PropTypes.string,
};

export default ErrorMessage;
