import * as yup from "yup";
import {
  FEES_TO_BE_PAID,
  SALE_TYPES,
} from "components/Sale/SaleMultistep/saleConstants";
const { SELLER_BUYER } = FEES_TO_BE_PAID;
const { INTER_AGENCY } = SALE_TYPES;

const REQUIRED_DEFAULT_MESSAGE =
  "Merci de remplir le(s) champ(s) encadré(s) en rouge.";

yup.addMethod(yup.string, "isBetween", function ({ min, max, message }) {
  return this.test(
    "isBetween",
    message,
    (value) => Number(value) >= min && Number(value <= max)
  );
});

const validationSchema = ({ isMasterUser, saleType }) => {
  return yup.object({
    mandateType: yup.string().required(REQUIRED_DEFAULT_MESSAGE),
    mandateNumber: yup.string().required(REQUIRED_DEFAULT_MESSAGE),
    mandateDate: yup.string().required(REQUIRED_DEFAULT_MESSAGE),
    feeToBePaid: yup.string().required(REQUIRED_DEFAULT_MESSAGE),
    percentageSeller: yup.string().when("feeToBePaid", {
      is: (feeToBePaid) => feeToBePaid === SELLER_BUYER.value,
      then: yup
        .string()
        .test(
          "total-is-100%",
          "La somme des pourcentages vendeur et acquéreur doit être égale à 100.",
          (value, context) => {
            const { percentageBuyer } = context.parent;
            return Number(percentageBuyer) + Number(value) === 100;
          }
        )
        .isBetween({
          min: 0,
          max: 100,
          message: "Le pourcentage vendeur doit être compris entre 0 et 100.",
        }),
    }),
    percentageBuyer: yup.string().when("feeToBePaid", {
      is: (feeToBePaid) => feeToBePaid === SELLER_BUYER.value,
      then: yup
        .string()
        .test(
          "total-is-100%",
          "La somme des pourcentages vendeur et acquéreur doit être égale à 100.",
          (value, context) => {
            const { percentageSeller } = context.parent;
            return Number(percentageSeller) + Number(value) === 100;
          }
        )
        .isBetween({
          min: 0,
          max: 100,
          message: "Le pourcentage acquéreur doit être compris entre 0 et 100.",
        }),
    }),
    netSellingPrice: yup
      .string()
      .test(
        "is-positive",
        "Le prix de vente net vendeur être supérieur à 0",
        (value) => value > 0
      )
      .required(REQUIRED_DEFAULT_MESSAGE),
    fees: yup
      .string()
      .test(
        "is-positive",
        "Les honoraires TTC doivent être supérieurs 0",
        (value) => value > 0
      )
      .required(REQUIRED_DEFAULT_MESSAGE),
    ...(saleType === INTER_AGENCY && {
      partnerAgencyFees: yup
        .string()
        .test(
          "is-positive",
          "Les honoraires TTC doivent être supérieurs 0",
          (value) => value > 0
        )
        .required(REQUIRED_DEFAULT_MESSAGE),
    }),
    financingMethod: yup.string().required(REQUIRED_DEFAULT_MESSAGE),
    ...(isMasterUser && {
      agencyFees: yup.string().isBetween({
        min: 0,
        max: 100,
        message:
          "Le pourcentage des honoraires d'agence doit être compris entre 0 et 100.",
      }),
      sellerAgent: yup.string(),
      sellerAgentFees: yup.string().when("sellerAgent", {
        is: (sellerAgent) => sellerAgent !== "",
        then: yup
          .string()
          .test(
            "total-is-100%",
            "La somme des pourcentages des honoraires d'agence, d'agent vendeur et d'agent acquéreur doit être égale à 100.",
            (value, context) => {
              const { agencyFees, buyerAgentFees } = context.parent;
              return (
                Number(agencyFees) + Number(buyerAgentFees) + Number(value) ===
                100
              );
            }
          )
          .isBetween({
            min: 0,
            max: 100,
            message:
              "Le pourcentage des honoraires de l'agent vendeur doit être compris entre 0 et 100.",
          }),
      }),
      buyerAgent: yup.string(),
      buyerAgentFees: yup.string().when("buyerAgent", {
        is: (buyerAgent) => buyerAgent !== "",
        then: yup
          .string()
          .test(
            "total-is-100%",
            "La somme des pourcentages des honoraires d'agence, d'agent vendeur et d'agent acquéreur doit être égale à 100.",
            (value, context) => {
              const { agencyFees, sellerAgentFees } = context.parent;

              return (
                Number(agencyFees) + Number(sellerAgentFees) + Number(value) ===
                100
              );
            }
          )
          .isBetween({
            min: 0,
            max: 100,
            message:
              "Le pourcentage des honoraires de l'agent acquéreur doit être compris entre 0 et 100.",
          }),
      }),
    }),
  });
};

export default validationSchema;
