import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "components/Card";
import Icon from "components/Icon";
import { IconButton } from "components/Button";
import Text from "components/Text";

const SaleProcessCard = ({
  title,
  handleHelpClick,
  isAlert,
  children,
  actions,
}) => {
  const getIconColor = () => {
    if (isAlert) {
      return "tertiary";
    }

    return "grey";
  };

  return (
    <div className={"sale-process-card"}>
      <Card>
        {handleHelpClick && (
          <div
            className={classNames("sale-process-card__button", {
              "sale-process-card__button--alert": isAlert,
            })}
          >
            <IconButton
              iconColor={getIconColor}
              icon="outline-question-circle"
              iconSize="medium"
              onClick={handleHelpClick}
            />
          </div>
        )}
        {title && (
          <>
            <Text uppercase align="center" fontWeight="bold" font="roboto-slab">
              {title}
            </Text>
            <Icon icon="ripple" size="big" />
          </>
        )}
        <div className="sale-process-card__content-container">
          <div className="sale-process-card__content">{children}</div>
          {actions && (
            <div className="sale-process-card__actions">{actions}</div>
          )}
        </div>
      </Card>
    </div>
  );
};

SaleProcessCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleHelpClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  state: PropTypes.oneOf(["complete", "alert"]),
  isAlert: PropTypes.bool.isRequired,
};

export default SaleProcessCard;
