import React from "react";
import fetchUrl from "api/fetchUrl";
import { getAgency } from "api/requests";
import GenericLayout from "components/GenericLayout";
import AnimatedTabs from "components/AnimatedTabs";
import General from "components/UserArea/General";
import Formula from "components/UserArea/Formula";
import Security from "components/UserArea/Security";
import Contact from "components/UserArea/Contact";
import { useAuth } from "components/Hooks/useAuth";
import ErrorMessage from "components/ErrorMessage";

const UserArea = () => {
  const {
    user: {
      people: {
        agency: { id: agencyId },
      },
    },
  } = useAuth();

  const [agency, setAgency] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const tabs = [
    {
      label: "Général",
      component: General,
    },
    {
      label: "Formule",
      component: Formula,
    },
    {
      label: "Sécurité",
      component: Security,
    },
    {
      label: "Contact",
      component: Contact,
    },
  ];
  const [currentTab, setCurrentTab] = React.useState(0);

  const CurrentComponent = tabs[currentTab].component;

  React.useEffect(() => {
    fetchUrl(getAgency(agencyId))
      .then((agency) => {
        setAgency(agency);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsLoading(false));
  }, [agencyId]);

  return (
    <GenericLayout
      headerIcon="lock-open"
      headerTitle="Abonnement & factures"
      headerContent={
        <h1 className="header-content__settings-title">Votre espace client</h1>
      }
    >
      <AnimatedTabs
        currentTab={currentTab}
        tabs={tabs.map((tab, index) => ({
          label: tab.label,
          onClick: () => setCurrentTab(index),
        }))}
      />
      {!isLoading && !error && (
        <CurrentComponent agency={agency} setCurrentTab={setCurrentTab} />
      )}
      {!isLoading && error && (
        <ErrorMessage customMessage="Une erreur est survenue lors de la récupération de vos informations" />
      )}
    </GenericLayout>
  );
};

export default UserArea;
