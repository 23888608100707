import * as yup from "yup";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Le mot de passe doit faire 8 caractères au minimum.")
    .required("Le mot de passe est requis."),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Le mot de passe et le mot de passe de confirmation doivent être identique."
    ),
});
export default validationSchema;
