import React from "react";
import { useFormContext } from "react-hook-form";
import { InputNumber } from "components/Form/Input";
import Select from "components/Form/Select";
import Text from "components/Text";
import roundToTwoDecimals from "utils/roundToTwoDecimals";
import {
  PEOPLE_PREFIX,
  TAX_RATE,
} from "components/Sale/SaleMultistep/saleConstants";

const FeesWrapper = ({ children, allTaxesIncluded, excludingTax }) => {
  return (
    <div className="fees-wrapper">
      {children}
      <div className="repartition">
        <div className="repartition__block">
          <div>CA vente TTC</div>
          {`${allTaxesIncluded} €`}
        </div>
        <div className="repartition__block">
          <div>CA vente HT</div>
          {`${excludingTax} €`}
        </div>
      </div>
    </div>
  );
};

const AgencyFeesDistribution = ({ peoples, disableAgencyFees }) => {
  const { values, setValue, trigger: triggerValidation } = useFormContext();
  const {
    fees,
    agencyFees,
    sellerAgent,
    sellerAgentFees,
    buyerAgent,
    buyerAgentFees,
  } = values;

  const peopleOptions = peoples
    .filter((people) => people.isAUser)
    .map(({ id, firstname, lastname }) => ({
      value: `${PEOPLE_PREFIX}${id}`,
      label: `${firstname} ${lastname}`,
    }));

  const _fees = Number(fees);

  const agencyFeesATI = roundToTwoDecimals(
    Number(_fees) * (Number(agencyFees) / 100) || 0
  );

  const agencyFeesET = roundToTwoDecimals(agencyFeesATI / TAX_RATE);

  const sellerAgentFeesATI =
    roundToTwoDecimals(Number(_fees) * (Number(sellerAgentFees) / 100)) || 0;
  const sellerAgentFeesET = roundToTwoDecimals(sellerAgentFeesATI / TAX_RATE);

  const buyerAgentFeesATI =
    roundToTwoDecimals(Number(_fees) * (Number(buyerAgentFees) / 100)) || 0;
  const buyerAgentFeesET = roundToTwoDecimals(buyerAgentFeesATI / TAX_RATE);

  React.useEffect(() => {
    const _sellerAgentFees = Number(sellerAgentFees) || 0;
    const _buyerAgentFees = Number(buyerAgentFees) || 0;
    const _agencyFees = roundToTwoDecimals(
      100 - _sellerAgentFees - _buyerAgentFees
    );
    setValue("agencyFees", _agencyFees);
    triggerValidation(["sellerAgentFees", "buyerAgentFees", "agencyFees"]);
  }, [sellerAgentFees, buyerAgentFees, setValue, triggerValidation]);

  React.useEffect(() => {
    if (!sellerAgent) {
      setValue("sellerAgentFees", 0);
    }
    if (!buyerAgent) {
      setValue("buyerAgentFees", 0);
    }
  }, [sellerAgent, buyerAgent, setValue]);

  return (
    <div className="agency-fees-distribution">
      <Text>Honoraires agence</Text>
      <FeesWrapper allTaxesIncluded={agencyFeesATI} excludingTax={agencyFeesET}>
        <InputNumber
          readOnly
          name="agencyFees"
          suffix={{ icon: "percentage", text: "agence" }}
          min={0}
          max={100}
        />
      </FeesWrapper>

      <Text>Honoraires agent acquéreur (vente)</Text>
      <Select
        allowEmpty
        readOnly={disableAgencyFees}
        name="buyerAgent"
        options={peopleOptions}
        placeholder="Agent acquéreur"
      />
      <FeesWrapper
        allTaxesIncluded={buyerAgentFeesATI}
        excludingTax={buyerAgentFeesET}
      >
        <InputNumber
          readOnly={!buyerAgent || disableAgencyFees}
          name="buyerAgentFees"
          suffix={{ icon: "percentage", text: "vente" }}
          min={0}
          max={100}
          defaultValue={0}
        />
      </FeesWrapper>

      <Text>Honoraires agent propriétaire (mandat)</Text>
      <Select
        allowEmpty
        readOnly={disableAgencyFees}
        name="sellerAgent"
        options={peopleOptions}
        placeholder="Agent vendeur"
      />
      <FeesWrapper
        allTaxesIncluded={sellerAgentFeesATI}
        excludingTax={sellerAgentFeesET}
      >
        <InputNumber
          readOnly={!sellerAgent || disableAgencyFees}
          name="sellerAgentFees"
          suffix={{ icon: "percentage", text: "mandat" }}
          min={0}
          max={100}
          defaultValue={0}
        />
      </FeesWrapper>
    </div>
  );
};

export default AgencyFeesDistribution;
