import React from "react";
import { useAuth } from "../Hooks/useAuth";
import IconButton from "../Button/IconButton";

const Logout = () => {
  const { logout } = useAuth();

  return (
    <span className="logout">
      <IconButton icon="logout" onClick={logout} />
    </span>
  );
};

export default Logout;
