import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "components/Dialog";
import Icon from "components/Icon";
import Text from "components/Text";
import Accordion from "components/Accordion";
import Select from "components/Form/Select";
import Form from "components/Form";
import Button from "components/Button";
import { FILE_TYPES } from "components/Sale/SaleMultistep/saleConstants";
import FileUploader from "components/FileUploader";
import { deleteDocument, postDocument } from "api/requests";
import fetchUrl from "api/fetchUrl";
import { useAuth } from "components/Hooks/useAuth";
import { InputCheckbox } from "components/Form/Input";
import { IconButton } from "components/Button";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import { notifyError, notifySuccess } from "utils/notify";
import convertBytesToMb from "utils/convertBytesToMb";
import { format } from "utils/date";
import {
  FILES_MAX_SIZE_IN_BYTES,
  FILE_MAX_SIZE_IN_BYTES,
  UPLOAD_STATES,
} from "components/AttachmentUploader/AttachmentUploader";

const {
  COMPROMISE_PROMISE,
  DIAGNOSIS_EXPERTISE,
  STATEMENT_OF_CHARGES,
  FINANCING,
  OFFER_TO_PURCHASE,
  RULES_CO_OWNERSHIP,
  PROPERTY_TAX,
  TITLE_DEEDS,
  OTHER_DOCUMENTS,
  GENERAL_MEETINGS_MINUTES,
} = FILE_TYPES;

const CheckboxLabel = ({ label }) => {
  return (
    <div className="checkbox-label">
      <Icon icon="paper" color={"primary"} />
      <label>{label}</label>
    </div>
  );
};

const DocumentModule = ({ open, handleClose, sale }) => {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    React.useState(false);
  const [deletedDocumentId, setDeletedDocumentId] = React.useState(null);
  const { setMailModuleOpen } = useSaleModules();
  const { user } = useAuth();
  const initialDocuments = sale.documents;
  const defaultValues = React.useMemo(
    () =>
      initialDocuments.reduce(
        (acc, document) => ({ ...acc, [document.id]: false }),
        {}
      ),
    [initialDocuments]
  );

  const [documents, setDocuments] = React.useState(initialDocuments);
  const [documentListOpen, setDocumentListOpen] = React.useState(false);

  const processFile = (file, fileType) => {
    const newDocument = {
      file,
      sale: sale.id,
      type: fileType,
      uploadedBy: user.people.id,
      isPhysical: false,
    };
    return fetchUrl(postDocument(newDocument))
      .then((response) => {
        setDocuments((previousDocuments) => [...previousDocuments, response]);
        notifySuccess(`Document(s) ajouté(s) avec succès.`);
        if (documents?.length) {
          setDocumentListOpen(true);
        }
      })
      .catch(() => {
        notifyError("Une erreur est survenue lors de l'ajout d'un document.");
      });
  };

  const handleDeleteDocument = (documentId, setValue) => {
    /** In case this function is called directly we set the value to false to prevent undesired behavior,
     * this is a quick fix waiting for more advanced solution
     */
    if (typeof setValue === "function") {
      setValue(String(documentId), false);
    }

    setDocuments((previousDocuments) =>
      previousDocuments.filter((document) => document.id !== Number(documentId))
    );

    fetchUrl(deleteDocument(documentId))
      .then(() => {
        setDeletedDocumentId(null);
        setIsDeleteConfirmationModalOpen(false);
        notifySuccess(`Document supprimé avec succès.`);
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de la suppression du document."
        );
      });
  };

  const handleCheckAll = (reset) => {
    const allCheckedState = documents.reduce(
      (acc, document) => ({ ...acc, [`${document.id}`]: true }),
      {}
    );
    reset(allCheckedState);
  };

  const handleDownloadSelection = (values) => {
    const selection = Object.keys(values).filter((key) => values[key]);

    if (!selection.length) {
      return;
    }

    const documentsById = documents.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );

    const downloadNext = (i) => {
      if (i >= selection.length) {
        return;
      }

      const documentId = selection[i];

      const a = document.createElement("a");
      a.href = `${process.env.REACT_APP_API_ENDPOINT}${documentsById[documentId].contentUrl}`;
      a.target = "_parent";
      // Use a.download if available, it prevents plugins from opening.
      if ("download" in a) {
        a.download = `${process.env.REACT_APP_API_ENDPOINT}${documentsById[documentId].name}`;
      }
      // Add a to the doc for click to work.
      (document.body || document.documentElement).appendChild(a);

      a.click(); // The click method is supported by most browsers.

      // Delete the temporary link.
      a.parentNode.removeChild(a);

      // Download the next file with a small timeout. The timeout is necessary
      // for IE, which will otherwise only download the first file.
      setTimeout(function () {
        downloadNext(i + 1);
      }, 500);
    };
    // Initiate the first download.
    downloadNext(0);
  };

  const handleShareSelection = (values) => {
    /** Open mail module and inject checked files IDs */
    const selection = Object.keys(values).filter((key) => values[key]);
    const documentsById = documents.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );

    const totalSizeInBytes = selection.reduce(
      (totalSizeInBytes, documentId) =>
        (totalSizeInBytes += documentsById[documentId].sizeInBytes),
      0
    );

    if (totalSizeInBytes > FILES_MAX_SIZE_IN_BYTES) {
      notifyError(
        "La taille maximale cumulée de tous les fichiers ne peut pas excéder 15 Mo."
      );
      return;
    }

    const formattedAttachment = selection.map((documentId) => {
      return {
        id: `physical_${documentId}`,
        documentId,
        name: documentsById[documentId].name,
        sizeInBytes: documentsById[documentId].sizeInBytes,
        sizeInMb: convertBytesToMb(documentsById[documentId].sizeInBytes),
        state: UPLOAD_STATES.uploaded,
      };
    });

    setMailModuleOpen({
      open: true,
      template: "freeMail",
      attachments: formattedAttachment,
    });
    handleClose();
  };

  const handleShareDocument = (document) => {
    if (document.sizeInBytes > FILE_MAX_SIZE_IN_BYTES) {
      notifyError(
        `Le fichier ${document.name} est trop volumineux (maximum 15 Mo).`
      );
      return;
    }

    setMailModuleOpen({
      open: true,
      template: "freeMail",
      attachments: [
        {
          id: `physical_${document.id}`,
          documentId: document.id,
          name: document.name,
          sizeInBytes: document.sizeInBytes,
          sizeInMb: convertBytesToMb(document.sizeInBytes),
          state: UPLOAD_STATES.uploaded,
        },
      ],
    });
    handleClose();
  };

  const handleDeleteSelection = (values, reset) => {
    const selection = Object.keys(values).filter((key) => values[key]);
    selection.forEach((documentId) => handleDeleteDocument(documentId));
    setIsDeleteConfirmationModalOpen(false);
    reset({});
  };

  return (
    <>
      <Dialog
        open={open}
        handleClose={handleClose}
        className="document-module-dialog"
      >
        <div className="document-module-dialog__header">
          <Icon icon="document-download" color="primary" />
          <div className="document-module-dialog__title">
            Documents de la vente
          </div>
        </div>
        <DialogContent>
          <Form>
            {({ values }) => (
              <>
                <Select
                  name="fileType"
                  placeholder="Ajouter un document"
                  options={[
                    COMPROMISE_PROMISE,
                    DIAGNOSIS_EXPERTISE,
                    STATEMENT_OF_CHARGES,
                    FINANCING,
                    OFFER_TO_PURCHASE,
                    RULES_CO_OWNERSHIP,
                    PROPERTY_TAX,
                    TITLE_DEEDS,
                    OTHER_DOCUMENTS,
                    GENERAL_MEETINGS_MINUTES,
                  ]}
                />

                {values.fileType && (
                  <>
                    <Text align="center">
                      Vous pouvez selectionner et importer plusieurs fichiers en
                      même temps
                    </Text>
                    <div className="document-module-dialog__download-actions">
                      <Button onClick={() => handleClose()}>Annuler</Button>
                      <FileUploader
                        multiple
                        process={(file) => processFile(file, values.fileType)}
                        label="importer des fichiers"
                        fileComponent={
                          <Button fullWidth appearance="primary">
                            Importer des fichiers
                          </Button>
                        }
                      />
                    </div>
                  </>
                )}
                <Icon icon="ripple" color="primary" size="extra-big" />
              </>
            )}
          </Form>
          <Accordion
            open={documentListOpen}
            setOpen={setDocumentListOpen}
            title="Liste des documents ajoutés"
          >
            <Form defaultValues={defaultValues}>
              {({ values, setValue, reset }) => (
                <>
                  {Object.keys(values).some((valueKey) => values[valueKey]) && (
                    <div className="document-selection-actions">
                      <IconButton
                        uppercase
                        fullWidth
                        icon="download"
                        iconSize="medium"
                        appearance="reversed-primary"
                        textPosition="left"
                        onClick={() => handleDownloadSelection(values)}
                      >
                        Télécharger sélection
                      </IconButton>
                      <IconButton
                        uppercase
                        fullWidth
                        icon="letter-close"
                        appearance="reversed-primary"
                        textPosition="left"
                        onClick={() => handleShareSelection(values)}
                      >
                        Partager sélection
                      </IconButton>
                      <IconButton
                        uppercase
                        fullWidth
                        icon="bin"
                        appearance="reversed-primary"
                        textPosition="left"
                        iconSize="small"
                        onClick={() => setIsDeleteConfirmationModalOpen(true)}
                      >
                        Supprimer sélection
                      </IconButton>
                    </div>
                  )}
                  <div className="document-list">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <Button
                              italic
                              uppercase={false}
                              appearance="naked"
                              textSize="mini"
                              onClick={() => handleCheckAll(reset)}
                            >
                              Tout cocher
                            </Button>
                          </th>
                          <th>Taille</th>
                          <th>Créé le</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents.map((document) => (
                          <tr key={document.id} className="document">
                            <td className="document__checkbox">
                              <InputCheckbox
                                name={`${document.id}`}
                                label={<CheckboxLabel label={document.name} />}
                              />
                            </td>
                            <td className="document__size">{`${convertBytesToMb(
                              document.sizeInBytes
                            ).toFixed(2)} Mo`}</td>
                            <td className="document__created-at">
                              {format(new Date(document.createdAt), "dd/MM/yy")}
                            </td>
                            <td className="document__type">
                              {FILE_TYPES[document.type].label}
                            </td>
                            <td className="document__actions">
                              <IconButton
                                icon="letter-close"
                                size="auto"
                                iconColor="primary"
                                appearance="transparent"
                                onClick={() => handleShareDocument(document)}
                              />
                              <a
                                href={`${process.env.REACT_APP_API_ENDPOINT}${document.contentUrl}`}
                                download={document.name}
                              >
                                <IconButton
                                  icon="download"
                                  iconSize="medium"
                                  iconColor="primary"
                                  size="auto"
                                  appearance="transparent"
                                />
                              </a>
                              <IconButton
                                icon="bin"
                                iconSize="small"
                                iconColor="medium-grey"
                                size="auto"
                                appearance="transparent"
                                onClick={() => {
                                  setIsDeleteConfirmationModalOpen(true);
                                  setDeletedDocumentId(document.id);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Dialog
                    className="delete-confirmation-dialog"
                    open={isDeleteConfirmationModalOpen}
                    handleClose={() => setIsDeleteConfirmationModalOpen(false)}
                  >
                    <DialogTitle>Confirmation Suppression</DialogTitle>
                    <DialogContent>
                      <Text fontWeight="semi-bold" align="center">
                        Voulez-vous <b>supprimer</b> les documents sélectionnés
                        ?
                      </Text>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setIsDeleteConfirmationModalOpen(false)}
                        appearance="reversed-primary"
                      >
                        Annuler
                      </Button>
                      <Button
                        onClick={() => {
                          deletedDocumentId
                            ? handleDeleteDocument(deletedDocumentId, setValue)
                            : handleDeleteSelection(values, reset);
                        }}
                        appearance="primary"
                      >
                        Oui
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Form>
          </Accordion>
        </DialogContent>
      </Dialog>
    </>
  );
};

DocumentModule.propTypes = {};

export default DocumentModule;
