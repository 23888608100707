import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import { Dialog, DialogTitle } from "components/Dialog";
import Icon from "components/Icon";
import Text from "components/Text";
import UserDialogForm from "../UserDialogForm";
import validationSchema from "../validationSchema";

const EditUserDialog = ({
  user,
  open,
  isCurrentUser,
  setOpen,
  handleSubmit,
  handleActivate,
}) => {
  if (
    !user ||
    (user &&
      Object.keys(user).length === 0 &&
      Object.getPrototypeOf(user) === Object.prototype)
  ) {
    return null;
  }
  const isUserActive = user.status === 1;
  const handleClose = () => {
    setOpen(false);
  };

  const fontColor = isUserActive ? "primary" : "disabled";
  const iconColor = isUserActive ? "primary" : "grey";

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="user-crud-dialog edit-user-dialog"
    >
      <DialogTitle>
        <Icon icon="user" color={iconColor} size="big" />
        <Text
          color={fontColor}
          size="medium"
          fontWeight="bold"
          font="roboto-slab"
        >
          {user.firstname} {user.lastname.toUpperCase()}
        </Text>{" "}
        {!isCurrentUser && (
          <div className="edit-user-dialog__user-status">
            {isUserActive ? (
              <>
                Utilisateur actif |{" "}
                <Button
                  noPadding={true}
                  uppercase={false}
                  boxShadow={false}
                  onClick={() => {
                    handleActivate(false, user.id);
                  }}
                >
                  Désactiver
                </Button>{" "}
              </>
            ) : (
              <>
                Utilisateur inactif |{" "}
                <Button
                  noPadding={true}
                  uppercase={false}
                  boxShadow={false}
                  onClick={() => {
                    handleActivate(true, user.id);
                  }}
                >
                  Réactiver
                </Button>
              </>
            )}
          </div>
        )}
      </DialogTitle>
      <UserDialogForm
        user={user}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

EditUserDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleActivate: PropTypes.func.isRequired,
};
export default EditUserDialog;
