import React from "react";
import LoginForm from "components/Login/LoginForm";
import LoginHeader from "components/Login/LoginHeader";

const Login = () => {
  return (
    <div className="login">
      <LoginHeader />
      <LoginForm />
    </div>
  );
};

export default Login;
