import React from "react";
import Card from "components/Card";
import Icon from "components/Icon";
import { IconButton } from "components/Button";
import PropTypes from "prop-types";

const AddUserCard = ({ setOpenAddUserDialog }) => {
  const handleAddUser = () => {
    setOpenAddUserDialog(true);
  };
  return (
    <Card className="user-card add-user-card">
      <div className="user-card__content">
        <Icon icon="user" color="primary" size="big" />
        <IconButton
          uppercase
          appearance="primary"
          icon="plus"
          textPosition="left"
          onClick={handleAddUser}
        >
          Ajouter un utilisateur
        </IconButton>
      </div>
    </Card>
  );
};

AddUserCard.propTypes = {
  setOpenAddUserDialog: PropTypes.func.isRequired,
};

export default AddUserCard;
