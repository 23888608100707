import React from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
import { format } from "utils/date";
import { Dialog, DialogContent, DialogActions } from "components/Dialog";
import { InputCheckbox } from "components/Form/Input";
import SubmitButton from "components/Form/SubmitButton";
import Form from "components/Form";
import Button from "components/Button";
import Divider from "components/Divider";
import Icon from "components/Icon";
import PlanningCalendar from "./PlanningCalendar";
import { IconButton } from "components/Button";
import Text from "components/Text";

import breakpoints from "components/Breakpoints";

import usePlanning from "./usePlanning";
import { getInitialDefaultValues, OWNER_TYPES } from "./planningUtils";

const localizer = momentLocalizer(moment);

const baseConfig = {
  /* 08h00 to 20h00 */
  min: new Date(0, 0, 0, 8, 0, 0),
  max: new Date(0, 0, 0, 20, 0, 0),
};

const SelectOwnersDialog = ({
  open,
  handleClose,
  handleSelectOwners,
  defaultValues,
  handleDeleteEvent,
  currentlyEditedEvent,
}) => {
  const handleCheckAll = (setValue) => {
    OWNER_TYPES.map(({ id }) => setValue(id, true));
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="select-owners-dialog"
    >
      <Form
        mode="all"
        onSubmit={handleSelectOwners}
        defaultValues={defaultValues}
      >
        {({ values, setValue }) => (
          <>
            <DialogContent>
              <Button
                italic
                uppercase={false}
                appearance="naked"
                textSize="mini"
                onClick={() => handleCheckAll(setValue)}
              >
                Tout cocher
              </Button>
              <Divider />
              <div className="select-owners-dialog__owners">
                {OWNER_TYPES.map(({ id, label }) => (
                  <InputCheckbox name={id} label={label} />
                ))}
              </div>
            </DialogContent>

            <DialogActions>
              {currentlyEditedEvent && (
                <Button
                  appearance="ghost"
                  onClick={() => {
                    handleDeleteEvent(currentlyEditedEvent.id);
                    handleClose();
                  }}
                >
                  Supprimer
                </Button>
              )}

              <SubmitButton
                fullWidth
                /** If at least one checkbox is checked we can submit the event */
                disabled={Object.keys(values).every((key) => !values[key])}
                appearance="primary"
              >
                Valider
              </SubmitButton>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

const OwnerTag = ({ ownerTag }) => {
  return <span className="owner-tag">{ownerTag}</span>;
};

const CustomEvent = ({ event, ownerTags }) => {
  return (
    <div className="custom-event">
      <div className="owners">
        {event.owners.map((owner, index) => {
          return (
            <OwnerTag
              key={`owner_tag_${index}_${event.id}`}
              ownerTag={ownerTags[owner]}
            />
          );
        })}
      </div>
    </div>
  );
};

const PlanningModuleToolbar = ({ onNavigate, isMobile, currentDate }) => {
  return (
    <div className="planning-module__toolbar">
      <IconButton icon="chevron-left" onClick={() => onNavigate("PREV")} />
      {isMobile && <Text>{format(currentDate, "EEE d MMM")}</Text>}
      <IconButton icon="chevron-right" onClick={() => onNavigate("NEXT")} />
    </div>
  );
};

const PlanningModule = ({ open, handleClose, sale }) => {
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= breakpoints.tablet - 1
  );

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= breakpoints.tablet - 1);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const {
    currentDate,
    setCurrentDate,
    handleDateChange,
    events,
    defaultValues,
    setDefaultValues,
    availabilities,
    ownerTags,
    handleSelectOwners,
    handleEditSelectedOwners,
    currentlyEditedEvent,
    setCurrentlyEditedEvent,
    selectOwnersDialogOpen,
    setSelectOwnersDialogOpen,
    calendarEvents,
    handleSelectSlot,
    handleDeleteEvent,
  } = usePlanning({
    sale,
    isMobile,
  });

  const getEventClassName = (event) => {
    const allOwnersAreAvailableDuringThisSlot =
      event.owners.length === OWNER_TYPES.length;

    return {
      ...(allOwnersAreAvailableDuringThisSlot && { className: "common-slot" }),
    };
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="planning-module-dialog"
    >
      <div className="planning-module-dialog__header">
        <Icon icon="planning" color="primary" />
        <div className="planning-module-dialog__title">Planning</div>
      </div>
      <DialogContent>
        <div className="planning-module-dialog__left-block">
          <PlanningCalendar
            date={currentDate}
            handleDateChange={handleDateChange}
            events={calendarEvents}
          />
          <div className="planning-module-dialog__availabilities">
            {availabilities.length ? (
              <>
                <div className="planning-module-dialog__availabilities-text">
                  <Text uppercase color="primary">
                    Dates de <br />
                    <b>
                      disponibilités <br />
                      communes
                    </b>
                  </Text>
                </div>

                <div className="planning-module-dialog__availabilities-list">
                  {availabilities.map((availability, index) => (
                    <div
                      key={`availability_${index}`}
                      className="planning-module-dialog__availability-item"
                    >
                      <Icon icon="smile" color="primary" />
                      <Text size="small" fontWeight="semi-bold">
                        {format(availability, "EEE d MMM H'h'mm")}
                      </Text>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="planning-module-dialog__availabilities-text">
                <Text uppercase color="primary">
                  <b>
                    Enregistrez des <br /> disponibilités
                  </b>
                </Text>
                <Text uppercase>
                  <b>
                    Pour trouver <br />
                    rapidement les <br />
                    disponibilités <br /> communes !
                  </b>
                </Text>
              </div>
            )}
          </div>
        </div>
        <div id="calendar" className="planning-module-dialog__right-block">
          <Calendar
            selectable
            date={currentDate}
            onNavigate={(date) => setCurrentDate(date)}
            culture="fr"
            localizer={localizer}
            events={events}
            defaultView={isMobile ? Views.DAY : Views.WEEK}
            view={isMobile ? Views.DAY : Views.WEEK}
            onSelectSlot={handleSelectSlot}
            components={{
              event: ({ event }) => (
                <CustomEvent ownerTags={ownerTags} event={event} />
              ),
              week: {
                header: ({ label }) => {
                  const [dayNumeric, dayText] = label.split(" ");
                  return (
                    <div className="week-header">
                      <div className="week-header__day-text">{dayText}</div>
                      <div className="week-header__day-numeric">
                        {dayNumeric}
                      </div>
                    </div>
                  );
                },
              },
              toolbar: ({ onNavigate }) => (
                <PlanningModuleToolbar
                  onNavigate={onNavigate}
                  isMobile={isMobile}
                  currentDate={currentDate}
                />
              ),
            }}
            eventPropGetter={getEventClassName}
            onSelectEvent={(slotInfo) => {
              handleEditSelectedOwners(slotInfo);
            }}
            {...baseConfig}
          />
        </div>
      </DialogContent>
      <div className="planning-module-dialog__legends">
        <div className="legend">
          <div className="legend__square--grey"></div>
          <div className="legend__text">Disponibilités enregistrées</div>
        </div>
        <div className="legend">
          <div className="legend__square--primary"></div>
          <div className="legend__text">Disponibilités communes</div>
        </div>
      </div>
      <SelectOwnersDialog
        open={selectOwnersDialogOpen}
        handleClose={() => {
          // Clear currently edited event to avoid side effects
          if (currentlyEditedEvent) {
            setCurrentlyEditedEvent(null);
            setDefaultValues(getInitialDefaultValues());
          }
          setSelectOwnersDialogOpen(false);
        }}
        handleSelectOwners={handleSelectOwners}
        defaultValues={defaultValues}
        currentlyEditedEvent={currentlyEditedEvent}
        handleDeleteEvent={handleDeleteEvent}
      />
    </Dialog>
  );
};

PlanningModule.propTypes = {};

export default PlanningModule;
