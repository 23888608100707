import React from "react";
import Form from "components/Form";
import { InputDate, InputTime } from "components/Form/Input";
import Button from "components/Button";
import { getHoursAndMinutesFromDate } from "utils/date";
import SubmitButton from "components/Form/SubmitButton";
import validationSchema from "./validationSchema";
import { Dialog, DialogActions, DialogTitle } from "components/Dialog";
import Text from "components/Text";
import Icon from "components/Icon";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import { getAvailableRecipients, TEMPLATE_REFERENCES } from "utils/email";

const SignPlanningDialog = ({ open, setOpen, isInterAgency }) => {
  const { setMailModuleOpen, setPlanningModuleOpen } = useSaleModules();

  return (
    <Dialog
      open={open}
      handleClose={() => setOpen(false)}
      className="sign-planning-dialog"
    >
      <DialogTitle>
        <Text uppercase fontWeight="semi-bold" font="roboto-slab">
          Planification de l'avant-contrat
        </Text>
      </DialogTitle>
      <DialogActions>
        <div className="sign-planning-dialog__action">
          <Text font="roboto-slab" size="mini" align="center">
            <Icon icon="emails" size="extra-big" color="primary" />
            <br />
            Demander les <br /> disponibilités
          </Text>
          <br />
          <Button
            appearance="reversed-primary"
            boxShadow={false}
            size="mini"
            onClick={() => {
              setOpen(false);
              setMailModuleOpen({
                open: true,
                template:
                  TEMPLATE_REFERENCES.availabilityRequestSignature.value,
                recipients: getAvailableRecipients({
                  hasPartnerAgency: isInterAgency,
                }),
              });
            }}
          >
            Demander par mail
          </Button>
        </div>
        <div className="sign-planning-dialog__action">
          <Text font="roboto-slab" size="mini" align="center">
            <Icon icon="planning" size="extra-big" color="primary" />
            <br />
            Enregistrer les <br /> disponibilités
          </Text>
          <br />
          <Button
            appearance="reversed-primary"
            boxShadow={false}
            size="mini"
            onClick={() => {
              setOpen(false);
              setPlanningModuleOpen(true);
            }}
          >
            Accéder au planning
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const SignDateFormActions = ({
  signatureDate,
  isAlert,
  defaultValues,
  values,
}) => {
  const isPristine =
    defaultValues.signDate === values.signDate &&
    defaultValues.signTime === values.signTime;

  if (signatureDate) {
    return (
      <SubmitButton
        boxShadow={false}
        appearance={isPristine ? "grey" : "primary"}
        disabled={isPristine}
      >
        Enregistrer
      </SubmitButton>
    );
  }

  return (
    <SubmitButton
      boxShadow={false}
      appearance={isAlert ? "reversed-tertiary" : "reversed-primary"}
      size="mini"
    >
      Enregistrer
    </SubmitButton>
  );
};

const SignDateForm = ({
  handleSubmit,
  signatureDate,
  signatureDateConfirmation,
  isAlert,
}) => {
  const defaultValues = signatureDate
    ? {
        signDate: new Date(signatureDate),
        signTime: getHoursAndMinutesFromDate(signatureDate),
      }
    : {};

  return (
    <Form
      mode="all"
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    >
      {({ values }) => (
        <>
          <div className="sign-date-handler__inputs">
            <InputDate
              disabled={signatureDateConfirmation}
              name="signDate"
              placeholder="Choisir date"
              appearance="grey"
            />
            <InputTime
              disabled={signatureDateConfirmation}
              name="signTime"
              placeholder="Choisir heure"
              appearance="grey"
            />
          </div>
          {!signatureDateConfirmation && (
            <SignDateFormActions
              signatureDate={signatureDate}
              isAlert={isAlert}
              defaultValues={defaultValues}
              values={values}
            />
          )}
        </>
      )}
    </Form>
  );
};

const SignDateHandler = ({
  handleSubmitSignDate,
  signatureDate,
  signatureDateConfirmation,
  isInterAgency,
  isAlert,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [signPlanningDialogOpen, setSignPlanningDialogOpen] =
    React.useState(false);
  return (
    <>
      <div className="sign-date-handler">
        {isEditing || signatureDate ? (
          <SignDateForm
            handleSubmit={handleSubmitSignDate}
            signatureDate={signatureDate}
            signatureDateConfirmation={signatureDateConfirmation}
            isAlert={isAlert}
          />
        ) : (
          <>
            <p>Vous connaissez la date de signature&nbsp;?</p>
            <br />
            <div className="sale-process-card__actions">
              <Button
                appearance={isAlert ? "reversed-tertiary" : "reversed-primary"}
                size="mini"
                boxShadow={false}
                onClick={() => setSignPlanningDialogOpen(true)}
              >
                Non, planifier !
              </Button>

              <Button
                appearance={isAlert ? "reversed-tertiary" : "reversed-primary"}
                size="mini"
                boxShadow={false}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Oui, enregistrer
              </Button>
            </div>
          </>
        )}
      </div>
      <SignPlanningDialog
        open={signPlanningDialogOpen}
        setOpen={setSignPlanningDialogOpen}
        isInterAgency={isInterAgency}
      />
    </>
  );
};

export default SignDateHandler;
