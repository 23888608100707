import React from "react";

const SaleModulesContext = React.createContext({});

const SaleModulesProvider = ({ children }) => {
  const [planningModuleOpen, setPlanningModuleOpen] = React.useState(false);

  const [mailModuleState, setMailModuleState] = React.useState({
    open: false,
    template: "",
    recipients: [],
    attachments: [],
  });

  const [documentModuleOpen, setDocumentModuleOpen] = React.useState(false);

  const [contactModuleOpen, setContactModuleOpen] = React.useState(false);

  const setMailModuleOpen = ({ open, template, recipients, attachments }) => {
    setMailModuleState((previousState) => ({
      ...previousState,
      open,
      template: template || "",
      ...(recipients && { recipients }),
      ...(attachments && { attachments }),
    }));
  };

  return (
    <SaleModulesContext.Provider
      value={{
        planningModuleOpen,
        setPlanningModuleOpen,
        mailModuleState,
        setMailModuleState,
        setMailModuleOpen,
        documentModuleOpen,
        setDocumentModuleOpen,
        contactModuleOpen,
        setContactModuleOpen,
      }}
    >
      {children}
    </SaleModulesContext.Provider>
  );
};
export { SaleModulesProvider, SaleModulesContext };

export const useSaleModules = () => React.useContext(SaleModulesContext);
