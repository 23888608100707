import React from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import Input from "components/Form/Input";

const AutoCompleteDropdown = ({
  options,
  handleChange,
  name,
  open,
  setDropdownIsOpen,
  dropdownIsHover,
}) => {
  return (
    <ul
      onMouseEnter={() => {
        dropdownIsHover.current = true;
      }}
      onMouseLeave={() => {
        dropdownIsHover.current = false;
      }}
      className={classNames("auto-complete-dropdown", {
        "auto-complete-dropdown--open": open,
      })}
    >
      {options.length > 0 ? (
        options.map((option) => (
          <li
            key={option.value}
            className="auto-complete-dropdown__item"
            onClick={() => {
              handleChange(name, option.value, option.label);
            }}
          >
            {option.label}
          </li>
        ))
      ) : (
        <li
          key="no-options"
          className="auto-complete-dropdown__no-result"
          onClick={() => setDropdownIsOpen(false)}
        >
          Aucun résultat
        </li>
      )}
    </ul>
  );
};

const InputAutoComplete = ({
  options,
  name,
  placeholder,
  minLength,
  ...inputProps
}) => {
  const { setValue, values, trigger: triggerValidation } = useFormContext();
  const { [name]: internalValue } = values;
  const [displayedValue, setDisplayedValue] = React.useState("");
  const [temporaryValue, setTemporaryValue] = React.useState("");
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
  const dropdownIsHover = React.useRef(false);

  const isEmptyValue = (value) => {
    return value === "";
  };

  const resetValues = () => {
    setValue(name, "");
    setDisplayedValue("");
  };

  const handleAutoCompleteChange = (name, value, label) => {
    if (internalValue !== value) {
      /** set real input value */
      setValue(name, value);

      /** we don't need the temporary value anymore so we reset it */
      setTemporaryValue(label);

      /** we display the label instead of the value */
      setDisplayedValue(label);

      triggerValidation([name]);
    }
    /** we close the dropdown since we already chose our value */
    setDropdownIsOpen(false);
  };

  const handleFocus = () => {
    setDropdownIsOpen(true);
  };

  const handleBlur = () => {
    /**
     * if the dropdown is hovered we are not closing it/validating the input onBlur
     * we let the dropdown onChange closing it and validating it.
     **/
    if (!dropdownIsHover.current) {
      /**
       * when we leave the input we don't need the temporary value anymore
       * this value is only used to feed the suggestions filter
       */
      setTemporaryValue("");
      setDropdownIsOpen(false);
      triggerValidation([name]);
    }
  };

  const handleInputChange = ({ target }) => {
    const { value } = target;

    setTemporaryValue(value);

    /** if the user deleted the whole content of the input we reset the values */
    if (isEmptyValue(value)) {
      resetValues();
    }
  };

  const withOnlySuggestions = (options) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(temporaryValue.toLowerCase())
    );
  };

  React.useEffect(() => {
    const selectedOption = options.find(
      (option) => option.value === internalValue
    );
    const label = selectedOption?.label;
    if (label) {
      setDisplayedValue(label);
    }
  }, [internalValue, options]);

  return (
    <div className="input-auto-complete">
      <Input type="hidden" name={name} {...inputProps} />
      <Input
        /** prevent chrome to auto-complete */
        autoComplete="chrome-off"
        type="text"
        placeholder={placeholder}
        value={!isEmptyValue(temporaryValue) ? temporaryValue : displayedValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {(temporaryValue.length > 0 || values[name] !== "") && (
        <AutoCompleteDropdown
          open={dropdownIsOpen}
          setDropdownIsOpen={setDropdownIsOpen}
          setOpen={setDropdownIsOpen}
          dropdownIsHover={dropdownIsHover}
          options={withOnlySuggestions(options)}
          handleChange={handleAutoCompleteChange}
          name={name}
        />
      )}
    </div>
  );
};

export default InputAutoComplete;
