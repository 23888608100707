import fr from "date-fns/locale/fr";
import {
  setHours,
  setMinutes,
  format as dateFnsFormat,
  getHours,
  getMinutes,
  differenceInDays,
  differenceInMinutes,
} from "date-fns";

export const format = (stringDate, format) => {
  return dateFnsFormat(stringDate, format, { locale: fr });
};

export const formatDate = (date, withTime = false) => {
  if (withTime) {
    return format(new Date(date), "dd/MM/yyyy à HH").concat(
      "H",
      format(new Date(date), "mm")
    );
  }

  return format(new Date(date), "dd/MM/yyyy");
};

const lpad = (str, length, padString) => {
  while (str.length < length) {
    str = padString + str;
  }
  return str;
};

export const getHoursAndMinutesFromDate = (date) => {
  const formattedDate = typeof date === "object" ? date : new Date(date);
  return `${lpad(`${getHours(formattedDate)}`, 2, "0")}:${lpad(
    `${getMinutes(formattedDate)}`,
    2,
    "0"
  )}`;
};

export const getDateFromDateAndHoursAndMinutes = (date, hoursMinutes) => {
  const dateWithoutHourAndMinute = new Date(date);
  const dateWithoutMinute = setHours(
    dateWithoutHourAndMinute,
    Number(hoursMinutes.split(":")[0])
  );
  const fullDateTime = setMinutes(
    dateWithoutMinute,
    Number(hoursMinutes.split(":")[1])
  );

  return format(fullDateTime, "yyyy-MM-dd HH:mm:ssz");
};

export const formatToLocaleDate = (stringDate) => {
  return new Date(stringDate).toLocaleDateString();
};

export const numberOfMinutesBetweenTwoDates = (startDate, endDate) => {
  return differenceInMinutes(new Date(endDate), new Date(startDate));
};

export const numberOfDaysBetweenTwoDates = (startDate, endDate) => {
  return differenceInDays(new Date(endDate), new Date(startDate));
};
