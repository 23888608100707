import { toast } from "react-toastify";
import "animate.css/animate.min.css";
import { cssTransition } from "react-toastify";

const TRANSITION_BOTTOM_TO_TOP = cssTransition({
  enter: "animate__animated animate__slideInUp",
  exit: "animate__animated animate__fadeOut",
});

const AUTO_CLOSE = 5000;

const commonConfig = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: AUTO_CLOSE,
  transition: TRANSITION_BOTTOM_TO_TOP,
};

const notifyError = (message) => {
  toast.error(message, {
    ...commonConfig,
    toastId: message,
  });
};

const notifySuccess = (message) => {
  toast.success(message, {
    ...commonConfig,
    toastId: message,
  });
};

const notifyWarning = (message) => {
  toast.warn(message, {
    ...commonConfig,
    toastId: message,
  });
};

export { notifyError, notifySuccess, notifyWarning };
