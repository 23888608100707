import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { useFormContext } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Select from "components/Form/Select";
import { SearchInputText } from "components/Form/Input";
import IconButton from "components/Button/IconButton";
import {
  getSaleStepOptions,
  getSaleStatusOptions,
  getPeopleOptions,
} from "utils/sale";
import { SALE_STATUS } from "components/Sale/SaleMultistep/saleConstants";
import Accordion from "components/Accordion";
import breakpoints from "components/Breakpoints";
import { useAuth } from "components/Hooks/useAuth";

const SelectFilters = ({ options, updateFilters, filters }) => {
  const {
    user: { isMasterUser },
  } = useAuth();

  return (
    <>
      <Select
        name="status"
        options={options.saleStatusOptions?.map(({ value, label }) => ({
          value,
          label,
        }))}
        onChange={(e) =>
          updateFilters({ name: "status[]", value: e.target.value })
        }
        selectFilter
      />
      <Select
        name="saleStep"
        options={options.saleStepOptions?.map(({ value, label }) => ({
          value,
          label,
        }))}
        onChange={(e) => {
          updateFilters({ name: "saleStep", value: e.target.value });
        }}
        selectFilter
        disabled={filters.some(
          (filter) =>
            filter.name === "status[]" && filter.value === SALE_STATUS.ACTED
        )}
      />
      {isMasterUser && (
        <Select
          name="assigned_to_id"
          options={options.peopleOptions?.map(({ value, label }) => ({
            value,
            label,
          }))}
          onChange={(e) => {
            updateFilters({
              name: "assignedTo.id",
              value: e.target.value,
            });
          }}
          selectFilter
        />
      )}
    </>
  );
};

const DashboardFilters = ({
  peoples,
  setIsCollapsed,
  updateFilters,
  filters,
  user,
}) => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const { reset } = useFormContext();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });
  const users = peoples ? peoples.filter((people) => Boolean(people.user)) : [];

  const options = {
    saleStepOptions: [
      { value: "", label: "Toutes les étapes" },
      ...getSaleStepOptions(),
    ],
    saleStatusOptions: [
      { value: "", label: "Tous les statuts" },
      ...getSaleStatusOptions(),
    ],
    peopleOptions: [
      { value: "", label: "Tous les utilisateurs" },
      ...getPeopleOptions(users),
    ],
  };

  const selectFilters = isDesktop ? (
    <SelectFilters
      options={options}
      updateFilters={updateFilters}
      filters={filters}
    />
  ) : (
    <Accordion focusContent={false} open={filtersOpen}>
      <SelectFilters
        options={options}
        updateFilters={updateFilters}
        filters={filters}
      />
    </Accordion>
  );

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  React.useEffect(() => {
    /* 
      Big hack to soft refresh the filters when we history.push on this same route 
      see : https://github.com/remix-run/react-router/issues/7416 for the suggestion 
      issue : https://projets.occitech.fr/issues/17455
    */
    reset();
  }, [location.key, reset]);

  return (
    <>
      {isDesktop && (
        <IconButton
          onClick={() => history.push("/sales/new")}
          onMouseEnter={() => setIsCollapsed(true)}
          onMouseLeave={() => setIsCollapsed(false)}
          icon="plus-ligth"
          iconSize="big"
          appearance="ghost-primary"
          uppercase={true}
          rounded
          roundedToFullWidthAnimation={true}
        >
          Nouvelle vente
        </IconButton>
      )}

      <div className="dashboard-filters__search">
        {!isDesktop && (
          <IconButton icon="filters" iconSize="big" onClick={toggleFilters} />
        )}

        <DebounceInput
          element={SearchInputText}
          minLength={2}
          debounceTimeout={300}
          onChange={(event) => {
            updateFilters({
              name: "search",
              value: event.target.value,
            });
          }}
          type="search"
          name="search"
          placeholder="RECHERCHER"
        />
      </div>
      {selectFilters}
    </>
  );
};

export default DashboardFilters;
