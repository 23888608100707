import React from "react";
import fetchUrl from "api/fetchUrl";
import { getClient, putClient } from "api/requests";
import validationSchema from "./validationSchema";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Form from "components/Form";
import { InputText } from "components/Form/Input";
import Select from "components/Form/Select";
import Button from "components/Button";
import {
  PEOPLE_TYPES,
  PEOPLE_CIVILITIES,
  CLIENT_PREFIX,
  PEOPLE_PREFIX,
} from "components/Sale/SaleMultistep/saleConstants";
import Spinner from "components/Spinner";
import { notifyError } from "utils/notify";

const { INDIVIDUAL, COMPANY, SCI, INDIVISION } = PEOPLE_TYPES;
const { MLLE, MME, M } = PEOPLE_CIVILITIES;

const EditClientDialog = ({
  open,
  setOpen,
  updateClientList,
  setValue,
  target,
  clientId,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClient = ({
    peopleId,
    type,
    companyName,
    civility,
    firstname,
    lastname,
    mobile,
    email,
    agency,
  }) => {
    const updatedClient = {
      email,
      people: {
        "@id": `${PEOPLE_PREFIX}${peopleId}`,
        type,
        companyName: type !== INDIVIDUAL.value ? companyName : null,
        civility,
        firstname,
        lastname,
        mobile,
        agency,
      },
    };
    fetchUrl(putClient({ client: updatedClient, clientId })).then((client) => {
      updateClientList(client);
      setValue(target, `${CLIENT_PREFIX}${client.id}`, {
        shouldValidate: true,
      });
      handleClose();
    });
  };

  React.useEffect(() => {
    if (!clientId) {
      return;
    }
    setLoading(true);
    fetchUrl(getClient(clientId))
      .then(
        ({
          people: {
            id,
            type,
            companyName,
            civility,
            firstname,
            lastname,
            mobile,
            agency,
          },
          email,
        }) => {
          setClient({
            peopleId: id,
            type,
            companyName,
            civility,
            firstname,
            lastname,
            mobile,
            email,
            agency,
          });
          setLoading(false);
        }
      )
      .catch(() => {
        notifyError(
          "Cette adresse mail est déjà utilisée par un autre client."
        );
        setLoading(false);
      });
  }, [clientId, open]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      className="edit-client-dialog"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          mode="all"
          validationSchema={validationSchema}
          defaultValues={client}
        >
          {({ values, isValid, trigger: triggerValidation }) => (
            <>
              <DialogTitle>MODIFIER UN CLIENT</DialogTitle>
              <DialogContent>
                <Select
                  name="type"
                  options={[INDIVIDUAL, COMPANY, SCI, INDIVISION]}
                  placeholder="Type"
                />
                {[COMPANY.value, SCI.value, INDIVISION.value].includes(
                  values?.type
                ) ? (
                  <InputText name="companyName" placeholder="Raison sociale" />
                ) : null}
                <Select
                  name="civility"
                  options={[MLLE, MME, M]}
                  placeholder="Civilité"
                />
                <InputText name="lastname" placeholder="Nom" />
                <InputText name="firstname" placeholder="Prénom" />
                <InputText name="mobile" placeholder="Téléphone" />
                <InputText name="email" placeholder="Email" />
              </DialogContent>
              <div className="edit-client-dialog__disclaimer">
                La modification de la fiche impactera toutes les ventes avec ce
                client
              </div>
              <DialogActions>
                <Button fullWidth appearance="ghost" onClick={handleClose}>
                  ANNULER
                </Button>
                <Button
                  fullWidth
                  appearance="primary"
                  onClick={() =>
                    isValid
                      ? handleEditClient(values)
                      : triggerValidation().then((isValidAfterValidation) => {
                          if (isValidAfterValidation) {
                            return handleEditClient(values);
                          }
                        })
                  }
                >
                  ENREGISTRER
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      )}
    </Dialog>
  );
};

export default EditClientDialog;
