import React from "react";
import { useFormContext } from "react-hook-form";
import IconButton from "components/Button/IconButton";
import InputText from "components/Form/Input/InputText";
import Select from "components/Form/Select";
import { RECIPIENT_TYPES } from "components/Sale/SaleMultistep/saleConstants";
import { getAvailableRecipients } from "utils/email";
import { Editor } from "react-draft-wysiwyg";
import AttachmentUploader from "components/AttachmentUploader";

const Recipients = ({ recipients, setRecipients, hasPartnerAgency }) => {
  const [displayRecipientSelector, setDisplayRecipientSelector] =
    React.useState(false);
  const [displayCustomEmailInput, setDisplayCustomEmailInput] =
    React.useState(false);
  const { values, setValue } = useFormContext();

  const recipientOptions = [
    ...getAvailableRecipients({
      hasPartnerAgency,
    }),
    { value: "custom", label: "Ajouter un email" },
  ];

  const initRecipientFields = () => {
    setValue("customEmail", "");
    setValue("selectRecipient", null);
    setDisplayCustomEmailInput(false);
    setDisplayRecipientSelector(false);
  };

  const handleSelectReciptient = ({ target: { value } }) => {
    if (!value) return;

    if (value === "custom") {
      setDisplayCustomEmailInput(true);
      return;
    }

    const isAlreadyAdded = recipients.find(
      (recipient) => recipient.value === value
    );

    if (!isAlreadyAdded) {
      const recipientLabel = Object.entries(RECIPIENT_TYPES).find(
        (recipientType) => recipientType[0] === value
      )[1].label;

      setRecipients([...recipients, { value, label: recipientLabel }]);
    }

    initRecipientFields();
  };

  const handleAddCustomEMail = (value) => {
    if (!value) return;

    setRecipients([...recipients, { value, label: value }]);
    initRecipientFields();
  };

  return (
    <div className="recipients">
      {recipients.map((recipientData) => (
        <div className="recipient" key={recipientData.value}>
          <IconButton
            className="delete-button"
            icon="close"
            iconSize="small"
            onClick={() =>
              setRecipients(
                recipients.filter(
                  (recipient) => recipientData.value !== recipient.value
                )
              )
            }
          />
          {recipientData.label}
        </div>
      ))}
      {displayRecipientSelector ? (
        <div className="recipients__add-form">
          <Select
            name="selectRecipient"
            placeholder="Sélectionner un destinataire"
            options={recipientOptions}
            onChange={handleSelectReciptient}
          />
          {displayCustomEmailInput && (
            <div className="recipients__custom-email">
              <InputText
                name="customEmail"
                placeholder="Email"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <IconButton
                icon="check"
                boxShadow={false}
                iconColor={values.customEmail ? "primary" : "grey"}
                onClick={() => handleAddCustomEMail(values["customEmail"])}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="recipients__add-button">
          <IconButton
            appearance="ghost-primary"
            rounded
            icon="plus"
            title="Ajouter un destinataire"
            onClick={() => setDisplayRecipientSelector(true)}
          />
        </div>
      )}
    </div>
  );
};

const Attachments = ({ attachments, setAttachments }) => {
  const handleRemoveAttachment = React.useCallback(
    (attachmentId) => {
      const updatedAttachments = attachments.filter(
        ({ id }) => id !== attachmentId
      );
      setAttachments(updatedAttachments);
    },
    [attachments, setAttachments]
  );

  return (
    <div className="attachments">
      <AttachmentUploader
        files={attachments}
        setFiles={setAttachments}
        handleRemoveFile={handleRemoveAttachment}
        fileComponent={
          <IconButton
            appearance="ghost-primary"
            rounded
            icon="plus"
            onClick={() => {}}
          />
        }
      />
    </div>
  );
};

const Row = ({ title, value }) => (
  <div className="row">
    <div className="row__label">
      {typeof title === "function" ? title() : title}
    </div>
    <div className="row__value">{value}</div>
  </div>
);

const MailTemplate = ({
  recipients,
  setRecipients,
  hasPartnerAgency,
  editorState,
  handleEditorStateChange,
  subject,
  setSubject,
  attachments,
  setAttachments,
}) => {
  return (
    <div className="template">
      <div className="template__head">
        <Row
          title="Pour"
          value={
            <Recipients
              recipients={recipients}
              setRecipients={setRecipients}
              hasPartnerAgency={hasPartnerAgency}
            />
          }
        />
        <Row
          title="Objet"
          value={
            <InputText
              value={subject}
              onChange={({ target: { value } }) => setSubject(value)}
            />
          }
        />
        <Row
          title={() => (
            <div className="template-docs-label">
              Docs{" "}
              <div className="template-docs-title__sub-label">
                Ajoutez jusqu'à 15 Mo
              </div>
            </div>
          )}
          value={
            <Attachments
              attachments={attachments}
              setAttachments={setAttachments}
            />
          }
        />
      </div>
      <div className="template__content">
        <Editor
          toolbarHidden
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
        />
      </div>
    </div>
  );
};

export default MailTemplate;
