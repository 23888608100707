import React from "react";
import fetchUrl from "api/fetchUrl";
import { postNotary } from "api/requests";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import Form from "components/Form";
import { InputText } from "components/Form/Input";
import Select from "components/Form/Select";
import Button from "components/Button";
import {
  PEOPLE_CIVILITIES,
  PEOPLE_TYPES,
  AGENCY_PREFIX,
  NOTARY_PREFIX,
} from "components/Sale/SaleMultistep/saleConstants";
import { useAuth } from "components/Hooks/useAuth";
import InputGoogleMapAutoComplete from "components/Form/Input/InputGoogleAutoComplete";
import validationSchema from "./validationSchema";
import { notifyError } from "utils/notify";

const { MLLE, MME, M } = PEOPLE_CIVILITIES;

const { INDIVIDUAL } = PEOPLE_TYPES;

const NewNotaryDialog = ({
  open,
  setOpen,
  addNewNotaryToList,
  setValue,
  target,
}) => {
  const { user } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNotary = (
    { civility, firstname, lastname, mobile, email, city },
    reset
  ) => {
    const newNotary = {
      email,
      city,
      people: {
        type: INDIVIDUAL.value,
        civility,
        firstname,
        lastname,
        mobile,
        agency: `${AGENCY_PREFIX}${user.people.agency.id}`,
      },
    };
    fetchUrl(postNotary(newNotary))
      .then((notary) => {
        addNewNotaryToList(notary);
        setValue(target, `${NOTARY_PREFIX}${notary.id}`, {
          shouldValidate: true,
        });
        handleClose();
        reset();
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors de l'ajout du nouveau notaire."
        );
      });
  };

  return (
    <Dialog open={open} handleClose={handleClose} className="new-notary-dialog">
      <Form mode="all" validationSchema={validationSchema}>
        {({ values, reset, isValid, trigger: triggerValidation }) => (
          <>
            <DialogTitle>CRÉER UN NOUVEAU NOTAIRE</DialogTitle>
            <DialogContent>
              <Select
                name="civility"
                options={[MLLE, MME, M]}
                placeholder="Civilité"
              />
              <InputText name="lastname" placeholder="Nom" />
              <InputText name="firstname" placeholder="Prénom" />
              <InputText name="mobile" placeholder="Téléphone" />
              <InputText name="email" placeholder="Email" />
              <InputGoogleMapAutoComplete
                onlyCity
                name="city"
                placeholder="Ville"
              />
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                appearance="ghost"
                boxShadow
                onClick={handleClose}
              >
                ANNULER
              </Button>
              <Button
                fullWidth
                boxShadow
                appearance="primary"
                onClick={() =>
                  isValid
                    ? handleAddNotary(values, reset)
                    : triggerValidation().then((isValidAfterValidation) => {
                        if (isValidAfterValidation) {
                          return handleAddNotary(values, reset);
                        }
                      })
                }
              >
                ENREGISTRER
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default NewNotaryDialog;
