import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import Input from "../Input";

const preventInputOtherThanNumber = (event) => {
  if (isNaN(event.key) && event.key !== "," && event.key !== ".") {
    event.preventDefault();
    return false;
  }

  return true;
};

const InputNumber = ({ min, max, suffix, ...inputProps }) => {
  const minMaxProps = {
    ...(typeof min === "number" ? { min } : {}),
    ...(typeof max === "number" ? { max } : {}),
  };

  if (suffix && (suffix.icon || suffix.text)) {
    const { icon, text } = suffix;
    return (
      <div className="input-number-wrapper">
        <Input
          type="number"
          {...minMaxProps}
          {...inputProps}
          onKeyPress={preventInputOtherThanNumber}
        />
        <div className="suffix-wrapper">
          {icon && <Icon icon={icon} size="small" />}
          {text && <div>{text}</div>}
        </div>
      </div>
    );
  }

  return (
    <Input
      type="number"
      {...minMaxProps}
      {...inputProps}
      onKeyPress={preventInputOtherThanNumber}
    />
  );
};

InputNumber.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  suffix: PropTypes.shape({ icon: PropTypes.string, text: PropTypes.string }),
};

export default InputNumber;
