const ANNEX_PREFIX = "/api/item_annexes/";
const PEOPLE_PREFIX = "/api/people/";
const AGENCY_FEES_PREFIX = "/api/agency_fees/";
const CO_OWNERSHIP_PREFIX = "/api/co_ownerships/";
const FINANCIAL_INFORMATION_PREFIX = "/api/financial_informations/";
const MANDATE_PREFIX = "/api/mandates/";
const AGENCY_PREFIX = "/api/agencies/";
const CLIENT_PREFIX = "/api/clients/";
const NOTARY_PREFIX = "/api/notaries/";
const BUYER_AND_NOTARY_PREFIX = "/api/buyers_and_notaries/";
const SELLER_AND_NOTARY_PREFIX = "/api/sellers_and_notaries/";
const DOCUMENT_PREFIX = "/api/documents/";
const SALE_PARTNER_AGENCY_PREFIX = "/api/sale_partner_agencies/";
const SALE_STEP_PREFIX = "/api/sale_steps/";
const SALE_KEY_DATES_PREFIX = "/api/sale_key_dates/";
const DOCUMENT_CHECKLIST_ITEM_PREFIX = "/api/document_checklist_items/";
const SALE_SUB_STEP_PREFIX = "/api/sale_sub_steps/";
const USER_PREFIX = "/api/users/";

const TAX_RATE = 1.2;

const PROPERTY_TYPES = {
  APARTMENT: { value: "APARTMENT", label: "Appartement" },
  HOUSE: { value: "HOUSE", label: "Maison" },
  BUILDING: { value: "BUILDING", label: "Immeuble" },
  OFFICE: { value: "OFFICE", label: "Bureaux" },
  BUSINESS: { value: "BUSINESS", label: "Commerce" },
  GARAGE_PARKING: { value: "GARAGE_PARKING", label: "Garage / Parking" },
  CELLAR: { value: "CELLAR", label: "Cave" },
  LAND: { value: "LAND", label: "Terrain" },
  OTHER: { value: "OTHER", label: "Autre" },
};

const PROJECT_TYPES = {
  PRIMARY_RESIDENCE: {
    value: "PRIMARY_RESIDENCE",
    label: "Résidence Principale",
  },
  INVESTMENT: { value: "INVESTMENT", label: "Investissement" },
};

const NUMBER_OF_LOTS = {
  UNDER_20: { value: "UNDER_20", label: "Moins de 20" },
  "20_40": { value: "20_40", label: "Entre 20 et 40" },
  "40_60": { value: "40_60", label: "Entre 40 et 60" },
  MORE_THAN_60: { value: "MORE_THAN_60", label: "Plus de 60" },
};

const YEARS_OF_CONSTRUCTION = {
  FIFTIES: { value: "FIFTIES", label: "Avant 1960" },
  SEVENTIES: { value: "SEVENTIES", label: "60-70" },
  HEIGHTYS: { value: "HEIGHTYS", label: "80-90" },
  "2000S": { value: "2000S", label: "2000 et +" },
};

const SALE_STATUS = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  SAVE: "SAVE",
  CANCELED: "CANCELED",
  ACTED: "ACTED",
  ARCHIVED: "ARCHIVED",
};

const MANDATE_TYPES = {
  SALE: { value: "SALE", label: "Mandat de vente" },
  SEARCH: { value: "SEARCH", label: "Mandat de recherche" },
};

const FEES_TO_BE_PAID = {
  SELLER: { value: "SELLER", label: "Vendeur" },
  BUYER: { value: "BUYER", label: "Acquéreur" },
  SELLER_BUYER: { value: "SELLER_BUYER", label: "Vendeur & acquéreur" },
};

const FINANCING_METHODS = {
  BANK_LOAN: { value: "BANK_LOAN", label: "Prêt bancaire" },
  CASH: { value: "CASH", label: "Comptant" },
};

const PEOPLE_TYPES = {
  INDIVIDUAL: { value: "individual", label: "Particulier" },
  COMPANY: { value: "company", label: "Société" },
  SCI: { value: "sci", label: "SCI" },
  INDIVISION: { value: "indivision", label: "Indivision" },
};

const PEOPLE_CIVILITIES = {
  MLLE: { value: "Mlle", label: "Mlle" },
  MME: { value: "Mme", label: "Mme" },
  M: { value: "M", label: "M" },
};

const FILE_TYPES = {
  COMPROMISE_PROMISE: {
    value: "COMPROMISE_PROMISE",
    label: "Compromis - Promesse",
  },
  DIAGNOSIS: {
    value: "DIAGNOSIS",
    label: "Diagnostics",
  },
  DIAGNOSIS_EXPERTISE: {
    value: "DIAGNOSIS_EXPERTISE",
    label: "Diagnostics - Expertises",
  },
  STATEMENT_OF_CHARGES: {
    value: "STATEMENT_OF_CHARGES",
    label: "Décompte de charges",
  },
  FINANCING: { value: "FINANCING", label: "Financement" },
  OFFER_TO_PURCHASE: { value: "OFFER_TO_PURCHASE", label: "Offre d'achat" },
  GENERAL_MEETINGS_MINUTES: {
    value: "GENERAL_MEETINGS_MINUTES",
    label: "PV assemblées générales",
  },
  RULES_CO_OWNERSHIP: {
    value: "RULES_CO_OWNERSHIP",
    label: "Règlement de copropriété",
  },
  PROPERTY_TAX: {
    value: "PROPERTY_TAX",
    label: "Taxe foncière",
  },
  TITLE_DEEDS: { value: "TITLE_DEEDS", label: "Titre de propriété" },
  CIVIL_STATUS_BUYERS: {
    value: "CIVIL_STATUS_BUYERS",
    label: "État civil acquéreur(s)",
  },
  CIVIL_STATUS_SELLERS: {
    value: "CIVIL_STATUS_SELLERS",
    label: "État civil vendeur(s)",
  },
  CIVIL_STATUS_BUYERS_SELLERS: {
    value: "CIVIL_STATUS_BUYERS_SELLERS",
    label: "État civil vendeurs(s) - acquéreur(s)",
  },
  OTHER_DOCUMENTS: { value: "OTHER_DOCUMENTS", label: "Documents divers" },
  CO_OWNERSHIP_MAINTENANCE_BOOKLET: {
    value: "CO_OWNERSHIP_MAINTENANCE_BOOKLET",
    label: "Livret d'entretien de la copropriété",
  },
  DATED_PRE_STATE: {
    value: "DATED_PRE_STATE",
    label: "Pré-état daté (syndic)",
  },
  /** Process step 3 documents */
  CERTIFICATE_OF_DEPOSIT_OF_BANK_FILE: {
    value: "CERTIFICATE_OF_DEPOSIT_OF_BANK_FILE",
    label: "Attestation dépôt dossier bancaire",
  },
  BANK_LOAN_OFFER: {
    value: "BANK_LOAN_OFFER",
    label: "Offre de prêt bancaire",
  },
  DATED_CONDITION: {
    value: "DATED_CONDITION",
    label: "Etat daté",
  },
};

const FORMALITY_TYPES = {
  RENEWAL_OF_DIAGNOSTICS: {
    value: "RENEWAL_OF_DIAGNOSTICS",
    label: "Renouvellement diagnostics",
  },
  SUSPENSIVE_CONDITIONS_LIFTED: {
    value: "SUSPENSIVE_CONDITIONS_LIFTED",
    label: "Conditions suspensives levées",
  },
  COUNTER_VISIT_OF_PROPERTY_BEFORE_THE_ACT: {
    value: "COUNTER_VISIT_OF_PROPERTY_BEFORE_THE_ACT",
    label: "Contre-visite du bien avant l’acte",
  },
};

const SALE_STEPS = {
  DOCUMENTS: "DOCUMENTS",
  COMPROMISE: "COMPROMISE",
  FORMALITIES: "FORMALITIES",
  ACTED: "ACTED",
};

const SALE_SUB_STEPS = {
  CHECK_DOCUMENTS: "CHECK_DOCUMENTS",
  CHECK_BUYER_ACKNOWLEDGED_DOCUMENTS: "CHECK_BUYER_ACKNOWLEDGED_DOCUMENTS",
  PLAN_PRE_CONTRACT_SIGNATURE: "PLAN_PRE_CONTRACT_SIGNATURE",
  CONFIRM_PRE_CONTRACT_SIGNATURE: "CONFIRM_PRE_CONTRACT_SIGNATURE",
  REGISTER_KEY_DATES_AND_CONDITIONS: "REGISTER_KEY_DATES_AND_CONDITIONS",
  CHECK_NOTARY_NOTIFIED_PRE_CONTRACT: "CHECK_NOTARY_NOTIFIED_PRE_CONTRACT",
  ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_FD:
    "ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_FD",
  ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_RO:
    "ENSURE_BANKING_PROCEDURES_ARE_ADVANCED_RO",
  ENSURE_PRE_DEED_SALES_FILE_IS_COMPLETE:
    "ENSURE_PRE_DEED_SALES_FILE_IS_COMPLETE",
  PLAN_THE_DEED_SIGNATURE: "PLAN_THE_DEED_SIGNATURE",
  CONFIRM_DEED_SIGNATURE: "CONFIRM_DEED_SIGNATURE",
};

const SALE_TYPES = {
  REGULAR: "REGULAR",
  INTER_AGENCY: "INTER_AGENCY",
};

const REPRESENTS = {
  BUYER: { value: "BUYER", label: "L'acquéreur" },
  SELLER: { value: "SELLER", label: "Le vendeur" },
};

const RECIPIENT_TYPES = {
  BUYER: { value: "BUYER", label: "Acquéreur(s)" },
  SELLER: { value: "SELLER", label: "Vendeur(s)" },
  BUYER_NOTARY: { value: "BUYER_NOTARY", label: "Notaire acquéreur" },
  SELLER_NOTARY: { value: "SELLER_NOTARY", label: "Notaire vendeur" },
  PARTNER_AGENCY: { value: "PARTNER_AGENCY", label: "Agence partenaire" },
};

const INSUFFICIENT_CREDIT_EXCEPTION = "INSUFFICIENT_CREDIT";

export {
  ANNEX_PREFIX,
  PEOPLE_PREFIX,
  AGENCY_FEES_PREFIX,
  CO_OWNERSHIP_PREFIX,
  FINANCIAL_INFORMATION_PREFIX,
  MANDATE_PREFIX,
  AGENCY_PREFIX,
  CLIENT_PREFIX,
  NOTARY_PREFIX,
  USER_PREFIX,
  BUYER_AND_NOTARY_PREFIX,
  SELLER_AND_NOTARY_PREFIX,
  DOCUMENT_PREFIX,
  SALE_PARTNER_AGENCY_PREFIX,
  SALE_STEP_PREFIX,
  SALE_KEY_DATES_PREFIX,
  DOCUMENT_CHECKLIST_ITEM_PREFIX,
  SALE_SUB_STEP_PREFIX,
  TAX_RATE,
  PROPERTY_TYPES,
  PROJECT_TYPES,
  NUMBER_OF_LOTS,
  YEARS_OF_CONSTRUCTION,
  SALE_STATUS,
  MANDATE_TYPES,
  FEES_TO_BE_PAID,
  FINANCING_METHODS,
  PEOPLE_TYPES,
  PEOPLE_CIVILITIES,
  FILE_TYPES,
  FORMALITY_TYPES,
  SALE_STEPS,
  SALE_SUB_STEPS,
  SALE_TYPES,
  REPRESENTS,
  RECIPIENT_TYPES,
  INSUFFICIENT_CREDIT_EXCEPTION,
};
