import React from "react";
import { Editor } from "react-draft-wysiwyg";
import Text from "components/Text";
import Icon from "components/Icon";
import Form from "components/Form";
import Select from "components/Form/Select";
import { IconButton } from "components/Button";
import fetchUrl from "api/fetchUrl";
import { editorStateToHtml } from "utils/email";
import { sendContactEmail } from "api/requests";
import { notifyError, notifySuccess } from "utils/notify";
import validationSchema from "./validationSchema";
import { useAuth } from "components/Hooks/useAuth";

const Contact = () => {
  const [editorState, setEditorState] = React.useState(null);
  const { user } = useAuth();

  const handleSubmit = ({ subject }) => {
    const formattedContent = editorStateToHtml(editorState);

    fetchUrl(
      sendContactEmail({
        subject,
        content: formattedContent,
        from: user?.email,
      })
    )
      .then(() => {
        notifySuccess("Email de contact envoyé avec succès.");
      })
      .catch(() =>
        notifyError(
          "Une erreur est survenue lors de l'envoi de l'email de contact."
        )
      );
  };

  return (
    <div className="contact">
      <div className="contact__header">
        <Text align="center" font="roboto-slab" fontWeight="light">
          N'hésitez pas à nous contacter
        </Text>
        <Text align="center">
          Pour toute question ou si vous rencontrez un problème avec votre
          compte
        </Text>
        <Icon icon="ripple" size="very-big" color="primary" />
      </div>
      <div className="contact__content">
        <div className="contact__contact-us">
          <Icon icon="mobile" />
          <Text
            uppercase
            font="roboto-slab"
            align="center"
            fontWeight="semi-bold"
          >
            Contacter notre <br />
            service commercial
          </Text>
          <Text color="primary" size="very-large" fontWeight="semi-bold">
            05 61 17 41 05
          </Text>
        </div>
        <div className="contact__contact-form">
          <Form
            mode="all"
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Select
              name="subject"
              placeholder="Selectionner"
              options={[
                { label: "Abonnement", value: "Abonnement" },
                {
                  label: "Assistance Technique",
                  value: "Assistance Technique",
                },
                { label: "Suggestions", value: "Suggestions" },
                { label: "Autre", value: "Autre" },
              ]}
            />
            <Editor
              toolbarHidden
              editorState={editorState}
              placeholder="Que pouvons-nous faire pour vous ?"
              onEditorStateChange={setEditorState}
            />
            <IconButton
              uppercase
              type="submit"
              icon="chevron-right"
              textPosition="left"
              appearance="primary"
            >
              Envoyer
            </IconButton>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
