import React from "react";
import classNames from "classnames";

const Card = ({ className, onClick, children }) => (
  <div
    className={classNames("card", { [className]: className })}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Card;
