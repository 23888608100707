import { notifyError } from "utils/notify";

const notifyFormErrors = (errors) => {
  notifyError(
    [
      ...new Set(
        Object.keys(errors).map(
          (attributeName) => errors[attributeName].message
        )
      ),
    ].join("\n")
  );
};

export default notifyFormErrors;
