import React from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Accordion from "components/Accordion";
import { IconButton } from "components/Button";
import Text from "components/Text";
import { InputDate } from "components/Form/Input";
import Select from "components/Form/Select";
import Divider from "components/Divider";
import {
  FILE_TYPES,
  INSUFFICIENT_CREDIT_EXCEPTION,
  SALE_KEY_DATES_PREFIX,
  SALE_STATUS,
} from "../../saleConstants";
import StepActions from "../../StepActions";
import { useAuth } from "components/Hooks/useAuth";
import fetchUrl from "api/fetchUrl";
import { deleteDocument, postDocument, putSale } from "api/requests";
import Icon from "components/Icon";
import FileUploader from "components/FileUploader";
import { notifyError, notifySuccess } from "utils/notify";
import { formatSale } from "pages/Sale/Sale";
import { useBusinessOfferDialog } from "components/Hooks/useBusinessOfferDialog";

const {
  COMPROMISE_PROMISE,
  DIAGNOSIS_EXPERTISE,
  STATEMENT_OF_CHARGES,
  FINANCING,
  OFFER_TO_PURCHASE,
  RULES_CO_OWNERSHIP,
  PROPERTY_TAX,
  TITLE_DEEDS,
  OTHER_DOCUMENTS,
  GENERAL_MEETINGS_MINUTES,
} = FILE_TYPES;

const DocumentList = ({ documents, deleteDocument }) => {
  return (
    <div className="document-list">
      {documents.map((document) => (
        <div key={document.id} className="document">
          <div className="document__name-wrapper">
            <Icon icon="paper" color="primary" />
            <span className="document__name">{document.name}</span>
          </div>
          <div className="document__action">
            <IconButton
              icon="bin"
              iconColor="medium-grey"
              onClick={() => {
                deleteDocument(document.id);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const DocumentsStep = ({
  isEdit,
  step,
  sale,
  setSale,
  isValid,
  active,
  nextStep,
  goToStep,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const { values, setValue, trigger: triggerValidation } = useFormContext();
  const [documents, setDocuments] = React.useState(sale?.documents ?? []);
  const { setBusinessOfferDialogOpen } = useBusinessOfferDialog();

  const { offerDate, fileType } = values;

  const processFile = (file) => {
    const newDocument = {
      file,
      sale: sale.id,
      type: fileType,
      uploadedBy: user.people.id,
      isPhysical: false,
    };
    return fetchUrl(postDocument(newDocument))
      .then((response) => {
        file.id = response.id;
        setDocuments([...documents, file]);
        setValue("fileType", "");
        notifySuccess("Le document a été téléchargé avec succès.");
      })
      .catch(() => {
        notifyError(
          "Une erreur est survenue lors du téléchargement du document."
        );
      });
  };

  const handleDelete = (documentId) => {
    fetchUrl(deleteDocument(documentId))
      .then(() => {
        const updatedDocuments = documents.filter(
          (document) => document.id !== documentId
        );
        setDocuments(updatedDocuments);
        notifySuccess("Le document a été supprimé.");
      })
      .catch(() => {
        notifyError("Une erreur est survenue lors de la suppression document.");
      });
  };

  const makeStepData = () => {
    const nextStep = step + 1;
    return {
      offerDate,
      draftStep: nextStep > sale.draftStep ? nextStep : sale.draftStep,
      ...(!user.isMasterUser &&
        sale.status === SALE_STATUS.DRAFT && {
          status: SALE_STATUS.SAVE,
          ...(!sale?.keyDates?.saleRecordedOn && {
            keyDates: {
              "@id": `${SALE_KEY_DATES_PREFIX}${sale.keyDates.id}`,
              saleRecordedOn: new Date(),
            },
          }),
        }),
    };
  };

  const handleFinish = () => {
    const stepData = makeStepData();

    fetchUrl(putSale({ sale: stepData, saleId: sale.id }))
      .then((sale) => {
        setSale(formatSale(sale));
        if (user.isMasterUser) {
          nextStep();
        } else {
          if (isEdit) {
            history.push({ pathname: `/sales/process/${sale.id}` });
          } else {
            history.push({ pathname: "/", state: { newSale: sale } });
          }
        }
      })
      .catch((error) => {
        if (error?.detail === INSUFFICIENT_CREDIT_EXCEPTION) {
          setBusinessOfferDialogOpen(true);
          notifyError("Nombre de crédits insuffisant");
        } else {
          notifyError("Une erreur est survenue");
        }
      });
  };

  return (
    <Accordion
      focusContent={false}
      disableToggle={sale.draftStep ? sale.draftStep < step : true}
      title={`${step + 1} • Documents`}
      open={active}
      setOpen={goToStep}
    >
      {active ? (
        <>
          <div className="document-step">
            <Text color="primary" font="roboto-slab" size="small">
              Offre d'achat
            </Text>
            <div className="offer-date">
              <Text>Date d'acceptation</Text>
              <InputDate
                maxDate={new Date()}
                name="offerDate"
                placeholder="Date d'acceptation"
              />
            </div>

            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Documents du bien
            </Text>
            <Select
              name="fileType"
              options={[
                COMPROMISE_PROMISE,
                DIAGNOSIS_EXPERTISE,
                STATEMENT_OF_CHARGES,
                FINANCING,
                OFFER_TO_PURCHASE,
                RULES_CO_OWNERSHIP,
                PROPERTY_TAX,
                TITLE_DEEDS,
                OTHER_DOCUMENTS,
                GENERAL_MEETINGS_MINUTES,
              ]}
              placeholder="Type de document"
            />
            <FileUploader
              disabled={!fileType}
              label="TÉLÉCHARGER LE DOCUMENT"
              process={processFile}
              fileComponent={
                <IconButton
                  fullWidth
                  textPosition="left"
                  appearance="reversed-primary"
                  icon="plus"
                >
                  Télécharger le document
                </IconButton>
              }
            />
            <Divider />
            <Text color="primary" font="roboto-slab" size="small">
              Liste des documents téléchargés
            </Text>
            <DocumentList documents={documents} deleteDocument={handleDelete} />
          </div>
          <StepActions>
            <IconButton
              uppercase
              fullWidth
              {...(!isEdit && { icon: "chevron-right" })}
              textPosition="left"
              appearance="primary"
              onClick={() =>
                isValid
                  ? handleFinish()
                  : triggerValidation().then((isValidAfterValidation) => {
                      if (isValidAfterValidation) {
                        return handleFinish();
                      }
                    })
              }
            >
              {isEdit
                ? "Enregistrer les modifications"
                : user.isMasterUser
                ? "Enregistrer & continuer"
                : "Enregistrer la vente"}
            </IconButton>
          </StepActions>
        </>
      ) : null}
    </Accordion>
  );
};

export default DocumentsStep;
