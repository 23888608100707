import React from "react";
import PropTypes from "prop-types";
import LinkButton from "components/Button/LinkButton";

const SaleCardDraft = ({ saleId, seller, buyer }) => {
  return (
    <div className="card-draft">
      <div className="card-draft__buyer-seller">
        {seller || "-"}
        <br />
        {buyer || "-"}
      </div>
      <LinkButton appearance="ghost" to={`/sales/${saleId}`}>
        compléter
      </LinkButton>
    </div>
  );
};

SaleCardDraft.propTypes = {
  seller: PropTypes.string.isRequired,
  buyer: PropTypes.string.isRequired,
  saleId: PropTypes.number.isRequired,
};

export default SaleCardDraft;
