import React from "react";
import fetchUrl from "api/fetchUrl";
import {
  postLoginRequest,
  postLogoutRequest,
  getUserRequest,
} from "api/requests";
import { notifyError, notifySuccess } from "utils/notify";
import { setCookie } from "utils/cookie";

const AuthContext = React.createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [refetchUser, setRefetchUser] = React.useState(false);

  /**
   * user = null => authenticated = null => we don't have information => loading
   * user = false => authenticated = false => not logged in
   * user = { id: 1, ...} => authenticated = true => logged in
   */
  let authenticated = null;

  if (user === false) {
    authenticated = false;
  } else if (user?.email) {
    authenticated = true;
  }

  React.useEffect(() => {
    const me = () => {
      return fetchUrl(getUserRequest())
        .then((user) => {
          if (!isAdminUser(user.roles)) {
            setUser({ ...user, isMasterUser: isMasterUser(user.roles) });
          } else {
            window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/admin`;
          }
        })
        .catch((error) => {
          if (error?.detail === "Subscription expired") {
            notifyError(
              "Votre abonnement est expiré, veuillez contacter votre administrateur Elio."
            );
          }
          setUser(false);
        });
    };
    if (user === null || refetchUser) {
      me();

      if (refetchUser) {
        setRefetchUser(false);
      }
    }
  }, [user, refetchUser]);

  const isMasterUser = (roles) => {
    return roles.includes("ROLE_MASTER_USER");
  };

  const isAdminUser = (roles) => {
    return roles.includes("ROLE_ADMIN");
  };

  const login = (email, password) => {
    return fetchUrl(postLoginRequest({ username: email, password }))
      .then((user) => {
        if (user) {
          if (!isAdminUser(user.roles)) {
            setUser({ ...user, isMasterUser: isMasterUser(user.roles) });
            notifySuccess("Vous êtes bien identifié.");
            return true;
          }

          window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/admin`;
          return true;
        } else {
          notifyError(
            "Votre identifiant ou mot de passe ne sont pas reconnus, merci de recommencer."
          );
          return false;
        }
      })
      .catch((error) => {
        if (error.detail && error.detail === "Account disabled") {
          notifyError(
            "Votre compte est désactivé, merci de vous rapprocher de votre administrateur."
          );
        } else if (error?.detail === "Subscription expired") {
          notifyError(
            "Votre abonnement est expiré, veuillez contacter votre administrateur Elio."
          );
        } else {
          notifyError(
            "Votre identifiant ou mot de passe ne sont pas reconnus, merci de recommencer."
          );
        }
        return false;
      });
  };

  const logout = () => {
    return fetchUrl(postLogoutRequest())
      .then(() => {
        setCookie("isUserAlreadyLoggedIn", "false");
        setUser(null);
        notifySuccess("Déconnecté avec succès.");
      })
      .catch((error) => {
        console.error({ error });
        setCookie("isUserAlreadyLoggedIn", "false");
        setUser(null);
        /**
         * What happens if we get an error when we log out? Given that the server is supposed to delete the cookie.
         * Potentially if we have an error here the cookie might not be deleted.
         */
        notifyError("Une erreur s'est produite lors de la déconnexion.");
      });
  };

  const toggleRefetchUser = React.useCallback(() => {
    setRefetchUser(true);
  }, [setRefetchUser]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        login,
        logout,
        toggleRefetchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthProvider, AuthContext };

export const useAuth = () => React.useContext(AuthContext);
