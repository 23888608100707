import React from "react";
import classNames from "classnames";
import { notifyError } from "utils/notify";
import fetchUrl from "api/fetchUrl";
import { uploadMailAttachment } from "api/requests";
import Icon from "components/Icon";
import IconButton from "components/Button/IconButton";
import { v4 as uuidv4 } from "uuid";
import convertBytesToMb from "utils/convertBytesToMb";

/* 15 Mo */
export const FILE_MAX_SIZE_IN_BYTES = 15 * 1000000;

/* 15 Mo */
export const FILES_MAX_SIZE_IN_BYTES = 15 * 1000000;

export const UPLOAD_STATES = {
  uploading: "UPLOADING",
  uploaded: "UPLOADED",
  failed: "FAILED",
};

const ALLOWED_FILE_TYPES = [
  "application/pdf", // pdf
  "image/jpeg", // jpg
  "image/png", // png
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "text/csv",
];

const AttachmentUploader = ({
  files,
  setFiles,
  fileComponent,
  handleRemoveFile,
}) => {
  const totalFileSizeInMb = React.useMemo(() => {
    if (!files.some((file) => file.sizeInMb || file.sizeInBytes)) return false;

    const total = files
      .filter((file) => file.state === UPLOAD_STATES.uploaded)
      .reduce((total, file) => {
        return (total += file.sizeInMb);
      }, 0);

    return total;
  }, [files]);

  const someFilesAreBeingUploaded = React.useMemo(() => {
    return files.some((file) => file.state === UPLOAD_STATES.uploading);
  }, [files]);

  const handleChange = async (event) => {
    const previousFiles = [...files];
    const filteredNewFiles = Array.from(event.target.files)
      .filter((file) => {
        if (file.size > FILE_MAX_SIZE_IN_BYTES) {
          notifyError(
            `Le fichier ${file.name} est trop volumineux (maximum 15 Mo).`
          );

          return false;
        }

        if (!file.type) {
          notifyError(`Le fichier ${file.name} n'a pas d'extension.`);

          return false;
        }

        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
          notifyError(
            `L'extension du fichier ${file.name} ne fait pas partie de la liste des extensions autorisées.`
          );

          return false;
        }

        return true;
      })
      .map((file) => {
        return {
          file,
          id: uuidv4(),
          sizeInMb: convertBytesToMb(file.size),
          name: file.name,
          type: file.type,
          path: null,
          state: UPLOAD_STATES.uploading,
        };
      });

    const futureTotalSizeInBytes = [
      ...previousFiles,
      ...filteredNewFiles,
    ].reduce((total, { file, sizeInBytes }) => {
      if (file) {
        total += file.size;
      } else if (sizeInBytes) {
        total += sizeInBytes;
      }

      return total;
    }, 0);

    if (futureTotalSizeInBytes > FILES_MAX_SIZE_IN_BYTES) {
      notifyError(
        "La taille maximale cumulée de tous les fichiers ne peut pas excéder 15 Mo."
      );
      return;
    }

    const updatedFiles = [...previousFiles, ...filteredNewFiles];

    setFiles(updatedFiles);

    const results = await Promise.allSettled(
      filteredNewFiles.map(({ file, id }) => {
        return new Promise((resolve) => {
          const fileIndex = updatedFiles.findIndex((file) => file.id === id);

          fetchUrl(uploadMailAttachment(file))
            .then((filePath) => {
              resolve({
                ...updatedFiles[fileIndex],
                path: filePath,
                state: UPLOAD_STATES.uploaded,
              });
            })
            .catch(() => {
              notifyError(
                `Le fichier ${file.name} n'a pas pu être ajouté, une erreur est survenue lors du téléchargement.`
              );

              resolve({
                ...updatedFiles[fileIndex],
                state: UPLOAD_STATES.failed,
              });
            });
        });
      })
    );

    setFiles([
      ...previousFiles,
      ...results.map(({ value }) => {
        return value;
      }),
    ]);
  };

  return (
    <>
      {files.length > 0 ? (
        <div className="attachment-uploader-files">
          <div className="attachment-uploader__total-size">{`Total : ${
            totalFileSizeInMb ? `${totalFileSizeInMb.toFixed(2)} Mo` : "N/A"
          } `}</div>
          {files.map((file) => (
            <div key={`file_${file.id}`} className="attachment-uploader-file">
              <div className="attachment-uploader-file__name">
                {`${file.name} ${`${
                  file?.sizeInMb ? `(${file.sizeInMb.toFixed(2)} Mo)` : "(N/A)"
                }`} `}
              </div>
              <div className="attachment-uploader-file__state">
                {file.state === UPLOAD_STATES.uploading ? (
                  <Icon icon="spinner" />
                ) : file.state === UPLOAD_STATES.failed ? (
                  <Icon
                    icon="warning"
                    title="Le fichier n'a pas pu être ajouté, une erreur est survenue lors du téléchargement. Ce fichier ne sera pas inclus dans les piéces-jointes."
                  />
                ) : null}
              </div>
              {file.state !== UPLOAD_STATES.uploading ? (
                <div className="attachment-uploader-file__action">
                  <IconButton
                    className="delete-button"
                    icon="close"
                    iconSize="small"
                    onClick={() => handleRemoveFile(file.id)}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      {!someFilesAreBeingUploaded ? (
        <div className={classNames("attachment-uploader", {})}>
          {/** Setting value to empty array prevent the onChange to not being triggered if we try to upload the same file twice. */}
          <input multiple type="file" value={[]} onChange={handleChange} />
          <div className="controller">{fileComponent}</div>
        </div>
      ) : null}
    </>
  );
};

export default AttachmentUploader;
