import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import Icon from "../Icon";
import IconButton from "../Button/IconButton";
import { useAuth } from "components/Hooks/useAuth";

const MenuNavigationItem = ({
  disabled,
  to,
  leftBlock,
  rightBlock,
  closeMenu,
}) => {
  const location = useLocation();

  const handleCloseMenu = (e) => {
    if (disabled) {
      return e.preventDefault();
    }
    closeMenu();
  };

  const getRightBlock = () => {
    if (disabled) {
      return (
        <div className="right-block">
          <Icon icon="lock-close" size="small" />
        </div>
      );
    }

    if (rightBlock) {
      return <div className="right-block">{rightBlock}</div>;
    }

    return null;
  };

  return (
    <div
      className={classNames("menu-navigation-item", {
        "menu-navigation-item--disabled": disabled,
        "menu-navigation-item--active": to === location.pathname,
      })}
    >
      <NavLink
        exact
        to={to}
        onClick={handleCloseMenu}
        className="menu-navigation-item__link"
      >
        <div className="left-block">{leftBlock}</div>
        {getRightBlock()}
      </NavLink>
    </div>
  );
};

const Ripple = () => {
  return (
    <div className="ripple">
      <Icon icon="ripple" />
    </div>
  );
};

const MenuNavigation = ({ closeMenu }) => {
  const {
    user: { isMasterUser, people },
  } = useAuth();

  const hasSubUsers = people.agency.subscription.seats > 0;

  return (
    <div className="menu-navigation">
      <MenuNavigationItem
        to="/"
        leftBlock={
          <>
            <Icon icon="document" />
            Tableau de bord - les ventes
          </>
        }
        closeMenu={closeMenu}
      />
      <MenuNavigationItem
        disabled
        to="/statistics"
        leftBlock={
          <>
            <Icon icon="statistic-bold" />
            Statistiques
          </>
        }
        closeMenu={closeMenu}
      />
      <Ripple />
      <MenuNavigationItem
        to="/sales/new"
        leftBlock={
          <>
            <Icon icon="folder-bold" />
            Créer une vente
          </>
        }
        closeMenu={closeMenu}
      />
      {/*<MenuNavigationItem
        disabled
        to="/"
        leftBlock={
          <>
            <Icon icon="letter-close" />
            Centre de notifications
          </>
        }
        rightBlock={<span className="notification-circle">5</span>}
        closeMenu={closeMenu}
      />*/}
      <MenuNavigationItem
        to="/contact-directory"
        leftBlock={
          <>
            <Icon icon="agenda" />
            Répertoire clients & notaires
          </>
        }
        closeMenu={closeMenu}
      />
      {isMasterUser && (
        <>
          <Ripple />
          <MenuNavigationItem
            to="/settings"
            leftBlock={
              <>
                <Icon icon="clock" />
                Paramètres alertes
              </>
            }
            closeMenu={closeMenu}
          />
          <MenuNavigationItem
            disabled={!hasSubUsers}
            to="/users"
            leftBlock={
              <>
                <Icon icon="gear" />
                Gestion des utilisateurs
              </>
            }
            closeMenu={closeMenu}
          />
          <MenuNavigationItem
            to="/user-area"
            leftBlock={
              <>
                <Icon icon="lock-open" />
                Espace client
              </>
            }
            closeMenu={closeMenu}
          />
        </>
      )}
    </div>
  );
};

const MenuClose = ({ closeMenu }) => {
  return (
    <div className="menu__close">
      <IconButton onClick={closeMenu} icon="close" iconSize="small" />
    </div>
  );
};

const MenuHeader = () => {
  return (
    <div className="menu__header">
      <Icon icon="logo" />
      <Icon icon="wave" size="medium" />
    </div>
  );
};

const MenuContent = ({ closeMenu }) => {
  return (
    <div className="menu__content">
      <MenuNavigation closeMenu={closeMenu} />
    </div>
  );
};

const Menu = () => {
  const [opened, setOpened] = React.useState(false);

  const openMenu = () => setOpened(true);

  const closeMenu = () => setOpened(false);

  return (
    <>
      <div className="menu-button-container">
        <IconButton noPadding icon="menu" onClick={openMenu}>
          menu
        </IconButton>
      </div>
      <div
        onClick={closeMenu}
        className={classNames("menu-overlay", {
          "menu-overlay--opened": opened,
        })}
      ></div>
      <div className={classNames("menu", { "menu--opened": opened })}>
        <MenuClose closeMenu={closeMenu} />
        <MenuHeader />
        <MenuContent closeMenu={closeMenu} />
      </div>
    </>
  );
};

export default Menu;
