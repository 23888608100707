import React from "react";
import SaleProgress from "../SaleProgress";
import { SALE_TYPES } from "../SaleMultistep/saleConstants";

const { INTER_AGENCY, REGULAR } = SALE_TYPES;

const SaleHeader = ({ currentStep, saleType, isMasterUser }) => {
  const getProgressMatrice = () => {
    if (saleType === REGULAR) {
      if (isMasterUser) {
        return [0, 25, 50, 70, 90, 95];
      }
      return [0, 25, 50, 70, 90];
    }

    if (saleType === INTER_AGENCY) {
      if (isMasterUser) {
        return [0, 20, 25, 50, 70, 90, 95];
      }
      return [0, 20, 25, 50, 70, 90];
    }
  };

  const progressByStep = getProgressMatrice();

  return (
    <div className="sale-header">
      {
        /** User has not yet selected a sale type */
        !saleType ? (
          <div className="sale-header__encouragement">
            {"ALLEZ C'EST PARTI !"}
          </div>
        ) : (
          <SaleProgress progress={progressByStep[currentStep]} />
        )
      }
    </div>
  );
};

export default SaleHeader;
