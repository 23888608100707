import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import Icon from "components/Icon";

const getButtonFunctions = ({
  typeOfSelection,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
}) => {
  if (typeOfSelection === "month" || typeOfSelection === "year") {
    return { prev: decreaseYear, next: increaseYear };
  }

  return {
    prev: decreaseMonth,
    next: increaseMonth,
  };
};

const getFormattedHeaderDate = ({ typeOfSelection, date }) => {
  if (typeOfSelection === "month" || typeOfSelection === "year") {
    return format(date, "yyyy");
  }
  return format(date, "LLL yyyy", {
    locale: fr,
  });
};

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  typeOfSelection,
  onClick = null,
}) => {
  const headerDate = getFormattedHeaderDate({ typeOfSelection, date });
  const { prev, next } = getButtonFunctions({
    typeOfSelection,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
  });

  return (
    <header className="react-datepicker__header--top">
      {typeOfSelection !== "month" && (
        <button type="button" onClick={prev} disabled={prevMonthButtonDisabled}>
          <span className="prev">
            <Icon icon="chevron-left" />
          </span>
        </button>
      )}
      <div
        className={
          typeof onClick === "function"
            ? "react-datepicker__current-month react-datepicker__current-month--button"
            : "react-datepicker__current-month"
        }
        onClick={onClick}
      >
        {headerDate}
      </div>
      {typeOfSelection !== "month" && (
        <button type="button" onClick={next} disabled={nextMonthButtonDisabled}>
          <span className="next">
            <Icon icon="chevron-right" />
          </span>
        </button>
      )}
    </header>
  );
};

CustomHeader.propTypes = {
  date: PropTypes.instanceOf(Date),
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  decreaseYear: PropTypes.func,
  increaseYear: PropTypes.func,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  typeOfSelection: PropTypes.string,
  onClick: PropTypes.func,
};

export default CustomHeader;
