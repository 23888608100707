import React from "react";
import PropTypes from "prop-types";
import { InputDate } from "components/Form/Input";
import { format } from "date-fns";

const StatisticsMonthSelect = ({ values, setSelectedMonthAndYear }) => {
  React.useEffect(() => {
    if (values.date) {
      setSelectedMonthAndYear(format(new Date(values.date), "MM/yyyy"));
    }
  }, [values, setSelectedMonthAndYear]);

  return (
    <div className="statistics-monthselect">
      <div className="select-wrapper">
        <InputDate
          name="date"
          placeholder="Sélectionner un mois"
          dateFormat="LLLL yyyy"
          type="month"
          defaultValue={new Date()}
        />
      </div>
    </div>
  );
};

StatisticsMonthSelect.propTypes = {
  values: PropTypes.object.isRequired,
  setSelectedMonthAndYear: PropTypes.func.isRequired,
};

export default StatisticsMonthSelect;
