import React from "react";
import { useFormContext } from "react-hook-form";
import {
  format,
  getMonth,
  getYear,
  getDay,
  parse,
  isDate,
  addMinutes,
} from "date-fns";
import { fr } from "date-fns/locale";
import DatePicker from "react-datepicker";
import useClickOutside from "utils/clickOutside";
import { Dialog, DialogContent } from "components/Dialog";
import CustomHeader from "./CustomeHeader";
import CustomInput from "./CustomInput";

const getOpenDate = (selectedMonth, selectedYear, date) => {
  if (!selectedYear && !selectedMonth) {
    return date;
  }

  if (!selectedYear) {
    return new Date(getYear(date), getMonth(selectedMonth), getDay(date));
  }

  if (!selectedMonth) {
    return new Date(getYear(selectedYear), getMonth(date), getDay(date));
  }

  return new Date(getYear(selectedYear), getMonth(selectedMonth), getDay(date));
};

const popperModifiers = [
  {
    name: "offset",
    options: {
      offset: [0, -25],
    },
  },
];

const getDate = (value) => {
  if (!value) {
    return new Date();
  }

  const date = isDate(value)
    ? value
    : parse(value, "yyyy-MM-dd", new Date(), { locale: fr });

  /**
   * We remove timezone offset (-2 for Paris) to have a date to midnight after parsed to UTC
   *
   * Without addMinutes :
   *  - Sun Oct 17 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale) BECOME 2021-10-16T22:00:00.000Z
   * With addMinutes
   *  - Sun Oct 17 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale BECOME 2021-10-17T00:00:00.000Z
   */
  const timezoneOffset = date.getTimezoneOffset();
  return addMinutes(date, -timezoneOffset);
};

const getCustomInputValue = (defaultValue, value, date, dateFormat) => {
  const useDateFormat = dateFormat || "dd/MM/yyyy";
  const locale = { locale: fr };

  if (!value && !defaultValue) {
    return null;
  }

  return format(date || defaultValue, useDateFormat, locale);
};

const InputDate = ({
  name,
  placeholder,
  disabled,
  dateFormat,
  defaultValue = null,
  type = "day",
  maxDate,
}) => {
  const {
    values: { [name]: value },
    formState: { errors },
    setValue,
  } = useFormContext();

  const date = getDate(value);
  const inputDateError = { ...errors }[name];
  const setDate = (date) => setValue(name, date);

  const nodeInput = React.useRef();
  const nodeDatePicker = React.useRef();
  const [typeOfSelection, seTypeOfSelection] = React.useState();
  const [selectedMonth, setSelectedMonth] = React.useState(null);
  const [selectedYear, setSelectedYear] = React.useState(null);
  useClickOutside([nodeInput, nodeDatePicker], typeOfSelection !== null, () => {
    if (value) {
      setDate(date);
      setSelectedMonth(date);
      setSelectedYear(date);
    }
    seTypeOfSelection(null);
  });

  return (
    <div className="input-date">
      <div ref={nodeInput}>
        <CustomInput
          value={getCustomInputValue(defaultValue, value, date, dateFormat)}
          onClick={() => seTypeOfSelection(type)}
          placeholder={placeholder}
          disabled={disabled}
          error={inputDateError}
        />
      </div>

      <Dialog
        open={typeOfSelection}
        handleClose={() => seTypeOfSelection(null)}
        className="input-date-dialog"
      >
        <DialogContent>
          {" "}
          {typeOfSelection === "day" && (
            <DatePicker
              {...(maxDate && { maxDate })}
              disabledKeyboardNavigation
              inline
              locale="fr"
              showPopperArrow={false}
              selected={date}
              openToDate={getOpenDate(selectedMonth, selectedYear, date)}
              onChange={(date) => {
                setDate(date);
                setSelectedMonth(date);
                setSelectedYear(date);
                seTypeOfSelection(null);
              }}
              renderCustomHeader={(props) => (
                <CustomHeader
                  {...props}
                  onClick={() => {
                    seTypeOfSelection("month");
                  }}
                />
              )}
              popperModifiers={popperModifiers}
              formatWeekDay={(date) => {
                return date.substring(0, 3);
              }}
            />
          )}
          {typeOfSelection === "month" && (
            <DatePicker
              {...(maxDate && { maxDate })}
              disabledKeyboardNavigation
              renderCustomHeader={(props) => (
                <CustomHeader
                  {...props}
                  typeOfSelection={typeOfSelection}
                  onClick={() => {
                    seTypeOfSelection("year");
                  }}
                />
              )}
              openToDate={getOpenDate(selectedMonth, selectedYear, date)}
              selected={selectedMonth}
              onChange={(dateMonth) => {
                const newDate = getOpenDate(dateMonth, selectedYear, date);
                setSelectedMonth(newDate);
                setSelectedYear(newDate);

                if (type === "month") {
                  setDate(newDate);
                  seTypeOfSelection(null);
                } else {
                  seTypeOfSelection("day");
                }
              }}
              inline
              locale="fr"
              popperModifiers={popperModifiers}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          )}
          {typeOfSelection === "year" && (
            <DatePicker
              {...(maxDate && { maxDate })}
              disabledKeyboardNavigation
              renderCustomHeader={(props) => (
                <CustomHeader {...props} typeOfSelection={typeOfSelection} />
              )}
              selected={selectedYear || date}
              onChange={(dateYear) => {
                const newDate = getOpenDate(selectedMonth, dateYear, date);
                setSelectedYear(newDate);
                setSelectedMonth(newDate);

                if (type === "year") {
                  setDate(newDate);
                  seTypeOfSelection(null);
                } else {
                  seTypeOfSelection("month");
                }
              }}
              inline
              locale="fr"
              popperModifiers={popperModifiers}
              showYearPicker
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InputDate;
