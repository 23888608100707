import React from "react";
import PropTypes from "prop-types";

const DashboardHeader = ({ salesNumber = 0, headerTitle }) => {
  const usePlural = salesNumber > 1;

  return (
    <div className="dashboard-header">
      <span className="dashboard-header__number">{salesNumber} </span>
      <span className="dashboard-header__text">
        {usePlural ? "ventes" : "vente"}
        <div>{headerTitle}</div>
      </span>
    </div>
  );
};

DashboardHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  salesNumber: PropTypes.number,
};

export default DashboardHeader;
