const convertDateToFormDate = (stringDate) => {
  if (!stringDate) {
    return "";
  }
  const leftPad = (s) => (s < 10 ? "0" + s : s);
  const date = new Date(stringDate);
  return [
    date.getFullYear(),
    leftPad(date.getMonth() + 1),
    leftPad(date.getDate()),
  ].join("-");
};

export { convertDateToFormDate };
