import * as yup from "yup";

const DEFAULT_REQUIRED_MESSAGE = "Veuillez remplir toutes les cases";

const DEFAULT_NUMBER_MESSAGE = "Veuillez rentrer des nombres uniquement";

const validationSchema = yup.object({
  loanSaleFileIsComplete: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  cashSaleFileIsComplete: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanBuyerKnowPropertyFiles: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  cashBuyerKnowPropertyFiles: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanPlanSignatureCompromise: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  cashPlanSignatureCompromise: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanNotaryNotifySRU: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  cashNotaryNotifySRU: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanBankFileDeposit: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanBankOfferReception: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanCompleteSalesFile: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  cashCompleteSalesFile: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  loanSignaturePlanned: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
  cashSignaturePlanned: yup
    .number(DEFAULT_NUMBER_MESSAGE)
    .required(DEFAULT_REQUIRED_MESSAGE),
});

export default validationSchema;
