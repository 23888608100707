import * as yup from "yup";

const validationSchema = yup.object({
  signDate: yup
    .string()
    .required("La date de signature de l'avant contrat est obligatoire."),
  signTime: yup
    .string()
    .required("L'heure de signature de l'avant contrat est obligatoire."),
});

export default validationSchema;
