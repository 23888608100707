import React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import PropTypes from "prop-types";
import useYupValidationResolver from "./utils/useYupValidationResolver";
import notifyFormErrors from "./utils/handleErrors";

const Form = ({
  defaultValues,
  children,
  onSubmit,
  validationSchema,
  mode = "onSubmit",
  reValidateMode = "all",
  shouldFocusError = false,
  ...formProps
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const { setValue, reset, ...methods } = useForm({
    defaultValues,
    resolver,
    mode,
    reValidateMode: "all",
    shouldFocusError,
  });

  /** We make sure to read formState before render to subscribe to the state update (Proxy). */
  const { errors, isDirty } = methods.formState;

  /**
   * useWatch is used to spy on state update and to prevent having to use getValues each time we need to retrieve values
   * this is the heart of our form system, multi-step without submit would not work without it because React Hook Form prevent
   * re-renders on input changes (it only get the form values on submit or when using getValues()).
   */
  const values = useWatch({ control: methods.control });

  const hasErrors = (errors) => Object.keys(errors).length > 0;

  React.useEffect(() => {
    if (!hasErrors(errors)) {
      return;
    }
    notifyFormErrors(errors);
  }, [errors]);

  React.useEffect(() => {
    /**
     * Allow us to override the default behavior of default values which is
     * "defaultValues are cached on the first render within the custom hook. If you want to reset the defaultValues, you should use the reset api."
     * We don't want to reset our entire form when changing defaultValues.
     * This also provide us a way to set form values from outside the form provider, which is great !
     */
    if (typeof defaultValues === "object") {
      Object.keys(defaultValues).map((key) =>
        setValue(key, defaultValues[key])
      );
    }
  }, [defaultValues, setValue]);

  React.useEffect(() => {
    /** We use reset() here because we want to reload the validation schema when it changes */
    reset({}, { keepValues: true });
  }, [validationSchema, reset]);

  return (
    <FormProvider
      values={values}
      reset={reset}
      setValue={setValue}
      isDirty={isDirty}
      {...methods}
    >
      {process.env.REACT_APP_FORM_DEBUG && (
        <pre>{JSON.stringify(values, null, 2)}</pre>
      )}
      <form
        onSubmit={methods.handleSubmit((submitValues, event) => {
          onSubmit(
            submitValues,
            { ...methods, values, reset, setValue, isDirty },
            event
          );
        })}
        {...formProps}
      >
        {typeof children === "function"
          ? children({ ...methods, values, reset, setValue, isDirty })
          : children}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  mode: PropTypes.string,
};

export default Form;
