import ModuleDialog from "../ModuleDialog";
import { DialogContent, DialogActions } from "components/Dialog";
import Button from "components/Button";
import Text from "components/Text";
import { useSaleModules } from "pages/SaleProcess/useSaleModules";
import { getAvailableRecipients, TEMPLATE_REFERENCES } from "utils/email";

const SignDateConfirmationEmailDialog = ({
  open,
  setOpen,
  isInterAgency,
  isEdit,
}) => {
  const { setMailModuleOpen } = useSaleModules();
  const templateUsed = isEdit
    ? TEMPLATE_REFERENCES.editDateOfSignature
    : TEMPLATE_REFERENCES.confirmationDateOfSignature;

  return (
    <ModuleDialog
      title="Envoyer un email"
      icon="emails"
      open={open}
      handleClose={() => setOpen(false)}
      className="send-confirmation-email-dialog"
    >
      <DialogContent>
        <Text font="roboto-slab" align="center">
          Souhaitez-vous envoyer un email de confirmation&nbsp;?
        </Text>
      </DialogContent>
      <DialogActions>
        <Button fullWidth appearance="ghost" onClick={() => setOpen(false)}>
          Ignorer
        </Button>

        <Button
          fullWidth
          appearance="primary"
          boxShadow={false}
          onClick={() => {
            setOpen(false);
            setMailModuleOpen({
              open: true,
              template: templateUsed.value,
              recipients: getAvailableRecipients({
                hasPartnerAgency: isInterAgency,
              }),
            });
          }}
        >
          Envoyer mail
        </Button>
      </DialogActions>
    </ModuleDialog>
  );
};

export default SignDateConfirmationEmailDialog;
